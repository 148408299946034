import React, { Component } from 'react';

import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import RefreshIcon from 'mdi-react/RefreshIcon';
import Select from 'react-select';
import swal from 'sweetalert';
import Alert from '../../../shared/components/Alert';
import EditIcon from 'mdi-react/EditIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import AlertIcon from 'mdi-react/AlertIcon';
const apiurl = MY_API_URL;
import {
	Button,
	Modal,
	Badge
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',

	token: localStorage.token,
	options: [],
	// question: [],
	questionSelected: {},
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	duration: 0,
	quesidslen: 0,
	newdiflevel: '',
};
class CheckQuestions extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.audittechid) {
			this.fetchQuestions(localStorage.audittechid);
		} else {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleCkey = () => {
		this.setState({
			upSingleModal: false,
			quesid: 0,
			question: '',
			optionA: '',
			optionB: '',
			optionC: '',
			optionD: '',
			correctAnswer: '',
			diflevel: '',
		});
	};
	handleSingle = (quesid, question, optiona, optionb, optionc, optiond, ans, dlevel, duration) => {
		this.setState({
			upSingleModal: true,
			quesid: quesid,
			question: question,
			optionA: optiona,
			optionB: optionb,
			optionC: optionc,
			optionD: optiond,
			correctAnswer: ans,
			diflevel: dlevel,
			duration: duration,
		});
	};

	handleSingleSubmit = (event) => {

		// alert(event);
		let optionss = [];
		optionss.push(this.state.optionA);
		optionss.push(this.state.optionB);
		optionss.push(this.state.optionC);
		optionss.push(this.state.optionD);
		console.log('OPTIONS ARRAY IS ', optionss);
		this.setState(
			{
				options: [...optionss],
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addsqurl = apiurl + 'techeditappQuestion';
				var postData = {
					technology: localStorage.audittechid,
					options: [...this.state.options],
					userid: localStorage.usercode,
					question: this.state.question,
					answer: this.state.correctAnswer,
					diflevel: this.state.diflevel,
					duration: this.state.duration,
					quesid: this.state.quesid,
					aevent: event,
				};
				axios
					.post(addsqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'danger',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{

									upSingleModal: false,
									showAlert: false,
									question: '',
									options: [],
									optionA: '',
									optionB: '',
									optionC: '',
									optionD: '',
									quesid: '',
									correctAnswer: '',
									duration: '',
								},
								() => {
									this.fetchQuestions();
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			},
		);
	};


	fetchQuestions = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			userid: localStorage.usercode,
			technology: localStorage.audittechid,

		};
		const getfqurl = apiurl + 'techquestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						questions: [...response.data.data],
						mycheckedques: [...response.data.mycheckedques],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleQuestionChange(editor) { //eslint-disable-line
		this.setState({ question: editor });
	}
	handleOptionAChange(editor) { //eslint-disable-line		 
		this.setState({ optionA: editor });
	}
	handleOptionBChange(editor) { //eslint-disable-line		 
		this.setState({ optionB: editor });
	}
	handleOptionCChange(editor) { //eslint-disable-line		 
		this.setState({ optionC: editor });
	}
	handleOptionDChange(editor) { //eslint-disable-line		 
		this.setState({ optionD: editor });
	}





	handleDelQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Report',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techdelQnew';
				var postData = {
					quesid: quesid,
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.fetchQuestions();
							swal(response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleAppQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Approve',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techappQnew';
				var postData = {
					quesid: quesid,
					newdiflevel: this.state['level' + quesid],
					newduration: this.state['time' + quesid],
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.setState({
								newdiflevel: ''
							}, () => {
								this.fetchQuestions();
								swal(response.data.message, {
									icon: 'success',
								});
							});

						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};

	handleSelectDFChange = (selectedOption) => {
		let cdifflevel = selectedOption.value;
		this.setState({ diflevel: cdifflevel });
	};
	handleSelectANSChange = (selectedOption) => {
		let correctAnswer = selectedOption.value;
		this.setState({ correctAnswer: correctAnswer });
	};
	handleSelectDURChange = (selectedOption) => {
		let duration = selectedOption.value;
		this.setState({ duration: duration });
	};
	handleSelectNewChange = (quesid, selectedOption) => {
		console.log('quesid', quesid);
		console.log('selectedOption', selectedOption);
		let cdifflevel = selectedOption.value;
		this.setState({ ['level' + quesid]: cdifflevel }, () => {
			console.log('this.state[level+quesid]', this.state['level' + quesid]);
		});
	};
	handleSelectTimeChange = (quesid, selectedOption) => {
		console.log('quesid', quesid);
		console.log('selectedOption', selectedOption);
		let cdifftime = selectedOption.value;
		this.setState({ ['time' + quesid]: cdifftime }, () => {
			console.log('this.state[time+quesid]', this.state['time' + quesid]);
		});
	};

	render() {
		const { quesid, question, optionA, optionB, optionC, optionD, diflevel, correctAnswer, duration, tinymceapikey } = this.state; //eslint-disable-line
		const ansoptions = [{ value: 0, label: 'Option A' }, { value: 1, label: 'Option B' }, { value: 2, label: 'Option C' }, { value: 3, label: 'Option D' }];

		const difflevels = [{ value: 'Beginner', label: 'Beginner' }, { value: 'Intermediate', label: 'Intermediate' }, { value: 'Advanced', label: 'Advanced' }];

		const timelevels = [{ value: '0.5', label: '30 Sec' }, { value: '1', label: '1min' }, { value: '1.5', label: '1m:30s' }, { value: '2', label: '2min' }, { value: '2.5', label: '2m:30s' }, { value: '3', label: '3min' }, { value: '3.5', label: '3m:30s' }, { value: '4', label: '4min' }, { value: '4.5', label: '4m:30s' }, { value: '5', label: '5min' }];

		// console.log('Options are ', options);
		return (
			<div className="col-md-12">
				<div className="row">



					<div className="col-md-6">
						<div className="grid card">
							<div className="card-body">
								{localStorage.audittechid ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.fetchQuestions.bind(this)}
									>
										<strong style={{ fontSize: '18px' }}><RefreshIcon /> {this.state.questions && this.state.questions.length}</strong>	Refresh  Questions
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Refresh   Questions of {localStorage.audittechname}
									</Button>

								)}


							</div>
						</div>
					</div>
					{this.state.questions && this.state.questions.length >= 1 ? (
						<div className="col-md-6">
							<div className="grid card">
								<div className="card-body">
									<h5 className="bold-text">Questions of {localStorage.audittechname}

									</h5>

								</div>
							</div>
						</div>
					) : null}


				</div>

				<div className="row">
					<div className="col-md-6">
						{this.state.questions &&
							this.state.questions.map((ques, index) => {
								var mquestion = ques.question;
								var moptiona = ques.options[0];
								var moptionb = ques.options[1];
								var moptionc = ques.options[2];
								var moptiond = ques.options[3];
								var newdiffvalue = this.state['level' + ques.id] != '' ? this.state['level' + ques.id] : ques.difficulty_level;
								var newtimevalue = this.state['time' + ques.id] != '' ? this.state['time' + ques.id] : ques.duration;
								return (
									<div className="col-md-12" key={index}>
										<div className="row">
											<div className="col-md-12">
												<div className="grid card">
													<div className="card-body">
														<h5
															className="col-md-12 bold-text"
															style={{ position: 'relative', paddingLeft: '20px' }}
														>
															<span className="cusquestionic">Q:{index + 1}</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + ques.question + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}

															/>
														</h5>
														<div className="clearfix"></div>
														<div className="row">
															<div
																className={
																	ques.correct == 0
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">A:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + ques.options[0] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>


															<div
																className={
																	ques.correct == 1
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">B:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + ques.options[1] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>



															<div className="clearfix"></div>
															<div
																className={
																	ques.correct == 2
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">C:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + ques.options[2] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>

															<div className="clearfix"></div>
															<div
																className={
																	ques.correct == 3
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">D:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + ques.options[3] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>


															<div className="clearfix"></div>
															<div className="col-md-12" style={{ marginTop: '15px' }}>
																<div className="row">
																	<div className="col-md-4">
																		<Select
																			name="newdiflevel"
																			value={difflevels.filter(
																				(option) => option.value == newdiffvalue,
																			)}
																			onChange={this.handleSelectNewChange.bind(this, ques.id)}
																			options={difflevels}
																			clearable={false}
																			placeholder={ques.difficulty_level}
																			// defaultMenuIsOpen={true}
																			// isDisabled={true}
																			style={{ fontSize: '9px' }}
																		/>
																	</div>
																	<div className="col-md-4">
																		<Select
																			name="duration"
																			value={timelevels.filter(
																				(option) => option.value == newtimevalue,
																			)}
																			onChange={this.handleSelectTimeChange.bind(this, ques.id)}
																			options={timelevels}
																			clearable={false}
																			placeholder={ques.duration}
																		// defaultMenuIsOpen={true}
																		// isDisabled={true}
																		/>
																	</div>
																	<div className="col-md-4">
																		<div className="row">
																			<div className="col-md-4">
																				<span onClick={this.handleSingle.bind(this, ques.id, mquestion, moptiona, moptionb, moptionc, moptiond, ques.correct, ques.difficulty_level, ques.duration)} style={{ color: 'blue', cursor: 'pointer' }} ><EditIcon /></span>

																			</div>

																			<div className="col-md-4">
																				<span style={{ color: 'red', cursor: 'pointer' }}
																					onClick={this.handleDelQues.bind(this, ques.id)}
																				>
																					<AlertIcon />
																				</span>
																			</div>

																			<div className="col-md-4">
																				<span style={{ color: 'green', cursor: 'pointer' }}

																					onClick={this.handleAppQues.bind(this, ques.id)}
																				>
																					<CheckIcon />
																				</span>

																			</div>

																		</div>

																	</div>
																</div>
															</div>
														</div>



													</div>
												</div>
											</div>
										</div>

									</div>
								);
							})}
					</div>
					<div className="col-md-6">
						{/* My Checked Questions start */}

						{this.state.mycheckedques &&
							this.state.mycheckedques.map((myques, index) => {
								var backgrnd = 'card-body border border-success';
								if (myques.status == 'Reported') {
									backgrnd = 'card-body border border-warning';
								} else if (myques.status == 'Edited') {
									backgrnd = 'card-body border border-primary';
								}

								return (
									<div className="col-md-12" key={index}>
										<div className="row">
											<div className="col-md-12">
												<div className="grid card">
													<div className={backgrnd} style={{ position: 'relative' }}>

														<div style={{ position: 'absolute', left: '10px', top: '5px' }}>
															<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="primary">
																{myques.difficulty_level}{' '}
															</Badge>
															<Badge style={{ marginRight: '20px', fontSize: '12px' }} color="success">
																{myques.duration}{' '}
															</Badge>
														</div>

														<h5
															className="col-md-12 bold-text"
															style={{ position: 'relative', paddingLeft: '10px' }}
														>
															<span className="cusquestionic">Q:{index + 1}</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + myques.question + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}

															/>
														</h5>
														<div className="clearfix"></div>

														<div className="row">
															<div
																className={
																	myques.correct == 0
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">A:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + myques.options[0] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>


															<div
																className={
																	myques.correct == 1
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">B:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + myques.options[1] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>



															<div className="clearfix"></div>
															<div
																className={
																	myques.correct == 2
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">C:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + myques.options[2] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>

															<div className="clearfix"></div>
															<div
																className={
																	myques.correct == 3
																		? 'col-md-12 mycusoptionsuccess'
																		: 'col-md-12 mycusoptions'
																}
																style={{ position: 'relative' }}
															>
																<span className="cusoptionic">D:</span>

																<Editor
																	apiKey={TINYMCEAPIKEY}
																	value={'<p>' + myques.options[3] + '</p>'}
																	disabled={true}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																/>
															</div>


															<div className="clearfix"></div>
														</div>



													</div>
												</div>
											</div>
										</div>

									</div>
								);
							})}
					</div>
				</div>
				<Modal
					isOpen={this.state.upSingleModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary modal-dialog-big "
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">
							Edit Question of {localStorage.audittechname}
						</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"


						>
							<div className="form__form-group">
								<span className="form__form-group-label">Question</span>
								<div className="form__form-group-field">
									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + question + '</p>'}
										init={{
											inline: true,
											height: 70,
											menubar: false,
											plugins: [
												'advlist autolink lists link image',
												'charmap print preview anchor help',
												'searchreplace visualblocks code',
												'insertdatetime media table paste wordcount',
											],
											toolbar: '',
										}}
										onEditorChange={this.handleQuestionChange.bind(this)}

									/>
								</div>
							</div>



							<div className="form__form-group">
								<span className="form__form-group-label">Option A</span>
								<div className="form__form-group-field">
									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + optionA + '</p>'}
										init={{
											inline: true,
											height: 70,
											menubar: false,
											plugins: [
												'advlist autolink lists link image',
												'charmap print preview anchor help',
												'searchreplace visualblocks code',
												'insertdatetime media table paste wordcount',
											],
											toolbar: '',
										}}
										onEditorChange={this.handleOptionAChange.bind(this)}

									/>
								</div>
							</div>
							<div className="form__form-group">
								<span className="form__form-group-label">Option B</span>
								<div className="form__form-group-field">
									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + optionB + '</p>'}
										init={{
											inline: true,
											height: 70,
											menubar: false,
											plugins: [
												'advlist autolink lists link image',
												'charmap print preview anchor help',
												'searchreplace visualblocks code',
												'insertdatetime media table paste wordcount',
											],
											toolbar: '',
										}}
										onEditorChange={this.handleOptionBChange.bind(this)}

									/>
								</div>
							</div>
							<div className="form__form-group">
								<span className="form__form-group-label">Option C</span>
								<div className="form__form-group-field">
									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + optionC + '</p>'}
										init={{
											inline: true,
											height: 70,
											menubar: false,
											plugins: [
												'advlist autolink lists link image',
												'charmap print preview anchor help',
												'searchreplace visualblocks code',
												'insertdatetime media table paste wordcount',
											],
											toolbar: '',
										}}
										onEditorChange={this.handleOptionCChange.bind(this)}

									/>
								</div>
							</div>
							<div className="form__form-group">
								<span className="form__form-group-label">Option D</span>
								<div className="form__form-group-field" style={{ height: '100px !important' }}>
									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + optionD + '</p>'}
										init={{
											inline: true,
											height: 70,
											menubar: false,
											plugins: [
												'advlist autolink lists link image',
												'charmap print preview anchor help',
												'searchreplace visualblocks code',
												'insertdatetime media table paste wordcount',
											],
											toolbar: '',
										}}
										onEditorChange={this.handleOptionDChange.bind(this)}

									/>
								</div>
							</div>
							<div className="col-md-12">
								<br />
							</div>


							<div className="row" style={{ width: '100%' }}>
								<div className="col-md-12">
									<br />
								</div>
								<div className="col-md-2">
									<div className="form__form-group">

										<div className="form__form-group-field">
											<Select
												name="duration"
												value={timelevels.filter(
													(option) => option.value == duration,
												)}
												onChange={this.handleSelectDURChange}
												options={timelevels}
												clearable={false}
												placeholder="Duration"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-2">
									<div className="form__form-group">

										<div className="form__form-group-field">
											<Select
												name="difflevel"
												value={difflevels.filter(
													(option) => option.value == diflevel,
												)}
												onChange={this.handleSelectDFChange}
												options={difflevels}
												clearable={false}
												placeholder="Difficulty Level"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-2">
									<div className="form__form-group">

										<div className="form__form-group-field">
											<Select
												name="correctAnswer"
												value={ansoptions.filter(
													(option) => option.value == correctAnswer,
												)}
												onChange={this.handleSelectANSChange}
												options={ansoptions}
												clearable={false}
												placeholder="Correct Answer"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>

										</div>
									</div>
								</div>
								<div className="col-md-4">
									<span className="btn btn-success  float-right"
										onClick={() => this.handleSingleSubmit('Approved')}
									>
										Approve
									</span>
								</div>




							</div>
						</form>
					</div>
				</Modal>
			</div >
		);
	}
}

export default CheckQuestions;
