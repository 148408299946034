import React, { Component } from 'react';

import PropTypes from 'prop-types';
// import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	justLogout = () => {
		//localStorage.clear();
		// eslint-disable-next-line
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					{/* <SidebarLink
						title="Profile"
						icon="user"
						route="/profile"
						onClick={this.hideSidebar}
					/> */}
					{/* <SidebarLink
						title="Take Test"
						icon="list"
						route="/taketest"
						onClick={this.hideSidebar}
					/> */}
					{/* <SidebarLink title="Log Out" icon="exit" onClick={this.justLogout} /> */}
				</ul>
				{/* <ul className="sidebar__block">
					<SidebarCategory title="Example Pages" icon="diamond">
						<SidebarLink
							title="Page one"
							route="/pages/one"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Page two"
							route="/pages/two"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
				</ul> */}
			</div>
		);
	}
}

export default SidebarContent;
