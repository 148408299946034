import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

import SearchIcon from 'mdi-react/SearchIcon';

import swal from 'sweetalert';
const apiurl = MY_API_URL;

import {
	Table,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	technologies: [],
	selectedTechnologies: [],
	technologyToSearch: '',
	difficulty: 'Beginner',
	responseQuestions: [],
	questionQuantity: [],
	difficultyLevels: [],
	gotCount: [],
	email: '',
	emails: [],
	technologyWise: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	upExcelModal: false,
	upSingleModal: false,
	token: localStorage.token,
	options: [],
	// question: [],
	questionSelected: {},
	technologySelected: '',
	question: '', //eslint-disable-line
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	currentcolor: [
		'#000000',
	],
	content_add: '',
	myItems: [],
	testEmails: [],
};
class MyQuestions extends Component {
	constructor(props) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.lastId = -1;
	}
	state = { ...initialstate };
	componentDidMount() {
		this.setState({ myItems: [], testEmails: [] });
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			// eslint-disable-next-line
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeys(localStorage.token);
		}
	}
	handleFocus(event) { //eslint-disable-line
		this.setState({ content_add: '' });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		this.setState({ content_add: usr_input });
	};
	handleAddEmail(event) {
		event.preventDefault();
		console.log(this.state.content_add);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		var currentcontent = this.state.content_add.trim();
		if (!currentcontent) {
			return;
		}
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			testemail: currentcontent,
			testid: this.state.selectedId,
		};
		const testidurl = apiurl + 'checktestuser';
		axios
			.post(testidurl, postData, {
				headers: headers,
			})
			.then(async (response) => {
				console.log(response);
				if (response.data.data[1].length >= 1) {
					var areusers = response.data.data[1].toString();
					console.log(areusers);
					swal('Are users ' + areusers, {
						icon: 'error',
					});
				}
				if (response.data.data[0].length >= 1) {
					const allemails = response.data.data[0];
					let isexist = [];
					await Promise.all(
						allemails.map((temails) => {
							return new Promise((resolve) => {
								this.state.myItems.filter((listitem) => {
									if (listitem.content == temails) {
										isexist.push(temails);
									}
								});
								// console.log('isexist ', isexist.length);

								if (isexist.length >= 1) {
									resolve('');
								} else {
									newArray.push({
										content: temails,
										id: ++this.lastId,
									});
									testEmails.push(temails);
									this.setState(
										{
											myItems: newArray,
											content_add: '',
										},
										() => {
											console.log(this.state.myItems);
											resolve('');
										},
									);
								}
							});
						}),
					);
					if (isexist.length >= 1) {
						isexist = isexist.toString();
						swal(isexist + ' Already Added', {
							icon: 'error',
						});
					}
				}
			});
	}

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			console.log('items ', this.state.myItems);
			console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	handleGetkeys = (token) => { //eslint-disable-line
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'allmyqueskeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	render() {

		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies</h5>

								<form className="form">
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<SearchIcon />
											</div>
											<input
												name="tkey"
												type="text"
												placeholder="Search"
												onChange={(e) => {
													this.handleSearch(e);
												}}
											/>
										</div>
									</div>
								</form>
								{/* technologies here */}
								<Table responsive hover>
									<tbody>
										{this.state.relatedKeywords.map((keyword, index) => {
											if (
												this.state.selectedTechnologies.indexOf(
													keyword.keyword,
												) === -1
											)
												return (
													<tr key={index}>
														<td
															// onClick={() => {
															// 	this.setState({
															// 		technologySelected: keyword.keyword,
															// 	});
															// }}
															onClick={() => {
																if (
																	this.state.selectedTechnologies.indexOf(
																		keyword.keyword,
																	) == -1
																) {
																	this.setState(
																		{
																			selectedTechnologies: [
																				...this.state.selectedTechnologies,
																				keyword.keyword,
																			],
																		},
																		() => {
																			let previousDifficultyArray = this.state
																				.difficultyLevels;
																			previousDifficultyArray.push('Beginner');
																			let previousQuantityArray = this.state
																				.questionQuantity;
																			previousQuantityArray.push(0);
																			this.setState(
																				{
																					difficultyLevels: previousDifficultyArray,
																					questionQuantity: previousQuantityArray,
																				},
																				() => {
																					this.getAvailableQuestions();
																				},
																			);
																		},
																	);
																}
															}}
															style={{
																background:
																	keyword.keyword ===
																		this.state.technologySelected
																		? 'red'
																		: 'transparent',

																cursor: 'pointer',
															}}
														>
															<strong>{keyword.keyword}</strong>
														</td>
													</tr>
												);
										})}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MyQuestions;
