import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

class CustomerCareSidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	justLogout = () => {
		//localStorage.clear();
		// eslint-disable-next-line
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					<SidebarLink
						title="Tickets"
						icon="list"
						route="/mytickets"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Opened Tickets"
						icon="list"
						route="/activet"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Closed Tickets"
						icon="list"
						route="/closedt"
						onClick={this.hideSidebar}
					/>

					{/* <SidebarLink title="Log Out" icon="exit" onClick={this.justLogout} /> */}
				</ul>
				{/* <ul className="sidebar__block">
					<SidebarCategory title="Example Pages" icon="diamond">
						<SidebarLink
							title="Page one"
							route="/pages/one"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Page two"
							route="/pages/two"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
				</ul> */}
			</div>
		);
	}
}

export default CustomerCareSidebarContent;
