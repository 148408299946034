import React, { Component } from 'react';
import ReactDOM from 'react-dom'; // eslint-disable-line
// import EditableLabel from 'react-inline-editing';
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
// import Switch from 'react-switch';
// import SearchIcon from 'mdi-react/SearchIcon';
import {
    Col,
    Row,
    Modal,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import WebIcon from 'mdi-react/WebIcon';
import Select from 'react-select';
import Resizer from 'react-image-file-resizer';
import StarIcon from 'mdi-react/StarIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AddLocationIcon from 'mdi-react/AddLocationIcon';
// import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
// import AccountsAddIcon from 'mdi-react/AccountsAddIcon';
import Alert from '../../../../shared/components/Alert';
import ClientImage from './ClientImage';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
    client_types: [],
    client_type: '',
    lead_statuss: [],
    lead_status: '',
    client_name: '',
    client_location: '',
    client_image: 0,
    lead_domain: '',
    addLeadModal: false,
    addPocModal: false,
    clienttypes: [{
        value: '',
        label: (<div>
            Search/Select Client Types
        </div>
        ),
    }],
    leadsts: [{
        value: '',
        label: (<div>
            Search/Select Lead Status
        </div>
        ),
    }],

};
class MngLead extends Component {
    constructor(props) {
        super(props);
        this.base64Image = this.base64Image.bind(this);
    }
    state = { ...initialstate };
    componentDidMount() {


        this.handleAkey();

    }

    handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name + ' ' + value);
        this.setState({ [name]: value });
    };
    handleLeadSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.client_type);
        if (this.state.client_type == '') {
            swal('Select Client Type', {
                icon: 'warning',
            });
        } else if (this.state.lead_status == '') {
            swal('Select Client Status', {
                icon: 'warning',
            });
        } else {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: localStorage.token,
            };
            const addkeyurl = apiurl + 'addlead';
            var postData = {
                client_type: this.state.client_type,
                client_name: this.state.client_name,
                client_location: this.state.client_location,
                client_image: this.state.client_image,
                lead_status: this.state.lead_status,
                lead_domain: this.state.lead_domain,
                dashbid: localStorage.dashbid,
                bdmid: localStorage.usercode,
            };
            axios
                .post(addkeyurl, postData, {
                    headers: headers,
                })
                .then((response) => {
                    console.log(response.data.message);
                    if (response.data.error) {
                        this.setState({
                            showAlert: true,
                            alertType: 'warning',
                            alertText: response.data.message,
                        });
                    } else {
                        this.setState(
                            {
                                showAlert: false,
                                addLeadModal: false,
                                client_type: '',
                            },
                            () => {
                                swal(this.state.client_name + ' ' + response.data.message, {
                                    icon: 'success',
                                });
                                window.location.reload(false);
                                // // eslint-disable-next-line
                                this.props.history.push('/clientlist'); //eslint-disable-line
                            },
                        );
                    }
                })
                .catch((error) => {
                    console.log('Error is ', error);
                    this.setState({
                        alertType: 'danger',
                        showAlert: true,
                        alertText: 'Failed Try Again Later!',
                    });
                });
        }
    };


    handleAkey = () => {
        this.setState({
            addLeadModal: true,
            client_name: '',
            client_location: '',
            client_image: 0,
            client_type: '',
            lead_status: '',
            lead_domain: '',
            clienttypes: [{
                value: '',
                label: (<div>
                    Search/Select Client Types
                </div>
                ),
            }],
            leadsts: [{
                value: '',
                label: (<div>
                    Search/Select Lead Status
                </div>
                ),
            }],
        }, () => {
            const allclitype = JSON.parse(localStorage.allclitype);
            const allleadsts = JSON.parse(localStorage.allleadsts);

            allclitype.map((clitype) => {
                this.state.clienttypes.push({
                    value: clitype.id,
                    label: (
                        <div>
                            {clitype.client_type}
                        </div>
                    ),
                });
            });

            allleadsts.map((sts) => {
                this.state.leadsts.push({
                    value: sts.id,
                    label: (
                        <div>
                            {sts.lead_status}
                        </div>
                    ),
                });
            });

        });
    };


    handleCkey = () => {
        this.setState({
            addLeadModal: false,
            leadid: 0,
        });
    };


    handleSelectCliChange = (selectedOption) => {
        let client_type = selectedOption.value;
        this.setState({ client_type: client_type });
    };
    handleSelectStsChange = (selectedOption) => {
        let lead_status = selectedOption.value;
        this.setState({ lead_status: lead_status });
    };

    getImageType(giventype) {
        var type = '';

        switch (giventype) {
            case 'image/png':
                type = 'PNG';
                break;
            case 'image/gif':
                type = 'JPEG';
                break;
            case 'image/jpeg':
                type = 'JPEG';
                break;
            case 'image/jpg':
                type = 'JPEG';
                break;
            default:
                type = 'JPEG';
                break;
        }
        return type;
    }
    base64Image(event) {
        var mig = event.target.files[0];
        console.log('gottype', event.target.files[0].type);
        var gottype = this.getImageType(event.target.files[0].type);

        console.log('gottype', gottype);
        this.setState(
            {
                failbtn: true,
            },
            () => {
                console.log(this.state.client_image);
                var fileInput = false;
                if (mig) {
                    fileInput = true;
                }
                if (fileInput) {
                    Resizer.imageFileResizer(
                        mig,
                        300,
                        300,
                        gottype,
                        100,
                        0,
                        (uri) => {
                            console.log(uri);
                            const baseimageuri = uri;

                            const headers = {
                                'Content-Type': 'application/json',
                                Authorization: localStorage.token,
                            };
                            var postData = {
                                base64: baseimageuri,
                            };

                            const base64loadurl = apiurl + 'base64load';
                            axios
                                .post(base64loadurl, postData, {
                                    headers: headers,
                                })
                                .then((response) => {
                                    console.log('Response is ', response.data);

                                    if (response.data.error) {
                                        this.setState({
                                            showAlert: true,
                                            alertType: 'warning',
                                            alertText: response.data.message,
                                            failbtn: false,
                                        });
                                    } else {
                                        this.setState({
                                            showAlert: false,
                                            client_image: response.data.fileurl,
                                            failbtn: false,
                                        });
                                    }
                                })
                                .catch(() => {
                                    this.setState({
                                        alertType: 'danger',
                                        showAlert: true,
                                        alertText: 'Failled Try Again Later!',
                                    });
                                });
                        },
                        'base64',
                    );
                }
            },
        );
    }

    render() {
        const { client_type, client_name, client_location, client_image, lead_status, lead_domain } = this.state;
        return (
            <div>
                <Modal
                    isOpen={this.state.addLeadModal}
                    modalClassName="ltr-support"
                    className="modal-dialog--primary clientmodal"
                >
                    <div className="modal__header">
                        <button
                            className="lnr lnr-cross modal__close-btn"
                            type="button"
                            onClick={this.handleCkey}
                        />
                        <h4 className="text-modal  modal__title">Add  Client Info</h4>
                    </div>
                    <div className="modal__body">
                        {this.state.showAlert ? (
                            <Alert color={this.state.alertType}>
                                <p>{this.state.alertText}</p>
                            </Alert>
                        ) : null}
                        <form
                            className="form"
                            onSubmit={(event) => this.handleLeadSubmit(event)}
                        >
                            <Row style={{ width: '100%' }}>
                                <Col md={6}>

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Client Name</span>
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-icon">
                                                <StarIcon />
                                            </div>

                                            <input
                                                name="client_name"
                                                type="text"
                                                placeholder="Enter Client Name"
                                                className="form-control"
                                                value={client_name}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Client Location</span>
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-icon">
                                                <AddLocationIcon />
                                            </div>

                                            <input
                                                name="client_location"
                                                type="text"
                                                placeholder="Enter Client Location"
                                                className="form-control"
                                                value={client_location}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Client Domain</span>
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-icon">
                                                <WebIcon />
                                            </div>

                                            <input
                                                name="lead_domain"
                                                type="text"
                                                placeholder="Enter Client Domain"
                                                className="form-control"
                                                value={lead_domain}
                                                onChange={this.handleChange}

                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Row style={{ marginBottom: '40px' }}>
                                        <Col md={3}>

                                            <ClientImage cliclname="client_img" cliflpath={client_image} />
                                            <br />
                                        </Col>
                                        <Col md={9}>
                                            <input type="file" onChange={this.base64Image} /></Col>
                                    </Row>

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Client Type</span>
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-icon">
                                                <AccountOutlineIcon />
                                            </div>

                                            <Select
                                                name="client_type"
                                                value={this.state.clienttypes.filter(
                                                    (option) => option.value == client_type,
                                                )}
                                                onChange={this.handleSelectCliChange}
                                                options={this.state.clienttypes}
                                                clearable={false}
                                                className="react-select-team"
                                                classNamePrefix="react-select"
                                            // defaultMenuIsOpen={true}
                                            // isDisabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Lead Status</span>
                                        <div className="form__form-group-field">
                                            <div className="form__form-group-icon">
                                                <AccountOutlineIcon />
                                            </div>

                                            <Select
                                                name="lead_status"
                                                value={this.state.leadsts.filter(
                                                    (option) => option.value == lead_status,
                                                )}
                                                onChange={this.handleSelectStsChange}
                                                options={this.state.leadsts}
                                                clearable={false}
                                                className="react-select-team"
                                                classNamePrefix="react-select"
                                            // defaultMenuIsOpen={true}
                                            // isDisabled={true}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="btn btn-primary account__btn account__btn--small"
                                        type="submit"
                                    >
                                        Add Client
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default MngLead;
