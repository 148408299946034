import React, { Component } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import update from 'react-addons-update';
import { Button } from 'react-bootstrap';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactCountdownClock from 'react-countdown-clock';
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	answerMarked: 5,
	noRulesViolated: false,
	test_given: false,
	seconds: 1,
	parawriting: '',
	showCEbox: false,
	tinymceapikey: TINYMCEAPIKEY,
};
class CustomStartTest extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			// eslint-disable-next-line
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchCustomTest();
		}
		this.myInterval = setInterval(() => {
			const { seconds } = this.state; //eslint-disable-line

			this.setState(({ seconds }) => ({
				seconds: seconds + 1,
			}));
		}, 1000);
	}

	fetchCustomTest = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			test_id: localStorage.testId,
			email: localStorage.by,
		};
		const getuurl = apiurl + 'getCustomNewTest';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.data[0].taken) {
					this.setState({
						taken: true,
					});
				} else {
					this.setState(
						{
							remail: response.data.data[0].sent_by,
							genid: response.data.data[0].testname,
							trotype: response.data.data[0].ttype,
							allqnos: response.data.question_ids.length,
						},
						() => {
							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};

							var postData = {
								email: this.state.remail,
							};
							const getrdetails = apiurl + 'getrdetails';

							axios
								.post(getrdetails, postData, {
									headers: headers,
								})

								.then((getrdetailscvs) => {
									this.setState(
										{
											rname: getrdetailscvs.data.data,
										},
										() => {
											this.fetchTestQuestions(response.data.question_ids);
										},
									);
								})
								.catch((error) => {
									console.log('Error in promise ', error);
								});
						},
					);
				}
			});
	};

	fetchTestQuestions = (question_ids) => {
		let ftpquest = 'fetchCustomTestQuestions';

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			id: localStorage.trotype,
			email: localStorage.by,
			question_ids: question_ids,
		};
		const getuurl = apiurl + ftpquest;
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('stae -- ', this.state);
				const unique = [
					...new Set(response.data.data.map((item) => item.technology)),
				];
				let editorvalue = [];
				let nbspvalue = 'Write Answer ..';
				const result_array = unique.map((tech) => {
					return {
						technology: tech,
						score: 0,
						correct: 0,
						incorrect: 0,
						unattempted: 0,
					};
				});
				let qresultsss = response.data.data;
				qresultsss.map((tech) => { //eslint-disable-line
					nbspvalue = nbspvalue + '.';
					editorvalue.push(nbspvalue);
				});
				console.log('Result is ', result_array);
				console.log('Result is ', editorvalue);

				this.setState(
					{
						result: [...result_array],
						questions: [...response.data.data],
						editorvalue: editorvalue,
					},
					() => {
						this.setState({
							currentDuration: this.state.questions[this.state.questionIndex]
								.Duration,
						});
						this.generateResult('no');
					},
				);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		var hDisplay = h > 0 ? h + (h == 1 ? ' Hr, ' : ' Hrs, ') : '';
		var mDisplay = m > 0 ? m + (m == 1 ? ' Min, ' : ' Mins, ') : '';
		var sDisplay = s > 0 ? s + (s == 1 ? ' Sec' : ' Secs') : '';
		return hDisplay + mDisplay + sDisplay;
	};
	generateResult = (step) => {
		const ftpquest = 'uploadCustomResult';
		var tdur = '';
		if (step == 'yes') {
			tdur = this.secondsToHms(this.state.seconds);
		}
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			testId: localStorage.testId,
			results: [...this.state.result],
			email: localStorage.by,
			seconds: this.state.seconds,
			remail: this.state.remail,
			genid: this.state.genid,
			allqnos: this.state.allqnos,
			step: step,
			rname: this.state.rname,
			tname: localStorage.name,
			tdur: tdur,
		};
		const getuurl = apiurl + ftpquest;
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						showCEbox: true,
						parawriting: '',
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	redirectUser = () => {
		localStorage.setItem('testId', '');
		localStorage.setItem('ttypefor', '');
		localStorage.setItem('test', '');
		localStorage.setItem('questionIndex', '');
		// eslint-disable-next-line
		this.props.history.push('/dashboard'); //eslint-disable-line
	};
	handleAnswerChange(basedone, editor) { //eslint-disable-line
		let basesix4 = new Buffer(basedone);
		let parawriting = basesix4.toString('base64');
		// console.log('para writing', parawriting);
		// console.log('para writing editor', editor);
		this.setState({ parawriting }, () => {
			this.setState({
				answerMarked: 9,
				questionAnswered: true,
				optionClicked: 0,
			});
		});
	}
	render() {
		const { tinymceapikey } = this.state;
		return (
			<div>
				{this.state.showCEbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Test
											<span className="account__logo-accent"> Completed!</span>
										</span>
									</h3>
								</div>

								<div style={{ color: 'red' }}>You will be Contacted Soon</div>
								<ul>
									<li>You have complete your test </li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUser}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						{this.state.taken ? (
							<Col md={12}>
								<Card>
									<CardBody>
										<div className="card__title">
											<h5 className="bold-text">
												Test is already completed👍🏻. Visit Reports section to
												see or download Report
											</h5>
										</div>
									</CardBody>
								</Card>
							</Col>
						) : (
							<div>
								{this.state.questions.length !== 0 &&
									this.state.questionIndex < this.state.questions.length ? (
									<Row>
										<Col md={8}>
											<Card>
												<CardBody>
													<div className="card__title">
														<h5 className="bold-text">
															Question {this.state.questionIndex + 1}/
															{this.state.questions.length}
															<span
																style={{
																	color: 'red',
																	fontSize: '15px',
																	float: 'right',
																}}
															>
																To Select Right Option and click next to Answer
																the Question
															</span>
														</h5>
													</div>

													{this.state.questionIndex !== undefined ? (
														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
																marginTop: 40,
															}}
														>
															<div className="mb-3">
																<b>
																	<Editor
																		apiKey={tinymceapikey}
																		disabled={true}
																		value={
																			'<p>' +
																			this.state.questions[
																			this.state.questionIndex
																			] &&
																			this.state.questions[
																				this.state.questionIndex
																			].question + '</p>'
																		}
																		init={{
																			inline: true,
																			height: 100,
																			menubar: false,

																			toolbar: '',
																		}}
																	/>
																</b>
															</div>

															{this.state.questions[this.state.questionIndex] &&
																this.state.questions[this.state.questionIndex]
																	.questype == 1 ? (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																	}}
																>
																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 0
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}
																		onClick={() => {
																			this.setState({
																				answerMarked: 0,
																				questionAnswered: true,
																				optionClicked: 0,
																			});
																		}}
																	>
																		<b
																			className="mr-4"
																			style={{ float: 'left' }}
																		>
																			A:
																		</b>

																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[0] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 1
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}
																		onClick={() => {
																			this.setState({
																				answerMarked: 1,
																				questionAnswered: true,
																				optionClicked: 1,
																			});
																		}}
																	>
																		<b
																			className="mr-4"
																			style={{ float: 'left' }}
																		>
																			B:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[1] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 2
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}
																		onClick={() => {
																			this.setState({
																				answerMarked: 2,
																				questionAnswered: true,
																				optionClicked: 2,
																			});
																		}}
																	>
																		<b
																			className="mr-4"
																			style={{ float: 'left' }}
																		>
																			C:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[2] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 3
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}
																		onClick={() => {
																			this.setState({
																				answerMarked: 3,
																				questionAnswered: true,
																				optionClicked: 3,
																			});
																		}}
																	>
																		<b
																			className="mr-4"
																			style={{ float: 'left' }}
																		>
																			D:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[3] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>
																</div>
															) : (
																''
															)}

															{this.state.questions[this.state.questionIndex] &&
																this.state.questions[this.state.questionIndex]
																	.questype == 2 ? (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																	}}
																>
																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 0
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}
																		onClick={() => {
																			this.setState({
																				answerMarked: 0,
																				questionAnswered: true,
																				optionClicked: 0,
																			});
																		}}
																	>
																		<b
																			className="mr-4"
																			style={{ float: 'left' }}
																		>
																			A:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[0] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 1
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}
																		onClick={() => {
																			this.setState({
																				answerMarked: 1,
																				questionAnswered: true,
																				optionClicked: 1,
																			});
																		}}
																	>
																		<b
																			className="mr-4"
																			style={{ float: 'left' }}
																		>
																			B:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[1] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>
																</div>
															) : (
																''
															)}
															{this.state.questions[this.state.questionIndex] &&
																this.state.questions[this.state.questionIndex]
																	.questype == 3 ? (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																	}}
																>
																	<div className="form__form-group">
																		<span className="form__form-group-label">
																			Write Answer
																		</span>
																		<div className="form__form-group-field">
																			<Editor
																				apiKey={TINYMCEAPIKEY}
																				value={
																					'<p>' +
																					this.state.editorvalue[
																					this.state.questionIndex
																					] +
																					'</p>'
																				}
																				outputFormat="html"
																				init={{
																					inline: true,
																					min_height: 350,
																					min_width: 500,
																					menubar: false,
																					plugins: ['autoresize paste'],
																					paste_block_drop: false,
																					paste_data_images: false,

																					autoresize_bottom_margin: 50,
																					autoresize_overflow_padding: 10,
																					toolbar: '',
																					paste_preprocess: function (
																						plugin,
																						args,
																					) {
																						args.content = '';
																					},
																				}}
																				onEditorChange={this.handleAnswerChange.bind(
																					this,
																				)}
																			/>
																		</div>
																	</div>
																</div>
															) : (
																''
															)}
														</div>
													) : (
														''
													)}

													<Button
														onClick={() => {
															let newquesid = this.state.questions[
																this.state.questionIndex
															].id;
															if (
																this.state.questions[this.state.questionIndex]
																	.questype == 3
															) {
																// writing start
																let indexOfObj = this.state.result.find(
																	(res) => {
																		return (
																			res.technology ===
																			this.state.questions[
																				this.state.questionIndex
																			].technology
																		);
																	},
																);
																let tech =
																	indexOfObj !== []
																		? this.state.result.indexOf(indexOfObj)
																		: undefined;
																if (this.state.parawriting) {
																	let paraswriting = this.state.parawriting;

																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					[newquesid]: {
																						$set: paraswriting,
																					},
																				},
																			}),
																		},
																		() => {
																			this.generateResult('no');
																		},
																	);
																} else {
																	let unattemptedAnswers =
																		tech !== undefined
																			? this.state.result[tech].unattempted
																			: 0;
																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					unattempted: {
																						$set: unattemptedAnswers + 1,
																					},
																				},
																			}),
																		},
																		() => {
																			this.setState(
																				{
																					result: update(this.state.result, {
																						[`${tech}`]: {
																							[newquesid]: {
																								$set: 'Not Answered',
																							},
																						},
																					}),
																				},
																				() => {
																					this.generateResult('no');
																				},
																			);
																		},
																	);
																}

																//  writing end
															} else {
																if (
																	this.state.answerMarked <= 3 &&
																	this.state.answerMarked ==
																	this.state.questions[
																		this.state.questionIndex
																	].correct
																) {
																	let indexOfObj = this.state.result.find(
																		(res) => {
																			return (
																				res.technology ===
																				this.state.questions[
																					this.state.questionIndex
																				].technology
																			);
																		},
																	);
																	let tech =
																		indexOfObj !== []
																			? this.state.result.indexOf(indexOfObj)
																			: undefined;

																	let currentScore =
																		tech !== undefined
																			? this.state.result[tech].score
																			: 0;

																	let correctAnswers =
																		tech !== undefined
																			? this.state.result[tech].correct
																			: 0;
																	// let incorrectAnswers =
																	// 	tech !== undefined
																	// 		? this.state.result[tech].incorrect
																	// 		: 0;
																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					correct: { $set: correctAnswers + 1 },
																				},
																			}),
																		},
																		() => {
																			this.setState(
																				{
																					result: update(this.state.result, {
																						[`${tech}`]: {
																							score: { $set: currentScore + 1 },
																							[newquesid]: {
																								$set: this.state.answerMarked,
																							},
																						},
																					}),
																				},
																				() => {
																					this.generateResult('no');
																				},
																			);
																		},
																	);
																} else if (
																	this.state.answerMarked <= 3 &&
																	this.state.answerMarked !=
																	this.state.questions[
																		this.state.questionIndex
																	].correct
																) {
																	let indexOfObj = this.state.result.find(
																		(res) => {
																			return (
																				res.technology ===
																				this.state.questions[
																					this.state.questionIndex
																				].technology
																			);
																		},
																	);
																	let tech =
																		indexOfObj !== []
																			? this.state.result.indexOf(indexOfObj)
																			: undefined;

																	let incorrectAnswers =
																		tech !== undefined
																			? this.state.result[tech].incorrect
																			: 0;
																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					incorrect: {
																						$set: incorrectAnswers + 1,
																					},
																					[newquesid]: {
																						$set: this.state.answerMarked,
																					},
																				},
																			}),
																		},
																		() => {
																			this.generateResult('no');
																		},
																	);
																} else if (this.state.answerMarked >= 5) {
																	console.log('Not marked');
																	let indexOfObj = this.state.result.find(
																		(res) => {
																			return (
																				res.technology ===
																				this.state.questions[
																					this.state.questionIndex
																				].technology
																			);
																		},
																	);
																	let tech =
																		indexOfObj !== []
																			? this.state.result.indexOf(indexOfObj)
																			: undefined;

																	let unattemptedAnswers =
																		tech !== undefined
																			? this.state.result[tech].unattempted
																			: 0;
																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					unattempted: {
																						$set: unattemptedAnswers + 1,
																					},
																					[newquesid]: {
																						$set: 'Not Answered',
																					},
																				},
																			}),
																		},
																		() => {
																			this.generateResult('no');
																		},
																	);
																}
															}
															if (
																this.state.questionIndex + 1 <
																this.state.questions.length
															) {
																this.setState(
																	{
																		questionIndex: this.state.questionIndex + 1,
																		currentDuration: 0,
																	},
																	() => {
																		this.setState({
																			currentDuration: this.state.questions[
																				this.state.questionIndex
																			].Duration,
																			questionAnswered: false,
																			optionClicked: 5,
																			answerMarked: 5,
																		});
																	},
																);
															}
															if (
																this.state.questionIndex ===
																this.state.questions.length - 1
															) {
																this.setState(
																	{
																		noRulesViolated: true,
																		test_given: true,
																	},
																	() => {
																		this.generateResult('yes');
																	},
																);
															}
														}}
														style={{ float: 'right' }}
													>
														{this.state.questionIndex ===
															this.state.questions.length - 1
															? ' End Test'
															: 'Next'}
													</Button>
												</CardBody>
											</Card>
										</Col>
										<Col md={3}>
											<Card>
												<CardBody>
													<div className="card__title">
														<h5 className="bold-text">Duration</h5>
													</div>
													{this.state.questions[this.state.questionIndex]
														.questype != 3 && this.state.currentDuration ? (
														<ReactCountdownClock
															seconds={this.state.currentDuration * 60}
															showMilliseconds={false}
															alpha={0.9}
															size={100}
															color="green"
															onComplete={() => {
																let cnewquesid = this.state.questions[
																	this.state.questionIndex
																].id;
																if (!this.state.questionAnswered) {
																	let indexOfObj = this.state.result.find(
																		(res) => {
																			return (
																				res.technology ===
																				this.state.questions[
																					this.state.questionIndex
																				].technology
																			);
																		},
																	);
																	let tech =
																		indexOfObj !== []
																			? this.state.result.indexOf(indexOfObj)
																			: undefined;

																	let unattemptedAnswers =
																		tech !== undefined
																			? this.state.result[tech].unattempted
																			: 0;
																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					unattempted: {
																						$set: unattemptedAnswers + 1,
																					},
																				},
																			}),
																		},
																		() => {
																			this.setState(
																				{
																					result: update(this.state.result, {
																						[`${tech}`]: {
																							[cnewquesid]: {
																								$set: 'Not Answered',
																							},
																						},
																					}),
																				},
																				() => {
																					this.generateResult('no');
																				},
																			);
																		},
																	);
																}
																if (
																	this.state.questionIndex <
																	this.state.questions.length - 1
																) {
																	this.setState(
																		{
																			questionIndex:
																				this.state.questionIndex + 1,
																			currentDuration: 0,
																		},
																		() => {
																			this.setState({
																				currentDuration: this.state.questions[
																					this.state.questionIndex
																				].Duration,
																			});
																			this.generateResult('no');
																		},
																	);
																} else if (
																	this.state.questionIndex ===
																	this.state.questions.length - 1
																) {
																	this.setState(
																		{
																			noRulesViolated: true,
																			test_given: true,
																		},
																		() => {
																			this.generateResult('yes');
																		},
																	);
																}
															}}
														/>
													) : (
														''
													)}
												</CardBody>
											</Card>
										</Col>
									</Row>
								) : (
									''
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default CustomStartTest;
