import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
/* global  */
import axios from 'axios';
import { Container, Row } from 'reactstrap';
// import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
// import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import TotalProducts from '../ecomponents/TotalProducts';
import TotalProfit from '../ecomponents/TotalProfit';
import RecentTestDetails from '../ecomponents/RecentTestDetails';
import RecentTakenTestDetails from '../ecomponents/RecentTakenTestDetails';

var moment = require('moment'); // eslint-disable-line
import { MY_API_URL } from '../../Apanel/QueryMe';
const apiurl = MY_API_URL;
const initialstate = {
	indrec: [{ name: 'Page A', uv: '0' }],
	comprec: [{ name: 'Page A', amt: '0' }],
	comps: [{ name: 'Page A', uv: '0' }],
	tsent: [{ name: 'Page A', uv: '0' }],
	ttaken: [{ name: 'Page A', uv: '0' }],
	aindrec: 0,
	acomprec: 0,
	acomps: 0,
	atsent: 0,
	attaken: 0,
	tkeyword: [{ name: 'Page A', uv: '0' }],
	takeyword: [{ name: 'Page A', uv: '0' }],
	tikeyword: [{ name: 'Page A', uv: '0' }],
	tques: [{ name: 'Page A', uv: '0' }],
	taques: [{ name: 'Page A', uv: '0' }],
	thques: [{ name: 'Page A', uv: '0' }],
	trques: [{ name: 'Page A', uv: '0' }],
	tfaq: [{ name: 'Page A', uv: '0' }],
	tafaq: [{ name: 'Page A', uv: '0' }],
	thfaq: [{ name: 'Page A', uv: '0' }],
	trfaq: [{ name: 'Page A', uv: '0' }],
	tlogged: [{ name: 'Page A', uv: '0' }],
	flogged: [{ name: 'Page A', uv: '0' }],
	atkeyword: 0,
	atakeyword: 0,
	atikeyword: 0,
	atques: 0,
	ataques: 0,
	athques: 0,
	atrques: 0,
	atfaq: 0,
	atafaq: 0,
	athfaq: 0,
	atrfaq: 0,
	atlogged: 0,
	atflogged: 0,
	testsent: [],
	testtaken: [],
};
class ReportsPage extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		this.handleGetTendays(localStorage.token);
	}

	handleGetTendays = (token) => {
		let tenthday = moment().subtract(9, 'days').format('YYYY-MM-DD');
		let ninethday = moment().subtract(8, 'days').format('YYYY-MM-DD');
		let eightthday = moment().subtract(7, 'days').format('YYYY-MM-DD');
		let seventhday = moment().subtract(6, 'days').format('YYYY-MM-DD');
		let sixthday = moment().subtract(5, 'days').format('YYYY-MM-DD');
		let fivethday = moment().subtract(4, 'days').format('YYYY-MM-DD');
		let fourthday = moment().subtract(3, 'days').format('YYYY-MM-DD');
		let threethday = moment().subtract(2, 'days').format('YYYY-MM-DD');
		let twothday = moment().subtract(1, 'days').format('YYYY-MM-DD');
		let today = moment().format('YYYY-MM-DD');
		let tendays = [
			today,
			twothday,
			threethday,
			fourthday,
			fivethday,
			sixthday,
			seventhday,
			eightthday,
			ninethday,
			tenthday,
		];
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			tendays: tendays,
		};
		const getuurl = apiurl + 'tendays';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('tendays: ', response.data);
				if (!response.data.error) {
					this.setState(
						{
							indrec: response.data.indrec,
							comprec: response.data.comprec,
							comps: response.data.comps,
							tsent: response.data.tsent,
							ttaken: response.data.ttaken,
							tkeyword: response.data.tkeyword,
							takeyword: response.data.takeyword,
							tikeyword: response.data.tikeyword,
							tques: response.data.tques,
							taques: response.data.taques,
							thques: response.data.thques,
							trques: response.data.trques,
							tfaq: response.data.tfaq,
							tafaq: response.data.tafaq,
							thfaq: response.data.thfaq,
							trfaq: response.data.trfaq,
							tlogged: response.data.tlogged,
							atlogged: response.data.tlogged[0].uv,
							flogged: response.data.flogged,
							atflogged: response.data.flogged[0].uv,
						},
						() => {
							this.handleGetAllcnts(localStorage.token);
						},
					);
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleGetAllcnts = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'reportcnt';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('reportcnt', response.data);
				if (!response.data.error) {
					this.setState(
						{
							aindrec: response.data.aindrec,
							acomprec: response.data.acomprec,
							acomps: response.data.acomps,
							atsent: response.data.atsent,
							attaken: response.data.attaken,
							atkeyword: response.data.atkeyword,
							atakeyword: response.data.atakeyword,
							atikeyword: response.data.atikeyword,
							atques: response.data.atques,
							ataques: response.data.ataques,
							athques: response.data.athques,
							atrques: response.data.atrques,
							atfaq: response.data.atfaq,
							atafaq: response.data.atafaq,
							athfaq: response.data.athfaq,
							atrfaq: response.data.atrfaq,
						},
						() => {
							this.handleGetRecentTests(localStorage.token);
						},
					);
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	handleGetRecentTests = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'fetchthreeTests';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('recent tests', response.data);
				if (!response.data.error) {
					this.setState({
						testsent: response.data.testsent,
						testtaken: response.data.testtaken,
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {




		const {
			indrec,
			comprec,
			comps,
			tsent,
			ttaken,
			aindrec,
			acomprec,
			acomps,
			atsent,
			attaken,
			tkeyword,
			takeyword,
			tikeyword,
			tques,
			taques,
			thques,
			trques,
			tfaq,
			tafaq,
			thfaq,
			trfaq,
			tlogged,
			flogged,
			atkeyword,
			atakeyword,
			atikeyword,
			atques,
			ataques,
			athques,
			atrques,
			atfaq,
			atafaq,
			athfaq,
			atrfaq,
			atlogged,
			atflogged,
			testsent,
			testtaken,
		} = this.state;

		var senttechnamesone = [];
		var senttechnamestwo = [];
		var senttechnamesthree = [];
		var takentechnamesone = [];
		var takentechnamestwo = [];
		var takentechnamesthree = [];
		if (this.state.testsent[0]) {
			const testsentone = JSON.parse(testsent[0].helper);
			const testsenttwo = JSON.parse(testsent[1].helper);
			const testsentthree = JSON.parse(testsent[2].helper);

			const testtakenone = JSON.parse(testtaken[0].helper);
			const testtakentwo = JSON.parse(testtaken[1].helper);
			const testtakenthree = JSON.parse(testtaken[2].helper);

			const testtakenresultone = JSON.parse(testtaken[0].results);
			const testtakenresulttwo = JSON.parse(testtaken[1].results);
			const testtakenresultthree = JSON.parse(testtaken[2].results);

			var totalCorrectone = 0;
			var totalquestionsone = 0;

			var totalCorrecttwo = 0;
			var totalquestionstwo = 0;

			var totalCorrectthree = 0;
			var totalquestionsthree = 0;
			testsentone.map((tech) => {
				senttechnamesone.push(tech.difficultyLevel);
			});
			senttechnamesone = senttechnamesone.join(', ');
			testsenttwo.map((tech) => {
				senttechnamestwo.push(tech.difficultyLevel);
			});
			senttechnamestwo = senttechnamestwo.join(', ');
			testsentthree.map((tech) => {
				senttechnamesthree.push(tech.difficultyLevel);
			});
			senttechnamesthree = senttechnamesthree.join(', ');

			testtakenone.map((tech) => {
				takentechnamesone.push(tech.difficultyLevel);
				tech.questions = parseInt(tech.questions);
				totalquestionsone = totalquestionsone + tech.questions;
			});
			takentechnamesone = takentechnamesone.join(', ');
			testtakentwo.map((tech) => {
				takentechnamestwo.push(tech.difficultyLevel);
				tech.questions = parseInt(tech.questions);
				totalquestionstwo = totalquestionstwo + tech.questions;
			});
			takentechnamestwo = takentechnamestwo.join(', ');
			testtakenthree.map((tech) => {
				takentechnamesthree.push(tech.difficultyLevel);
				tech.questions = parseInt(tech.questions);
				totalquestionsthree = totalquestionsthree + tech.questions;
			});
			takentechnamesthree = takentechnamesthree.join(', ');

			testtakenresultone.map((result) => {
				totalCorrectone = totalCorrectone + result.correct;
			});
			totalCorrectone = (100 / totalquestionsone) * totalCorrectone;
			testtakenresulttwo.map((result) => {
				totalCorrecttwo = totalCorrecttwo + result.correct;
			});
			totalCorrecttwo = (100 / totalquestionstwo) * totalCorrecttwo;
			testtakenresultthree.map((result) => {
				totalCorrectthree = totalCorrectthree + result.correct;
			});
			totalCorrectthree = (100 / totalquestionsthree) * totalCorrectthree;
		}
		return (
			<Container className="dashboard">
				<div>{ }</div>
				<Row>
					<TotalProducts
						tname="Independent Recruiters"
						totaln={aindrec}
						rdata={indrec}
					/>

					<TotalProducts
						tname="Total Logins Today"
						totaln={atlogged}
						rdata={tlogged}
					/>
					{this.state.testsent[0] ? (
						<RecentTestDetails
							tname={this.state.testsent[0].generated_id}
							tstep="Sent 1"
							tstepd="st"
							sentby={
								this.state.testsent[0].rname +
								' - ' +
								this.state.testsent[0].sent_by
							}
							technologies={senttechnamesone}
							sentto={
								this.state.testsent[0].tname +
								' - ' +
								this.state.testsent[0].sent_to
							}
						/>
					) : null}
					{this.state.testtaken[0] ? (
						<RecentTakenTestDetails
							tname={this.state.testtaken[0].generated_id}
							tstep="Taken 1"
							tstepd="st"
							sentby={
								this.state.testtaken[0].rname +
								' - ' +
								this.state.testtaken[0].taken_by
							}
							technologies={takentechnamesone}
							sentto={
								this.state.testtaken[0].tname +
								' - ' +
								this.state.testtaken[0].sent_to
							}
							totalCorrect={totalCorrectone}
						/>
					) : null}
				</Row>
				<Row>
					<TotalProducts tname="Companies" totaln={acomps} rdata={comps} />
					<TotalProfit
						tname="Company Recruiters"
						totaln={acomprec}
						rdata={comprec}
					/>
					{this.state.testsent[1] ? (
						<RecentTestDetails
							tname={this.state.testsent[1].generated_id}
							tstep="Sent 2"
							tstepd="nd"
							sentby={
								this.state.testsent[1].rname +
								' - ' +
								this.state.testsent[1].sent_by
							}
							technologies={senttechnamestwo}
							sentto={
								this.state.testsent[1].tname +
								' - ' +
								this.state.testsent[1].sent_to
							}
						/>
					) : null}
					{this.state.testtaken[1] ? (
						<RecentTakenTestDetails
							tname={this.state.testtaken[1].generated_id}
							tstep={'Taken 2'}
							tstepd="nd"
							sentby={
								this.state.testtaken[1].rname +
								' - ' +
								this.state.testtaken[1].taken_by
							}
							technologies={takentechnamestwo}
							sentto={
								this.state.testtaken[1].tname +
								' - ' +
								this.state.testtaken[1].sent_to
							}
							totalCorrect={totalCorrecttwo}
						/>
					) : null}
				</Row>
				<Row>
					<TotalProducts tname="Tests Sent" totaln={atsent} rdata={tsent} />
					<TotalProfit tname="Tests Taken" totaln={attaken} rdata={ttaken} />
					{this.state.testsent[2] ? (
						<RecentTestDetails
							tname={this.state.testsent[2].generated_id}
							tstep="Sent 3"
							tstepd="rd"
							sentby={
								this.state.testsent[2].rname +
								' - ' +
								this.state.testsent[2].sent_by
							}
							technologies={senttechnamesthree}
							sentto={
								this.state.testsent[2].tname +
								' - ' +
								this.state.testsent[2].sent_to
							}
						/>
					) : null}
					{this.state.testtaken[2] ? (
						<RecentTakenTestDetails
							tname={this.state.testtaken[2].generated_id}
							tstep="Taken 3"
							tstepd="rd"
							sentby={
								this.state.testtaken[2].rname +
								' - ' +
								this.state.testtaken[2].taken_by
							}
							technologies={takentechnamesthree}
							sentto={
								this.state.testtaken[2].tname +
								' - ' +
								this.state.testtaken[2].sent_to
							}
							totalCorrect={totalCorrectthree}
						/>
					) : null}
				</Row>
				<Row>
					<TotalProducts
						tname="Total Keywords"
						totaln={atkeyword}
						rdata={tkeyword}
					/>
					<TotalProducts
						tname="Active Keywords"
						totaln={atakeyword}
						rdata={takeyword}
					/>
					<TotalProducts
						tname="Inactive Keywords"
						totaln={atikeyword}
						rdata={tikeyword}
					/>

					<TotalProducts
						tname="Faqs Requested Today"
						totaln={atflogged}
						rdata={flogged}
					/>
				</Row>
				<Row>
					<TotalProducts tname="Total MCQ's" totaln={atques} rdata={tques} />
					<TotalProducts tname="Approved" totaln={ataques} rdata={taques} />
					<TotalProducts tname="On Hold" totaln={athques} rdata={thques} />
					<TotalProducts tname="Reported" totaln={atrques} rdata={trques} />
				</Row>
				<Row>
					<TotalProducts tname="Total FAQ&apos;S" totaln={atfaq} rdata={tfaq} />
					<TotalProducts tname="Approved" totaln={atafaq} rdata={tafaq} />
					<TotalProducts tname="On Hold" totaln={athfaq} rdata={thfaq} />
					<TotalProducts tname="Reported" totaln={atrfaq} rdata={trfaq} />
				</Row>
				<Row>{/* <RecentOrders />   */}</Row>
			</Container>
		);
	}
}

export default withRouter(ReportsPage);
