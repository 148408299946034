import React, { Component } from 'react';

import ReactCountdownClock from 'react-countdown-clock';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import update from 'react-addons-update';
import { Button } from 'react-bootstrap';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	answerMarked: 5,
	noRulesViolated: false,
	test_given: false,
	seconds: 1,
	parawriting: '',
	tinymceapikey: TINYMCEAPIKEY,
	okbtn: 2,
};
class StartTest extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			// eslint-disable-next-line
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchTest();
		}
		this.myInterval = setInterval(() => {
			const { seconds } = this.state; //eslint-disable-line

			this.setState(({ seconds }) => ({
				seconds: seconds + 1,
			}));
		}, 1000);
	}
	fetchTest = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			test_id: localStorage.testId,
			email: localStorage.by,
		};
		const getuurl = apiurl + 'getNewTest';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.data[0].taken) {
					this.setState({
						taken: true,
					});
				} else {
					this.setState(
						{
							remail: response.data.data[0].sent_by,
							genid: response.data.data[0].generated_id,
							trotype: response.data.data[0].ttype,
							allqnos: response.data.question_ids.length,
							qcount: response.data.qcount,
							technames: response.data.technames,
							tcount: response.data.tcount,
						},
						() => {
							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};

							var postData = {
								email: this.state.remail,
							};
							const getrdetails = apiurl + 'getrdetails';

							axios
								.post(getrdetails, postData, {
									headers: headers,
								})

								.then((getrdetailscvs) => {
									this.setState(
										{
											rname: getrdetailscvs.data.data,
										},
										() => {
											this.fetchTestQuestions(response.data.question_ids);
										},
									);
								})
								.catch((error) => {
									console.log('Error in promise ', error);
								});
						},
					);
				}
			});
	};
	fetchTestQuestions = (question_ids) => {
		const trotype = this.state.trotype;
		let ftpquest = 'fetchTestQuestions';
		if (trotype == 'my') {
			ftpquest = 'fetchMyTestQuestions';
		}

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			id: localStorage.trotype,
			email: localStorage.by,
			question_ids: question_ids,
		};
		const getuurl = apiurl + ftpquest;
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('stae -- ', this.state);
				const unique = [
					...new Set(response.data.data.map((item) => item.technology)),
				];
				const result_array = unique.map((tech) => {
					return {
						technology: tech,
						score: 0,
						correct: 0,
						incorrect: 0,
						unattempted: 0,
					};
				});
				console.log('Result is ', result_array);

				this.setState(
					{
						result: [...result_array],
						questions: [...response.data.data],
					},
					() => {
						this.setState({
							currentDuration: this.state.questions[this.state.questionIndex]
								.Duration,
							okbtn: 1,
						});
						this.generateResult('no');
					},
				);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		var hDisplay = h > 0 ? h + (h == 1 ? ' Hr, ' : ' Hrs, ') : '';
		var mDisplay = m > 0 ? m + (m == 1 ? ' Min, ' : ' Mins, ') : '';
		var sDisplay = s > 0 ? s + (s == 1 ? ' Sec' : ' Secs') : '';
		return hDisplay + mDisplay + sDisplay;
	};
	generateResult = (step) => {
		let ftpquest = 'uploadResult';
		var tdur = '';
		if (step == 'yes') {
			tdur = this.secondsToHms(this.state.seconds);
		}
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			jobid: localStorage.jobid,
			testId: localStorage.testId,
			results: [...this.state.result],
			email: localStorage.by,
			seconds: this.state.seconds,
			remail: this.state.remail,
			genid: this.state.genid,
			allqnos: this.state.allqnos,
			qcount: this.state.qcount,
			step: step,
			technames: this.state.technames,
			tcount: this.state.tcount,
			rname: this.state.rname,
			tname: localStorage.name,
			tdur: tdur,
		};
		const getuurl = apiurl + ftpquest;
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => { //eslint-disable-line
				if (this.state.test_given) {
					// eslint-disable-next-line
					this.props.history.push({ //eslint-disable-line
						pathname: '/testreport',
						testId: localStorage.testId,
						email: localStorage.by,
						test_given: this.state.test_given,
					});
				} else {
					this.setState({
						okbtn: 1,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	render() {
		const { tinymceapikey } = this.state; //eslint-disable-line
		return (
			<div>
				{this.state.showCEbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Test
											<span className="account__logo-accent"> Completed!</span>
										</span>
									</h3>
								</div>

								<div style={{ color: 'red' }}>You will be Contacted Soon</div>
								<ul>
									<li>You have complete your test </li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUser}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						{this.state.taken ? (
							<Col md={12}>
								<Card>
									<CardBody>
										<div className="card__title">
											<h5 className="bold-text">
												Test is already completed👍🏻. Visit Reports section to
												see or download Report
											</h5>
										</div>
									</CardBody>
								</Card>
							</Col>
						) : (
							<div>
								{this.state.questions.length !== 0 &&
									this.state.questionIndex < this.state.questions.length ? (
									<Row className="nopads">
										<Col md={8}>
											<Card>
												<CardBody>
													<div className="card__title">
														<h5 className="bold-text">
															Question {this.state.questionIndex + 1}/
															{this.state.questions.length} -{' '}
															<b>
																{
																	this.state.questions[this.state.questionIndex]
																		.keyword
																}
															</b>

														</h5>
													</div>

													{this.state.questionIndex !== undefined ? (
														<div
															style={{
																display: 'flex',
																flexDirection: 'column',
																marginTop: 40,
															}}
														>
															<div
																className="pt-4 testquestion"
																style={{
																	fontSize: 16,
																}}
															>
																<b
																	className="radioabcd"
																	style={{ float: 'left' }}
																>
																	Q:
																</b>
																<Editor
																	apiKey={tinymceapikey}
																	disabled={true}
																	value={
																		'<p>' +
																		this.state.questions[
																		this.state.questionIndex
																		] &&
																		this.state.questions[
																			this.state.questionIndex
																		].question + '</p>'
																	}
																	init={{
																		inline: true,
																		height: 100,
																		menubar: false,

																		toolbar: '',
																	}}
																	style={{ marginLeft: '40px' }}
																/>
															</div>

															{this.state.questions[this.state.questionIndex] &&
																this.state.questions[this.state.questionIndex]
																	.questype == 1 ? (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																	}}
																>
																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 0
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}

																	>
																		<b
																			className="radioabcd"
																			style={{ float: 'left' }}
																		>
																			<input
																				className="radio-btn__radio radioabcdp"
																				name="options"
																				type="radio"
																				onChange={() => {
																					this.setState({
																						answerMarked: 0,
																						questionAnswered: true,
																						optionClicked: 0,

																					});
																				}}
																				checked={this.state.optionClicked == 0}
																			/>{' '}
																			A:
																		</b>

																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[0] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 1
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}

																	>
																		<b
																			className="radioabcd"
																			style={{ float: 'left' }}
																		>
																			<input
																				className="radio-btn__radio radioabcdp"
																				name="options"
																				type="radio"
																				onChange={() => {
																					this.setState({
																						answerMarked: 1,
																						questionAnswered: true,
																						optionClicked: 1,

																					});
																				}}
																				checked={this.state.optionClicked == 1}
																			/>{' '}
																			B:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[1] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 2
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}

																	>
																		<b
																			className="radioabcd"
																			style={{ float: 'left' }}
																		>
																			<input
																				className="radio-btn__radio radioabcdp"
																				name="options"
																				type="radio"
																				onChange={() => {
																					this.setState({
																						answerMarked: 2,
																						questionAnswered: true,
																						optionClicked: 2,

																					});
																				}}
																				checked={this.state.optionClicked == 2}
																			/>{' '}
																			C:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[2] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 3
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}

																	>
																		<b
																			className="radioabcd"
																			style={{ float: 'left' }}
																		>
																			<input
																				className="radio-btn__radio radioabcdp"
																				name="options"
																				type="radio"
																				onChange={() => {
																					this.setState({
																						answerMarked: 3,
																						questionAnswered: true,
																						optionClicked: 3,

																					});
																				}}
																				checked={this.state.optionClicked == 3}
																			/>{' '}
																			D:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[3] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>
																</div>
															) : (
																''
															)}

															{this.state.questions[this.state.questionIndex] &&
																this.state.questions[this.state.questionIndex]
																	.questype == 2 ? (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																	}}
																>
																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 0
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}

																	>
																		<b
																			className="radioabcd"
																			style={{ float: 'left' }}
																		>
																			<input
																				className="radio-btn__radio radioabcdp"
																				name="options"
																				type="radio"
																				onChange={() => {
																					this.setState({
																						answerMarked: 0,
																						questionAnswered: true,
																						optionClicked: 0,

																					});
																				}}
																				checked={this.state.optionClicked == 0}
																			/>{' '}
																			A:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[0] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>

																	<div
																		className="pt-4"
																		style={{
																			cursor: 'pointer',
																			fontSize: 16,
																			backgroundColor:
																				this.state.questionAnswered &&
																					this.state.questions[
																					this.state.questionIndex
																					] &&
																					this.state.optionClicked == 1
																					? '#A9FAFF'
																					: '#FFFFFF',
																		}}

																	>
																		<b
																			className="radioabcd"
																			style={{ float: 'left' }}
																		>
																			<input
																				className="radio-btn__radio radioabcdp"
																				name="options"
																				type="radio"
																				onChange={() => {
																					this.setState({
																						answerMarked: 1,
																						questionAnswered: true,
																						optionClicked: 1,

																					});
																				}}
																				checked={this.state.optionClicked == 1}
																			/>{' '}
																			B:
																		</b>
																		<Editor
																			apiKey={tinymceapikey}
																			disabled={true}
																			value={
																				'<p>' +
																				this.state.questions[
																				this.state.questionIndex
																				] &&
																				this.state.questions[
																					this.state.questionIndex
																				].options[1] + '</p>'
																			}
																			init={{
																				inline: true,
																				height: 100,
																				menubar: false,

																				toolbar: '',
																			}}
																		/>
																	</div>
																</div>
															) : (
																''
															)}
															{this.state.questions[this.state.questionIndex] &&
																this.state.questions[this.state.questionIndex]
																	.questype == 3 ? (
																<div
																	style={{
																		display: 'flex',
																		flexDirection: 'column',
																	}}
																>
																	<div className="form__form-group">
																		<span className="form__form-group-label">
																			Write Answer
																		</span>
																		<div className="form__form-group-field">
																			<Editor
																				apiKey={tinymceapikey}
																				value={
																					'<p>' +
																					this.state.editorvalue[
																					this.state.questionIndex
																					] +
																					'</p>'
																				}
																				init={{
																					inline: true,
																					height: 100,
																					menubar: false,
																					plugins: [
																						'advlist autolink lists link image',
																						'charmap print preview anchor help',
																						'searchreplace visualblocks code',
																						'insertdatetime media table paste wordcount',
																					],
																					toolbar: '',
																				}}
																				onEditorChange={this.handleAnswerChange.bind(
																					this,
																				)}
																			/>
																		</div>
																	</div>
																</div>
															) : (
																''
															)}
														</div>
													) : (
														''
													)}

													{this.state.okbtn == 1 ? (<Button

														onClick={() => {

															let newquesid = this.state.questions[
																this.state.questionIndex
															].id;
															let indexOfObj = this.state.result.find(
																(res) => {
																	return (
																		res.technology ===
																		this.state.questions[
																			this.state.questionIndex
																		].technology
																	);
																},
															);
															let tech =
																indexOfObj !== []
																	? this.state.result.indexOf(indexOfObj)
																	: undefined;
															if (
																this.state.answerMarked ==
																this.state.questions[
																	this.state.questionIndex
																].correct
															) {



																let currentScore =
																	tech !== undefined
																		? this.state.result[tech].score
																		: 0;

																let correctAnswers =
																	tech !== undefined
																		? this.state.result[tech].correct
																		: 0;

																this.setState(
																	{
																		result: update(this.state.result, {
																			[`${tech}`]: {
																				correct: { $set: correctAnswers + 1 },
																				score: { $set: currentScore + 1 },
																				[newquesid]: {
																					$set: this.state.answerMarked,
																				},
																			},
																		}),

																	},

																);
															} else if (
																this.state.answerMarked !=
																this.state.questions[
																	this.state.questionIndex
																].correct
															) {



																let incorrectAnswers =
																	tech !== undefined
																		? this.state.result[tech].incorrect
																		: 0;
																this.setState(
																	{
																		result: update(this.state.result, {
																			[`${tech}`]: {
																				incorrect: {
																					$set: incorrectAnswers + 1,
																				},
																				[newquesid]: {
																					$set: this.state.answerMarked,
																				},
																			},
																		}),

																	},

																);
															} else if (this.state.answerMarked >= 5) {
																console.log('Not marked');



																let unattemptedAnswers =
																	tech !== undefined
																		? this.state.result[tech].unattempted
																		: 0;
																this.setState(
																	{
																		result: update(this.state.result, {
																			[`${tech}`]: {
																				unattempted: {
																					$set: unattemptedAnswers + 1,
																				},
																				[newquesid]: {
																					$set: 'Not Answered',
																				},
																			},
																		}),

																	},

																);
															}

															if (
																this.state.questionIndex + 1 <
																this.state.questions.length
															) {
																this.setState(
																	{
																		questionIndex: this.state.questionIndex + 1,
																		currentDuration: this.state.questions[
																			this.state.questionIndex
																		].Duration,
																		questionAnswered: false,
																		optionClicked: 5,
																		answerMarked: 5,
																		okbtn: 2,
																	},
																	() => {
																		this.generateResult('no');
																	},
																);
															} else if (
																this.state.questionIndex + 1 ==
																this.state.questions.length
															) {
																this.setState(
																	{
																		noRulesViolated: true,
																		test_given: true,
																	},
																	() => {
																		this.generateResult('yes');
																	},
																);
															}

														}
														}
														style={{ float: 'right' }}
													>
														{this.state.questionIndex + 1 ==
															this.state.questions.length
															? ' End Test'
															: 'Next'}
													</Button>
													) : null}
												</CardBody>
											</Card>
										</Col>
										<Col md={3}>
											<Card>
												<CardBody>
													<div className="card__title">
														<h5 className="bold-text">Duration</h5>
													</div>
													{this.state.okbtn == 1 && this.state.currentDuration ? (
														<ReactCountdownClock
															seconds={this.state.currentDuration * 60}
															showMilliseconds={false}
															alpha={0.9}
															size={100}
															color="green"
															onComplete={() => {
																let cnewquesid = this.state.questions[
																	this.state.questionIndex
																].id;
																let indexOfObj = this.state.result.find(
																	(res) => {
																		return (
																			res.technology ===
																			this.state.questions[
																				this.state.questionIndex
																			].technology
																		);
																	},
																);
																let tech =
																	indexOfObj !== []
																		? this.state.result.indexOf(indexOfObj)
																		: undefined;
																if (!this.state.questionAnswered) {

																	let unattemptedAnswers =
																		tech !== undefined
																			? this.state.result[tech].unattempted
																			: 0;
																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					unattempted: {
																						$set: unattemptedAnswers + 1,
																					},
																					[cnewquesid]: {
																						$set: 'Not Answered',
																					},
																				},
																			}),

																		}
																	);
																} else if (this.state.questionAnswered) {

																	let currentScore =
																		tech !== undefined
																			? this.state.result[tech].score
																			: 0;

																	let correctAnswers =
																		tech !== undefined
																			? this.state.result[tech].correct
																			: 0;

																	this.setState(
																		{
																			result: update(this.state.result, {
																				[`${tech}`]: {
																					correct: { $set: correctAnswers + 1 },
																					score: { $set: currentScore + 1 },
																					[cnewquesid]: {
																						$set: this.state.answerMarked,
																					},
																				},
																			}),

																		},

																	);
																}
																if (
																	this.state.questionIndex + 1 <
																	this.state.questions.length
																) {
																	this.setState(
																		{
																			questionIndex:
																				this.state.questionIndex + 1,
																			currentDuration: this.state.questions[
																				this.state.questionIndex
																			].Duration,
																			questionAnswered: false,
																			optionClicked: 5,
																			answerMarked: 5,
																			okbtn: 2,
																		},
																		() => {

																			this.generateResult('no');
																		},
																	);
																} else if (
																	this.state.questionIndex + 1 ==
																	this.state.questions.length
																) {
																	this.setState(
																		{
																			noRulesViolated: true,
																			test_given: true,
																		},
																		() => {
																			this.generateResult('yes');
																		},
																	);
																}
															}}
														/>
													) : ('Wait')}
												</CardBody>
											</Card>
										</Col>
									</Row>
								) : (
									''
								)}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default StartTest;
