import React, { PureComponent } from 'react';

import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfileComp from './TopbarProfileComp';
// import TopbarMail from './TopbarMail';
// import TopbarLanguage from './TopbarLanguage';
// import TopbarNotification from './TopbarNotification'; 
// import TopbarSearch from './TopbarSearch';
import Flagimg from '../Flagimg';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	UncontrolledDropdown,
} from 'reactstrap';
var alldashboards = '[]';
if (localStorage.dashboards) {
	alldashboards = localStorage.dashboards;
}
const initialstate = {
	showrecsnow: localStorage.viewrecruiter,
	dashboards: JSON.parse(alldashboards)
};
class TopbarWithNavigation extends PureComponent {
	constructor(props) {
		super(props);
		localStorage.viewrecruiter;

	}
	state = { ...initialstate };
	static propTypes = {
		changeMobileSidebarVisibility: PropTypes.func.isRequired,
	};
	handleCkey = () => {
		localStorage.setItem('viewrecruiter', '');
		location.assign('/dashboard');
	};
	render() {
		const { changeMobileSidebarVisibility } = this.props;

		return (
			<div className="topbar topbar--navigation">
				{this.state.dashboards.length > 0 ? (
					<div className="topbar__wrapper">
						<div className="topbar__left">
							<TopbarSidebarButton
								changeMobileSidebarVisibility={changeMobileSidebarVisibility}
							/>
							<Link className="topbar__logo" to="/dashboard" />
						</div>
						<nav className="topbar__nav">
							<UncontrolledDropdown className="topbar__nav-dropdown">
								<DropdownToggle className="topbar__nav-dropdown-toggle">
									Dashboards <DownIcon />
								</DropdownToggle>
								<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
									{this.state.dashboards && this.state.dashboards.map((dashboard, index) => {
										var flagname = dashboard.ccode;
										flagname = flagname.toLowerCase();
										return (
											<DropdownItem key={index}>
												<span className="topbar__link"
													onClick={() => {

														localStorage.setItem('dashname', dashboard.dashboard);
														localStorage.setItem('dashimg', flagname);
														localStorage.setItem('dashboard', dashboard.id);
														// // eslint-disable-next-line
														this.props.history.push("/staffingboard");
														location.assign('/staffingboard');
													}}
												>

													<Flagimg flagname={flagname} clname="flagimg" />
													{dashboard.dashboard}

												</span>

											</DropdownItem>
										)
									})}

								</DropdownMenu>
							</UncontrolledDropdown>
							{/* <UncontrolledDropdown className="topbar__nav-dropdown">
								<Link className="topbar__link" to="/usersroles">

									<p className="topbar__link-title"> Users & Roles</p>
								</Link>
							</UncontrolledDropdown> */}

							<UncontrolledDropdown className="topbar__nav-dropdown">
								<DropdownToggle className="topbar__nav-dropdown-toggle">
									Reports <DownIcon />
								</DropdownToggle>
								<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
									<DropdownItem>
										<Link className="topbar__link" to="/reportspage">
											<span className={`topbar__link-icon lnr lnr-layers`} />
											<p className="topbar__link-title"> Reports </p>
										</Link>

									</DropdownItem>
									<DropdownItem>
										<Link className="topbar__link" to="/reportseven">
											<span className={`topbar__link-icon lnr lnr-layers`} />
											<p className="topbar__link-title"> Weekly Report </p>
										</Link>

									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
							{this.state.showrecsnow && this.state.showrecsnow ? (
								<UncontrolledDropdown className="topbar__nav-dropdown">
									<DropdownToggle className="topbar__nav-dropdown-toggle">
										Screening
										<DownIcon />
									</DropdownToggle>

									<DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
										<DropdownItem>
											<div className="topbar__category-wrap">
												<div className="topbar__link topbar__category">
													<span className={`topbar__link-icon lnr lnr-smart-phone`} />
													<p className="topbar__link-title">
														Phone Screen  <span className="topbar__category-icon lnr lnr-chevron-right" />
													</p>
												</div>
												<div className="topbar__submenu">
													<Link className="topbar__link" to="/recruiter/addtest">
														<span className={`topbar__link-icon lnr lnr-layers`} />
														<p className="topbar__link-title"> Add Test</p>
													</Link>

													<Link className="topbar__link" to="/recruiter/savetest">
														<span className={`topbar__link-icon lnr lnr-layers`} />
														<p className="topbar__link-title"> Saved Tests</p>
													</Link>
													<Link className="topbar__link" to="/recruiter/senttests">
														<span className={`topbar__link-icon lnr lnr-layers`} />
														<p className="topbar__link-title"> Sent Test</p>
													</Link>
													<Link className="topbar__link" to="/recruiter/comtest">
														<span className={`topbar__link-icon lnr lnr-layers`} />
														<p className="topbar__link-title"> Completed Tests</p>
													</Link>


												</div>
											</div>

										</DropdownItem>
										{/* <DropdownItem>
								<TopbarNavCategory title="Custom Tests" icon="smart-phone">
									<TopbarNavLink
										title="Add Custom Test"
										route="/recruiter/addcustomtest"
									/>
									<TopbarNavLink
										title="UnSaved Test List"
										route="/recruiter/mycustoms"
									/>
									<TopbarNavLink
										title="Saved Test List"
										route="/recruiter/customsavedtest"
									/>
									<TopbarNavLink
										title="Sent Test List"
										route="/recruiter/customsenttest"
									/>
									<TopbarNavLink
										title="Completed Custom Tests"
										route="/recruiter/cuscomtest"
									/>
								</TopbarNavCategory>
							</DropdownItem> */}
									</DropdownMenu>
								</UncontrolledDropdown>
							) : null}
							{this.state.showrecsnow && this.state.showrecsnow ? (
								<div
									style={{ cursor: 'pointer' }}
									onClick={this.handleCkey}
									className="topbar__nav-link"
								>
									Back to Dashboard
								</div>
							) : null}
						</nav>
						<div className="topbar__right">
							{/* <TopbarSearch /> */}
							{/* <TopbarNotification /> */}
							{/* <TopbarMail new /> */}
							<TopbarProfileComp />
							{/* <TopbarLanguage /> */}
						</div>
					</div>
				) : null}
			</div>
		);
	}
}
export default withRouter(TopbarWithNavigation);