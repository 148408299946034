import React, { Component } from 'react';
/* eslint-disable  */
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
// import EditableLabel from 'react-inline-editing';
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
// import Switch from 'react-switch';
// import SearchIcon from 'mdi-react/SearchIcon';
var dateFormat = require('dateformat');
import swal from 'sweetalert';
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
	Modal,
	Table,
	Badge,
} from 'reactstrap';

import EyeIcon from 'mdi-react/EyeIcon';
import EditIcon from 'mdi-react/EditIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import Select from 'react-select';

import Alert from '../../../../shared/components/Alert';


const apiurl = MY_API_URL;
const initialstate = {

	showAlert: false,
	alertType: '',
	alertText: '',
	addJobModal: false,
	editJobModal: false,
	ajobs: 0,
	bjobs: 0,
	tjobs: 0,
	token: localStorage.token,
	currentcolor: [
		'#000000',
	],
	req_documents: [],
	emp_types: [],
	interview_modes: [{
		value: '',
		label: (<div>
			Select Interview Mode
		</div>
		),
	}],
	security_clearances: [{
		value: '',
		label: (<div>
			Select Security Clearance
		</div>
		),
	}],
	dash_states: [{
		value: '',
		label: (<div>
			Select State
		</div>
		),
	}],
	job_types: [{
		value: '',
		label: (<div>
			Select Job Type
		</div>
		),
	}],
	visatypess: [],

	client: '',
	jobtitle: '',
	job_type: '',
	duration: '',
	location: '',
	pincode: '',
	req_document: '',
	emp_type: '',
	interview_mode: '',
	security_clearance: '',
	dash_state: '',
	visatypes: '',
	jd: '',
	rskills: '',
	payrate: '',
	workexp: '',
	edureq: '',
	testjobid: 0,
	jobtestkeyids: '',
	content_add: '',
	myItems: [],
	testEmails: [],
	isalready: false,
};
class JobsList extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.deactivateJob = this.deactivateJob.bind(this);
		this.viewJobApps = this.viewJobApps.bind(this);
		this.lastId = -1;



	}
	state = { ...initialstate };
	componentDidMount() {

		if (localStorage.dashbid) {
			this.handleGetkeys(localStorage.dashbid);
		} else {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		}


		// console.log('in list', localStorage.jstatus);

	}

	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		// console.log(usr_input);
		this.setState({ content_add: usr_input });
	};

	handleAddEmail(event) {
		event.preventDefault();
		console.log('testid:', this.state.generated_id);
		console.log('jobid:', this.state.selectedId);
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];
		var validemails = [];
		var rawemails = this.state.content_add.trim();
		var aallemails = rawemails
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

		aallemails.map((aallemail) => {
			let isvalidrec = re.test(aallemail);
			if (isvalidrec) {
				var newemail_string_array = aallemail.split('@');
				var domain_string_location = newemail_string_array.length - 1;
				var newemaildomain = newemail_string_array[domain_string_location];
				if (mainemaildomain == newemaildomain) {
					this.setState({
						content_add: '',
					});
				} else {
					var dnjk = this.state.testEmails.includes(aallemail);

					if (dnjk) {
						this.setState({
							content_add: '',
						});
					} else {
						validemails.push(aallemail);
					}
				}
			} else {
				this.setState({
					content_add: '',
				});
			}
		});
		// console.log(validemails);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		if (validemails.length >= 1) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				testemail: validemails,
				testid: this.state.generated_id,
				jobid: this.state.selectedId,
			};
			const testidurl = apiurl + 'checkjobemails';
			axios
				.post(testidurl, postData, {
					headers: headers,
				})
				.then(async (response) => {
					// console.log(response);
					if (response.data.data[2].length >= 1) {
						// var areusers = response.data.data[1].toString();
						var aretook = response.data.data[2].toString();
						// console.log(aretook);
						swal('Already Applied or Sent ' + aretook, {
							icon: 'error',
						});
						// swal('Employers ' + areusers, {
						// 	icon: 'error',
						// });
					}
					if (response.data.data[0].length >= 1) {
						const allemails = response.data.data[0];
						let isexist = [];
						await Promise.all(
							allemails.map((temails) => {
								return new Promise((resolve) => {
									this.state.myItems.filter((listitem) => {
										if (listitem.content == temails) {
											isexist.push(temails);
										}
									});
									// console.log('isexist ', isexist.length);

									if (isexist.length >= 1) {
										resolve('');
									} else {
										newArray.push({
											content: temails,
											id: ++this.lastId,
										});
										testEmails.push(temails);
										this.setState(
											{
												myItems: newArray,
												content_add: '',
											},
											() => {
												console.log(this.state.myItems);
												resolve('');
											},
										);
									}
								});
							}),
						);
						if (isexist.length >= 1) {
							isexist = isexist.toString();
							swal(isexist + ' Already Added', {
								icon: 'error',
							});
						}
					}
				});
		}
	}
	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			// console.log('items ', this.state.myItems);
			// console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	sendjobtoemails = (event) => {

		event.preventDefault();
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'sendJobToEmails';
		var postData = {
			email: this.state.testEmails,
			jobid: this.state.selectedId,
			recid: localStorage.usercode,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				// console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					swal(
						' Job ' + response.data.message,
						{
							icon: 'success',
						},
					);
					this.setState({
						showAlert: false,
						showEbox: false,
					}, () => {
						this.handleGetkeys(localStorage.dashbid);

					});
				}
			})
			.catch((error) => { //eslint-disable-line
				// console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};


	handlejobSubmit = (event) => {
		localStorage.setItem('whatnow', '');
		localStorage.setItem('testjobtitle', '');
		localStorage.setItem('testjobid', '');
		localStorage.setItem('jobtestkeyids', '');
		event.preventDefault();
		console.log(this.state.req_document);
		var vtypes = [];
		var reqdocs = [];
		var empts = [];

		if (this.state.dash_state == '') {
			swal('Select State', {
				icon: 'warning',
			});
		} else if (this.state.job_type == '') {
			swal('Select Job Type', {
				icon: 'warning',
			});
		} else if (this.state.emp_type == '') {
			swal('Select Employment Type', {
				icon: 'warning',
			});
		} else if (this.state.visatypes.length <= 0) {
			swal('Select Visa Types', {
				icon: 'warning',
			});
		} else {

			var vts = this.state.visatypes;
			vts.map((vt) => {
				vtypes.push(vt.value);
			});
			var rqs = this.state.req_document;
			if (rqs.length >= 1) {
				rqs.map((rq) => {
					reqdocs.push(rq.value);
				});
			}
			var emps = this.state.emp_type;
			if (emps.length >= 1) {
				emps.map((rq) => {
					empts.push(rq.value);
				});
			}


			vtypes = JSON.stringify(vtypes);
			reqdocs = JSON.stringify(reqdocs);
			empts = JSON.stringify(empts);
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const addkeyurl = apiurl + 'addjob';
			var postData = {
				client: this.state.client,
				jobtitle: this.state.jobtitle,
				job_type: this.state.job_type,
				emp_type: empts,
				duration: this.state.duration,
				location: this.state.location,
				pincode: this.state.pincode,
				req_document: reqdocs,
				interview_mode: this.state.interview_mode,
				security_clearance: this.state.security_clearance,
				dash_state: this.state.dash_state,
				visatypes: vtypes,
				jd: this.state.jd,
				rskills: this.state.rskills,
				payrate: this.state.payrate,
				workexp: this.state.workexp,
				edureq: this.state.edureq,
				dashbid: localStorage.dashbid,
				recid: localStorage.usercode,
				compcode: localStorage.compcode,
			};
			axios
				.post(addkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState(
							{
								showAlert: false,
								addJobModal: false,
								editJobModal: false,
							},
							() => {
								localStorage.setItem('whatnow', 'new');
								localStorage.setItem('testjobtitle', response.data.jobtitle);
								localStorage.setItem('testjobid', response.data.jobid);
								localStorage.setItem('testjobcode', response.data.jobcode);
								localStorage.setItem('jobtestkeyids', response.data.akeywords);

								// swal(this.state.jobtitle + ' ' + response.data.message, {
								// 	icon: 'success',
								// }).then(() => {
								// 	// eslint-disable-next-line
								this.props.history.push('/addjobtest'); //eslint-disable-line

								// });

								// eslint-disable-next-line
								this.props.history.push('/addjobtest'); //eslint-disable-line
								// this.handleGetkeys(localStorage.dashbid);
							},
						);
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		}
	};

	handleAkey = () => {
		this.setState({
			addJobModal: true,
			editJobModal: false,
			client: '',
			jobtitle: '',
			job_type: '',
			emp_type: '',
			duration: '',
			location: '',
			pincode: '',
			req_document: '',
			interview_mode: '',
			security_clearance: '',
			dash_state: '',
			visatypes: '',
			jd: '',
			rskills: '',
			payrate: '',
			workexp: '',
			edureq: '',
		});
	};


	handleCkey = () => {
		this.setState({
			addJobModal: false,
			editJobModal: false,
			showEbox: false,
			client: '',
			jobtitle: '',
			job_type: '',
			emp_type: '',
			duration: '',
			location: '',
			pincode: '',
			req_document: '',
			interview_mode: '',
			security_clearance: '',
			dash_state: '',
			visatypes: '',
			jd: '',
			rskills: '',
			payrate: '',
			workexp: '',
			edureq: '',
		}, () => {
			localStorage.setItem('testjobid', '');
			localStorage.setItem('jobtestkeyids', '');
		});
	};


	handleEditJob = (jobid, client, jobtitle, job_type, emp_type, duration, location, pincode, req_document, interview_mode, security_clearance, dash_state, jd, rskills, visatypes, payrate, workexp, edureq, e) => { //eslint-disable-line

		var ereq_document = [];
		this.state.req_documents.map((req_docx) => {
			var req_docy = req_document.includes(req_docx.value);
			if (req_docy) {
				ereq_document.push(req_docx);
			}
		});
		var eemp_type = [];
		this.state.emp_types.map((emp_typx) => {
			var emp_typy = emp_type.includes(emp_typx.value);
			if (emp_typy) {
				eemp_type.push(emp_typx);
			}
		});
		var evisatypes = [];
		this.state.visatypess.map((visatyp) => {
			var visatypy = visatypes.includes(visatyp.value);
			if (visatypy) {
				evisatypes.push(visatyp);
			}
		});

		this.setState({
			addJobModal: false,
			client: client,
			ejobid: jobid,
			jobtitle: jobtitle,
			job_type: job_type,
			emp_type: eemp_type,
			duration: duration,
			location: location,
			pincode: pincode,
			req_document: ereq_document,
			interview_mode: interview_mode,
			security_clearance: security_clearance,
			dash_state: dash_state,
			visatypes: evisatypes,
			jd: jd,
			rskills: rskills,
			payrate: payrate,
			workexp: workexp,
			edureq: edureq,
			editJobModal: true,
		});
	};

	handleEditjobSubmit = (event) => {
		localStorage.setItem('testjobtitle', '');
		localStorage.setItem('testjobid', '');
		localStorage.setItem('jobtestkeyids', '');
		event.preventDefault();
		console.log(this.state.req_document);
		var vtypes = [];
		var reqdocs = [];
		var empts = [];

		if (this.state.dash_state == '') {
			swal('Select State', {
				icon: 'warning',
			});
		} else if (this.state.job_type == '') {
			swal('Select Job Type', {
				icon: 'warning',
			});
		} else if (this.state.emp_type == '') {
			swal('Select Employment Type', {
				icon: 'warning',
			});
		} else if (this.state.visatypes.length <= 0) {
			swal('Select Visa Types', {
				icon: 'warning',
			});
		} else {

			var vts = this.state.visatypes;
			vts.map((vt) => {
				vtypes.push(vt.value);
			});
			var rqs = this.state.req_document;
			if (rqs.length >= 1) {
				rqs.map((rq) => {
					reqdocs.push(rq.value);
				});
			}
			var emps = this.state.emp_type;
			if (emps.length >= 1) {
				emps.map((rq) => {
					empts.push(rq.value);
				});
			}


			vtypes = JSON.stringify(vtypes);
			reqdocs = JSON.stringify(reqdocs);
			empts = JSON.stringify(empts);
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const addkeyurl = apiurl + 'editjob';
			var postData = {
				client: this.state.client,
				jobtitle: this.state.jobtitle,
				job_type: this.state.job_type,
				emp_type: empts,
				duration: this.state.duration,
				location: this.state.location,
				pincode: this.state.pincode,
				req_document: reqdocs,
				interview_mode: this.state.interview_mode,
				security_clearance: this.state.security_clearance,
				dash_state: this.state.dash_state,
				visatypes: vtypes,
				jd: this.state.jd,
				rskills: this.state.rskills,
				payrate: this.state.payrate,
				workexp: this.state.workexp,
				edureq: this.state.edureq,
				dashbid: localStorage.dashbid,
				recid: localStorage.usercode,
				jobid: this.state.ejobid,
			};
			axios
				.post(addkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						console.log('response.data.akeywords', response.data.akeywords);
						this.setState(
							{
								showAlert: false,
								addJobModal: false,
								editJobModal: false,
							});
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		}
	};

	handleGetkeys = (dashbid) => {
		console.log('localStorage.jstatus', localStorage.jstatus);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			dashbid: dashbid,
			recid: localStorage.usercode,
			status: localStorage.jstatus
		};
		const getuurl = apiurl + 'allmyjobs';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);
					const allreq_documents = response.data.allreq_documents;
					const allinterview_modes = response.data.allinterview_modes;
					const allsecurity_clearances = response.data.allsecurity_clearances;
					const alldash_states = response.data.alldash_states;
					const alljob_types = response.data.alljob_types;
					const allemp_types = response.data.allemp_types;
					const allvisatypess = response.data.allvisatypess;
					const alljobs = response.data.alljobs;
					console.log('allemp_types', allemp_types);
					let tjobs = 0;
					alljobs.map(() => {
						tjobs = tjobs + 1;
					});
					this.setState({
						allreq_documents: allreq_documents,
						allemp_types: allemp_types,
						allvisatypess: allvisatypess,
						tjobs: tjobs,
						alljobs: alljobs,
						req_documents: [],
						emp_types: [],
						interview_modes: [{
							value: '',
							label: (<div>
								Select Interview Mode
							</div>
							),
						}],
						security_clearances: [{
							value: '',
							label: (<div>
								Select Security Clearance
							</div>
							),
						}],
						dash_states: [{
							value: '',
							label: (<div>
								Select State
							</div>
							),
						}],
						job_types: [{
							value: '',
							label: (<div>
								Select Job Type
							</div>
							),
						}],

						visatypess: [],


					}, () => {
						allreq_documents.map((req_document) => {
							this.state.req_documents.push({
								value: req_document.id,
								label: (
									<div>
										{req_document.req_document}
									</div>
								),
							});
						});

						allemp_types.map((emp_type) => {
							this.state.emp_types.push({
								value: emp_type.id,
								label: (
									<div>
										{emp_type.emp_type}
									</div>
								),
							});
						});

						allinterview_modes.map((interview_mode) => {
							this.state.interview_modes.push({
								value: interview_mode.id,
								label: (
									<div>
										{interview_mode.interview_mode}
									</div>
								),
							});
						});
						allsecurity_clearances.map((security_clearance) => {
							this.state.security_clearances.push({
								value: security_clearance.id,
								label: (
									<div>
										{security_clearance.security_clearance}
									</div>
								),
							});
						});
						alldash_states.map((dash_state) => {
							this.state.dash_states.push({
								value: dash_state.dash_state,
								label: (
									<div>
										{dash_state.dash_state}
									</div>
								),
							});
						});
						alljob_types.map((job_type) => {
							this.state.job_types.push({
								value: job_type.id,
								label: (
									<div>
										{job_type.job_type}
									</div>
								),
							});
						});
						allvisatypess.map((visatypes) => {
							this.state.visatypess.push({
								value: visatypes.id,
								label: (
									<div>
										{visatypes.visatypes}
									</div>
								),
							});
						});
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};
	publishjob = (jobid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			dashbid: localStorage.dashbid,
			recid: localStorage.usercode,
			jobid: jobid,
			status: 1
		};
		const getuurl = apiurl + 'setjob';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);
					this.handleGetkeys(localStorage.dashbid);

				} else {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};

	deactivateJob = (jobid, jobtitle) => { //eslint-disable-line
		swal('Type "deactivate job" in the input box', {
			content: "input",
		})
			.then((value) => {
				if (value == "deactivate job") {
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};
					var postData = {
						dashbid: localStorage.dashbid,
						recid: localStorage.usercode,
						jobid: jobid,
						status: 1
					};
					const getuurl = apiurl + 'desetjob';
					axios
						.post(getuurl, postData, {
							headers: headers,
						})
						.then((response) => {
							if (!response.data.error) {
								// console.log('data', response.data);
								swal("Deactivated!", jobtitle, "success");
								this.handleGetkeys(localStorage.dashbid);

							} else {
								swal("Failed!", jobtitle, "warning");
							}
						})
						.catch(() => {
							//localStorage.clear();
							// eslint-disable-next-line
							this.props.history.push('/dashboard'); //eslint-disable-line
						});

				} else {
					swal(`Invalid input: ${value}`);
				}

			});

	};


	handleSelectDocTypeChange = (req_document) => {
		// let req_document = selectedOption.value;
		// this.setState({ req_document: req_document });
		this.setState({ req_document }, () => {
			console.log(`req_document selected:`, this.state[req_document]);
		});

	};
	handleSelectEmpTypeChange = (emp_type) => {
		this.setState({ emp_type }, () => {
			console.log(`emp_type selected:`, this.state[emp_type]);
		});
	};
	handleSelectIntModeChange = (selectedOption) => {
		let interview_mode = selectedOption.value;
		this.setState({ interview_mode: interview_mode });
	};
	handleSelectSecClrChange = (selectedOption) => {
		let security_clearance = selectedOption.value;
		this.setState({ security_clearance: security_clearance });
	};
	handleSelectDashStateChange = (selectedOption) => {
		let dash_state = selectedOption.value;
		this.setState({ dash_state: dash_state });
	};
	handleSelectJobTypeChange = (selectedOption) => {
		let job_type = selectedOption.value;
		this.setState({ job_type: job_type });
	};
	handleSelectVisaTypesChange = (visatypes) => {
		// let visatypes = selectedOption.value;
		// this.setState({ visatypes: visatypes });
		this.setState({ visatypes });
		console.log(`visatypes selected:`, visatypes);
		console.log(`this.state.visatypes:`, this.state.visatypes);
	};
	showThisTest = (showthistest) => {
		this.setState({ showthistest });
	};
	editJobTest = (testjobid, jobtestkeyids, testid, jobtitle, jobcode, whatnow, e) => { //eslint-disable-line


		localStorage.setItem('whatnow', whatnow);
		localStorage.setItem('testid', testid);
		localStorage.setItem('jobtestkeyids', jobtestkeyids);
		localStorage.setItem('testjobid', testjobid);
		localStorage.setItem('testjobtitle', jobtitle);
		localStorage.setItem('testjobcode', jobcode);
		// eslint-disable-next-line
		this.props.history.push('/' + whatnow + 'jobtest'); //eslint-disable-line

	};

	viewJobApps = (jobapplistid, e) => { //eslint-disable-line


		localStorage.setItem('jobapplistid', jobapplistid);
		// eslint-disable-next-line
		this.props.history.push('/jobapplist'); //eslint-disable-line

	};

	render() {
		const { client, jobtitle, job_type, emp_type, duration, location, pincode, req_document, interview_mode, security_clearance, dash_state, jd, rskills, visatypes, payrate, workexp, edureq } = this.state;
		return (
			<div>
				<Col md={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">
									Jobs

									<Button
										className="float-right btn-sm"
										color="success"
										onClick={this.handleAkey}
									>
										+
									</Button>
								</h3>

							</div>
						</CardBody>
					</Card>
				</Col>

				{this.state.alljobs && this.state.alljobs.map((xjob, index) => {

					var job = xjob.thisjob;
					var test = xjob.thistest;
					var istest = xjob.istest;
					var jobapps = xjob.jobapps;
					var testhelper = [];

					if (istest) {
						testhelper = JSON.parse(test.helper);
					}
					let req_documentz = JSON.parse(job.req_document);
					let emp_typez = JSON.parse(job.emp_type);
					let visatypesz = JSON.parse(job.visatypes);
					var ereq_document = req_documentz;
					var eemp_type = emp_typez;
					var evisatypes = visatypesz;
					var req_document_arr = [];
					this.state.allreq_documents.map((req_docx) => {
						var req_docy = req_documentz.includes(req_docx.id);
						if (req_docy) {
							req_document_arr.push(req_docx.req_document);
						}
					});
					var emp_type_arr = [];
					this.state.allemp_types.map((emp_typx) => {
						var emp_typy = emp_typez.includes(emp_typx.id);
						if (emp_typy) {
							emp_type_arr.push(emp_typx.emp_type);
						}
					});
					var visatypes_arr = [];
					this.state.allvisatypess.map((req_docx) => {
						var req_docy = visatypesz.includes(req_docx.id);
						if (req_docy) {
							visatypes_arr.push(req_docx.visatypes);
						}
					});

					req_document_arr = req_document_arr.toString();
					visatypes_arr = visatypes_arr.toString();
					emp_type_arr = emp_type_arr.toString();

					let allkeys = JSON.parse(job.keywords);
					let jobtestkeyids = [0];
					if (allkeys.length >= 1) {
						allkeys.map((tech) => {
							jobtestkeyids.push(tech.id);
						})
					}
					let posted = dateFormat(job.posted, 'd-m-yyyy h:mm:ss',);
					return (
						<Col md={12} key={index}>
							<Card >
								<CardBody style={{ position: 'relative' }}>

									<span onClick={this.handleEditJob.bind(this, job.id, job.client, job.jobtitle, job.ejob_type, eemp_type, job.duration, job.location, job.pincode, ereq_document, job.einterview_mode, job.esecurity_clearance, job.dash_state, job.jd, job.rskills, evisatypes, job.payrate, job.workexp, job.edureq)} style={{ cursor: 'pointer', marginRight: '20px', position: 'absolute', left: '10px', zIndex: '99' }} ><EditIcon /> </span>
									<span style={{ cursor: 'pointer', marginRight: '20px', position: 'absolute', right: '10px', zIndex: '99' }} >
										<b style={{ color: 'blue' }}>{job.jobcode}</b><br />
										{job.status == 1 ? (
											<>
												<b style={{ color: 'green' }}>Status: Published</b><br />
												<div
													className="jdkeyslist"
													style={{
														backgroundColor: 'green',
														width: 'auto',
													}}
													onClick={() => {
														this.setState({
															showEbox: true,
															selectedId: job.id,
															generated_id: job.generated_id,
															myItems: [],
															testEmails: [],
														});
													}}
												>
													Send Job
												</div>
												<br />
												<br />
												<br />
												<div
													className="jdkeyslist"
													style={{
														backgroundColor: 'red',
														width: 'auto',
													}}
													onClick={this.deactivateJob.bind(this, job.id, job.jobtitle)}
												>
													Deactivate
												</div>
											</>


										) : null}

										{job.status == 0 ? (
											<>
												<b style={{ color: 'red' }}>Status: Draft</b><br />

												<div
													className="jdkeyslist"
													style={{
														backgroundColor: 'green',
														width: 'auto',
													}}
													onClick={this.publishjob.bind(this, job.id)}
												>
													Publish Job
												</div>

											</>
										) : null}


										{job.status == 3 ? (
											<>
												<b style={{ color: 'red' }}>Status: Deactivated</b><br />

												<div
													className="jdkeyslist"
													style={{
														backgroundColor: 'green',
														width: 'auto',
													}}
													onClick={this.publishjob.bind(this, job.id)}
												>
													Activate
												</div>

											</>
										) : null}
									</span>
									<Col md={12}>
										<div className="card__title" style={{ marginBottom: '5px' }}>
											<h4 className="bold-text">

												{job.jobtitle}
												<div
													className="jdkeyslist"
													style={{
														backgroundColor: 'green',
														width: 'auto',
														marginLeft: '20px',
													}}
													onClick={this.viewJobApps.bind(this, job.id)}
												>
													<EyeIcon size={10} />  {jobapps}
												</div>


											</h4>
											<b>Date: </b>{posted}
										</div>

									</Col>

									<Row>
										<Col md={3}><p><b>Job Type:</b> {job.job_type}</p></Col>



										<Col md={3}><p><b>Duration:</b> {job.duration}</p></Col>
										<Col md={3}><p><b>Location:</b> {job.location}</p></Col>
										<Col md={3}><p><b>State:</b> {job.dash_state}</p></Col>
										<Col md={3}><p><b>Zipcode:</b> {job.pincode}</p></Col>




										<Col md={3}><p><b>Visa types Accepted:</b> {visatypes_arr}</p></Col>
										<Col md={3}><p><b>Employment Types:</b> {emp_type_arr}</p></Col>
										<Col md={3}><p><b>Pay Rate:</b> {job.payrate}</p></Col>
										<Col md={3}><p><b>Required documents:</b> {req_document_arr}</p></Col>
										<Col md={3}><p><b>Interview Mode:</b> {job.interview_mode}</p></Col>
										<Col md={3}><p><b>Security Clearance:</b> {job.security_clearance}</p></Col>


									</Row>
									<Row>
										<Col md={12} style={{ margin: '5px 0px 5px 0px' }}><p><b>Client:</b> {job.client}</p></Col>
										<Col md={12} style={{ marginBottom: '5px' }}><p><b>Extracted Technologies from Job Description:</b> {allkeys.length >= 1 &&
											allkeys.map((tech, index) => {
												return (

													<span key={index} >
														{tech.keyword}{', '}
													</span>
												);
											})}</p></Col>
										<Col md={12} style={{ marginBottom: '5px' }}><p><b>Job Description:</b> {job.jd}</p></Col>

										<Col md={12} style={{ marginBottom: '5px' }}><p><b>Education Requirements:</b> {job.edureq}</p></Col>

										<Col md={12} style={{ marginBottom: '5px' }}><p><b>Work Experience requirements:</b> {job.workexp}</p></Col>

										<Col md={12} style={{ marginBottom: '5px' }}><p><b>Required Skills:</b> {job.rskills}</p></Col>
										<Col md={12} style={{ marginBottom: '15px' }}>
											<p>
												{istest == 1 ? (
													<>
														<span style={{ cursor: 'pointer' }} onClick={this.editJobTest.bind(this, job.id, job.keywords, test.id, job.jobtitle, job.jobcode, 'edit')}><EditIcon /></span><b>Test ID:</b>  {job.generated_id} {' '}
														{this.state.showthistest == job.id ? (
															<b style={{ cursor: 'pointer', fontSize: '12px' }} onClick={this.showThisTest.bind(this, 0)}><MinusIcon /> Hide</b>
														) : (
															<b onClick={this.showThisTest.bind(this, job.id)}> <PlusIcon style={{ cursor: 'pointer', fontSize: '12px' }} /> Show</b>
														)}
													</>
												) : (
													<>
														<span style={{ cursor: 'pointer' }} onClick={this.editJobTest.bind(this, job.id, job.keywords, test.id, job.jobtitle, job.jobcode, 'add')}><PlusIcon /><b> Add Test</b></span>
													</>
												)}
											</p></Col>
									</Row>
									{this.state.showthistest && this.state.showthistest == job.id ? (
										<Table responsive striped bordered hover>
											<thead>
												<tr>

													<th>Date</th>
													<th>Test Id</th>
													<th>Details</th>
												</tr>
											</thead>
											<tbody>

												<tr>
													<td>{dateFormat(test.posted, 'd-m-yyyy')}</td>
													<td>
														{test.generated_id}
														<br />
														{test.ttype == 'my' ? (
															<Badge color={'success'}>Private</Badge>
														) : null}
													</td>

													<td>
														<Table>
															<thead>
																<tr>
																	<th>Technology</th>
																	<th>No of Questions</th>
																</tr>
															</thead>
															<tbody>
																{testhelper.map((tech, index) => {

																	return (
																		<tr key={index}>
																			<td>{tech.difficultyLevel}</td>

																			<td>{tech.questions}</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</td>
												</tr>


											</tbody>
										</Table>
									) : null}



								</CardBody>
							</Card>

						</Col>
					);
				})
				}


				<Modal
					isOpen={this.state.addJobModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">Add  Job</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handlejobSubmit(event)}
						>
							<Row style={{ width: '100%' }}>
								<Col md={6}>

									<div className="form__form-group">
										<span className="form__form-group-label">Job Title <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="jobtitle"
												type="text"
												placeholder="Enter Job Title"
												className="form-control"
												value={jobtitle}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Job Type  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="job_type"
												value={this.state.job_types.filter(
													(option) => option.value == job_type,
												)}
												onChange={this.handleSelectJobTypeChange}
												options={this.state.job_types}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Payrate  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="payrate"
												type="text"
												placeholder="Enter Payrate"
												className="form-control"
												value={payrate}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Duration  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="duration"
												type="text"
												placeholder="Enter Duration"
												className="form-control"
												value={duration}
												onChange={this.handleChange}
												required

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Location  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="location"
												type="text"
												placeholder="Enter Location"
												className="form-control"
												value={location}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">State  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="dash_state"
												value={this.state.dash_states.filter(
													(option) => option.value == dash_state,
												)}
												onChange={this.handleSelectDashStateChange}
												options={this.state.dash_states}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Zipcode  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="pincode"
												type="text"
												placeholder="Enter Pincode"
												className="form-control"
												value={pincode}
												onChange={this.handleChange}
												pattern="\d*"

											/>
										</div>
									</div>




								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Client  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="client"
												type="text"
												placeholder="Enter client"
												className="form-control"
												value={client}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Visa Types accepted <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ minHeight: '30px', height: 'auto' }}>
											<Select
												name="visatypes"
												isMulti={true}
												value={visatypes}
												onChange={this.handleSelectVisaTypesChange}
												options={this.state.visatypess}
												className="react-select-team"
												classNamePrefix="react-select"
											/>
											{/* <Select
												name="visatypes"
												value={this.state.allvisatypess.filter(
													(option) => option.value == visatypes,
												)}
												onChange={this.handleSelectVisaTypesChange}
												options={this.state.visatypess}
												clearable={false}
												isMulti={true}
												className="react-select-team"
												classNamePrefix="react-select" 
											/> */}
										</div>
									</div>


									<div className="form__form-group">
										<span className="form__form-group-label">Required documents  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>
											<Select
												name="req_document"
												isMulti={true}
												value={req_document}
												onChange={this.handleSelectDocTypeChange}
												options={this.state.req_documents}
												className="react-select-team"
												classNamePrefix="react-select"
											/>

										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Employment Type  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>
											<Select
												name="emp_type"
												isMulti={true}
												value={emp_type}
												onChange={this.handleSelectEmpTypeChange}
												options={this.state.emp_types}
												className="react-select-team"
												classNamePrefix="react-select"
											/>

										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Interview Mode  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field" style={{ height: '40px' }}>

											<Select
												name="interview_mode"
												value={this.state.interview_modes.filter(
													(option) => option.value == interview_mode,
												)}
												onChange={this.handleSelectIntModeChange}
												options={this.state.interview_modes}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Security Clearances  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="security_clearance"
												value={this.state.security_clearances.filter(
													(option) => option.value == security_clearance,
												)}
												onChange={this.handleSelectSecClrChange}
												options={this.state.security_clearances}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>




								</Col>
								<Col md={12}>
									<div className="form__form-group">
										<span className="form__form-group-label">Job Description <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<textarea
												name="jd"
												value={jd}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Required Skills <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<textarea
												name="rskills"
												value={rskills}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Education Requirements <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<textarea
												name="edureq"
												value={edureq}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Work Experience Requirements <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<textarea
												name="workexp"
												value={workexp}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
									</div>

								</Col>
								<Col md={4}>{' '}</Col>
								<Col md={4}>{' '}</Col>
								<Col md={4}>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Next Step
									</button>
								</Col>
							</Row>


						</form>
					</div>
				</Modal>


				{/* editing modals */}

				<Modal
					isOpen={this.state.editJobModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">Edit {jobtitle}</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleEditjobSubmit(event)}
						>
							<Row style={{ width: '100%' }}>
								<Col md={6}>

									<div className="form__form-group">
										<span className="form__form-group-label">Job Title <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="jobtitle"
												type="text"
												placeholder="Enter Job Title"
												className="form-control"
												value={jobtitle}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Job Type  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="job_type"
												value={this.state.job_types.filter(
													(option) => option.value == job_type,
												)}
												onChange={this.handleSelectJobTypeChange}
												options={this.state.job_types}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Duration  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="duration"
												type="text"
												placeholder="Enter Duration"
												className="form-control"
												value={duration}
												onChange={this.handleChange}
												required

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Location  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="location"
												type="text"
												placeholder="Enter Location"
												className="form-control"
												value={location}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">State  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="dash_state"
												value={this.state.dash_states.filter(
													(option) => option.value == dash_state,
												)}
												onChange={this.handleSelectDashStateChange}
												options={this.state.dash_states}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Zipcode  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="pincode"
												type="text"
												placeholder="Enter Pincode"
												className="form-control"
												value={pincode}
												onChange={this.handleChange}
												pattern="\d*"

											/>
										</div>
									</div>




								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Client  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="client"
												type="text"
												placeholder="Enter client"
												className="form-control"
												value={client}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Visa Types accepted <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>
											<Select
												name="visatypes"
												isMulti={true}
												value={visatypes}
												onChange={this.handleSelectVisaTypesChange}
												options={this.state.visatypess}
												className="react-select-team"
												classNamePrefix="react-select"
											/>
											{/* <Select
												name="visatypes"
												value={this.state.allvisatypess.filter(
													(option) => option.value == visatypes,
												)}
												onChange={this.handleSelectVisaTypesChange}
												options={this.state.visatypess}
												clearable={false}
												isMulti={true}
												className="react-select-team"
												classNamePrefix="react-select" 
											/> */}
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Payrate  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="payrate"
												type="text"
												placeholder="Enter Payrate"
												className="form-control"
												value={payrate}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Required documents  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>
											<Select
												name="req_document"
												isMulti={true}
												value={req_document}
												onChange={this.handleSelectDocTypeChange}
												options={this.state.req_documents}
												className="react-select-team"
												classNamePrefix="react-select"
											/>

										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Employment Type  <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>
											<Select
												name="emp_type"
												isMulti={true}
												value={emp_type}
												onChange={this.handleSelectEmpTypeChange}
												options={this.state.emp_types}
												className="react-select-team"
												classNamePrefix="react-select"
											/>

										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Interview Mode  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="interview_mode"
												value={this.state.interview_modes.filter(
													(option) => option.value == interview_mode,
												)}
												onChange={this.handleSelectIntModeChange}
												options={this.state.interview_modes}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Security Clearances  <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="security_clearance"
												value={this.state.security_clearances.filter(
													(option) => option.value == security_clearance,
												)}
												onChange={this.handleSelectSecClrChange}
												options={this.state.security_clearances}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>




								</Col>
								<Col md={12}>
									<div className="form__form-group">
										<span className="form__form-group-label">Job Description <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<textarea
												name="jd"
												value={jd}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Required Skills <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<textarea
												name="rskills"
												value={rskills}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Education Requirements <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<textarea
												name="edureq"
												value={edureq}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Work Experience Requirements <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<textarea
												name="workexp"
												value={workexp}
												onChange={this.handleChange}
												style={{ width: '100%', fontSize: '15px' }}
											/>
										</div>
									</div>

								</Col>
								<Col md={4}>{' '}</Col>
								<Col md={4}>{' '}</Col>
								<Col md={4}>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Edit  Job
									</button>
								</Col>
							</Row>
						</form>
					</div>
				</Modal>

				<Modal
					isOpen={this.state.showEbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary addemailsbox"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">
							Send   Job
						</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleAddEmail(event)}
						>
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<p>{this.makeAddedList()}</p>
							<div className="form__form-group">
								{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
								<div className="form__form-group-field">
									<textarea
										name="email"
										type="email"
										placeholder="Add Email ID"
										onFocus={this.handleFocus}
										onChange={this.handleChange}
										value={this.state.content_add}
										style={{ width: '75%', margin: '15px' }}
										onKeyUp={(e) => {
											e.preventDefault();

											// if (
											// 	e.keyCode == 13 ||
											// 	e.keyCode == 32 ||
											// 	e.keyCode == 188
											// ) {
											// 	this.handleAddEmail(event);
											// }
										}}
									/>
									<button
										className="btn btn-primary btn-sm"
										style={{ marginTop: '15px' }}
										type="submit"
									>
										Add Email
									</button>
								</div>
							</div>
						</form>
						<form
							className="form"
							onSubmit={(event) => this.sendjobtoemails(event)}
						>
							<Col md={12}>
								{this.state.testEmails.length > 0 ? (
									<button
										className="btn btn-primary float-right"
										type="submit"
										style={{ width: '40%' }}
									>
										Send Job
									</button>
								) : (
									<button
										className="btn btn-secondary float-right"
										type="button"
										desabled="true"
										style={{ width: '40%' }}
									>
										Send Job
									</button>
								)}
							</Col>
						</form>
					</div>
				</Modal>

			</div >
		);
	}
}


export default withRouter(JobsList);
