import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
// import { TagInput } from 'reactjs-tag-input';
import SearchIcon from 'mdi-react/SearchIcon';
import { Button, Card, CardBody, Col, Badge, Table, Modal } from 'reactstrap';

import Alert from '../../../shared/components/Alert';

import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	keyword: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	aliasnames: '',
	editalias: '',
	noorid: 0,
	tags: [],
	addKeyModal: false,
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
	currentcolor: [
		'#000000',
	],
	okcolor: [
		'#06b13a',
	],
	xcolor: [
		'#0697b1',
	],
	fcolor: [
		'#de0922',
	],
	content_add: '',
	myItems: [],
	testTags: [],
	okaliases: [],
	xaliases: [],
	faliases: [],
};
class AddKeywordSearchItemsNew extends Component {
	constructor(props) {
		super(props);
		this.lastId = -1;
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetkeys(localStorage.token);
		// this.setaliasmainkeyword(localStorage.token);

		this.deleteTags = this.deleteTags.bind(this);

		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.handleDClick = this.handleDClick.bind(this);
		this.handleEditkey = this.handleEditkey.bind(this);
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};


	handleGetkeys = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'aliaswithkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.nowkeys];
					console.log('new', response.data.nowkeys);
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	// setaliasmainkeyword = (token) => {
	// 	const headers = {
	// 		'Content-Type': 'application/json',
	// 		Authorization: localStorage.token,
	// 	};
	// 	var postData = {
	// 		token: token,
	// 	};
	// 	const getuurl = apiurl + 'setaliasmainkeyword';
	// 	axios
	// 		.post(getuurl, postData, {
	// 			headers: headers,
	// 		})
	// 		.then((response) => {
	// 			if (response.data.error) { //eslint-disable-line
	// 			} else {
	// 				console.log(response.data);
	// 			}
	// 		})
	// 		.catch((error) => { }); //eslint-disable-line
	// };
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};



	handleEditkey = async (keyid, keyname, aliases, e) => {
		e.preventDefault();
		console.log(keyid);


		this.setState({
			noorid: keyid,
			keyName: keyname,
			myItems: [],
			testTags: [],
			editUserModal: true,
			addUserModal: false,
		}, async () => {
			var newArray = this.state.myItems;
			var testTags = this.state.testTags;
			await Promise.all(
				aliases.map((datatag) => {
					return new Promise((resolve) => {
						newArray.push({
							content: datatag,
							id: ++this.lastId,
						});
						testTags.push(datatag);
						this.setState(
							{
								myItems: newArray,
								testTags: testTags,
							},
							() => {
								console.log(this.state.myItems);
								resolve('');
							},
						);
					});
				}),
			);
		});
	};
	deleteTags(event) {
		event.preventDefault();
		if (this.state.noorid && this.state.testTags) {
			// post started
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const editkeyurl = apiurl + 'neweditalias';
			var postData = {
				noorid: this.state.noorid,
				ktags: this.state.testTags,
			};
			axios
				.post(editkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						swal(this.state.keyName + ' ' + response.data.message, {
							icon: 'warning',
						});
						this.setState({
							editUserModal: false,
							noorid: 0,
							keyName: '',
							editalias: '',
						});
					} else {
						swal(this.state.keyName + ' ' + response.data.message, {
							icon: 'success',
						});
						this.setState(
							{
								editUserModal: false,
								noorid: 0,
								keyName: '',
								editalias: '',
							},
							() => {
								this.handleGetkeys(localStorage.token);
							},
						);
					}
				});
			//post ended
		}
	}
	handleAkey = (keyid, keyname) => {

		console.log(keyid);
		this.setState({
			noorid: keyid,
			keyName: keyname,
			addUserModal: true,
			editUserModal: false,
			okaliases: [],
			xaliases: [],
			faliases: [],
			showinput: true,
		});
	};
	handleCkey = () => {
		this.setState({
			addUserModal: false,
			editUserModal: false,
			tags: [],
			okaliases: [],
			xaliases: [],
			faliases: [],
			showinput: true,
		});
	};


	handleChange = (event) => {
		const { name, value } = event.target;

		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		const low_input = usr_input.toLowerCase();
		this.setState({ content_add: low_input });
	};
	handleAddTag(event) {
		event.preventDefault();

		var validtags = [];
		var rawtags = this.state.content_add.trim();

		rawtags = rawtags.replace(/\(/gi, '');
		rawtags = rawtags.replace(/\)/gi, '');
		rawtags = rawtags.replace(/\{/gi, '');
		rawtags = rawtags.replace(/\}/gi, '');
		rawtags = rawtags.replace(/\[/gi, '');
		rawtags = rawtags.replace(/\]/gi, '');
		rawtags = rawtags.replace(/</gi, '');
		rawtags = rawtags.replace(/>/gi, '');
		rawtags = rawtags.replace(/=/gi, '');
		rawtags = rawtags.replace(/&/gi, '');
		rawtags = rawtags.replace(/~/gi, '');
		rawtags = rawtags.replace(/@/gi, '');
		rawtags = rawtags.replace(/\$/gi, '');
		rawtags = rawtags.replace(/\*/gi, '');
		rawtags = rawtags.replace(/%/gi, '');
		rawtags = rawtags.replace(/!/gi, '');
		rawtags = rawtags.replace(/_/gi, '');
		rawtags = rawtags.replace(/-/gi, '');
		rawtags = rawtags.replace(/\?/gi, '');
		rawtags = rawtags.replace(/\^/gi, '');
		rawtags = rawtags.replace(/\|/gi, '');
		rawtags = rawtags.replace(/\//gi, '');
		rawtags = rawtags.replace(/\\/gi, '');
		rawtags = rawtags.replace(/;/gi, '');
		rawtags = rawtags.replace(/:/gi, '');

		rawtags = rawtags.replace(/`/gi, '');
		rawtags = rawtags.replace(/'/gi, '');
		rawtags = rawtags.replace(/"/gi, '');
		var aalltags = rawtags
			.split('\n')
			.join(',')
			.split(',');

		// console.log('aalltags:', aalltags);
		aalltags.map((aalltag) => {
			if (aalltag) {
				var temptesttags = this.state.testTags;
				var dnjk = temptesttags.includes(aalltag);
				if (dnjk) {
					this.setState(
						{

							content_add: '',
						});
				} else { // eslint-disable-line
					temptesttags.push(aalltag);
					validtags.push(aalltag);
				}
			}
		});
		// console.log(validtags);
		// var newArray = this.state.myItems;
		// var testTags = this.state.testTags;
		if (validtags.length >= 1) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				newtags: validtags,
				noorid: this.state.noorid,
			};
			const testidurl = apiurl + 'checktechtags';
			axios
				.post(testidurl, postData, {
					headers: headers,
				})
				.then(async (response) => {
					// console.log(response);
					const okaliases = response.data.data[0];
					const xaliases = response.data.data[1];
					const faliases = response.data.data[2];

					this.setState(
						{
							okaliases: okaliases,
							xaliases: xaliases,
							content_add: '',
							faliases: faliases,
							showinput: false,
						}, () => {
							this.handleGetkeys(localStorage.token);
						});
					// if (response.data.data[0].length >= 1) {


					// await Promise.all(
					// 	alldatatags.map((datatag) => {
					// 		return new Promise((resolve) => {
					// 			newArray.push({
					// 				content: datatag,
					// 				id: ++this.lastId,
					// 			});
					// 			testTags.push(datatag);
					// 			this.setState(
					// 				{
					// 					myItems: newArray,
					// 					testTags: testTags,
					// 					content_add: '',
					// 					addUserModal: false,
					// 				},
					// 				() => {
					// 					console.log(this.state.myItems);
					// 					resolve('');
					// 				},
					// 			);
					// 		});
					// 	}),
					// );
					// 	if (isexist.length >= 1) {
					// 		isexist = isexist.toString();
					// 		swal(isexist + ' Already Added', {
					// 			icon: 'error',
					// 		});
					// 	}
					// }
				});
		}
	}

	handleClick(event) {
		const myitems = this.state.myItems;
		const ntesttags = this.state.testTags;
		if (ntesttags.length <= 1) {
			console.log('no edits');
		} else {
			const tagidToRemove = Number(event.target.dataset['tagid']);
			const tagToRemove = event.target.dataset['tagcontent'];
			const newArray = myitems.filter((listitem) => {
				return listitem.id !== tagidToRemove;
			});
			const testTags = ntesttags.filter((elistitem) => {
				return elistitem !== tagToRemove;
			});
			this.setState({ myItems: newArray, testTags: testTags }, () => {
				console.log('items ', this.state.myItems);
				console.log('tags ', this.state.testTags);
			});
		}
	}
	handleDClick(event) {
		console.log('event ', event);
	}

	makeAddedList() {
		var nowtags = this.state.myItems.map((listitem, index) => (
			<span
				className={index == 0 ? "tagslist" : "emaillist"}
				key={listitem.id}
				onClick={index == 0 ? this.handleDClick : this.handleClick}
				// onClick={this.handleClick}
				data-tagid={listitem.id}
				data-tagcontent={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>)


		);
		return nowtags;

	}
	okAddedList() {
		const elements = this.state.okaliases.map((listitem, index) => (
			<span
				className="tagslist"
				key={index}
				style={{
					backgroundColor: this.state.okcolor[
						index % this.state.okcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem}
			</span>
		));
		return elements;
	}
	xAddedList() {
		const elements = this.state.xaliases.map((listitem, index) => (
			<span
				className="tagslist"
				key={index}
				style={{
					backgroundColor: this.state.xcolor[
						index % this.state.xcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem}
			</span>
		));
		return elements;
	}
	fAddedList() {
		const elements = this.state.faliases.map((listitem, index) => (
			<span
				className="tagslist"
				key={index}
				style={{
					backgroundColor: this.state.fcolor[
						index % this.state.fcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem}
			</span>
		));
		return elements;
	}

	render() {

		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								Technology Keywords{' '}
								<Badge color="primary">Total {this.state.tkeys} </Badge>
								{'  '}
								<Badge color="success">Active {this.state.akeys} </Badge>
								{'  '}
								<Badge color="warning">InActive {this.state.bkeys} </Badge>
								{'  '}
							</h5>
						</div>
						<form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										onChange={(e) => {
											this.handleSearch(e);
										}}
									/>
								</div>
							</div>
						</form>
						{this.state.tshowAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Keyword</th>
									<th>Alias Names</th>
									<th>Add Alias</th>
									<th>Edit & Update</th>
								</tr>
							</thead>
							<tbody>
								{this.state.relatedKeywords &&
									this.state.relatedKeywords.map((hkey, index) => {



										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<strong>{hkey.keyword}</strong>
												</td>

												<td>
													<strong>{hkey.alias}</strong>
												</td>

												<td>
													<Button
														className="btn-sm"
														color="success"
														onClick={this.handleAkey.bind(
															this,
															hkey.id,
															hkey.keyword,
															hkey.aliasarr
														)}
													>
														+
													</Button>
												</td>
												<td>
													{hkey.aliasarr.length >= 2 ? (
														<Button
															className="btn-sm"
															color="primary"
															onClick={this.handleEditkey.bind(
																this,
																hkey.id,
																hkey.keyword,
																hkey.aliasarr,
															)}
														>
															Edit & Update
														</Button>
													) : null}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
						<Modal
							isOpen={this.state.addUserModal}
							size="lg"
							modalClassName="ltr-support"
							className="modal-dialog--primary aliasbox"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
							</div>
							<div className="modal__body">
								<form
									className="form"
									onSubmit={(event) => this.handleAddTag(event)}
								>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}
									{/* <p>{this.makeAddedList()}</p> */}
									<p>{this.okAddedList()}</p>
									<br />
									<p>{this.xAddedList()}</p>
									<br />
									<p>{this.fAddedList()}</p>
									{this.state.showinput ? (<div className="form__form-group">
										{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
										<div className="form__form-group-field">
											<textarea
												name="email"
												type="email"
												placeholder="Add Alias Tags"
												onChange={this.handleChange}
												value={this.state.content_add}
												style={{ width: '75%', margin: '15px' }}
												onKeyUp={(e) => {
													e.preventDefault();

													// if (
													// 	e.keyCode == 13 ||
													// 	e.keyCode == 32 ||
													// 	e.keyCode == 188
													// ) {
													// 	this.handleAddTag(event);
													// }
												}}
											/>
											<button
												className="btn btn-primary btn-sm"
												type="submit"
												style={{ marginTop: '15px' }}
											>
												Add Alias to {this.state.keyName}
											</button>
										</div>
									</div>
									) : null}
								</form>
							</div>
						</Modal>
						<Modal
							isOpen={this.state.editUserModal}
							size="lg"
							modalClassName="ltr-support"
							className="modal-dialog--primary aliasbox"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
							</div>
							<div className="modal__body">

								<p>{this.makeAddedList()}</p>

								<form
									className="form"
									onSubmit={(event) => this.deleteTags(event)}
								>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}

									{this.state.testTags.length >= 1 ? (<div className="form__form-group">
										{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
										<div className="form__form-group-field">

											<button
												className="btn btn-primary btn-sm"
												type="submit"
												style={{ marginTop: '15px' }}
											>
												Edit Alias to {this.state.keyName}
											</button>
										</div>
									</div>
									) : null}
								</form>
							</div>
						</Modal>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AddKeywordSearchItemsNew;
