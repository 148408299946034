import React, { Component } from 'react';
/* global  */
/* eslint-disable  */
import swal from 'sweetalert';
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../Apanel/QueryMe';
import CheckProfileDone from '../../Apanel/CheckProfileDone';
import Switch from 'react-switch';
import {
	Button,

	Card,
	CardBody,
	Col,
	Badge,
	Table,
	Modal,
	Container,
	Row,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../../shared/components/Alert';
import ProfileImage from '../../Apanel/ProfileImage';
const apiurl = MY_API_URL;

// import TotalProfit from '../../AdminPanel/ecomponents/TotalProfit';

const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	newrole: 'recruiter',
	emails: '',
	domain: '',
	token: localStorage.token,
	content_add: '',
	myItems: [],
	testEmails: [],
	currentcolor: [
		'#000000',
	],
	comprec: [{ name: 'Page A', amt: '0' }],
	addtm: false,
	addtl: false,
	addrec: false,
	usertypes: [],
	tms: 0,
	tls: 0,
	trecs: 0,
	email: '',
	tmid: 0,
	tlid: 0,
};
class UsersRoles extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('viewrecruiter', '');
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			// eslint-disable-next-line
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else if (!localStorage.dashimg) {
			// eslint-disable-next-line
			this.props.history.push('/'); //eslint-disable-line
		} else {
			this.handleGetUsers();
			this.handleFocus = this.handleFocus.bind(this);
			this.handleChange = this.handleChange.bind(this);

			this.handleClick = this.handleClick.bind(this);
			this.handleManagerSubmit = this.handleManagerSubmit.bind(this);
			this.lastId = -1;
		}
	}
	// handleChange = (event) => {
	// 	const { name, value } = event.target;
	// 	console.log(name + ' ' + value);
	// 	this.setState({ [name]: value });
	// };

	handleFocus(e) {
		e;
		this.setState({ content_add: '' });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		this.setState({ content_add: usr_input });
	};
	handleSingleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });

	};
	handleAddEmail = async (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];

		var rawemails = this.state.content_add.trim();
		var aallemails = rawemails
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		// console.log(validemails);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		// console.log(this.state.allones);
		await Promise.all(
			aallemails.map((aallemail) => {
				return new Promise((resolve) => {
					let isvalidrec = re.test(aallemail);
					if (isvalidrec) {
						var newemail_string_array = aallemail.split('@');
						var domain_string_location = newemail_string_array.length - 1;
						var newemaildomain = newemail_string_array[domain_string_location];
						if (mainemaildomain == newemaildomain) {
							var njk = this.state.allones.includes(aallemail);
							var dnjk = this.state.testEmails.includes(aallemail);
							if (njk) {
								resolve('');
							} else {
								if (dnjk) {
									resolve('');
								} else {
									newArray.push({
										content: aallemail,
										id: ++this.lastId,
									});
									testEmails.push(aallemail);
									this.setState(
										{
											myItems: newArray,
											content_add: '',
										},
										() => {
											console.log(this.state.myItems);
											resolve('');
										},
									);
								}
							}
						} else {
							resolve('');
						}
					} else {
						resolve('');
					}
				});
			}),
		);
		this.setState({
			content_add: '',
		});
	};

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			console.log('items ', this.state.myItems);
			console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	handleAkey = (teamid, e) => {
		e;
		console.log(teamid);
		this.setState({
			addUserModal: true,
			addtm: false,
			addtl: false,
			addrec: true,
			teamid: teamid,
		});
	};
	handleTMAkey = () => {
		this.setState({
			addUserModal: true,
			addtm: true,
			addtl: false,
			addrec: false,
			teamid: this.state.tmid,
		});
	};
	handleTLAkey = () => {
		this.setState({
			addUserModal: true,
			addtm: false,
			addtl: true,
			addrec: false,
			teamid: this.state.tmid,
		});
	};
	handleManagerSubmit = (event) => {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];

		var manageremail = this.state.email;


		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line


		const isvalidrec = re.test(manageremail);
		if (isvalidrec) {
			var newemail_string_array = manageremail.split('@');
			var domain_string_locationn = newemail_string_array.length - 1;
			var newemaildomain = newemail_string_array[domain_string_locationn];
			if (mainemaildomain == newemaildomain) {
				const njk = this.state.allones.includes(manageremail);
				console.log('njk', njk);
				if (njk) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: "Email Already Exist!",
					});
				} else {
					console.log('came');
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};

					const addkeyurl = apiurl + 'addcomptmm';
					var postData = {
						email: this.state.email,
						cemail: localStorage.by,
						cname: localStorage.name,
						utype: 2,
						dboard: localStorage.dashboard,
						ccode: localStorage.dashimg,
					};
					axios
						.post(addkeyurl, postData, {
							headers: headers,
						})
						.then((response) => {
							console.log('respon', response.data);
							if (!response.data.error) {
								this.setState(
									{
										addUserModal: false,
										showAlert: false,
										emails: '',
										myItems: [],
										testEmails: [],
									},
									() => {
										swal(manageremail + ' Manager ' + response.data.message, {
											icon: 'success',
										});
										this.handleGetUsers();
									},
								);
							}
						})
						.catch(() => {
							// //localStorage.clear();
							// // eslint-disable-next-line
							this.props.history.push('/'); //eslint-disable-line
						});

				}
			} else {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: "Email Should be of your Company Domain!",
				});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Email Invalid!",
			});
		}


	};
	handleUserSubmit = (event) => {
		event.preventDefault();

		var jjjjjjjj = this.state.testEmails;
		if (jjjjjjjj.length >= 1) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const addkeyurl = apiurl + 'addcomptmrecs';
			var postData = {
				emails: this.state.testEmails,
				cemail: localStorage.by,
				cname: localStorage.name,
				dboard: localStorage.dashboard,
				ccode: localStorage.dashimg,
				teamid: this.state.teamid,
			};
			axios
				.post(addkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						var okemailss = response.data.data[0];
						okemailss.toString();
						this.setState(
							{
								addUserModal: false,
								showAlert: false,
								emails: '',
								myItems: [],
								testEmails: [],
							},
							() => {
								swal(okemailss + ' ' + response.data.message, {
									icon: 'success',
								});
								this.handleGetUsers();
							},
						);
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	handleCkey = () => {
		this.setState({
			addUserModal: false,
		});
	};
	handleTkey = (id, keyword, checked) => {
		// console.log(checked + 'checked');
		// console.log(id + 'id');
		// console.log(keyword + 'keyword');
		var keysts = checked == true ? 0 : 1;
		var kstatus = 'Block';
		if (checked) {
			kstatus = 'Unblock';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'toguser';
				var postData = {
					userid: id,
					ustatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetUsers();
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleGetUsers = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			role: 'recruiter',
			cemail: localStorage.by,
			dashboard: localStorage.dashboard,
		};
		const getuurl = apiurl + 'compdashtexperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('response.data', response.data);
					let hk = [localStorage.by];
					let allusersd = [...response.data.allusersd];
					let allusers = [...response.data.allusers];
					let tms = 0;
					let tls = 0;
					let rec = 0;
					let tmid = 0;
					allusersd.map((rk) => {
						if (rk.usertype == 2) {
							tms = tms + 1;
							tmid = rk.id;
						}
						if (rk.usertype == 3) {
							tls = tls + 1;
						}
						if (rk.usertype == 1) {
							rec = rec + 1;
						}
					});
					allusers.map((rkg) => {
						hk.push(rkg.email);
					});
					this.setState({
						allusers: [...response.data.allusersd],
						usertypes: response.data.usertypes,
						allones: hk,
						tms: tms,
						tls: tls,
						trecs: rec,
						tmid: tmid,
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	getDetails = (utypeid) => {
		var utype = '';
		let usertype = this.state.usertypes.filter((tt) => {
			return tt.id == utypeid;
		});
		if (usertype.length) {
			utype = usertype[0].usertype;
		}
		return utype;
	};
	getrecsDetails = (tlid) => {
		var allrecs = [];
		let recs = this.state.allusers.filter((tt) => {
			return tt.teamid == tlid;
		});
		if (recs.length) {
			allrecs = recs;
		}
		return allrecs;
	};
	render() {
		const {
			email,
		} = this.state;
		return (
			<Container className="dashboard">
				<CheckProfileDone />
				<Row>
					<Col md={3}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										<img
											src={'flags/' + localStorage.dashimg + '.svg'}
											style={{
												float: 'left',
												width: '25px',
												height: '15px',
												marginRight: '5px',
											}}
										/>{localStorage.dashname}
									</h5>
								</div>

							</CardBody>
						</Card>
					</Col>
					<Col md={9}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h5 className="bold-text">
										Dashboard Manager
										{this.state.tmid <= 0 ? (
											<Button
												className="float-right btn-sm"
												color="success"
												onClick={this.handleTMAkey}
											>
												+
											</Button>
										) : null}
									</h5>
								</div>
								{this.state.tmid ? (
									<Table responsive hover >

										<tbody>
											{this.state.allusers &&
												this.state.allusers.map((huser, index) => {
													// var usertype = this.getDetails(huser.usertype);
													if (huser.usertype == 2) {
														var usts = true;
														if (huser.blocked === 1) {
															usts = false;
														}
														return (
															<tr key={index}>
																<td>{index + 1}</td>
																<td>
																	<ProfileImage
																		clname="topbar__avatar-img"
																		flpath={
																			huser.image_url
																				? huser.image_url
																				: this.state.image
																		}
																	/>
																</td>
																<td>
																	<Switch
																		onChange={this.handleTkey.bind(
																			this,
																			huser.id,
																			huser.email,
																		)}
																		checked={usts}
																	/>
																</td>
																<td>{huser.email}</td>

																<td>{huser.name}</td>
																<td>{huser.mobile}</td>
																<td>{huser.createdon}</td>

															</tr>
														);
													}
												})}
										</tbody>
									</Table>
								) : null}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>



					<Col md={12}>
						{this.state.tmid ? (
							<Card>
								<CardBody>
									<div className="card__title">
										<h5 className="bold-text">
											All Team Leads
											<Button
												className="float-right btn-sm"
												color="success"
												onClick={this.handleTLAkey}
											>
												+
											</Button>
										</h5>
									</div>
									<Table responsive hover>
										<thead>
											<tr>
												<th>#</th>
												<th>.</th>
												<th>Manage</th>
												<th>Email</th>
												<th>Type</th>
												<th>Name</th>
												<th>Mobile</th>
												<th>Registered</th>
												<th>Recruiters</th>
												<th>Add Recruiter</th>

											</tr>
										</thead>
										<tbody>
											{this.state.allusers && this.state.tmid &&
												this.state.allusers.map((huser, index) => {
													// var usertype = this.getDetails(huser.usertype);
													if (huser.usertype == 3) {
														var allrecs = this.getrecsDetails(huser.id);
														// console.log('allrecs', allrecs);
														var usts = true;
														if (huser.blocked === 1) {
															usts = false;
														}
														let indexid = 0;
														return (
															<tr key={index}>
																<td>{indexid + 1}</td>
																<td>
																	<ProfileImage
																		clname="topbar__avatar-img"
																		flpath={
																			huser.image_url
																				? huser.image_url
																				: this.state.image
																		}
																	/>
																</td>
																<td>
																	<Switch
																		onChange={this.handleTkey.bind(
																			this,
																			huser.id,
																			huser.email,
																		)}
																		checked={usts}
																	/>
																</td>
																<td>{huser.email}</td>
																<td>
																	<h4>
																		<Badge color="success">{huser.profile_completed ? 'Completed' : 'Not Completed'}</Badge>
																	</h4>
																</td>
																<td>{huser.name}</td>
																<td>{huser.mobile}</td>
																<td>{huser.createdon}</td>
																<td>

																	{allrecs && allrecs.map((myrec, index) => {
																		return (<div className="comprecslist" key={index}>{index + 1}{'.'}{myrec.email}</div>
																		)
																	})}


																</td>
																<td><Button
																	className="float-right btn-sm"
																	color="primary"
																	onClick={this.handleAkey.bind(this, huser.id)}
																>
																	+
																</Button></td>

															</tr>
														);
													}
												})}
										</tbody>
									</Table>

								</CardBody>
							</Card>
						) : (
							<Card>
								<CardBody>
									<div className="card__title">
										<h5 className="bold-text">
											Manager Not Set
										</h5>
									</div>

								</CardBody>
							</Card>
						)}


					</Col>
				</Row>
				<Row>



					<Col md={12}>
						{this.state.tmid && this.state.tls >= 1 ? (
							<Card>
								<CardBody>
									<div className="card__title">
										<h5 className="bold-text">
											All Recruiters

										</h5>
									</div>
									<Table responsive hover>
										<thead>
											<tr>
												<th>#</th>
												<th>.</th>
												<th>Manage</th>
												<th>Email</th>
												<th>Type</th>
												<th>Name</th>
												<th>Mobile</th>
												<th>Registered</th>

											</tr>
										</thead>
										<tbody>
											{this.state.allusers && this.state.tmid && this.state.tls && this.state.trecs &&
												this.state.allusers.map((huser, index) => {
													// var usertype = this.getDetails(huser.usertype);
													if (huser.usertype == 1) {
														var usts = true;
														if (huser.blocked === 1) {
															usts = false;
														}
														let indexid = 0;
														return (
															<tr key={index}>
																<td>{indexid + 1}</td>
																<td>
																	<ProfileImage
																		clname="topbar__avatar-img"
																		flpath={
																			huser.image_url
																				? huser.image_url
																				: this.state.image
																		}
																	/>
																</td>
																<td>
																	<Switch
																		onChange={this.handleTkey.bind(
																			this,
																			huser.id,
																			huser.email,
																		)}
																		checked={usts}
																	/>
																</td>
																<td>{huser.email}</td>
																<td>
																	<h4>
																		<Badge color="success">{huser.profile_completed ? 'Completed' : 'Not Completed'}</Badge>
																	</h4>
																</td>
																<td>{huser.name}</td>
																<td>{huser.mobile}</td>
																<td>{huser.createdon}</td>


															</tr>
														);
													}
												})}
										</tbody>
									</Table>

								</CardBody>
							</Card>
						) : (
							<Card>
								<CardBody>
									<div className="card__title">
										<h5 className="bold-text">
											Team Leads Not Set
										</h5>
									</div>

								</CardBody>
							</Card>
						)}


					</Col>
				</Row>

				<Modal
					isOpen={this.state.addUserModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary addemailsbox"
				>
					{this.state.tmid && this.state.addtl ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Add Team Lead
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleAddEmail(event)}
							>
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<p>{this.makeAddedList()}</p>
								<div className="form__form-group">
									{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
									<div className="form__form-group-field">
										<textarea
											name="email"
											type="email"
											placeholder="Add Email ID"
											onFocus={this.handleFocus}
											onChange={this.handleChange}
											value={this.state.content_add}
											style={{ width: '75%', margin: '15px' }}
											onKeyUp={(e) => {
												e.preventDefault();

												// if (
												// 	e.keyCode == 13 ||
												// 	e.keyCode == 32 ||
												// 	e.keyCode == 188
												// ) {
												// 	this.handleAddEmail(event);
												// }
											}}
										/>
										<button
											className="btn btn-primary btn-sm"
											style={{ marginTop: '15px' }}
											type="submit"
										>
											Add Team Lead Email
										</button>
									</div>
								</div>
							</form>
							<form
								className="form"
								onSubmit={(event) => this.handleUserSubmit(event)}
							>
								<Col md={12}>
									{this.state.testEmails.length > 0 ? (
										<button
											className="btn btn-primary float-right"
											type="submit"
											style={{ width: '40%' }}
										>
											Add Team Lead
										</button>
									) : (
										<button
											className="btn btn-secondary float-right"
											type="button"
											desabled="true"
											style={{ width: '40%' }}
										>
											Add Team Lead
										</button>
									)}
								</Col>
							</form>
						</div>
					</div>) : null}

					{this.state.tmid && this.state.teamid && this.state.addrec ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Add Recruiter
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleAddEmail(event)}
							>
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<p>{this.makeAddedList()}</p>
								<div className="form__form-group">
									{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
									<div className="form__form-group-field">
										<textarea
											name="email"
											type="email"
											placeholder="Add Email ID"
											onFocus={this.handleFocus}
											onChange={this.handleChange}
											value={this.state.content_add}
											style={{ width: '75%', margin: '15px' }}
											onKeyUp={(e) => {
												e.preventDefault();

												// if (
												// 	e.keyCode == 13 ||
												// 	e.keyCode == 32 ||
												// 	e.keyCode == 188
												// ) {
												// 	this.handleAddEmail(event);
												// }
											}}
										/>
										<button
											className="btn btn-primary btn-sm"
											style={{ marginTop: '15px' }}
											type="submit"
										>
											Add Recruiter Email
										</button>
									</div>
								</div>
							</form>
							<form
								className="form"
								onSubmit={(event) => this.handleUserSubmit(event)}
							>
								<Col md={12}>
									{this.state.testEmails.length > 0 ? (
										<button
											className="btn btn-primary float-right"
											type="submit"
											style={{ width: '40%' }}
										>
											Add Recruiter
										</button>
									) : (
										<button
											className="btn btn-secondary float-right"
											type="button"
											desabled="true"
											style={{ width: '40%' }}
										>
											Add Recruiter
										</button>
									)}
								</Col>
							</form>
						</div>
					</div>) : null}

					{this.state.addtm && this.state.tmid == 0 ? (<div>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">Add New Manager</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleManagerSubmit(event)}
							>
								<div className="form__form-group">
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<AccountOutlineIcon />
										</div>

										<input
											name="email"
											type="email"
											placeholder="Enter email"
											className="form-control"
											value={email}
											onChange={this.handleSingleChange}
											required
										/>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									type="submit"
								>
									Add Manager
								</button>
							</form>
						</div>
					</div>) : null}
				</Modal>

			</Container>
		);
	}
}

export default UsersRoles;
