import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
import { Table } from 'react-bootstrap';
import { Card, CardBody, Col } from 'reactstrap';
import ResultMeter from '../../../Apanel/ResultMeter';
var dateFormat = require('dateformat');
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,
	testTakers: [],
};
class ComTest extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'comtest');
			// eslint-disable-next-line
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.fetchSentTests();
		}
	}
	timespent = (given_seconds) => {
		let hours = Math.floor(given_seconds / 3600);
		let minutes = Math.floor((given_seconds - hours * 3600) / 60);
		let seconds = given_seconds - hours * 3600 - minutes * 60;

		let timeString =
			hours.toString().padStart(2, '0') +
			':' +
			minutes.toString().padStart(2, '0') +
			':' +
			seconds.toString().padStart(2, '0');

		return timeString;
	};

	fetchSentTests = () => {
		this.setState(
			{
				loading: true,
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};
				var postData = {
					email: localStorage.by,
				};
				const getfqurl = apiurl + 'fetchRCTests';
				axios
					.post(getfqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						// console.log('cccccccccc: ', response.data.data);
						this.setState(
							{
								tests: [...response.data.data],
								akeywords: response.data.akeywords,
							},
							() => {
								this.setState({
									loading: false,
								});
							},
						);
					})
					.catch((error) => {
						console.log(error);
					});
			},
		);
	};
	getBadgeColor = (index) => {
		switch (index % 3) {
			case 0:
				return 'badge-purple';
			case 1:
				return 'badge-yellow';
			case 2:
				return 'badge-grey';
		}
	};
	getDetails = (techid) => {
		// console.log('normal ', techid);
		let techname = this.state.akeywords.filter((tt) => {
			return tt.id === techid;
		});
		// console.log(techname[0].keyword);
		return techname[0].keyword;
	};
	render() {
		const mystyle = {
			color: 'green',
			cursor: 'pointer',
			fontSize: '22px',
		};
		let klk = 1;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">List of Completed Tests</h5>
						</div>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Date</th>
									<th>Test Id</th>
									<th>Test Taker Details</th>
									<th>
										Time Taken
										<br />
										HH:MM:SS
									</th>
									<th>Details</th>
									<th>% of Score</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{this.state.tests &&
									this.state.tests.map((test, index) => {
										var allresults = test.results;
										// console.log(test.helper);
										var myresult = test.helper;
										// console.log(myresult);
										// console.log('results here ', allresults);
										let totalCorrect = 0;
										// let totalIncorrect = 0;
										// let totalUnattempted = 0;
										let totalquestions = 0;

										allresults.map((result) => {
											totalCorrect += result.correct;
											// totalIncorrect += result.incorrect;
											// totalUnattempted += result.unattempted;
										});
										myresult.map((resul) => {
											resul.questions = parseInt(resul.questions);
											totalquestions += resul.questions;
										});
										totalCorrect = (100 / totalquestions) * totalCorrect;
										return (
											<tr key={index}>
												<td>{klk++}</td>
												<td>{dateFormat(test.taken_at, 'd-m-yyyy')}</td>
												<td>{test.generated_id}</td>
												<td>
													<b>Email: </b>
													{test.sent_to}
													<br />
													<b>Name: </b>
													{test.name}
													<br />
													<b>Mobile: </b>
													{test.mobile}
												</td>
												<td>{this.timespent(test.nqno)}</td>
												<td>
													<Table>
														<thead>
															<tr>
																<th>Technology</th>
																<th>No of Questions</th>
															</tr>
														</thead>
														<tbody>
															{test.helper.map((tech, index) => {
																return (
																	<tr key={index}>
																		<td>{this.getDetails(tech.technology)}</td>
																		<td>{tech.questions}</td>
																	</tr>
																);
															})}
														</tbody>
													</Table>
												</td>
												<td>
													<ResultMeter
														marks={totalCorrect}
														mmarks={totalquestions}
													/>
												</td>
												<td>
													{test.taken === 1 ? (
														<div>
															{/* <i
																className="lnr lnr-download"
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	this.fetchTest(test.id, test.sent_to);
																}}
															></i> */}
															<i
																className="lnr lnr-eye"
																style={mystyle}
																onClick={() => {
																	// eslint-disable-next-line
																	this.props.history.push({ //eslint-disable-line
																		pathname: '/testreport',
																		testId: test.id,
																		email: test.sent_to,
																	});
																}}
															></i>
														</div>
													) : (
														''
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default ComTest;
