import React, { Component } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import Notifications, { notify } from 'react-notify-toast';
import axios from 'axios';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import { Button } from 'react-bootstrap';
import { Card, CardBody, Input, Row, Col } from 'reactstrap';
import TechName from '../../Apanel/TechName';
const apiurl = MY_API_URL;
const initialstate = {
	questions: [],
	questionIndex: 0,
	comments: '',
	showstatus: false,
	statusmsg: '',
	seconds: 1,
	tinymceapikey: TINYMCEAPIKEY,
	question: '',
	option: '',
	editinglines: 'editorlines',
	failbtn: false,
	srcimage: '',
};
class StartCheckQues extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			// eslint-disable-next-line
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			if (localStorage.checkquesTech) {
				this.getquesTech(localStorage.checkquesTech, localStorage.auditype);
			} else {
				// eslint-disable-next-line
				this.props.history.push('/listautechs');
			}
		}
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	reportquestion = (ques_id, dplus, w) => {
		w;
		this.setState({ failbtn: true }, () => {
			console.log(ques_id);
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				ques_id: ques_id,
				rby: localStorage.by,
				comments: this.state.comments,
				srcimage: this.state.srcimage,
				auditype: localStorage.auditype,
			};
			const getfqurl = apiurl + 'reportquestion';
			axios
				.post(getfqurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						notify.show(response.data.message, 'warning', 3000, '');
					} else {
						if (dplus) {
							this.setState(
								{
									questionIndex: this.state.questionIndex + 1,
									comments: '',
									srcimage: '',
									failbtn: false,
								},
								() => {
									notify.show(response.data.message, 'success', 3000, '');
								},
							);
						} else {
							// eslint-disable-next-line
							this.props.history.push('/listautechs');
						}
					}
				})
				.catch(() => {
					// // eslint-disable-next-line
					this.props.history.push('/'); //eslint-disable-line
				});
		});
	};
	approvequestion = (ques_id, dplus, w) => {
		w;
		this.setState({ failbtn: true }, () => {
			console.log(ques_id);
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				ques_id: ques_id,
				rby: localStorage.by,
			};
			const getfqurl = apiurl + 'approvequestion';
			axios
				.post(getfqurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						notify.show(response.data.message, 'warning', 3000, '');
					} else {
						if (dplus) {
							this.setState(
								{
									questionIndex: this.state.questionIndex + 1,
									failbtn: false,
								},
								() => {
									notify.show(response.data.message, 'success', 3000, '');
								},
							);
						} else {
							// eslint-disable-next-line
							this.props.history.push('/listautechs');
						}
					}
				})
				.catch(() => {
					// // eslint-disable-next-line
					this.props.history.push('/'); //eslint-disable-line
				});
		});
	};
	holdquestion = (ques_id, dplus, w) => {
		w;
		this.setState({ failbtn: true }, () => {
			console.log(ques_id);
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				ques_id: ques_id,
				rby: localStorage.by,
			};
			const getfqurl = apiurl + 'holdquestion';
			axios
				.post(getfqurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						notify.show(response.data.message, 'warning', 3000, '');
					} else {
						if (dplus) {
							this.setState(
								{
									questionIndex: this.state.questionIndex + 1,
									failbtn: false,
								},
								() => {
									notify.show(response.data.message, 'success', 3000, '');
								},
							);
						} else {
							// eslint-disable-next-line
							this.props.history.push('/listautechs');
						}
					}
				})
				.catch(() => {
					// // eslint-disable-next-line
					this.props.history.push('/'); //eslint-disable-line
				});
		});
	};
	getquesTech = (techid, auditype) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			technology: techid,
			auditype: auditype,
		};
		const getfqurl = apiurl + 'chktechQuestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					this.setState({
						questions: [],
						message: response.data.message,
					});
				} else {
					this.setState({
						questions: [...response.data.data],
					});
				}
			})
			.catch(() => {
				// // eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	_handleFocusOut(noorid, tfor, opt, e) {
		e;
		if (this.state[tfor]) {
			// post started
			var postData = {};
			let editkeyurl = '';
			if (tfor == 'question') {
				console.log('question change');
				editkeyurl = apiurl + 'editnormalques';
				postData = {
					noorid: noorid,
					quesname: this.state[tfor],
				};
			} else if (tfor == 'option') {
				console.log('opt change');
				editkeyurl = apiurl + 'editnormalquesopt';
				postData = {
					noorid: noorid,
					optext: this.state[tfor],
					opt: opt,
				};
			}
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			axios
				.post(editkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						this.setState(
							{
								question: '',
								option: '',
								failbtn: false,
							},
							() => {
								notify.show(response.data.message, 'warning', 3000, '');
							},
						);

						window.location.reload(false);
					} else {
						this.setState(
							{
								question: '',
								option: '',
								failbtn: false,
							},
							() => {
								notify.show(response.data.message, 'success', 3000, '');
							},
						);
					}
				});
			//post ended
		}
	}

	handleQuestionChange(question, editor) {
		editor;
		this.setState({ question });
	}
	handleOptionChange(option, editor) {
		editor;
		console.log('optiona was optiona:', option);
		// console.log('optiona was editor:', editor);
		this.setState({ option });
	}
	desableButtons(e) {
		e;
		this.setState({ failbtn: true });
	}

	uploadImage = (mfile) => {
		const proimg = mfile.target.files[0];
		this.setState({ failbtn: true, srcimage: '' }, () => {
			console.log(this.state.srcimage);

			const data = new FormData();

			data.append('proimg', proimg);
			data.append('email', localStorage.by);
			data.append('role', localStorage.roleSelected);
			const proimgurl = apiurl + 'screenimgupload';
			axios
				.post(proimgurl, data)
				.then((response) => {
					if (response.data.error) {
						notify.show(response.data.message, 'success', 10000, '');
					} else {
						const headers = {
							ContentType: proimg.type,
						};
						const imgtopost = response.data.imgtopost;
						const newfile = response.data.fileurl;
						console.log(newfile);

						axios
							.put(imgtopost, proimg, headers)
							.then((response) => {
								response;
								this.setState(
									{
										showAlert: false,
										srcimage: newfile,
										failbtn: false,
									},
									() => {
										console.log(this.state.srcimage);
										notify.show('Screen Shot is Ready', 'success', 3000, '');
									},
								);
							})
							.catch((error) => {
								console.log('Error is ', error.response);
								notify.show('Failled Try Again Later!', 'warning', 3000, '');
							});
					}
				})
				.catch((error) => {
					error;
					notify.show('Try Again Later!', 'warning', 3000, '');
				});
		});
	};

	render() {
		const { comments, tinymceapikey, editinglines, failbtn } = this.state;
		return (
			<div>
				<Notifications />
				<Row className="nopads">
					<Col md={8}>
						<Card>
							<CardBody>
								<div className="card__title">
									{this.state.questions.length >= 1 ? (
										<h5 className="bold-text">
											Question {this.state.questionIndex + 1}/
											{this.state.questions.length}
										</h5>
									) : (
										<h5 className="bold-text">Notice: {this.state.message}</h5>
									)}
								</div>
								{this.state.questions.length !== 0 &&
									this.state.questionIndex < this.state.questions.length ? (
									<div>
										{this.state.questionIndex !== undefined ? (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													marginTop: 40,
												}}
											>
												<div className={editinglines}>
													<span className="lbls">Q:</span>
													<Editor
														apiKey={tinymceapikey}
														value={
															this.state.questions[this.state.questionIndex]
																.question
														}
														id="question"
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onChange={this.desableButtons.bind(this)}
														onEditorChange={this.handleQuestionChange.bind(
															this,
														)}
														onFocusOut={this._handleFocusOut.bind(
															this,
															this.state.questions[this.state.questionIndex].id,
															'question',
															'opt',
														)}
													/>
												</div>
												<div className={editinglines}>
													<span className="lbls">A:</span>
													<Editor
														apiKey={tinymceapikey}
														value={
															this.state.questions[this.state.questionIndex]
																.options[0]
														}
														id="optiona"
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onChange={this.desableButtons.bind(this)}
														onEditorChange={this.handleOptionChange.bind(this)}
														onFocusOut={this._handleFocusOut.bind(
															this,
															this.state.questions[this.state.questionIndex].id,
															'option',
															0,
														)}
													/>
												</div>
												<div className={editinglines}>
													<span className="lbls">B:</span>
													<Editor
														apiKey={tinymceapikey}
														value={
															this.state.questions[this.state.questionIndex]
																.options[1]
														}
														id="optionb"
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onChange={this.desableButtons.bind(this)}
														onEditorChange={this.handleOptionChange.bind(this)}
														onFocusOut={this._handleFocusOut.bind(
															this,
															this.state.questions[this.state.questionIndex].id,
															'option',
															1,
														)}
													/>
												</div>
												<div className={editinglines}>
													<span className="lbls">C:</span>
													<Editor
														apiKey={tinymceapikey}
														value={
															this.state.questions[this.state.questionIndex]
																.options[2]
														}
														id="optionc"
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onChange={this.desableButtons.bind(this)}
														onEditorChange={this.handleOptionChange.bind(this)}
														onFocusOut={this._handleFocusOut.bind(
															this,
															this.state.questions[this.state.questionIndex].id,
															'option',
															2,
														)}
													/>
												</div>
												<div className={editinglines}>
													<span className="lbls">D:</span>
													<Editor
														apiKey={tinymceapikey}
														value={
															this.state.questions[this.state.questionIndex]
																.options[3]
														}
														id="optiond"
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onChange={this.desableButtons.bind(this)}
														onEditorChange={this.handleOptionChange.bind(this)}
														onFocusOut={this._handleFocusOut.bind(
															this,
															this.state.questions[this.state.questionIndex].id,
															'option',
															3,
														)}
													/>
												</div>
											</div>
										) : (
											''
										)}
										<Button
											className="btn-primary btn-sm"
											disabled={failbtn}
											onClick={() => {
												if (this.state.questionIndex >= 1) {
													this.setState({
														questionIndex: this.state.questionIndex - 1,
													});
												}
											}}
										>
											{this.state.questionIndex === 0 ? ' First' : 'Previous'}
										</Button>

										<Button
											className="btn-success float-right"
											disabled={failbtn}
											onClick={() => {
												if (
													this.state.questionIndex + 1 <
													this.state.questions.length
												) {
													this.approvequestion(
														this.state.questions[this.state.questionIndex].id,
														1,
														this,
													);
												} else if (
													this.state.questionIndex ===
													this.state.questions.length - 1
												) {
													this.approvequestion(
														this.state.questions[this.state.questionIndex].id,
														0,
														this,
													);
												}
											}}
										>
											{this.state.questionIndex ===
												this.state.questions.length - 1
												? ' Last Question'
												: 'Approve'}
										</Button>

										<Button
											className="btn-warning float-right"
											style={{ marginRight: '70px' }}
											disabled={failbtn}
											onClick={() => {
												if (
													this.state.questionIndex + 1 <
													this.state.questions.length
												) {
													this.holdquestion(
														this.state.questions[this.state.questionIndex].id,
														1,
														this,
													);
												} else if (
													this.state.questionIndex ===
													this.state.questions.length - 1
												) {
													this.holdquestion(
														this.state.questions[this.state.questionIndex].id,
														0,
														this,
													);
												}
											}}
										>
											{this.state.questionIndex ===
												this.state.questions.length - 1
												? ' Hold Last Question'
												: 'Hold'}
										</Button>
									</div>
								) : (
									''
								)}
								<div></div>
							</CardBody>
						</Card>
					</Col>
					<Col md={3}>
						<Card style={{ height: 'auto' }}>
							{this.state.questions.length >= 1 ? (
								<CardBody style={{ padding: '10px' }}>
									<div className="card__title" style={{ marginBottom: '5px' }}>
										<h5 className="bold-text">Q Info</h5>
										<p>
											ID: {this.state.questions[this.state.questionIndex].id}
										</p>
										<p>
											Technology:{' '}
											<TechName
												technolid={
													this.state.questions[this.state.questionIndex]
														.technology
												}
											/>
										</p>
									</div>
								</CardBody>
							) : null}
						</Card>

						<Card style={{ height: 'auto' }}>
							{this.state.questions.length >= 1 ? (
								<CardBody>
									<div className="card__title">
										<h5 className="bold-text">Manage</h5>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">
											Screen Shot (Optional)
										</span>
										<div className="form__form-group-field">
											<input type="file" onChange={this.uploadImage} />
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Comments</span>
										<div className="form__form-group-field">
											<Input
												type="textarea"
												name="comments"
												value={comments}
												onChange={this.handleChange}
											/>
										</div>
									</div>
									<Button
										style={{ float: 'right' }}
										className="btn-danger"
										disabled={failbtn}
										onClick={() => {
											if (
												this.state.questionIndex + 1 <
												this.state.questions.length
											) {
												this.reportquestion(
													this.state.questions[this.state.questionIndex].id,
													1,
													this,
												);
											} else if (
												this.state.questionIndex ===
												this.state.questions.length - 1
											) {
												this.reportquestion(
													this.state.questions[this.state.questionIndex].id,
													0,
													this,
												);
											}
										}}
									>
										{this.state.questionIndex ===
											this.state.questions.length - 1
											? ' Last Question'
											: 'Report'}
									</Button>
								</CardBody>
							) : null}
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default StartCheckQues;
