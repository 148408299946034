import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class AdminSidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	justLogout = () => {
		//localStorage.clear();
		// eslint-disable-next-line
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					<SidebarLink
						title="Dashboard"
						icon="list"
						route="/dashboard"
						onClick={this.hideSidebar}
					/>
					<SidebarCategory title="Reports" icon="users">
						<SidebarLink
							title="Reports"
							icon="list"
							route="/reportspage"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Tech Tests"
							icon="list"
							route="/techreportspage"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Custom Tests"
							icon="list"
							route="/cusreportspage"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Keywords"
							icon="list"
							route="/keyreportspage"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Questions"
							icon="list"
							route="/quesreportspage"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
					<SidebarCategory title="User Management" icon="users">
						<SidebarLink
							title="Creater"
							route="/users/creaters"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Auditor"
							route="/users/auditors"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Companies"
							route="/users/companies"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Recruiter"
							route="/users/recruiter"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Tech Expert"
							route="/users/technicals"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Customer Care"
							route="/users/ccares"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
				</ul>
				<ul className="sidebar__block">
					<SidebarLink
						title="Keyword Details"
						icon="diamond"
						route="/allkeywords"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Keyword Management"
						icon="diamond"
						route="/editkeywords"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Ticket Management"
						icon="diamond"
						route="/alltickets"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Questions as Excel"
						icon="diamond"
						route="/getquesexcel"
						onClick={this.hideSidebar}
					/>


					{/* <SidebarLink title="Log Out" icon="exit" onClick={this.justLogout} /> */}
				</ul>
				<ul className="sidebar__block">
					<SidebarLink
						title="Manage  Alias"
						icon="diamond"
						route="/addsearchitems"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="New Manage  Alias"
						icon="diamond"
						route="/addsearchitemsnew"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Manage Countries"
						icon="diamond"
						route="/countries"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Get OTP"
						icon="diamond"
						route="/getotp"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Manage Dashboards"
						icon="diamond"
						route="/managedashboards"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Manage Emails for Reports"
						icon="diamond"
						route="/manageemails"
						onClick={this.hideSidebar}
					/>
				</ul>
			</div>
		);
	}
}

export default AdminSidebarContent;
