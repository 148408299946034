import React, { Component } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../Apanel/QueryMe';
import Switch from 'react-switch';
var dateFormat = require('dateformat');
import {
	Button,

	Card,
	CardBody,
	Col,
	Badge,
	Table,
	Modal,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../../shared/components/Alert';
import ProfileImage from '../../Apanel/ProfileImage';
const apiurl = MY_API_URL;

const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	newrole: 'company',
	email: '',
	domain: '',
	token: localStorage.token,
};
class AllCompany extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetUsers(localStorage.token);
		this.fetchCompanies();
	}
	fetchCompanies = () => {
		const headers = {
			'Content-Type': 'application/json',
		};
		var postData = {
			allcomps: '',
		};
		const testidurl = apiurl + 'fetchCompanies';
		axios
			.post(testidurl, postData, {
				headers: headers,
			})
			.then((response) => {
				// console.log([...response.data.data]);
				this.setState({
					allCompanies: [...response.data.data],
				});
			})
			.catch((error) => {
				console.log('Error is ', error);
			});
	};
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleAkey = () => {
		this.setState({
			addUserModal: true,
		});
	};
	handleCsettings = (compid, compname, e) => {
		e;
		localStorage.setItem('compid', compid);
		localStorage.setItem('compname', compname);
		// eslint-disable-next-line
		this.props.history.push('/csettings'); //eslint-disable-line
	};
	handleUserSubmit = (event) => {
		event.preventDefault();

		var email = this.state.email;
		var email_string_array = email.split('@');
		var domain_string_location = email_string_array.length - 1;
		var emaildomain = email_string_array[domain_string_location];
		console.log(emaildomain);
		let checkForDomainError = false;
		this.state.allCompanies.map((comp) => {
			if (comp.domain === emaildomain) {
				checkForDomainError = true;
			}
		});
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			if (checkForDomainError) {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: emaildomain + ' Domain Already Registered',
				});
			} else {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addkeyurl = apiurl + 'addnewuser';
				var postData = {
					email: this.state.email,
					newrole: this.state.newrole,
					domain: emaildomain,
					cemail: '',
				};
				axios
					.post(addkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'warning',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{
									addUserModal: false,
									showAlert: false,
									email: '',
								},
								() => {
									swal(emaildomain + ' Company ' + response.data.message, {
										icon: 'success',
									});
									this.handleGetUsers(localStorage.token);
									this.fetchCompanies();
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	handleCkey = () => {
		this.setState({
			addUserModal: false,
		});
	};
	handleTkey = (id, keyword, checked) => {
		// console.log(checked + 'checked');
		// console.log(id + 'id');
		// console.log(keyword + 'keyword');
		var keysts = checked == true ? 0 : 1;
		var kstatus = 'Block';
		if (checked) {
			kstatus = 'Unblock';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'toguser';
				var postData = {
					userid: id,
					ustatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(keyword + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetUsers(localStorage.token);
							swal(keyword + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleGetUsers = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			role: 'company',
		};
		const getuurl = apiurl + 'texperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response);
				if (!response.data.error) {
					this.setState({
						allusers: [...response.data.data],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		const {
			email,
		} = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								All Companies
								<Button
									className="float-right btn-sm"
									color="success"
									onClick={this.handleAkey}
								>
									+
								</Button>
							</h5>
						</div>
						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>.</th>
									<th>Manage</th>
									<th>Email</th>
									<th>Type</th>
									<th>Name</th>
									<th>Mobile</th>
									<th>Registered</th>
								</tr>
							</thead>
							<tbody>
								{this.state.allusers &&
									this.state.allusers.map((huser, index) => {
										let registereded = dateFormat(
											huser.createdon,
											'd-m-yyyy h:mm:ss',
										);
										if (huser.role === 'company') {
											var usts = true;
											if (huser.blocked === 1) {
												usts = false;
											}
											return (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>
														<ProfileImage
															clname="topbar__avatar-img"
															flpath={
																huser.image_url
																	? huser.image_url
																	: this.state.image
															}
														/>
													</td>
													<td>
														<Switch
															onChange={this.handleTkey.bind(
																this,
																huser.id,
																huser.email,
															)}
															checked={usts}
														/>
													</td>
													<td>{huser.email}</td>
													<td>
														<h4>
															<Badge
																style={{ cursor: 'pointer' }}
																color="success"
																onClick={this.handleCsettings.bind(
																	this,
																	huser.id,
																	huser.name,
																)}
															>
																{huser.role} Settings
															</Badge>
														</h4>
													</td>
													<td>{huser.name}</td>
													<td>{huser.mobile}</td>
													<td>{registereded}</td>
												</tr>
											);
										}
									})}
							</tbody>
						</Table>
						<Modal
							isOpen={this.state.addUserModal}
							modalClassName="ltr-support"
							className="modal-dialog--primary"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
								<h4 className="text-modal  modal__title">Add New Company</h4>
							</div>
							<div className="modal__body">
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<form
									className="form"
									onSubmit={(event) => this.handleUserSubmit(event)}
								>
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="email"
												type="email"
												placeholder="Enter email"
												className="form-control"
												value={email}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Add Company
									</button>
								</form>
							</div>
						</Modal>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AllCompany;
