import React, { Component } from 'react';


import { withRouter } from 'react-router-dom';

import Topbar from '../Apanel/topbar/IndexTopbar';
import { Card, CardBody, Col, Row } from 'reactstrap';
import img1 from '../../shared/img/texpert-img1.jpg';

const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
};

class FaqsPanel extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() { }

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	render() {
		return (
			<div>
				<div className="layout layout--top-navigation">
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
				</div>
				<div className="container__wrap" style={{ paddingTop: '62px' }}>
					<Row>
						<Col md={{ size: 8, offset: 2 }}>
							<Card>
								<CardBody>
									<Row>
										<Col md={12}>
											<Card>
												<CardBody>
													<div className="card__title">
														<h5 className="bold-text">
															<span style={{ textTransform: 'capitalize' }}>
																Frequently Asked Questions
															</span>{' '}
														</h5>
													</div>
													<img
														className="ddd"
														style={{
															float: 'right',
															width: '50%',
															marginLeft: '10px',
														}}
														src={img1}
														alt="avatar"
													/>
													<p>
														<b>Q. What does this software do?</b>
													</p>
													<p>
														<b>A.</b> The software allows you to create tests
														and administer them. It can be used by recruiters,
														teachers, for employee training, certification or
														any one who wants to give an online test to a test
														taker.{' '}
													</p>
													<p>
														<b>Q. Is this platform free?</b>
													</p>
													<p>
														<b>A.</b> Yes, the platform is free at this time. At
														this point we do not know when we will begin
														charging for the platform. We will provide at least
														three months notice.{' '}
													</p>
													<p>
														<b>
															Q. Is there a limitation to the number of tests?
														</b>
													</p>
													<p>
														<b>A.</b> Yes at this time you may not create over
														100 tests per month. 1000 tests per month limit for
														educational institutions.{' '}
													</p>
													<p>
														<b>
															Q. What version of the platform is being used?
														</b>
													</p>
													<p>
														<b>A.</b> Currently we are in Alpha version.{' '}
													</p>
													<p>
														<b>Q. Where can I send inquiries?</b>
													</p>
													<p>
														<b>A.</b> Please send inquiries to info@screenhr.com
														. You may also send questions (mention
														technologies), feature requests, or suggestions.{' '}
													</p>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Row>
										<Col md={12}>
											<Card>
												<CardBody>
													<p>
														Copyright 2020 screenhr.com. All Rights Reserved.
													</p>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default withRouter(FaqsPanel);
