import React, { Component } from 'react';
import ReactDOM from 'react-dom'; // eslint-disable-line
// import EditableLabel from 'react-inline-editing';
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';
// import Switch from 'react-switch';
// import SearchIcon from 'mdi-react/SearchIcon';
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
	Modal,
	Table,
	Badge,
} from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import GridIcon from 'mdi-react/GridIcon';
import EditIcon from 'mdi-react/EditIcon';
import WebIcon from 'mdi-react/WebIcon';
import Select from 'react-select';
import Resizer from 'react-image-file-resizer';
import StarIcon from 'mdi-react/StarIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AddLocationIcon from 'mdi-react/AddLocationIcon';
// import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
// import AccountsAddIcon from 'mdi-react/AccountsAddIcon';
import Alert from '../../../../shared/components/Alert';
import ClientImage from './ClientImage';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	client_types: [],
	client_type: '',
	lead_statuss: [],
	lead_status: '',
	client_name: '',
	client_location: '',
	client_image: 0,
	lead_domain: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	oldkeyname: '',
	newkeyname: '',
	noorid: 0,

	addLeadModal: false,
	addPocModal: false,
	aleads: 0,
	bleads: 0,
	tleads: 0,
	token: localStorage.token,
	clienttypes: [{
		value: '',
		label: (<div>
			Search/Select Client Types
		</div>
		),
	}],
	leadsts: [{
		value: '',
		label: (<div>
			Search/Select Lead Status
		</div>
		),
	}],
	name: '',
	email: '',
	phone: '',
	address: '',
	city: '',
	statee: '',
	fax: '',
	improfile: '',
	linkedin: '',
	leadPhones: [],
	testPhones: [],
	currentcolor: [
		'#a5e6ff',
	],
};
class Clients extends Component {
	constructor(props) {
		super(props);
		// this._handleFocus = this._handleFocus.bind(this);
		// this._handleFocusOut = this._handleFocusOut.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.lastId = -1;
		this.base64Image = this.base64Image.bind(this);

	}
	state = { ...initialstate };
	componentDidMount() {
		this.setState({ leadPhones: [], testPhones: [] });
		if (localStorage.dashbid) {
			this.handleGetkeys(localStorage.dashbid);
		} else {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		}


	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleLeadSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.client_type);
		if (this.state.client_type == '') {
			swal('Select Client Type', {
				icon: 'warning',
			});
		} else if (this.state.lead_status == '') {
			swal('Select Client Status', {
				icon: 'warning',
			});
		} else {


			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			const addkeyurl = apiurl + 'addlead';
			var postData = {
				client_type: this.state.client_type,
				client_name: this.state.client_name,
				client_location: this.state.client_location,
				client_image: this.state.client_image,
				lead_status: this.state.lead_status,
				lead_domain: this.state.lead_domain,
				dashbid: localStorage.dashbid,
				bdmid: localStorage.usercode,
			};
			axios
				.post(addkeyurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState(
							{
								showAlert: false,
								addLeadModal: false,
								addPocModal: false,
								client_type: '',
							},
							() => {
								swal(this.state.client_name + ' ' + response.data.message, {
									icon: 'success',
								});
								this.handleGetkeys(localStorage.dashbid);
							},
						);
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		}
	};

	handleEditLeadSubmit = (event) => {

		event.preventDefault();
		console.log(this.state.client_type);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'editlead';
		var postData = {
			client_type: this.state.client_type,
			client_name: this.state.client_name,
			client_location: this.state.client_location,
			client_image: this.state.client_image,
			lead_status: this.state.lead_status,
			lead_domain: this.state.lead_domain,
			dashbid: localStorage.dashbid,
			bdmid: localStorage.usercode,
			eleadid: this.state.eleadid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: false,
							addLeadModal: false,
							editLeadModal: false,
							addPocModal: false,
							editPocModal: false,
							client_name: '',
							client_location: '',
							client_image: 0,
							client_type: '',
							lead_status: '',
							lead_domain: '',
						},
						() => {
							swal(this.state.client_name + ' ' + response.data.message, {
								icon: 'success',
							});
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleAkey = () => {
		this.setState({
			addLeadModal: true,
			client_name: '',
			client_location: '',
			client_image: 0,
			client_type: '',
			lead_status: '',
			lead_domain: '',
		});
	};
	handleEditLead = (eleadid, leadclient_name, leadclient_location, leadclient_image, leadclient_typeid, leadlead_statusid, leadlead_domain, e) => { //eslint-disable-line
		this.setState({
			editLeadModal: true,
			eleadid: eleadid,
			client_name: leadclient_name,
			client_location: leadclient_location,
			client_image: leadclient_image,
			client_type: leadclient_typeid,
			lead_status: leadlead_statusid,
			lead_domain: leadlead_domain,
		});
	};
	handlePkey = (leadid, e) => { //eslint-disable-line
		this.setState({
			addPocModal: true,
			leadid: leadid,
			name: '',
			email: '',
			phone: '',
			fax: '',
			linkedin: '',
			improfile: '',
			address: '',
			city: '',
			statee: '',
			leadPhones: [],
		});
	};
	handleEditPkey = (epocid, pocname, pocemail, pocphone, pocfax, poclinkedin, pocim_profiles, pocaddress, poccity, pocstatee, e) => { //eslint-disable-line
		this.setState({
			editPocModal: true,
			epocid: epocid,
			name: pocname,
			email: pocemail,
			phone: pocphone,
			fax: pocfax,
			linkedin: poclinkedin,
			improfile: pocim_profiles,
			address: pocaddress,
			city: poccity,
			statee: pocstatee,
			testPhones: [],

		}, () => {
			this.handleEditPhone();
		});
	};

	handleEditPhone() {
		var validphones = [];
		var rawphones = this.state.phone.trim();
		var fallphones = rawphones
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		fallphones.map((fallphone) => {
			if (fallphone) {
				var dnjk = this.state.testPhones.includes(fallphone);
				if (dnjk) {
					this.setState(
						{
							phone: '',
						});
				} else {
					validphones.push(fallphone);
				}
			}


		});
		var newArray = this.state.leadPhones;
		var testPhones = this.state.testPhones;
		if (validphones.length >= 1) {
			validphones.map((phone) => {
				newArray.push({
					content: phone,
					id: ++this.lastId,
				});
				testPhones.push(phone);
				this.setState(
					{
						leadPhones: newArray,
						phone: '',
					},
					() => {
						console.log(this.state.leadPhones);

					},
				);
			});
		}
	}


	handleEditLeadPocSubmit = (event) => {
		event.preventDefault();
		console.log(this.state.client_type);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const addkeyurl = apiurl + 'editleadpoc';
		var postData = {
			name: this.state.name,
			email: this.state.email,
			phone: this.state.testPhones,
			address: this.state.address,
			city: this.state.city,
			statee: this.state.statee,
			fax: this.state.fax,
			improfile: this.state.improfile,
			linkedin: this.state.linkedin,
			dashbid: localStorage.dashbid,
			bdmid: localStorage.usercode,
			pocid: this.state.epocid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					swal(response.data.message, {
						icon: 'warning',
					});
				} else {
					this.setState(
						{
							addLeadModal: false,
							addPocModal: false,
							editLeadModal: false,
							editPocModal: false,
							epocid: '',
							name: '',
							email: '',
							phone: '',
							address: '',
							city: '',
							statee: '',
							fax: '',
							improfile: '',
							linkedin: '',
							testPhones: [],

						},
						() => {
							swal(this.state.name + ' ' + response.data.message, {
								icon: 'success',
							});
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleCkey = () => {
		this.setState({
			addLeadModal: false,
			addPocModal: false,
			editLeadModal: false,
			editPocModal: false,
			leadPhones: [],
			leadid: 0,
		});
	};
	handleGetkeys = (dashbid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			dashbid: dashbid,
			bdmid: localStorage.usercode,
		};
		const getuurl = apiurl + 'allmyleads';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('data', response.data);
					const allclitype = response.data.allclitype;
					const allleadsts = response.data.allleadsts;
					const allleads = response.data.allleads;
					let tleads = 0;
					this.setState({
						clienttypes: [{
							value: '',
							label: (<div>
								Search/Select Client Types
							</div>
							),
						}],
						leadsts: [{
							value: '',
							label: (<div>
								Search/Select Lead Status
							</div>
							),
						}],
					}, () => {
						allclitype.map((clitype) => {
							this.state.clienttypes.push({
								value: clitype.id,
								label: (
									<div>
										{clitype.client_type}
									</div>
								),
							});
						});

						allleadsts.map((sts) => {
							this.state.leadsts.push({
								value: sts.id,
								label: (
									<div>
										{sts.lead_status}
									</div>
								),
							});
						});
						allleads.map(() => {
							tleads = tleads + 1;
						});
					});



					this.setState({
						tleads: tleads,
						allleads: allleads,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};

	handleSelectCliChange = (selectedOption) => {
		let client_type = selectedOption.value;
		this.setState({ client_type: client_type });
	};
	handleSelectStsChange = (selectedOption) => {
		let lead_status = selectedOption.value;
		this.setState({ lead_status: lead_status });
	};

	handleAddPhone(event) {
		event.preventDefault();

		var validphones = [];
		var rawphones = this.state.phone.trim();
		var fallphones = rawphones
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		fallphones.map((fallphone) => {
			if (fallphone) {
				var dnjk = this.state.testPhones.includes(fallphone);
				if (dnjk) {
					this.setState(
						{
							phone: '',
						});
				} else {
					validphones.push(fallphone);
				}
			}


		});
		var newArray = this.state.leadPhones;
		var testPhones = this.state.testPhones;
		if (validphones.length >= 1) {
			validphones.map((phone) => {
				newArray.push({
					content: phone,
					id: ++this.lastId,
				});
				testPhones.push(phone);
				this.setState(
					{
						leadPhones: newArray,
						phone: '',
					},
					() => {
						console.log(this.state.leadPhones);

					},
				);
			});
		}
	}
	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['phone'];
		let abcd = this.state.leadPhones;
		const newArray = abcd.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testPhones = this.state.testPhones.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ leadPhones: newArray, testPhones: testPhones }, () => {
			console.log('items ', this.state.leadPhones);
			console.log('phones ', this.state.testPhones);
		});
	}
	makeAddedList() {
		const elements = this.state.leadPhones.map((listitem, index) => (
			<span
				className="leadphonelist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-phone={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}
	handleLeadPocSubmit = (event) => {
		event.preventDefault();
		console.log(this.state.client_type);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const addkeyurl = apiurl + 'addleadpoc';
		var postData = {
			name: this.state.name,
			email: this.state.email,
			phone: this.state.testPhones,
			address: this.state.address,
			city: this.state.city,
			statee: this.state.statee,
			fax: this.state.fax,
			improfile: this.state.improfile,
			linkedin: this.state.linkedin,
			dashbid: localStorage.dashbid,
			bdmid: localStorage.usercode,
			leadid: this.state.leadid,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					swal(response.data.message, {
						icon: 'warning',
					});
				} else {
					this.setState(
						{
							addLeadModal: false,
							addPocModal: false,
							name: '',
							email: '',
							phone: '',
							address: '',
							city: '',
							statee: '',
							fax: '',
							improfile: '',
							linkedin: '',
							testPhones: [],

						},
						() => {
							swal(this.state.name + ' ' + response.data.message, {
								icon: 'success',
							});
							this.handleGetkeys(localStorage.dashbid);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};

	getImageType(giventype) {
		var type = '';

		switch (giventype) {
			case 'image/png':
				type = 'PNG';
				break;
			case 'image/gif':
				type = 'JPEG';
				break;
			case 'image/jpeg':
				type = 'JPEG';
				break;
			case 'image/jpg':
				type = 'JPEG';
				break;
			default:
				type = 'JPEG';
				break;
		}
		return type;
	}
	base64Image(event) {
		var mig = event.target.files[0];
		console.log('gottype', event.target.files[0].type);
		var gottype = this.getImageType(event.target.files[0].type);

		console.log('gottype', gottype);
		this.setState(
			{
				failbtn: true,
			},
			() => {
				console.log(this.state.client_image);
				var fileInput = false;
				if (mig) {
					fileInput = true;
				}
				if (fileInput) {
					Resizer.imageFileResizer(
						mig,
						300,
						300,
						gottype,
						100,
						0,
						(uri) => {
							console.log(uri);
							const baseimageuri = uri;

							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};
							var postData = {
								base64: baseimageuri,
							};

							const base64loadurl = apiurl + 'base64load';
							axios
								.post(base64loadurl, postData, {
									headers: headers,
								})
								.then((response) => {
									console.log('Response is ', response.data);

									if (response.data.error) {
										this.setState({
											showAlert: true,
											alertType: 'warning',
											alertText: response.data.message,
											failbtn: false,
										});
									} else {
										this.setState({
											showAlert: false,
											client_image: response.data.fileurl,
											failbtn: false,
										});
									}
								})
								.catch(() => {
									this.setState({
										alertType: 'danger',
										showAlert: true,
										alertText: 'Failled Try Again Later!',
									});
								});
						},
						'base64',
					);
				}
			},
		);
	}

	showGridPocs = (showgridpoc) => {
		this.setState({ showgridpoc, showlistpoc: 0 }, () => {
			console.log('grid poc ', this.state.showgridpoc);
		});
	};
	showListPocs = (showlistpoc) => {
		this.setState({ showlistpoc, showgridpoc: 0 }, () => {
			console.log('list poc ', this.state.showlistpoc);
		});
	};

	render() {
		const { client_type, client_name, client_location, client_image, lead_status, lead_domain, name, email, phone, address, city, statee, fax, linkedin, improfile } = this.state;
		return (
			<div>
				<Col md={12}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h3 className="bold-text">
									Clients

									<Button
										className="float-right btn-sm"
										color="success"
										onClick={this.handleAkey}
									>
										+
									</Button>
								</h3>

							</div>
						</CardBody>
					</Card>
				</Col>



				{this.state.allleads && this.state.allleads.map((dlead, index) => {
					let lead = dlead.lead;
					let pocs = dlead.pocs;
					return (
						<Col md={12} key={index}>
							<Card >
								<CardBody>
									<div className="card__title">
										<h4 className="bold-text">
											<ClientImage cliclname='client_img' cliflpath={lead.client_image} /> :{' '}
											{lead.client_name} <span onClick={this.handleEditLead.bind(this, lead.id, lead.client_name, lead.client_location, lead.client_image, lead.client_typeid, lead.lead_statusid, lead.lead_domain)} style={{ cursor: 'pointer' }} ><EditIcon /> </span>
											<Button
												className="float-right btn-sm"
												color="success"
												onClick={this.handlePkey.bind(this, lead.id)}
											>
												+ POC
											</Button>
										</h4>
									</div>

									<p><b>Client Location:</b> {lead.client_location}</p>
									<p><b>Client Type:</b> {lead.client_type}</p>
									<p><b>Client Status:</b> {lead.lead_status}</p>
									<p><b>Client Domain:</b> {lead.lead_domain}</p>
									<h4 className="bold-text" style={{ margin: '10px 0px' }}>Point of Contacts <span style={{ margin: '0px 10px 0px 25px', cursor: 'pointer' }} onClick={this.showListPocs.bind(this, lead.id)}><MenuIcon /></span><span style={{ margin: '0px 10px 0px 10px', cursor: 'pointer' }} onClick={this.showGridPocs.bind(this, lead.id)}><GridIcon /></span></h4>
									{this.state.showgridpoc && this.state.showgridpoc == lead.id ? (
										<Row>
											{pocs && pocs.map((poc, index) => {
												return (
													<Col md={6} key={index}>
														<Table responsive hover>
															<tbody>
																<tr>
																	<td>
																		<span className='pocname'><b><Badge>{index + 1}</Badge> Name:</b> {poc.name} <span onClick={this.handleEditPkey.bind(this, poc.id, poc.name, poc.email, poc.phone, poc.fax, poc.linkedin, poc.im_profiles, poc.address, poc.city, poc.statee)} style={{ cursor: 'pointer' }} ><EditIcon /></span></span>
																		<span className='pocname'><b>Email:</b> {poc.email}</span>
																		<span className='pocname'><b>Phone:</b> {poc.phone}</span>
																		<span className='pocname'><b>Fax:</b> {poc.fax}</span>
																		<span className='pocname'><b>Linkedin:</b> <a
																			href={poc.linkedin}
																			target="_blank" rel="noreferrer"

																		>
																			Click Here to see
																		</a></span>

																	</td>
																	<td>

																		<span className='pocname'><b>IM Profiles:</b> {poc.im_profiles}</span>
																		<span className='pocname'><b>Address:</b> {poc.address}</span>
																		<span className='pocname'><b>City:</b> {poc.city}</span>
																		<span className='pocname'><b>State:</b> {poc.statee} </span>
																	</td>
																</tr>
															</tbody>
														</Table>
													</Col>
												);
											})}
										</Row>
									) : null}

									{this.state.showlistpoc && this.state.showlistpoc == lead.id ? (
										<div>
											<Table responsive hover striped>
												<tbody>
													{pocs && pocs.map((poc, index) => {
														return (


															<tr key={index}>
																<td>  <b><Badge>{index + 1}</Badge> Name:</b> {poc.name} <span onClick={this.handleEditPkey.bind(this, poc.id, poc.name, poc.email, poc.phone, poc.fax, poc.linkedin, poc.im_profiles, poc.address, poc.city, poc.statee)} style={{ cursor: 'pointer' }} ><EditIcon /></span> </td>
																<td><b>Email:</b> {poc.email} </td>
																<td>
																	<b>Phone:</b> {poc.phone} <br />
																	<b>Fax:</b> {poc.fax} <br />
																	<b>IM Profiles:</b> {poc.im_profiles} <br />
																	<b>Address:</b> {poc.address} <br />
																</td>

																<td><b>State:</b> {poc.statee}</td>
																<td><b>City:</b> {poc.city}</td>
																<td><b>Linkedin:</b> <a
																	href={poc.linkedin}
																	target="_blank" rel="noreferrer"

																>
																	Click Here to see
																</a> </td>
															</tr>


														);
													})}
												</tbody>
											</Table>
										</div>
									) : null}
								</CardBody>
							</Card>

						</Col>
					);
				})}


				<Modal
					isOpen={this.state.addLeadModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">Add  Client Info</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleLeadSubmit(event)}
						>
							<Row style={{ width: '100%' }}>
								<Col md={6}>

									<div className="form__form-group">
										<span className="form__form-group-label">Client Name</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<StarIcon />
											</div>

											<input
												name="client_name"
												type="text"
												placeholder="Enter Client Name"
												className="form-control"
												value={client_name}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Client Location</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AddLocationIcon />
											</div>

											<input
												name="client_location"
												type="text"
												placeholder="Enter Client Location"
												className="form-control"
												value={client_location}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Client Domain</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<WebIcon />
											</div>

											<input
												name="lead_domain"
												type="text"
												placeholder="Enter Client Domain"
												className="form-control"
												value={lead_domain}
												onChange={this.handleChange}

											/>
										</div>
									</div>
								</Col>
								<Col md={6}>
									<Row style={{ marginBottom: '40px' }}>
										<Col md={3}>

											<ClientImage cliclname="client_img" cliflpath={client_image} />
											<br />
										</Col>
										<Col md={9}>
											<input type="file" onChange={this.base64Image} /></Col>
									</Row>

									<div className="form__form-group">
										<span className="form__form-group-label">Client Type</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<Select
												name="client_type"
												value={this.state.clienttypes.filter(
													(option) => option.value == client_type,
												)}
												onChange={this.handleSelectCliChange}
												options={this.state.clienttypes}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Lead Status</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<Select
												name="lead_status"
												value={this.state.leadsts.filter(
													(option) => option.value == lead_status,
												)}
												onChange={this.handleSelectStsChange}
												options={this.state.leadsts}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Add Client
									</button>
								</Col>
							</Row>


						</form>
					</div>
				</Modal>
				<Modal
					isOpen={this.state.addPocModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">Add Client POC Info</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleLeadPocSubmit(event)}
						>
							<Row style={{ width: '100%' }}>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Contact Name</span>
										<div className="form__form-group-field">


											<input
												name="name"
												type="text"
												placeholder="Enter Name"
												className="form-control"
												value={name}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Email</span>
										<div className="form__form-group-field">


											<input
												name="email"
												type="email"
												placeholder="Enter Email"
												className="form-control"
												value={email}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone (Add Multiple with Comma Separated)
											<p>{this.makeAddedList()}</p>
										</span>
										<div className="form__form-group-field">


											<input
												name="phone"
												type="text"
												placeholder="Enter Phone"
												className="form-control"
												value={phone}
												onChange={this.handleChange}
												onKeyUp={(e) => {
													e.preventDefault();
													if (
														e.keyCode == 13 ||
														e.keyCode == 188
													) { this.handleAddPhone(event); }
												}}
												onBlur={(e) => {
													e.preventDefault();
													this.handleAddPhone(event);
												}}

											/>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Fax</span>
											<div className="form__form-group-field">


												<input
													name="fax"
													type="text"
													placeholder="Enter Fax"
													className="form-control"
													value={fax}
													onChange={this.handleChange}

												/>
											</div>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">


											<input
												name="linkedin"
												type="url"
												placeholder="Enter Linkedin"
												className="form-control"
												value={linkedin}
												onChange={this.handleChange}

											/>
										</div>
									</div>
								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Address</span>
										<div className="form__form-group-field">


											<input
												name="address"
												type="text"
												placeholder="Enter Address"
												className="form-control"
												value={address}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">City</span>
										<div className="form__form-group-field">


											<input
												name="city"
												type="text"
												placeholder="Enter City"
												className="form-control"
												value={city}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">State</span>
										<div className="form__form-group-field">


											<input
												name="statee"
												type="text"
												placeholder="Enter State"
												className="form-control"
												value={statee}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">IM Profiles</span>
										<div className="form__form-group-field">
											<input
												name="improfile"
												type="text"
												placeholder="Enter IM Profile"
												className="form-control"
												value={improfile}
												onChange={this.handleChange}

											/>
										</div>
									</div>

									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Add POC
									</button>
								</Col>
							</Row>
						</form>
					</div>
				</Modal>

				{/* editing modals */}

				<Modal
					isOpen={this.state.editLeadModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">Edit Client Info</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleEditLeadSubmit(event)}
						>
							<Row style={{ width: '100%' }}>
								<Col md={6}>

									<div className="form__form-group">
										<span className="form__form-group-label">Client Name</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<StarIcon />
											</div>

											<input
												name="client_name"
												type="text"
												placeholder="Enter Client Name"
												className="form-control"
												value={client_name}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Client Location</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AddLocationIcon />
											</div>

											<input
												name="client_location"
												type="text"
												placeholder="Enter Client Location"
												className="form-control"
												value={client_location}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Client Domain</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<WebIcon />
											</div>

											<input
												name="lead_domain"
												type="text"
												placeholder="Enter Client Domain"
												className="form-control"
												value={lead_domain}
												onChange={this.handleChange}

											/>
										</div>
									</div>
								</Col>
								<Col md={6}>
									<Row style={{ marginBottom: '40px' }}>
										<Col md={3}>

											<ClientImage cliclname="client_img" cliflpath={client_image} />
											<br />
										</Col>
										<Col md={9}>
											<input type="file" onChange={this.base64Image} /></Col>
									</Row>

									<div className="form__form-group">
										<span className="form__form-group-label">Client Type</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<Select
												name="client_type"
												value={this.state.clienttypes.filter(
													(option) => option.value == client_type,
												)}
												onChange={this.handleSelectCliChange}
												options={this.state.clienttypes}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Lead Status</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<Select
												name="lead_status"
												value={this.state.leadsts.filter(
													(option) => option.value == lead_status,
												)}
												onChange={this.handleSelectStsChange}
												options={this.state.leadsts}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Edit Client Info
									</button>
								</Col>
							</Row>
						</form>
					</div>
				</Modal>
				<Modal
					isOpen={this.state.editPocModal}
					modalClassName="ltr-support"
					className="modal-dialog--primary clientmodal"
				>
					<div className="modal__header">
						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>
						<h4 className="text-modal  modal__title">Edit Client POC Info</h4>
					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}
						<form
							className="form"
							onSubmit={(event) => this.handleEditLeadPocSubmit(event)}
						>
							<Row style={{ width: '100%' }}>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Contact Name</span>
										<div className="form__form-group-field">


											<input
												name="name"
												type="text"
												placeholder="Enter Name"
												className="form-control"
												value={name}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Email</span>
										<div className="form__form-group-field">


											<input
												name="email"
												type="email"
												placeholder="Enter Email"
												className="form-control"
												value={email}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone (Add Multiple with Comma Separated)
											<p>{this.makeAddedList()}</p>
										</span>
										<div className="form__form-group-field">


											<input
												name="phone"
												type="text"
												placeholder="Enter Phone"
												className="form-control"
												value={phone}
												onChange={this.handleChange}
												onKeyUp={(e) => {
													e.preventDefault();
													if (
														e.keyCode == 13 ||
														e.keyCode == 188
													) { this.handleAddPhone(event); }
												}}
												onBlur={(e) => {
													e.preventDefault();
													this.handleAddPhone(event);
												}}

											/>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Fax</span>
											<div className="form__form-group-field">


												<input
													name="fax"
													type="text"
													placeholder="Enter Fax"
													className="form-control"
													value={fax}
													onChange={this.handleChange}

												/>
											</div>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">


											<input
												name="linkedin"
												type="url"
												placeholder="Enter Linkedin"
												className="form-control"
												value={linkedin}
												onChange={this.handleChange}

											/>
										</div>
									</div>
								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Address</span>
										<div className="form__form-group-field">


											<input
												name="address"
												type="text"
												placeholder="Enter Address"
												className="form-control"
												value={address}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">City</span>
										<div className="form__form-group-field">


											<input
												name="city"
												type="text"
												placeholder="Enter City"
												className="form-control"
												value={city}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">State</span>
										<div className="form__form-group-field">


											<input
												name="statee"
												type="text"
												placeholder="Enter State"
												className="form-control"
												value={statee}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">IM Profiles</span>
										<div className="form__form-group-field">
											<input
												name="improfile"
												type="text"
												placeholder="Enter IM Profile"
												className="form-control"
												value={improfile}
												onChange={this.handleChange}

											/>
										</div>
									</div>

									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Edit POC
									</button>
								</Col>
							</Row>





						</form>
					</div>
				</Modal>


			</div>
		);
	}
}

export default Clients;
