import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

import SearchIcon from 'mdi-react/SearchIcon';

const apiurl = MY_API_URL;

import {
	Table,
	Badge,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	selectedTechnologies: [],
	techusedby: [],
	technologyToSearch: '',
	difficultyLevels: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	token: localStorage.token,
	options: [],

	currentcolor: [
		'#000000',
	],
	techwarning: false,
};
class Asettings extends Component {
	constructor(props) {
		super(props);

		this.lastId = -1;
	}
	state = { ...initialstate };
	componentDidMount() {

		if (localStorage.profile_completed === '0') {
			// eslint-disable-next-line
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeys();

		}
	}






	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			userid: localStorage.usercode
		};
		const getuurl = apiurl + 'alltechkeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {

				if (!response.data.error) {

					var st = [];
					var dt = [];
					var usr = [];
					if (response.data.mykeys.length >= 1) {
						response.data.mykeys.map((mkey) => {
							st.push(mkey.id);
							dt.push(mkey.keyword);
							usr.push(mkey.users);
						});
					}

					this.setState({
						mykeys: [...response.data.mykeys],
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
						selectedTechnologies: [...st],
						difficultyLevels: [...dt],
						techusedby: [...usr],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	unselectTechnology = (technology) => {
		console.log('Technology is ', technology);
		console.log('Selected Technologies', this.state.selectedTechnologies);
		console.log('Selected difficultyLevels', this.state.difficultyLevels);
		let index = this.state.selectedTechnologies.indexOf(technology);
		console.log('Index is ', index);
		let copyArray = [...this.state.selectedTechnologies];
		let copyDIfArray = [...this.state.difficultyLevels];
		copyArray.splice(index, 1);
		copyDIfArray.splice(index, 1);
		this.setState(
			{
				selectedTechnologies: [...copyArray],
				difficultyLevels: [...copyDIfArray],
				techwarning: false,
			},
			() => {
				console.log('Selected Technologies', this.state.selectedTechnologies);
				console.log('Selected difficultyLevels', this.state.difficultyLevels);
			},
		);
	};

	setMyTech = (event) => {
		event.preventDefault();
		var mytecharray = this.state.selectedTechnologies;
		this.setState(
			{
				showAlert: false,
			},
			() => {
				if (mytecharray) {
					// var faqtechs = mytecharray.join(',');
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};
					var postData = {
						userid: localStorage.usercode,
						technologies: mytecharray
					};
					const getuurl = apiurl + 'setmytech';
					axios
						.post(getuurl, postData, {
							headers: headers,
						})
						.then((response) => {
							if (response.data.error) {
								this.setState({
									showAlert: true,
									alertType: 'warning',
									alertText: 'Failed Try Again Later!',
								});

							} else {
								this.handleGetkeys();
							}
						})
						.catch((error) => { //eslint-disable-line
							localStorage.clear();
							// eslint-disable-next-line
							this.props.history.push('/'); //eslint-disable-line
						});

				} else {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'You need to select at least one technology',
					});
				}
			},
		);
	};

	render() {

		// console.log('Options are ', options);
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-3 order-md-3 order-sm-1 order-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies Selected  <span className='float-right'>UsedBy</span></h5>


								{/* technologies here */}
								<Table responsive hover>
									<tbody>

										{this.state.selectedTechnologies.map(
											(technology, index) => {
												return (
													<tr key={index}>
														<td
														>
															{this.state.difficultyLevels[index]}

															<i
																className="lnr lnr-cross"
																style={{ marginLeft: 10 }}
																onClick={() => {
																	this.unselectTechnology(technology);
																}}
															></i>
															<Badge className='float-right' color="success">{this.state.techusedby[index]}</Badge>

														</td>
													</tr>

												);
											},
										)}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
					<div className="col-md-3 order-md-2 order-sm-12 order-1">
						<div className="row">
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<h3>{this.state.selectedTechnologies.length}/10</h3>
										{this.state.techwarning ? (
											<span style={{ color: 'red' }}>Cant Add More Than 10 Technologies</span>
										) : null}
									</div>
								</div>
							</div>


							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<form
											className="form"
											onSubmit={(event) => this.setMyTech(event)}
										>
											{this.state.selectedTechnologies.length > 0 ? (
												<button
													className="btn btn-primary account__btn account__btn--small"
													type="submit"
												>
													Save Technologies
												</button>
											) : (
												<button
													className="btn btn-primary account__btn account__btn--small"
													type="button"
													desabled="true"
												>
													Save Technologies
												</button>
											)}
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 order-md-1 order-sm-1 order-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies</h5>

								<form className="form">
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<SearchIcon />
											</div>
											<input
												name="tkey"
												type="text"
												placeholder="Search"
												onChange={(e) => {
													this.handleSearch(e);
												}}
											/>
										</div>
									</div>
								</form>
								{/* technologies here */}
								<Table responsive hover>
									<tbody>
										{this.state.relatedKeywords.map((keyword, index) => {
											if (
												this.state.selectedTechnologies.indexOf(keyword.id) ===
												-1
											) {

												return (
													<tr key={index}>
														<td

															onClick={() => {
																if (
																	this.state.selectedTechnologies.indexOf(
																		keyword.id,
																	) == -1
																) {
																	if (this.state.selectedTechnologies.length <= 9) {
																		this.setState(
																			{
																				selectedTechnologies: [
																					...this.state.selectedTechnologies,
																					keyword.id,
																				],
																				techwarning: false,
																			},
																			() => {
																				let previousDifficultyArray = this.state
																					.difficultyLevels;
																				previousDifficultyArray.push(
																					keyword.keyword,
																				);

																				this.setState({
																					difficultyLevels: previousDifficultyArray,
																				});
																			},
																		);
																	} else if (this.state.selectedTechnologies.length >= 10) {
																		this.setState({
																			techwarning: true,
																		});
																	}
																}
															}
															}

														>
															<strong>{keyword.keyword}</strong>
														</td>
													</tr>
												);
											}
										})}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Asettings;
