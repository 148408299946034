import React, { Component } from 'react';

// import ReactExport from 'react-export-excel';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

import { Card, CardBody, Col, Table, Button } from 'reactstrap';

const apiurl = MY_API_URL;
const initialstate = {
	quests: [],
	wquests: [],
	token: localStorage.token,
};
class RepairTechQues extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.repairtech) {
			this.getquesTech(localStorage.repairtech);
		} else {
			// eslint-disable-next-line
			this.props.history.push('/listqueskeys');
		}
	}
	getquesTech = (techid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			technology: techid,
		};
		const getfqurl = apiurl + 'gettechQuestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					this.setState({
						questions: [],
						message: response.data.message,
					});
				} else {
					this.setState(
						{
							questions: [...response.data.data],
						},
						() => {
							this.state.questions.map((hkey) => {
								let answer = 555555;

								let qcorrect = hkey.correct;
								let optiona = hkey.options[0];
								let optionb = hkey.options[1];
								let optionc = hkey.options[2];
								let optiond = hkey.options[3];

								// qcorrect = qcorrect.replace(/\./gi, ' &#46;');

								if (optiona == qcorrect) {
									answer = 0;
								} else if (optionb == qcorrect) {
									answer = 1;
								} else if (optionc == qcorrect) {
									answer = 2;
								} else if (optiond == qcorrect) {
									answer = 3;
								}
								if (answer <= 3) {
									this.state.quests.push({ id: hkey.id, answer: answer });
								} else if (answer == 555555) {
									this.state.wquests.push({ id: hkey.id });
								}
							});
						},
					);
				}
			})
			.catch(() => {
				// // eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	setRepairQues = (e) => {
		e;
		// console.log('ok', this.state.quests);
		// console.log('not ok', this.state.wquests);

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			quests: this.state.quests,
		};
		const getfqurl = apiurl + 'setRepairquestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data);
				if (!response.data.error) {
					// eslint-disable-next-line
					this.props.history.push('/listqueskeys');
				}
			})
			.catch(() => {
				// eslint-disable-next-line
				this.props.history.push('/listqueskeys');
			});
	};
	setDeleteQues = (e) => {
		e;
		// console.log('ok', this.state.quests);
		// console.log('not ok', this.state.wquests);

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			quests: this.state.wquests,
		};
		const getfqurl = apiurl + 'setDeletequestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data);
				if (!response.data.error) {
					// eslint-disable-next-line
					this.props.history.push('/listqueskeys');
				}
			})
			.catch(() => {
				// eslint-disable-next-line
				this.props.history.push('/listqueskeys');
			});
	};
	render() {
		const { wquests } = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						{/* <ExcelFile filename={localStorage.repairtechname}>
							<ExcelSheet data={wquests} name="Errors">
								<ExcelColumn label="Question ID" value="id" />
							</ExcelSheet>
							<ExcelSheet data={quests} name="Fixed Questions">
								<ExcelColumn label="Question ID" value="id" />
								<ExcelColumn label="Answer" value="answer" />
							</ExcelSheet>
						</ExcelFile> */}
						<h2>{localStorage.repairtechname}</h2>
						{wquests && wquests.length >= 1 ? (
							<Button
								className="btn-sm float-right"
								color="danger"
								onClick={this.setDeleteQues.bind(this)}
							>
								Delete errored Questions
							</Button>
						) : (
							<Button
								className="btn-sm float-right"
								color="success"
								onClick={this.setRepairQues.bind(this)}
							>
								Fix Questions
							</Button>
						)}
					</CardBody>
				</Card>
				<Card>
					<CardBody>
						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Question</th>

									<th>options</th>
									<th>Correct</th>
									<th>Answer</th>
								</tr>
							</thead>
							<tbody>
								{this.state.questions &&
									this.state.questions.map((thkey, index) => {
										let tanswer = 555555;
										let tcorrect = '';
										let tqcorrect = thkey.correct;
										let optiona = thkey.options[0];
										let optionb = thkey.options[1];
										let optionc = thkey.options[2];
										let optiond = thkey.options[3];

										// tqcorrect = tqcorrect.replace(/\./gi, ' &#46;');

										if (optiona == tqcorrect) {
											tanswer = 0;
										} else if (optionb == tqcorrect) {
											tanswer = 1;
										} else if (optionc == tqcorrect) {
											tanswer = 2;
										} else if (optiond == tqcorrect) {
											tanswer = 3;
										}

										if (isNaN(tqcorrect)) {
											tcorrect = 'Answer is Word';
										} else {
											tcorrect = 'Number';
											tcorrect;
										}
										return (
											<tr
												className={tanswer == 555555 ? 'noanswer' : 'yesanswer'}
												key={index}
											>
												<td>{index + 1}</td>
												<td>
													<strong>{thkey.question}</strong>
												</td>
												<td>
													<p style={{ borderBottom: '1px solid #ccc' }}>
														{optiona}
													</p>

													<p style={{ borderBottom: '1px solid #ccc' }}>
														{optionb}
													</p>

													<p style={{ borderBottom: '1px solid #ccc' }}>
														{optionc}
													</p>

													<p style={{ borderBottom: '1px solid #ccc' }}>
														{optiond}
													</p>
												</td>
												<td>{tqcorrect}</td>
												<td>{tanswer}</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default RepairTechQues;
