import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

class AuditorSidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};

	justLogout = () => {
		//localStorage.clear();
		// eslint-disable-next-line
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					{/* <SidebarLink
						title="Pending Questions"
						icon="list"
						route="/allauques"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Approved Questions"
						icon="list"
						route="/allauappques"
						onClick={this.hideSidebar}
					/> */}
					<SidebarLink
						title="Technologies"
						icon="list"
						route="/autechs"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Audit Questions"
						icon="list"
						route="/listautechs"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Audit Again Questions"
						icon="list"
						route="/listauagaintechs"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="On Hold Questions"
						icon="list"
						route="/listauonholdtechs"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Reported Questions"
						icon="list"
						route="/listaureptechs"
						onClick={this.hideSidebar}
					/>
					{/* <SidebarLink title="Log Out" icon="exit" onClick={this.justLogout} /> */}
				</ul>
				<ul className="sidebar__block">
					<SidebarLink
						title="Audit Faqs"
						icon="list"
						route="/listfaqtechs"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Audit Again Faqs"
						icon="list"
						route="/listfaqagaintechs"
						onClick={this.hideSidebar}
					/>
				</ul>
				<ul className="sidebar__block">
					<SidebarLink
						title="Experts Technologies"
						icon="list"
						route="/techautechs"
						onClick={this.hideSidebar}
					/>

				</ul>
				{/* <ul className="sidebar__block">
					<SidebarCategory title="Example Pages" icon="diamond">
						<SidebarLink
							title="Page one"
							route="/pages/one"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Page two"
							route="/pages/two"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
				</ul> */}
			</div>
		);
	}
}

export default AuditorSidebarContent;
