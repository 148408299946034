import React, { Component } from 'react';
import JobsList from './JobsList';
import { withRouter } from 'react-router-dom';

class JobsDeactivated extends Component {
	constructor(props) {
		super(props);
		localStorage.setItem('jstatus', 3);
	}

	componentDidMount() {
		if (localStorage.dashbid) { //eslint-disable-line
		} else {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}




	render() {

		return (
			<div> <JobsList /></div >
		);
	}
}


export default withRouter(JobsDeactivated);
