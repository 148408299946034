import React, { Component } from 'react';


import { Row, Container } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import ProfileImage from '../ProfileImage';

const initialstate = {
	image: localStorage.profile_img,
};
class ProfileCard extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			// eslint-disable-next-line
			this.props.history.push({
				pathname: '/profileedit',
				backTo: '/',
			});
		}
	}
	render() {
		return (
			<Container className="dashboard">
				<Row>
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title"> Profile</h3>
								</div>

								<div className="profile__information">
									<div className="profile__avatar">
										<ProfileImage clname="" flpath={this.state.image} />
									</div>
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item">
										<b>Name: </b>
										{localStorage.name}
									</li>
									<li className="list-group-item">
										<b>Email: </b>
										{localStorage.by}
									</li>
									<li className="list-group-item">
										<b>Mobile: </b>
										{localStorage.mobile}
									</li>
								</ul>

								<Link
									className="btn btn-outline-primary account__btn account__btn--small"
									to="/profileedit"
								>
									Edit Profile
								</Link>
							</div>
						</div>
					</div>
				</Row>
			</Container>
		);
	}
}

export default withRouter(ProfileCard);
