import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import swal from 'sweetalert';
import Resizer from 'react-image-file-resizer';
import {
	Card, CardBody, Row, Col, Table
} from 'reactstrap';
import Switch from 'react-switch';
import Alert from '../../../shared/components/Alert';
const apiurl = MY_API_URL;
const initialstate = {
	asettings: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	failbtn: false,
	logo: localStorage.logo,

	token: localStorage.token,
};
class Asettings extends Component {
	constructor(props) {
		super(props);
		this.base64Image = this.base64Image.bind(this);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('viewrecruiter', '');
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'Asettings');
			// eslint-disable-next-line
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetSettings('yes');
		}
	}
	updatelogo = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		let tutu = 'updatelogo';

		const addkeyurl = apiurl + tutu;
		var postData = {
			email: localStorage.by,
			logo: this.state.logo,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							showAlert: true,
							alertType: 'success',
							alertText: response.data.message,
						},
						() => {
							localStorage.setItem('logo', this.state.logo);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};

	getImageType(giventype) {
		var type = '';

		switch (giventype) {
			case 'image/png':
				type = 'PNG';
				break;
			case 'image/gif':
				type = 'JPEG';
				break;
			case 'image/jpeg':
				type = 'JPEG';
				break;
			case 'image/jpg':
				type = 'JPEG';
				break;
			default:
				type = 'JPEG';
				break;
		}
		return type;
	}
	base64Image(event) {
		var mig = event.target.files[0];
		console.log('gottype', event.target.files[0].type);
		var gottype = this.getImageType(event.target.files[0].type);

		console.log('gottype', gottype);
		this.setState(
			{
				failbtn: true,
			},
			() => {
				console.log(this.state.logo);
				var fileInput = false;
				if (mig) {
					fileInput = true;
				}
				if (fileInput) {
					Resizer.imageFileResizer(
						mig,
						136,
						36,
						gottype,
						100,
						0,
						(uri) => {
							console.log(uri);
							const baseimageuri = uri;

							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};
							var postData = {
								base64: baseimageuri,
							};

							const base64loadurl = apiurl + 'base64load';
							axios
								.post(base64loadurl, postData, {
									headers: headers,
								})
								.then((response) => {
									console.log('Response is ', response.data);

									if (response.data.error) {
										this.setState({
											showAlert: true,
											alertType: 'warning',
											alertText: response.data.message,
											failbtn: false,
										});
									} else {
										this.setState(
											{
												showAlert: false,
												logo: response.data.fileurl,
												failbtn: false,
											},
											() => {
												localStorage.setItem('logo', this.state.logo);
											},
										);
									}
								})
								.catch(() => {
									this.setState({
										alertType: 'danger',
										showAlert: true,
										alertText: 'Failled Try Again Later!',
									});
								});
						},
						'base64',
					);
				}
			},
		);
	}

	handleGetSettings = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			compid: localStorage.usercode,
		};
		const getuurl = apiurl + 'asettings';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					console.log('all:', response.data);
					localStorage.setItem('dashboards', response.data.compdash);
					var fromdash = JSON.parse(response.data.compdash);
					let dashboards = [];
					if (fromdash.length > 0) {
						fromdash.map((fdash) => {
							dashboards.push(fdash.id);
						});

					}
					this.setState({
						alldashs: response.data.alldashs,
						dashboards: dashboards,
						showAlert: false,
					}, () => {
						if (token == 'ok') {
							location.assign('/dashboard');
						}
					});
				}
			})
			.catch((error) => {
				console.log(error);
				// //localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	updateDashboards = () => {
		var newdash = [];
		this.state.dashboards.map((dash) => {
			let ndash = this.state.alldashs.filter((tt) => {
				// console.log('tt ', tt);
				return tt.id === dash;
			});


			newdash.push({
				id: ndash[0].id,
				dashboard: ndash[0].dashboard,
				conid: ndash[0].conid,
				cid: ndash[0].cid,
				cname: ndash[0].cname,
				ccode: ndash[0].ccode
			});
		});

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		let tutu = 'updatedashboard';

		const addkeyurl = apiurl + tutu;
		var postData = {
			compid: localStorage.usercode,
			dashboards: newdash,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState({
						showAlert: true,
						alertType: 'success',
						alertText: response.data.message,
					}, () => {
						this.handleGetSettings('ok');
					});
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleDash = (id, keyword, checked) => {
		// checked = checked.target.checked;
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(keyword + 'keyword');
		// var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + keyword,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				if (checked) {
					if (this.state.dashboards.indexOf(id) == -1) {
						this.setState({
							dashboards: [...this.state.dashboards, id],
							showAlert: false,
						});
					}
				} else {
					if (this.state.dashboards.length == 1) {
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Atleast One Dashboard to be Selected!',
						});
					} else {
						let index = this.state.dashboards.indexOf(id);
						let dashboardsArray = [...this.state.dashboards];
						console.log(dashboardsArray);
						dashboardsArray.splice(index, 1);
						console.log(dashboardsArray);
						this.setState({
							dashboards: [...dashboardsArray],
						});
					}

				}
				// console.log('dashid:', id);
			}
		});
	};
	getDetails = (dashid) => {
		// console.log('normal ', dashid);
		var yesdel = false;
		if (this.state.dashboards.length > 0) {
			let techname = this.state.dashboards.filter((tt) => {
				// console.log('tt ', tt);
				return tt === dashid;
			});
			// console.log('techname ', techname);
			if (techname.length) {
				yesdel = true;
			}
		}

		return yesdel;
	};
	render() {
		const { logo, failbtn } = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">Company Settings</h5>
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
						</div>
						<Table responsive hover>
							<thead>
								<tr>
									<th>White Lable Logo on Reports</th>

									<th>Dashboard Management</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Row>
											<Col md={6}>
												<div style={{ margin: '20px' }}>
													<img
														src={logo}
														className="img-responsive"
														style={{ width: '136px' }}
														alt="Logo"
													/>
													<br />
													width: 135px X height:35px{' '}
												</div>
											</Col>
											<Col md={6}>

												<div style={{ margin: '20px' }}>
													<input type="file" onChange={this.base64Image} />
												</div>
												<button
													className="btn btn-primary btn-sm"
													onClick={this.updatelogo}
													disabled={failbtn}
												>
													Update Logo
												</button>
											</Col>
										</Row>








									</td>
									<td>

										{this.state.alldashs &&
											this.state.alldashs.map((adash, index) => {
												var ksts = this.getDetails(adash.id);

												return (
													<div
														key={index}
														style={{ margin: '20px' }}
													>{' '}

														<Switch
															height={21}
															onChange={this.handleDash.bind(
																this,
																adash.id,
																adash.dashboard,
															)}
															checked={ksts}
														/>{' '}
														{' '}
														<span
															style={{
																fontSize: '18px',
																verticalAlign: 'top',
															}}
														>
															{adash.dashboard}
														</span>
													</div>
												);
											})}

										<button
											className="btn btn-primary btn-sm"
											onClick={this.updateDashboards}
											disabled={failbtn}
										>
											Update Dashboards
										</button>
									</td>

								</tr>
							</tbody>
						</Table>

					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default Asettings;
