import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import Switch from 'react-switch';
import SearchIcon from 'mdi-react/SearchIcon';
import {
	Button,

	Card,
	CardBody,
	Col,
	Badge,
	Table,
	Modal,
} from 'reactstrap';

import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import Alert from '../../../shared/components/Alert';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
const initialstate = {
	tickets: [],
	relatedTickets: [],
	ticket: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',

	addKeyModal: false,
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class AllTickets extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetkeys(localStorage.token);
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleAkey = () => {
		this.setState({
			addKeyModal: true,
		});
	};
	handleKeySubmit = (event) => {
		event.preventDefault();
		console.log(this.state.ticket);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addkeyurl = apiurl + 'addticket';
		var postData = {
			ticket: this.state.ticket,
		};
		axios
			.post(addkeyurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							addKeyModal: false,
						},
						() => {
							this.handleGetkeys(localStorage.token);
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleDkey = (id, ticket, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(ticket + 'Ticket');

		swal({
			title: 'Delete ' + ticket,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const delkeyurl = apiurl + 'delticket';
				var postData = {
					keyid: id,
				};
				axios
					.post(delkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(ticket + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.token);
							swal(ticket + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'warning',
						});
					});
			}
		});
	};
	handleTkey = (id, ticket, checked) => {
		console.log(checked + 'checked');
		console.log(id + 'id');
		console.log(ticket + 'Ticket');
		var keysts = checked == true ? 1 : 0;
		var kstatus = 'Deactivate';
		if (checked) {
			kstatus = 'Activate';
		}
		swal({
			title: kstatus + ' ' + ticket,
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'togticketname';
				var postData = {
					keyid: id,
					kstatus: keysts,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(ticket + ' ' + response.data.message, {
								icon: 'warning',
							});
						} else {
							this.handleGetkeys(localStorage.token);
							swal(ticket + ' ' + response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleCkey = () => {
		this.setState({
			addKeyModal: false,
		});
	};

	handleGetkeys = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'admintickets';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.allkeys];
					console.log('new', response.data.allkeys);
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						tickets: [...response.data.allkeys],
						relatedTickets: [...response.data.allkeys],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	fuzzy = (source, target) => {
		var hay = source.ticket.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedTickets: [...this.state.tickets],
					});
				} else {
					let relatedTickets = this.state.tickets.filter((ticket) => {
						return this.fuzzy(ticket, this.state.technologyToSearch);
					});
					this.setState({
						relatedTickets: [...relatedTickets],
					});
				}
			},
		);
	};
	render() {
		const { ticket } = this.state;
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								Customer Care Tickets{' '}
								<Badge color="primary">Total {this.state.tkeys} </Badge>
								{'  '}
								<Badge color="success">Active {this.state.akeys} </Badge>
								{'  '}
								<Badge color="warning">InActive {this.state.bkeys} </Badge>
								{'  '}
								<Button
									className="float-right btn-sm"
									color="success"
									onClick={this.handleAkey}
								>
									+
								</Button>
							</h5>
						</div>
						<form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										onChange={(e) => {
											this.handleSearch(e);
										}}
									/>
								</div>
							</div>
						</form>
						{this.state.tshowAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Tickets</th>
									<th>Manage</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>
								{this.state.relatedTickets &&
									this.state.relatedTickets.map((hkey, index) => {
										var ksts = false;
										if (hkey.activated === 1) {
											ksts = true;
										}

										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<strong>{hkey.ticket}</strong>
												</td>

												<td>
													<Switch
														onChange={this.handleTkey.bind(
															this,
															hkey.id,
															hkey.ticket,
														)}
														checked={ksts}
													/>
												</td>
												<td>
													{hkey.allq == 0 ? (
														<button
															className="btn btn-danger btn-sm"
															color="primary"
															id={hkey.id}
															onClick={this.handleDkey.bind(
																this,
																hkey.id,
																hkey.ticket,
															)}
														>
															<span className="lnr lnr-trash" />
														</button>
													) : null}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
						<Modal
							isOpen={this.state.addKeyModal}
							modalClassName="ltr-support"
							className="modal-dialog--primary"
						>
							<div className="modal__header">
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
								<h4 className="text-modal  modal__title">Add New Ticket</h4>
							</div>
							<div className="modal__body">
								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}
								<form
									className="form"
									onSubmit={(event) => this.handleKeySubmit(event)}
								>
									<div className="form__form-group">
										<span className="form__form-group-label">Ticket</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<KeyVariantIcon />
											</div>

											<input
												name="ticket"
												type="text"
												placeholder="Enter Ticket"
												className="form-control"
												value={ticket}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Add Ticket
									</button>
								</form>
							</div>
						</Modal>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AllTickets;
