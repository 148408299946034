import React, { Component } from 'react';
/* eslint-disable */
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { MY_API_URL, TINYMCEAPIKEY } from '../../../Apanel/QueryMe';
import CheckIcon from 'mdi-react/CheckIcon';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Doughnut } from 'react-chartjs-2';
var dateFormat = require('dateformat');
var download = require('downloadjs');
const apiurl = MY_API_URL;
import {
	Card,
	CardBody,
	Col,
	Table,
	Button,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	ListGroup,
	ListGroupItem,
	Badge,
	Input,
} from 'reactstrap';
const initialstate = {
	token: localStorage.token,
	results: [],
	details: [],
};
class ComponentToPrint extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'CustomTestReport');
			// eslint-disable-next-line
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			if (localStorage.cusreporttestId) {
				this.handleGetTest(localStorage.cusreporttestId);
			} else {
				// eslint-disable-next-line
				this.props.history.push('/dashboard');
			}
		}
	}
	getQuesName = (testid) => {
		return 'question Name';
	};
	handleGetTest = (testid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			testid: testid,
		};
		const getfqurl = apiurl + 'getcustomreport';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
				} else {
					this.setState(
						{
							results: response.data.data[0].results,
							newtestname: response.data.data[0].testname,
							customtest: [...response.data.data],
							questions: [...response.data.question_ids],
						},
						() => {
							this.handleGetTester(response.data.data[0].sent_to);
						},
					);
				}
			})
			.catch((error) => {
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleGetTester = (testerid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: testerid,
		};
		const getfqurl = apiurl + 'fetchTestTaker';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
				} else {
					this.setState({
						details: response.data.data,
					});
				}
			})
			.catch((error) => {
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	render() {
		const { results, details } = this.state;
		// console.log('results ', results);
		return (
			<div>
				{this.state.newtestname ? (
					<Col md={8}>
						<Card>
							<CardBody>
								<div className="card__title" style={{ margin: '10px 0px' }}>
									<h5 className="bold-text">
										Test Name: {this.state.newtestname}
									</h5>
								</div>
								<div className="col-md-12 viewques">
									<b>Email: </b>
									{details.email}
									<br />
									<b>Name: </b>
									{details.name && details.name}
									<br />
									<b>Mobile: </b>
									{details.mobile && details.mobile}
								</div>

								{this.state.questions &&
									this.state.questions.map((ques, index) => {
										let qid = ques.id;
										let myans = results[0][qid];
										if (ques.questype == 3) {
											let buff = new Buffer(myans, 'base64');
											myans = buff.toString('ascii');
										}
										let resul = '';
										// console.log(myans, ques.correct);
										if (myans == ques.correct) {
											// console.log('correct');
											resul = 'col-md-12 reportmycusoptionsuccess';
										} else {
											resul = 'col-md-12 reportmycusoptiondanger';
										}
										return (
											<div className="col-md-12 viewques" key={index}>
												<h5
													className="bold-text"
													style={{
														position: 'relative',
														marginTop: '15px',
														marginBottom: '5px',
													}}
												>
													<span className="viewcusquestionic">Q:</span>
													{ques.question}
												</h5>
												<div className="clearfix"></div>
												{ques.questype == 1 ? (
													<div className="row">
														<div
															className={
																myans == 0
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusreptoptionic">
																{ques.correct == 0 ? <CheckIcon /> : null} A:
															</span>
															{ques.options[0]}
														</div>

														<div
															className={
																myans == 1
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusreptoptionic">
																{ques.correct == 1 ? <CheckIcon /> : null} B:
															</span>
															{ques.options[1]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																myans == 2
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusreptoptionic">
																{ques.correct == 2 ? <CheckIcon /> : null} C:
															</span>
															{ques.options[2]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																myans == 3
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusreptoptionic">
																{ques.correct == 3 ? <CheckIcon /> : null} D:
															</span>
															{ques.options[3]}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 2 ? (
													<div className="row">
														<div
															className={
																myans == 0
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusreptoptionic">
																{ques.correct == 0 ? <CheckIcon /> : null} A:
															</span>
															{ques.options[0]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																myans == 1
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusreptoptionic">
																{ques.correct == 1 ? <CheckIcon /> : null} B:
															</span>
															{ques.options[1]}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 3 ? (
													<div className="row">
														<div className="col-md-12 reportmycusoption">
															<Editor
																apiKey={TINYMCEAPIKEY}
																disabled={true}
																value={'<p>' + myans + '</p>'}
																init={{
																	inline: true,
																	min_height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
											</div>
										);
									})}

								<Doughnut
									height={150}
									data={{
										labels: ['Correct', 'Incorrect', 'Unattempted'],

										datasets: [
											{
												data: [
													results[0].correct,
													results[0].incorrect,
													results[0].unattempted,
												],
												backgroundColor: ['green', 'red', 'yellow'],
												hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
											},
										],
									}}
								/>
							</CardBody>
						</Card>
					</Col>
				) : null}
			</div>
		);
	}
}
class CustomTestReport extends Component {
	render() {
		return (
			<div>
				<ReactToPrint content={() => this.componentRef}>
					<PrintContextConsumer>
						{({ handlePrint }) => (
							<button
								className="btn btn-sm btn-success float-right"
								onClick={handlePrint}
							>
								Print this out!
							</button>
						)}
					</PrintContextConsumer>
				</ReactToPrint>
				<ComponentToPrint ref={(el) => (this.componentRef = el)} />
			</div>
		);
	}
}
export default CustomTestReport;
