import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import SearchIcon from 'mdi-react/SearchIcon';
import {
	Button,
	Card,
	CardBody,
	Col,
	Table,
} from 'reactstrap';

const apiurl = MY_API_URL;
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	keyword: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class ListQuesKeywords extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetkeys();
		localStorage.setItem('viewittech', '');
		localStorage.setItem('repairtechname', '');
	}
	handleRepairSubmit = (chktechid, techname, w) => {
		w;
		console.log(chktechid);
		localStorage.setItem('repairtech', chktechid);
		localStorage.setItem('repairtechname', techname);
		// // eslint-disable-next-line
		this.props.history.push('/repairtech');
		const win = window.open('/repairtech', '_blank');
		if (win != null) {
			win.focus();
		}
	};
	handleViewQues = (chktechid, techname, w) => {
		w;
		console.log(chktechid);
		localStorage.setItem('viewittech', chktechid);
		localStorage.setItem('repairtechname', techname);
		// // eslint-disable-next-line
		this.props.history.push('/repairtech');
		const win = window.open('/viewittech', '_blank');
		if (win != null) {
			win.focus();
		}
	};
	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			reqtype: 'all',
		};
		const getuurl = apiurl + 'listRepairkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {

					console.log('new', response.data.nowkeys);

					this.setState({
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};
	render() {

		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										onChange={(e) => {
											this.handleSearch(e);
										}}
									/>
								</div>
							</div>
						</form>

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Technology</th>

									<th>Repair</th>
									<th>All</th>
									<th>Total</th>
									<th>Old</th>
									{/* <th>Five</th> */}
									<th>Answer</th>
								</tr>
							</thead>
							<tbody>
								{this.state.relatedKeywords &&
									this.state.relatedKeywords.map((hkey, index) => {
										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<strong>{hkey.keyword}</strong>
												</td>

												<td>
													{hkey.allq == hkey.ansyes ? (
														<Button
															className="btn-sm"
															color="primary"
															onClick={this.handleViewQues.bind(
																this,
																hkey.key_id,
																hkey.keyword,
															)}
														>
															View Questions
														</Button>
													) : (
														<Button
															className="btn-sm"
															color="success"
															onClick={this.handleRepairSubmit.bind(
																this,
																hkey.key_id,
																hkey.keyword,
															)}
														>
															View Questions
														</Button>
													)}
												</td>
												<td>{hkey.allrows}</td>
												<td>{hkey.allq}</td>
												<td>{hkey.oldans}</td>
												{/* <td>{hkey.five}</td> */}
												<td>{hkey.ansyes}</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default ListQuesKeywords;
