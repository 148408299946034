import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import ReactExport from 'react-export-excel';
import SearchIcon from 'mdi-react/SearchIcon';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {

	Card,
	CardBody,
	Col,
	Badge,
	Table,

} from 'reactstrap';


import Alert from '../../../shared/components/Alert';


const apiurl = MY_API_URL;
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	keyword: '',
	tshowAlert: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	keyId: 0,
	keyName: '',
	akeys: 0,
	bkeys: 0,
	tkeys: 0,
	token: localStorage.token,
};
class AllKeyQuesExcel extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetkeys(localStorage.token);
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleGetkeys = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'adminkeywordsexcel';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.nowkeys];
					console.log('new', response.data.nowkeys);
					let akeys = 0;

					let tkeys = 0;
					allkeys.map((dkey) => {
						akeys = akeys + dkey.activated;
						tkeys = tkeys + 1;
					});

					this.setState({
						akeys: akeys,
						bkeys: tkeys - akeys,
						tkeys: tkeys,
						keywords: [...response.data.nowkeys],
						relatedKeywords: [...response.data.nowkeys],
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// // eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	render() {

		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								Technology Keywords{' '}
								<Badge color="primary">Total kk{this.state.tkeys} </Badge>
								{'  '}
								<Badge color="success">Active {this.state.akeys} </Badge>
								{'  '}
								<Badge color="warning">InActive {this.state.bkeys} </Badge>
								{'  '}
							</h5>
						</div>
						<form className="form">
							<div className="form__form-group">
								<div className="form__form-group-field">
									<div className="form__form-group-icon">
										<SearchIcon />
									</div>
									<input
										name="tkey"
										type="text"
										placeholder="Search"
										onChange={(e) => {
											this.handleSearch(e);
										}}
									/>
								</div>
							</div>
						</form>
						{this.state.tshowAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th>Keywords</th>
									<th>Created</th>
									<th>Approved</th>
									<th>Pending</th>
									<th>On Hold</th>
									<th>Reported</th>
									<th>Manage</th>
								</tr>
							</thead>
							<tbody>
								{this.state.relatedKeywords &&
									this.state.relatedKeywords.map((hkey, index) => {

										var dwn = false;

										if (hkey.qids.length >= 1) {
											dwn = true;
										}
										return (
											<tr key={index}>
												<td>{index + 1}</td>

												<td>
													<strong>{hkey.keyword}</strong>
												</td>
												<td>
													<Badge color="primary">{hkey.allq}</Badge>
												</td>

												<td>
													<Badge color="success">{hkey.approved}</Badge>
												</td>
												<td>
													<Badge color="warning">{hkey.pending}</Badge>
												</td>
												<td>
													<Badge color="primary">{hkey.onhold}</Badge>
												</td>
												<td>
													<Badge color="danger">{hkey.reported}</Badge>
												</td>

												<td>
													{dwn ? (
														<ExcelFile
															filename={'Questions_of_' + hkey.keyword}
														>
															<ExcelSheet
																data={hkey.allques}
																name="Fixed Questions"
															>
																<ExcelColumn label="Question ID" value="id" />
																<ExcelColumn
																	label="Question"
																	value="question"
																/>
																<ExcelColumn label="Option A" value="optiona" />
																<ExcelColumn label="Option B" value="optionb" />
																<ExcelColumn label="Option C" value="optionc" />
																<ExcelColumn label="Option D" value="optiond" />
																<ExcelColumn label="Answer" value="correct" />
															</ExcelSheet>
														</ExcelFile>
													) : null}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AllKeyQuesExcel;
