import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../../shared/components/Alert';
const apiurl = MY_API_URL;
const initialstate = {
	email: '',
	testId: '',
	showEbox: false,
	showTbox: true,
	showAlert: false,
	alertType: '',
	alertText: '',
	verifiedTest: {},
};
class TakeTest extends PureComponent {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };

	componentDidMount() { }

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleTestSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			testId: this.state.testId,
		};
		const testidurl = apiurl + 'verifyTest';
		axios
			.post(testidurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState({
						showAlert: true,
						alertType: 'success',
						alertText: response.data.message,
						verifiedTest: response.data.data,
						showEbox: true,
						showTbox: false,
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Request Failed Try Again Later!',
				});
			});
	};
	redirectUser = () => {
		localStorage.setItem('testId', this.state.verifiedTest.generated_id);
		localStorage.setItem('test', JSON.stringify(this.state.verifiedTest));
		localStorage.setItem(
			'questionIndex',
			localStorage.getItem('testId') === this.state.verifiedTest.generated_id &&
				localStorage.getItem('questionIndex')
				? localStorage.getItem('questionIndex') + 1
				: 0,
		);

		// eslint-disable-next-line
		this.props.history.push('/starttest', { //eslint-disable-line
			test: this.state.verifiedTest,
		});
	};
	render() {
		const {
			testId,
		} = this.state;
		return (
			<div>
				{this.state.showEbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Test
											<span className="account__logo-accent"> Verified</span>
										</span>
									</h3>
								</div>
								<div>
									<strong>Welcome to the test!</strong>
								</div>
								<div style={{ color: 'red' }}>
									Read these instructions very carefully
								</div>
								<ul>
									<li>
										This test will take {this.state.verifiedTest.duration}{' '}
										minutes time to be completed
									</li>
									<li>You have to complete your test in one sitting</li>
									<li>
										Make sure you are connected to a stable internet connection
									</li>
									<li>
										Every question has an alloted time, if that that time is
										exceeded you will be automatically moved to the next
										Question
									</li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUser}
								>
									Start Test
								</button>
							</div>
						</div>
					</div>
				) : null}

				{this.state.showTbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Verify
											<span className="account__logo-accent"> Test ID</span>
										</span>
									</h3>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}
								</div>
								<form
									className="form"
									onSubmit={(event) => this.handleTestSubmit(event)}
								>
									<div className="form__form-group">
										<span className="form__form-group-label">
											Enter Test Id
										</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="testId"
												type="text"
												placeholder="Enter Test Id"
												value={testId}
												onChange={this.handleChange}
												required
												onKeyUp={(e) => {
													e.preventDefault();

													if (e.keyCode == 13) {
														this.handleTestSubmit();
													}
												}}
											/>
										</div>
									</div>
									<button
										className="btn btn-primary account__btn account__btn--small"
										// onClick={this.handleTestSubmit}
										type="submit"
									>
										Verify Test ID
									</button>
								</form>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default withRouter(TakeTest);
