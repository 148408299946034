import React, { PureComponent } from 'react';

import axios from 'axios';
import { MY_API_URL, VENDOR_SHARE_URL } from '../../../Apanel/QueryMe';
import EyeIcon from 'mdi-react/EyeIcon'; //eslint-disable-line
import KeyVariantIcon from 'mdi-react/KeyVariantIcon'; //eslint-disable-line
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import DownloadIcon from 'mdi-react/DownloadIcon';
import EditIcon from 'mdi-react/EditIcon';
import AppsIcon from 'mdi-react/AppsIcon';
import ReorderHorizontalIcon from 'mdi-react/ReorderHorizontalIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import Alert from '../../../../shared/components/Alert';
import { withRouter } from 'react-router-dom';
// import Select from 'react-select';
// import MaskedInput from 'react-text-mask';
var dateFormat = require('dateformat');
// import swal from 'sweetalert';
const apiurl = MY_API_URL;
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
	Modal,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	// Table,
} from 'reactstrap';
import classnames from 'classnames';

const initialstate = {
	vendorToSearch: '',
	relatedbvendors: [],
	relatedrvendors: [],
	bvendors: [],
	rvendors: [],
	showBvbox: false,
	showRvbox: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	email: '',
	name: '',
	phone: '',
	company_name: '',
	company_address: '',
	company_website: '',
	designation: '',
	linkedin: '',
	showbvlist: true,
	showrvlist: true,
	activeTab: '1',
	sharelink: '',
};
class VendorList extends PureComponent {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.bvtoggle = this.bvtoggle.bind(this);
		this.rvtoggle = this.rvtoggle.bind(this);

	}
	state = { ...initialstate };
	componentDidMount() {

		this.handleGetkeys();
		console.log('this.state', this.state);
	}
	toggle(tab) {
		const { activeTab } = this.state;
		if (activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}
	bvtoggle(s, e) { //eslint-disable-line
		if (s == 'list') {
			this.setState({
				showbvlist: true,
			});
		} else {
			this.setState({
				showbvlist: false,
			});
		}
	}
	rvtoggle(s, e) { //eslint-disable-line
		if (s == 'list') {
			this.setState({
				showrvlist: true,
			});
		} else {
			this.setState({
				showrvlist: false,
			});
		}
	}

	handleCkey = () => {
		this.setState({
			showBvbox: false,
			showRvbox: false,
			showEBvbox: false,
			showERvbox: false,
			showAlert: false,
			alertType: '',
			alertText: '',
			bvid: '',
			rvid: '',
			email: '',
			name: '',
			phone: '',
			company_name: '',
			company_address: '',
			company_website: '',
			designation: '',
			linkedin: '',
		});
	};

	handleEditBv = (id, email, name, phone, designation, company_name, company_address, company_website, linkedin) => {
		this.setState({
			showBvbox: false,
			showRvbox: false,
			showEBvbox: true,
			showERvbox: false,
			showAlert: false,
			alertType: '',
			alertText: '',
			bvid: id,
			email: email,
			name: name,
			phone: phone,
			company_name: company_name,
			company_address: company_address,
			company_website: company_website,
			designation: designation,
			linkedin: linkedin,
		});
	};
	handleEditRv = (id, email, name, phone, designation, company_name, company_address, company_website, linkedin) => {
		this.setState({
			showBvbox: false,
			showRvbox: false,
			showEBvbox: false,
			showERvbox: true,
			showAlert: false,
			alertType: '',
			alertText: '',
			rvid: id,
			email: email,
			name: name,
			phone: phone,
			company_name: company_name,
			company_address: company_address,
			company_website: company_website,
			designation: designation,
			linkedin: linkedin,
		});
	};


	baven = () => {
		this.setState({
			showBvbox: true,
			showRvbox: false,
			showAlert: false,
			alertType: '',
			alertText: '',
			email: '',
			name: '',
			phone: '',
			company_name: '',
			company_address: '',
			company_website: '',
			designation: '',
			linkedin: '',
		});
	};

	raven = () => {
		this.setState({
			showBvbox: false,
			showRvbox: true,
			showAlert: false,
			alertType: '',
			alertText: '',
			email: '',
			name: '',
			phone: '',
			company_name: '',
			company_address: '',
			company_website: '',
			designation: '',
			linkedin: '',
		});
	};


	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};

	handleBVendorSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				email: this.state.email,
				rid: localStorage.usercode,
				bemail: localStorage.by,
				name: this.state.name,
				phone: this.state.phone,
				company_name: this.state.company_name,
				company_address: this.state.company_address,
				company_website: this.state.company_website,
				designation: this.state.designation,
				linkedin: this.state.linkedin,
			};
			const loginurl = apiurl + 'addbvlist';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: false,
							showBvbox: false,
							showRvbox: false,
							email: '',
							name: '',
							phone: '',
							company_name: '',
							company_address: '',
							company_website: '',
							designation: '',
							linkedin: '',
						}, () => {
							this.handleGetkeys();
						});
					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	handleRVendorSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				email: this.state.email,
				rid: localStorage.usercode,
				bemail: localStorage.by,
				name: this.state.name,
				phone: this.state.phone,
				company_name: this.state.company_name,
				company_address: this.state.company_address,
				company_website: this.state.company_website,
				designation: this.state.designation,
				linkedin: this.state.linkedin,
			};
			const loginurl = apiurl + 'addrvlist';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: false,
							showBvbox: false,
							showRvbox: false,
							email: '',
							name: '',
							phone: '',
							company_name: '',
							company_address: '',
							company_website: '',
							designation: '',
							linkedin: '',
						}, () => {
							this.handleGetkeys();
						});
					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleEBVendorSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				bvid: this.state.bvid,
				email: this.state.email,
				rid: localStorage.usercode,
				bemail: localStorage.by,
				name: this.state.name,
				phone: this.state.phone,
				company_name: this.state.company_name,
				company_address: this.state.company_address,
				company_website: this.state.company_website,
				designation: this.state.designation,
				linkedin: this.state.linkedin,
			};
			const loginurl = apiurl + 'editbvlist';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: false,
							showBvbox: false,
							showRvbox: false,
							showEBvbox: false,
							showERvbox: false,
							email: '',
							name: '',
							phone: '',
							company_name: '',
							company_address: '',
							company_website: '',
							designation: '',
							linkedin: '',
						}, () => {
							this.handleGetkeys();
						});
					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	handleERVendorSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				rvid: this.state.rvid,
				email: this.state.email,
				rid: localStorage.usercode,
				bemail: localStorage.by,
				name: this.state.name,
				phone: this.state.phone,
				company_name: this.state.company_name,
				company_address: this.state.company_address,
				company_website: this.state.company_website,
				designation: this.state.designation,
				linkedin: this.state.linkedin,
			};
			const loginurl = apiurl + 'editrvlist';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: false,
							showBvbox: false,
							showRvbox: false,
							showEBvbox: false,
							showERvbox: false,
							email: '',
							name: '',
							phone: '',
							company_name: '',
							company_address: '',
							company_website: '',
							designation: '',
							linkedin: '',
						}, () => {
							this.handleGetkeys();
						});
					}
				})
				.catch((error) => { //eslint-disable-line
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleGetkeys = () => {

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			rid: localStorage.usercode,
		};
		const getuurl = apiurl + 'allmyvendors';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);

					this.setState({
						bvendors: [...response.data.bvendors],
						relatedbvendors: [...response.data.bvendors],
						rvendors: [...response.data.rvendors],
						relatedrvendors: [...response.data.rvendors],
						sharelink: response.data.mydetails,
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};


	fuzzy = (source, target) => {
		var hay = source.email.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};


	handleBvSearch = (e) => {
		this.setState(
			{
				vendorToSearch: e.target.value,
			},
			() => {
				if (this.state.vendorToSearch === '') {
					this.setState({
						relatedbvendors: [...this.state.bvendors],
					});
				} else {
					let relatedbvendors = this.state.bvendors.filter((keyword) => {
						return this.fuzzy(keyword, this.state.vendorToSearch);
					});

					this.setState({
						relatedbvendors: [...relatedbvendors],
					});
				}
			},
		);
	};
	handleRvSearch = (e) => {
		this.setState(
			{
				vendorToSearch: e.target.value,
			},
			() => {
				if (this.state.vendorToSearch === '') {
					this.setState({
						relatedrvendors: [...this.state.rvendors],
					});
				} else {
					let relatedrvendors = this.state.rvendors.filter((keyword) => {
						return this.fuzzy(keyword, this.state.vendorToSearch);
					});

					this.setState({
						relatedrvendors: [...relatedrvendors],
					});
				}
			},
		);
	};

	render() {
		const { email, name, phone, company_name, company_address, company_website, designation, linkedin, sharelink } = this.state; //eslint-disable-line

		const { activeTab } = this.state;

		return (
			<>
				<Col md={12}>
					<Card>
						<CardBody>


							<p>{VENDOR_SHARE_URL + sharelink.ssio + "/" + sharelink.company + "/" + sharelink.myname}</p>




						</CardBody>
					</Card>
				</Col>
				<Col md={12} lg={12} xl={12}>
					<CardBody>
						<div className="profile__card tabs tabs--bordered-bottom">
							<div className="tabs__wrap">
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '1' })}
											onClick={() => {
												this.toggle('1');
											}}
										>Benchsales Vendor list</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '2' })}
											onClick={() => {
												this.toggle('2');
											}}
										>Recruitment Vendor list</NavLink>
									</NavItem>
									{/* <NavItem>
										<NavLink
											className={classnames({ active: activeTab === '3' })}
											onClick={() => {
												this.toggle('3');
											}}
										>Marketing</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '4' })}
											onClick={() => {
												this.toggle('4');
											}}
										>Others</NavLink>
									</NavItem> */}
								</Nav>
								<TabContent activeTab={activeTab}>
									<TabPane tabId="1" style={{ position: 'relative' }}>
										<h4>
											<form className="form vendorsearch">
												<div className="form__form-group">
													<div className="form__form-group-field">
														<div className="form__form-group-icon">
															<SearchIcon />
														</div>
														<input
															name="tkey"
															type="text"
															placeholder="Search"
															onChange={(e) => {
																this.handleBvSearch(e);
															}}
														/>
													</div>
												</div>
											</form>

											<Button
												className="float-right btn-sm addvendorbtn"
												color="success"
												onClick={this.baven}
											>
												+ Benchsales Vendor
											</Button>
											<span className="venlisticons" >
												<span onClick={this.bvtoggle.bind(this, 'list')} style={{ marginRight: '15px', cursor: 'pointer' }}><ReorderHorizontalIcon /></span>
												<span onClick={this.bvtoggle.bind(this, 'cards')} style={{ cursor: 'pointer' }}><AppsIcon /></span>
											</span>
										</h4>


										<Row className="nopads">
											{this.state.showbvlist ? (
												<>
													{
														this.state.relatedbvendors && this.state.relatedbvendors.map((bvendor, index) => {
															let posted = dateFormat(bvendor.posted, 'd-m-yyyy h:mm:ss',);

															return (


																<Col key={index} md={12} className="vendorlist" >
																	<span onClick={this.handleEditBv.bind(this, bvendor.id, bvendor.email, bvendor.name, bvendor.phone, bvendor.designation, bvendor.company_name, bvendor.company_address, bvendor.company_website, bvendor.linkedin)} className="venlistediticon" ><EditIcon size={14} color="green" /> </span>
																	<Row>
																		<Col md={3}><p><b>Date:</b> {posted}</p></Col>
																		<Col md={3}><p><b>Email:</b> {bvendor.email}</p></Col>
																		<Col md={3}><p><b>Full Name:</b> {bvendor.name}</p></Col>
																		<Col md={3}><p><b>Phone:</b> {bvendor.phone}</p></Col>

																		<Col md={3}><p><b>Designation:</b> {bvendor.designation}</p></Col>
																		<Col md={3}><p><b>Company Name:</b> {bvendor.company_name}</p></Col>
																		<Col md={3}><p><b>Company Address:</b> {bvendor.company_address}</p></Col>
																		<Col md={3}><p><b>Company Website:</b> {bvendor.company_website}</p></Col>
																		<Col md={3}><p><b>Linkedin:</b> {bvendor.linkedin}</p></Col>


																	</Row>

																</Col>
															)
														})
													}
												</>
											) : (
												<>
													{this.state.relatedbvendors && this.state.relatedbvendors.map((bvendor, index) => {
														let posted = dateFormat(bvendor.posted, 'd-m-yyyy h:mm:ss',);

														return (
															<Col key={index} md={6} xl={3} sm={12}>
																<Card style={{ position: 'relative' }} >
																	<span onClick={this.handleEditBv.bind(this, bvendor.id, bvendor.email, bvendor.name, bvendor.phone, bvendor.designation, bvendor.company_name, bvendor.company_address, bvendor.company_website, bvendor.linkedin)} className="vencardediticon" ><EditIcon size={14} color="green" /> </span>
																	<CardBody className="pricing-card pricing-card--primary">
																		<div className="pricing-card__body">
																			<h4 className="pricing-card__plan">{bvendor.email}</h4>
																			<hr />
																			<p className="pricing-card__feature"><b>Date:</b> {posted}</p>
																			<p className="pricing-card__feature"><b>Email:</b> {bvendor.email}</p>
																			<p className="pricing-card__feature"><b>Full Name:</b> {bvendor.name}</p>
																			<p className="pricing-card__feature"><b>Phone:</b> {bvendor.phone}</p>

																			<p className="pricing-card__feature"><b>Designation:</b> {bvendor.designation}</p>
																			<p className="pricing-card__feature"><b>Company Name:</b> {bvendor.company_name}</p>
																			<p className="pricing-card__feature"><b>Company Address:</b> {bvendor.company_address}</p>
																			<p className="pricing-card__feature"><b>Company Website:</b> {bvendor.company_website}</p>
																			<p className="pricing-card__feature"><b>Linkedin:</b> {bvendor.linkedin}</p>




																		</div>
																	</CardBody>
																</Card>
															</Col>




														)
													})}
												</>
											)
											}
										</Row>
									</TabPane >
									<TabPane tabId="2" style={{ position: 'relative', minHeight: '300px' }}>
										<h4>
											<form className="form vendorsearch">
												<div className="form__form-group">
													<div className="form__form-group-field">
														<div className="form__form-group-icon">
															<SearchIcon />
														</div>
														<input
															name="tkey"
															type="text"
															placeholder="Search"
															onChange={(e) => {
																this.handleRvSearch(e);
															}}
														/>
													</div>
												</div>
											</form>

											<Button
												className="float-right btn-sm addvendorbtn"
												color="success"
												onClick={this.raven}
											>
												+ Recruiter Vendor
											</Button>
											<span className="venlisticons" >
												<span onClick={this.rvtoggle.bind(this, 'list')} style={{ marginRight: '15px', cursor: 'pointer' }}><ReorderHorizontalIcon /></span>
												<span onClick={this.rvtoggle.bind(this, 'cards')} style={{ cursor: 'pointer' }}><AppsIcon /></span>
											</span>
										</h4>
										<Row className="nopads">
											{this.state.showrvlist ? (
												<>
													{
														this.state.relatedrvendors && this.state.relatedrvendors.map((rvendor, index) => {
															let posted = dateFormat(rvendor.posted, 'd-m-yyyy h:mm:ss',);

															return (


																<Col key={index} md={12} className="vendorlist" >
																	<span onClick={this.handleEditRv.bind(this, rvendor.id, rvendor.email, rvendor.name, rvendor.phone, rvendor.designation, rvendor.company_name, rvendor.company_address, rvendor.company_website, rvendor.linkedin)} className="venlistediticon" ><EditIcon size={14} color="green" /> </span>
																	<Row>
																		<Col md={3}><p><b>Date:</b> {posted}</p></Col>
																		<Col md={3}><p><b>Email:</b> {rvendor.email}</p></Col>
																		<Col md={3}><p><b>Full Name:</b> {rvendor.name}</p></Col>
																		<Col md={3}><p><b>Phone:</b> {rvendor.phone}</p></Col>

																		<Col md={3}><p><b>Designation:</b> {rvendor.designation}</p></Col>
																		<Col md={3}><p><b>Company Name:</b> {rvendor.company_name}</p></Col>
																		<Col md={3}><p><b>Company Address:</b> {rvendor.company_address}</p></Col>
																		<Col md={3}><p><b>Company Website:</b> {rvendor.company_website}</p></Col>
																		<Col md={3}><p><b>Linkedin:</b> {rvendor.linkedin}</p></Col>


																	</Row>

																</Col>
															)
														})
													}
												</>
											) : (
												<>
													{this.state.relatedrvendors && this.state.relatedrvendors.map((rvendor, index) => {
														let posted = dateFormat(rvendor.posted, 'd-m-yyyy h:mm:ss',);

														return (
															<Col key={index} md={6} xl={3} sm={12}>
																<Card style={{ position: 'relative' }} >
																	<span onClick={this.handleEditRv.bind(this, rvendor.id, rvendor.email, rvendor.name, rvendor.phone, rvendor.designation, rvendor.company_name, rvendor.company_address, rvendor.company_website, rvendor.linkedin)} className="vencardediticon" ><EditIcon size={14} color="green" /> </span>
																	<CardBody className="pricing-card pricing-card--primary">
																		<div className="pricing-card__body">
																			<h4 className="pricing-card__plan">{rvendor.email}</h4>
																			<hr />
																			<p className="pricing-card__feature"><b>Date:</b> {posted}</p>
																			<p className="pricing-card__feature"><b>Email:</b> {rvendor.email}</p>
																			<p className="pricing-card__feature"><b>Full Name:</b> {rvendor.name}</p>
																			<p className="pricing-card__feature"><b>Phone:</b> {rvendor.phone}</p>

																			<p className="pricing-card__feature"><b>Designation:</b> {rvendor.designation}</p>
																			<p className="pricing-card__feature"><b>Company Name:</b> {rvendor.company_name}</p>
																			<p className="pricing-card__feature"><b>Company Address:</b> {rvendor.company_address}</p>
																			<p className="pricing-card__feature"><b>Company Website:</b> {rvendor.company_website}</p>
																			<p className="pricing-card__feature"><b>Linkedin:</b> {rvendor.linkedin}</p>




																		</div>
																	</CardBody>
																</Card>
															</Col>




														)
													})}
												</>
											)
											}
										</Row>
									</TabPane>
									{/* <TabPane tabId="3">
										<p>third</p>
									</TabPane>
									<TabPane tabId="4">
										<p>Fourth</p>
									</TabPane> */}
								</TabContent >

							</div >
						</div >
					</CardBody >
				</Col >





				<Modal
					isOpen={this.state.showBvbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title"><b>Benchsales Vendor Details</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<form
							className="form"
							onSubmit={(event) => this.handleBVendorSubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID</span>
										<div className="form__form-group-field">
											<input
												name="email"
												type="email"
												placeholder="Benchsales Vendor Email"
												value={email}
												className="form-control"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Name</span>
										<div className="form__form-group-field">
											<input
												name="name"
												type="text"
												placeholder="Name"
												value={name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone</span>
										<div className="form__form-group-field">
											<input
												name="phone"
												type="text"
												placeholder="Phone"
												value={phone}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">
											<input
												name="linkedin"
												type="url"
												placeholder="Linkedin"
												value={linkedin}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>

								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Designation</span>
										<div className="form__form-group-field">
											<input
												name="designation"
												type="text"
												placeholder="Designation"
												value={designation}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Name</span>
										<div className="form__form-group-field">
											<input
												name="company_name"
												type="text"
												placeholder="Company Name"
												value={company_name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Address</span>
										<div className="form__form-group-field">
											<input
												name="company_address"
												type="text"
												placeholder="Company Address"
												value={company_address}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Website</span>
										<div className="form__form-group-field">
											<input
												name="company_website"
												type="url"
												placeholder="Company Website"
												value={company_website}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>


									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Submit
									</button>
								</Col>

							</Row>







						</form>

					</div>
				</Modal>

				<Modal
					isOpen={this.state.showRvbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title"><b>Recruiter Vendor Details</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<form
							className="form"
							onSubmit={(event) => this.handleRVendorSubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID</span>
										<div className="form__form-group-field">
											<input
												name="email"
												type="email"
												placeholder="Recruiter Vendor Email"
												value={email}
												className="form-control"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Name</span>
										<div className="form__form-group-field">
											<input
												name="name"
												type="text"
												placeholder="Name"
												value={name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone</span>
										<div className="form__form-group-field">
											<input
												name="phone"
												type="text"
												placeholder="Phone"
												value={phone}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">
											<input
												name="linkedin"
												type="url"
												placeholder="Linkedin"
												value={linkedin}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>

								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Designation</span>
										<div className="form__form-group-field">
											<input
												name="designation"
												type="text"
												placeholder="Designation"
												value={designation}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Name</span>
										<div className="form__form-group-field">
											<input
												name="company_name"
												type="text"
												placeholder="Company Name"
												value={company_name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Address</span>
										<div className="form__form-group-field">
											<input
												name="company_address"
												type="text"
												placeholder="Company Address"
												value={company_address}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Website</span>
										<div className="form__form-group-field">
											<input
												name="company_website"
												type="url"
												placeholder="Company Website"
												value={company_website}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>


									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Submit
									</button>
								</Col>

							</Row>







						</form>

					</div>
				</Modal>

				<Modal
					isOpen={this.state.showEBvbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title"><b>Edit Benchsales Vendor Details</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<form
							className="form"
							onSubmit={(event) => this.handleEBVendorSubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID</span>
										<div className="form__form-group-field">
											<input
												name="email"
												type="email"
												placeholder="Benchsales Vendor Email"
												value={email}
												className="form-control"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Name</span>
										<div className="form__form-group-field">
											<input
												name="name"
												type="text"
												placeholder="Name"
												value={name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone</span>
										<div className="form__form-group-field">
											<input
												name="phone"
												type="text"
												placeholder="Phone"
												value={phone}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">
											<input
												name="linkedin"
												type="url"
												placeholder="Linkedin"
												value={linkedin}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>

								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Designation</span>
										<div className="form__form-group-field">
											<input
												name="designation"
												type="text"
												placeholder="Designation"
												value={designation}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Name</span>
										<div className="form__form-group-field">
											<input
												name="company_name"
												type="text"
												placeholder="Company Name"
												value={company_name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Address</span>
										<div className="form__form-group-field">
											<input
												name="company_address"
												type="text"
												placeholder="Company Address"
												value={company_address}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Website</span>
										<div className="form__form-group-field">
											<input
												name="company_website"
												type="url"
												placeholder="Company Website"
												value={company_website}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>


									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Submit
									</button>
								</Col>

							</Row>







						</form>

					</div>
				</Modal>

				<Modal
					isOpen={this.state.showERvbox}
					modalClassName="ltr-support"
					className="modal-dialog--primary candymodal"
				>
					<div className="modal__header">

						<button
							className="lnr lnr-cross modal__close-btn"
							type="button"
							onClick={this.handleCkey}
						/>


						<h4 className="text-modal  modal__title"><b>Edit Recruiter Vendor Details</b>  </h4>

					</div>
					<div className="modal__body">
						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						<form
							className="form"
							onSubmit={(event) => this.handleERVendorSubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID</span>
										<div className="form__form-group-field">
											<input
												name="email"
												type="email"
												placeholder="Recruiter Vendor Email"
												value={email}
												className="form-control"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Name</span>
										<div className="form__form-group-field">
											<input
												name="name"
												type="text"
												placeholder="Name"
												value={name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Phone</span>
										<div className="form__form-group-field">
											<input
												name="phone"
												type="text"
												placeholder="Phone"
												value={phone}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">
											<input
												name="linkedin"
												type="url"
												placeholder="Linkedin"
												value={linkedin}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>

								</Col>
								<Col md={6}>
									<div className="form__form-group">
										<span className="form__form-group-label">Designation</span>
										<div className="form__form-group-field">
											<input
												name="designation"
												type="text"
												placeholder="Designation"
												value={designation}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Name</span>
										<div className="form__form-group-field">
											<input
												name="company_name"
												type="text"
												placeholder="Company Name"
												value={company_name}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Address</span>
										<div className="form__form-group-field">
											<input
												name="company_address"
												type="text"
												placeholder="Company Address"
												value={company_address}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Company Website</span>
										<div className="form__form-group-field">
											<input
												name="company_website"
												type="url"
												placeholder="Company Website"
												value={company_website}
												className="form-control"
												onChange={this.handleChange}

											/>
										</div>
									</div>


									<button
										className="btn btn-primary account__btn account__btn--small"
										type="submit"
									>
										Submit
									</button>
								</Col>

							</Row>







						</form>

					</div>
				</Modal>

			</>
		);
	}
}

export default withRouter(VendorList);
