import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../../../Apanel/QueryMe';

import SearchIcon from 'mdi-react/SearchIcon';
import Alert from '../../../../shared/components/Alert';

import swal from 'sweetalert';
const apiurl = MY_API_URL;
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
	Table,
	Button,
	Badge,
	Modal,
	Row,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	technologies: [],
	selectedTechnologies: [],
	technologyToSearch: '',
	difficulty: 'Beginner',
	responseQuestions: [],
	questionQuantity: [],
	techKeyName: [],
	dlevel: [],
	gotCount: [],
	email: '',
	emails: [],
	technologyWise: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	upExcelModal: false,
	upSingleModal: false,
	token: localStorage.token,
	options: [],
	// question: [],
	questionSelected: {},
	technologySelected: '',
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	currentcolor: [
		'#000000',
	],
	content_add: '',
	myItems: [],
	testEmails: [],
};
class AddAdvTest extends Component {
	constructor(props) {
		super(props);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.handleClick = this.handleClick.bind(this);
		this.lastId = -1;
	}
	state = { ...initialstate };
	componentDidMount() {
		this.setState({ myItems: [], testEmails: [] });
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			// eslint-disable-next-line
			this.props.history.push({ //eslint-disable-line
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeys();
			localStorage.setItem('faqtechs', '');
		}
	}
	handleFocus(event) { //eslint-disable-line
		// this.setState({ content_add: '' });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
		const usr_input = event.target.value;
		console.log(usr_input);
		this.setState({ content_add: usr_input });
	};
	handleAddEmail(event) {
		event.preventDefault();
		var mainemail = localStorage.by;
		var mainemail_string_array = mainemail.split('@');
		var domain_string_location = mainemail_string_array.length - 1;
		var mainemaildomain = mainemail_string_array[domain_string_location];
		var validemails = [];
		var rawemails = this.state.content_add.trim();
		var aallemails = rawemails
			.split('\n')
			.join(',')
			.split(' ')
			.join(',')
			.split(',');
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
		// console.log('aallemails:', aallemails);
		aallemails.map((aallemail) => {
			let isvalidrec = re.test(aallemail);
			if (isvalidrec) {
				// console.log('aallemail:', aallemail);
				var newemail_string_array = aallemail.split('@');
				var domain_string_location = newemail_string_array.length - 1;
				var newemaildomain = newemail_string_array[domain_string_location];
				if (mainemaildomain == newemaildomain) {
					this.setState({
						content_add: '',
					});
				} else {
					var dnjk = this.state.testEmails.includes(aallemail);

					if (dnjk) {
						this.setState({
							content_add: '',
						});
					} else {
						validemails.push(aallemail);
					}
				}
			} else {
				this.setState({
					content_add: '',
				});
			}
		});
		// console.log(validemails);
		var newArray = this.state.myItems;
		var testEmails = this.state.testEmails;
		if (validemails.length >= 1) {
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				testemail: validemails,
				testid: this.state.selectedId,
			};
			const testidurl = apiurl + 'valchecktestuser';
			axios
				.post(testidurl, postData, {
					headers: headers,
				})
				.then(async (response) => {
					console.log(response);
					if (response.data.data[1].length >= 1) {
						var areusers = response.data.data[1].toString();
						console.log(areusers);
						swal('Are users ' + areusers, {
							icon: 'error',
						});
					}
					if (response.data.data[0].length >= 1) {
						const allemails = response.data.data[0];
						let isexist = [];
						await Promise.all(
							allemails.map((temails) => {
								return new Promise((resolve) => {
									this.state.myItems.filter((listitem) => {
										if (listitem.content == temails) {
											isexist.push(temails);
										}
									});
									// console.log('isexist ', isexist.length);

									if (isexist.length >= 1) {
										resolve('');
									} else {
										newArray.push({
											content: temails,
											id: ++this.lastId,
										});
										testEmails.push(temails);
										this.setState(
											{
												myItems: newArray,
												content_add: '',
											},
											() => {
												console.log(this.state.myItems);
												resolve('');
											},
										);
									}
								});
							}),
						);
						if (isexist.length >= 1) {
							isexist = isexist.toString();
							swal(isexist + ' Already Added', {
								icon: 'error',
							});
						}
					}
				});
		}
	}

	handleClick(event) {
		const idToRemove = Number(event.target.dataset['item']);
		const emailToRemove = event.target.dataset['email'];
		const newArray = this.state.myItems.filter((listitem) => {
			return listitem.id !== idToRemove;
		});
		const testEmails = this.state.testEmails.filter((elistitem) => {
			return elistitem !== emailToRemove;
		});
		this.setState({ myItems: newArray, testEmails: testEmails }, () => {
			console.log('items ', this.state.myItems);
			console.log('emails ', this.state.testEmails);
		});
	}
	makeAddedList() {
		const elements = this.state.myItems.map((listitem, index) => (
			<span
				className="emaillist"
				key={listitem.id}
				onClick={this.handleClick}
				data-item={listitem.id}
				data-email={listitem.content}
				style={{
					backgroundColor: this.state.currentcolor[
						index % this.state.currentcolor.length
					],
					width: 'auto',
				}}
			>
				{listitem.content}
			</span>
		));
		return elements;
	}

	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'allqueskeystestadv';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('allkeys: ', response.data.allkeys);
				if (!response.data.error) {
					this.setState({
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				// localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/');//eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	unselectTechnology = (technology) => {
		console.log('Technology is ', technology);
		console.log('Selected Technologies', this.state.selectedTechnologies);
		console.log('Selected techKeyName', this.state.techKeyName);
		let index = this.state.selectedTechnologies.indexOf(technology);
		console.log('Index is ', index);
		let copyArray = [...this.state.selectedTechnologies];
		let copyTechNameArray = [...this.state.techKeyName];
		let copyQuesQntArray = [...this.state.questionQuantity];
		let copyDlevelArray = [...this.state.dlevel];
		copyArray.splice(index, 1);
		copyTechNameArray.splice(index, 1);
		copyQuesQntArray.splice(index, 1);
		copyDlevelArray.splice(index, 1);
		this.setState(
			{
				selectedTechnologies: [...copyArray],
				techKeyName: [...copyTechNameArray],
				questionQuantity: [...copyQuesQntArray],
				dlevel: [...copyQuesQntArray],
			},
			() => {
				console.log('Selected Technologies', this.state.selectedTechnologies);
				console.log('Selected techKeyName', this.state.techKeyName);
				console.log('Selected dlevel', this.state.dlevel);
				console.log('Selected questionQuantity', this.state.questionQuantity);
			},
		);
	};

	generateTest = (event) => {
		event.preventDefault();
		let quantity = this.state.questionQuantity.reduce((total, quant) => {
			return Number(total) + Number(quant);
		});
		console.log('Quantity is ', quantity);
		this.setState(
			{
				showAlert: false,
			},
			() => {

				if (this.state.testEmails == []) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'Email cannot be empty',
					});
				} else if (this.state.selectedTechnologies == []) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'You need to select at least one technology',
					});
				} else if (quantity > 100 || quantity < 10) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText:
							'Number of questions should be more than 10 and less than 100',
					});
				} else {
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};
					var postData = {
						technologies: [...this.state.selectedTechnologies],
						questionCount: this.state.questionQuantity,
						level: 'Beginner',
						mode: 'Single',
					};
					const generateTestx = apiurl + 'generateTestadv';

					axios
						.post(generateTestx, postData, {
							headers: headers,
						})
						.then((response) => {
							// console.log('Response is ', response);
							console.log(response.data.data);
							let duration = 0;
							response.data.data.map((question) => {
								duration = duration + Number(question.Duration);
							});
							if (duration === 0) {
								this.setState({
									showAlert: true,
									alertType: 'warning',
									alertText:
										'No test Can  be generated meeting your requirements.Try changing your requirements and Try Again',
								});
							} else {
								this.setState(
									{
										timeRequired: duration,
										startGeneration: false,
										responseQuestions: [...response.data.data],
									},
									() => {
										this.setState({
											showModal: true,
										});
									},
								);
							}
						})
						.catch((error) => {
							console.log(error);
						});
				}
			},
		);
	};
	createTest = () => {
		let ids = this.state.responseQuestions.map((question) => {
			return question.id;
		});
		let helper = this.state.selectedTechnologies.map((tech, index) => {
			return {
				technology: tech,
				techKeyName: this.state.techKeyName[index],
				questions: this.state.questionQuantity[index],
				dlevel: this.state.dlevel[index],
			};
		});
		var techarray = this.state.techKeyName;
		var technames = techarray.join(', ');
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: this.state.testEmails,
			technologies: [...this.state.selectedTechnologies],
			ids: [...ids],
			level: 'Advanced',
			mode: 'Single',
			duration: this.state.timeRequired,
			by: localStorage.by,

			helper: helper,
		};
		const createTestx = apiurl + 'createTestadv';

		axios
			.post(createTestx, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('Create Test response is ', response);

				this.setState(
					{
						showModal: false,
					},
					() => {
						console.log(response.data);

						const headers = {
							'Content-Type': 'application/json',
							Authorization: localStorage.token,
						};
						var rname = localStorage.name;
						if (localStorage.domain) {
							rname + ' - ' + localStorage.domain;
						}
						var postData = {
							email: [this.state.email],
							id: response.data.data,
							by: localStorage.by,
							technames: technames,
							rname: rname,
							tname: 'User',
						};
						const sendTestMailx = apiurl + 'sendTestMail';

						axios
							.post(sendTestMailx, postData, {
								headers: headers,
							})

							.then(() => {
								// eslint-disable-next-line
								this.props.history.push({ //eslint-disable-line
									pathname: '/saveadvtest',
								});
							})
							.catch((error) => {
								console.log('Error in promise ', error);
							});
					},
				);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	redirectFaqs = (event) => { //eslint-disable-line

	};
	render() {
		let countingArray = [2, 3, 4, 5, 6, 7, 8, 9, 10];
		let dlevelArray = ['Beginner', 'Intermediate', 'Advanced'];
		const difflevels = [{ value: 'Beginner', label: 'Beginner' }, { value: 'Intermediate', label: 'Intermediate' }, { value: 'Advanced', label: 'Advanced' }]; // eslint-disable-line
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-3 order-md-1 order-sm-1 order-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies</h5>

								<form className="form">
									<div className="form__form-group">
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<SearchIcon />
											</div>
											<input
												name="tkey"
												type="text"
												placeholder="Search"
												onChange={(e) => {
													this.handleSearch(e);
												}}
											/>
										</div>
									</div>
								</form>
								{/* technologies here */}
								<Table responsive hover>
									<tbody>
										{this.state.relatedKeywords.map((keyword, index) => {
											if (
												this.state.selectedTechnologies.indexOf(keyword.id) ===
												-1
											)
												return (
													<tr key={index}>
														<td
															// onClick={() => {
															// 	this.setState({
															// 		technologySelected: keyword.id,
															// 	});
															// }}
															onClick={() => {
																if (
																	this.state.selectedTechnologies.indexOf(
																		keyword.id,
																	) == -1
																) {
																	this.setState(
																		{
																			selectedTechnologies: [
																				...this.state.selectedTechnologies,
																				keyword.id,
																			],
																		},
																		() => {
																			let previousTechKeyNameArray = this.state
																				.techKeyName;
																			previousTechKeyNameArray.push(
																				keyword.keyword,
																			);
																			let previousDlevelArray = this.state
																				.dlevel;
																			previousDlevelArray.push(
																				'Beginner',
																			);
																			let previousQuantityArray = this.state
																				.questionQuantity;
																			previousQuantityArray.push(2);
																			this.setState({
																				techKeyName: previousTechKeyNameArray,
																				dlevel: previousDlevelArray,
																				questionQuantity: previousQuantityArray,
																			});
																		},
																	);
																}
															}}
															style={{
																background:
																	keyword.id === this.state.technologySelected
																		? 'red'
																		: 'transparent',

																cursor: 'pointer',
															}}
														>
															<strong>{keyword.keyword}</strong>
														</td>
													</tr>
												);
										})}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
					<div className="col-md-9 order-md-12 order-sm-12 order-1">
						<div className="row">
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										{this.state.selectedTechnologies.map(
											(technology, index) => {
												return (
													<Button
														key={index}
														className="btn-sm icon btn btn-primary"
														style={{ marginLeft: 10 }}
													>
														{this.state.techKeyName[index]}

														<i
															className="lnr lnr-cross"
															style={{ marginLeft: 10 }}
															onClick={() => {
																this.unselectTechnology(technology);
															}}
														></i>
													</Button>
												);
											},
										)}
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<form
											className="form"
											onSubmit={(event) => this.handleAddEmail(event)}
										>
											{this.state.showAlert ? (
												<Alert color={this.state.alertType}>
													<p>{this.state.alertText}</p>
												</Alert>
											) : null}
											<p>{this.makeAddedList()}</p>
											<div className="form__form-group">
												{/* <span className="form__form-group-label">
													Add Email ID
												</span> */}
												<div className="form__form-group-field">
													<textarea
														name="email"
														type="email"
														placeholder="Add Email ID"
														onFocus={this.handleFocus}
														onChange={this.handleChange}
														value={this.state.content_add}
														style={{ width: '75%', margin: '15px' }}
														onKeyUp={(e) => {
															e.preventDefault();

															// if (
															// 	e.keyCode == 13 ||
															// 	e.keyCode == 32 ||
															// 	e.keyCode == 188
															// ) {
															// 	this.handleAddEmail(event);
															// }
														}}
													/>
													<button
														className="btn btn-primary btn-sm"
														type="submit"
														style={{ marginTop: '15px' }}
													>
														Add Email
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="grid card">
									<div className="card-body">
										<h5 className="bold-text">Selected Technologies</h5>
										<Table className="table--bordered">
											<thead>
												<tr>
													<th>#</th>
													<th>Technology</th>
													<th>Difficulty Level</th>
													<th>Select no of Questions</th>
												</tr>
											</thead>
											<tbody>
												{this.state.selectedTechnologies.map((tech, index) => {



													return (
														<tr key={index}>
															<td>{index + 1}</td>

															<td>
																<strong>
																	{this.state.techKeyName[index]}
																</strong>
															</td>
															<td>

																<DropdownButton
																	size="sm"
																	title={this.state.dlevel[index]}
																	value={this.state.dlevel[index]}
																	onSelect={(k) => {
																		let initialDLArray = [
																			...this.state.dlevel,
																		];
																		initialDLArray[index] = k;

																		this.setState({
																			dlevel: [...initialDLArray],
																			showAlert: false,
																		});
																	}}
																>
																	{dlevelArray.length > 1 ? (
																		dlevelArray.map((dlevel, index) => {
																			return (
																				<Dropdown.Item
																					key={index}
																					eventKey={dlevel}
																				>
																					{dlevel}
																				</Dropdown.Item>
																			);
																		})
																	) : (
																		<Dropdown.Item>No Levels </Dropdown.Item>
																	)}
																</DropdownButton>
															</td>
															<td>
																<DropdownButton
																	size="sm"
																	title={
																		this.state.questionQuantity[index] <
																			countingArray[countingArray.length - 1]
																			? this.state.questionQuantity[index] || 2
																			: countingArray[
																			countingArray.length - 1
																			] || 2
																	}
																	value={2}
																	onSelect={(k) => {
																		let initialArray = [
																			...this.state.questionQuantity,
																		];
																		initialArray[index] = k;

																		this.setState({
																			questionQuantity: [...initialArray],
																			showAlert: false,
																		});
																	}}
																>
																	{countingArray.length > 1 ? (
																		countingArray.map((counts, index) => {
																			return (
																				<Dropdown.Item
																					key={index}
																					eventKey={counts}
																				>
																					{counts}
																				</Dropdown.Item>
																			);
																		})
																	) : (
																		<Dropdown.Item>No Questions </Dropdown.Item>
																	)}
																</DropdownButton>
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
										<Row>
											<div className="col-md-6">
												<form
													className="form"
													onSubmit={(event) => this.generateTest(event)}
												>
													{this.state.questionQuantity.length > 0 ? (
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="submit"
														>
															Generate Test
														</button>
													) : (
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="button"
															desabled="true"
														>
															Generate Test
														</button>
													)}
												</form>
											</div>
											<div className="col-md-6">
												<form
													className="form"
													onSubmit={(event) => this.redirectFaqs(event)}
												>
													{this.state.selectedTechnologies.length > 0 ? (
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="submit"
														>
															View FAQ&#39;S
														</button>
													) : (
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="button"
															desabled="true"
														>
															View FAQ&#39;S
														</button>
													)}
												</form>
											</div>
										</Row>
										<Modal
											isOpen={this.state.showModal}
											modalClassName="ltr-support"
											className="modal-dialog--primary"
										>
											<div className="modal__header">
												<button
													className="lnr lnr-cross modal__close-btn"
													type="button"
													onClick={() => {
														this.setState({
															showModal: false,
														});
													}}
												/>
												<h4 className="text-modal  modal__title">
													A test has been Created
												</h4>
											</div>
											<div className="modal__body">
												{this.state.showAlert ? (
													<Alert color={this.state.alertType}>
														<p>{this.state.alertText}</p>
													</Alert>
												) : null}
												<div>
													<strong>Technologies:</strong>
													{this.state.selectedTechnologies.map(
														(technology, index) => {
															return (
																<div key={index}>
																	<Badge color="success">
																		{this.state.techKeyName[index]}
																	</Badge>
																	<div> </div>
																</div>
															);
														},
													)}
												</div>
												<div>
													<strong>Time Required:</strong>
													{this.state.timeRequired} minutes
												</div>

												<div>
													<strong> Number of questions generated:</strong>
													{this.state.responseQuestions.length}
												</div>
												<div className="mt-3" style={{ color: 'red' }}>
													*Note: If the number of generated questions is lesser
													than mentioned then there might not be enough
													questions in the database that meet your criteria
												</div>
											</div>

											<button
												className="btn btn-primary account__btn account__btn--small"
												type="button"
												onClick={this.createTest}
											>
												Send Test to User
											</button>
										</Modal>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AddAdvTest;
