import React, { Component } from 'react';
/* eslint-disable */
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import RefreshIcon from 'mdi-react/RefreshIcon';
import Alert from '../../../shared/components/Alert';
import Select from 'react-select';
import readXlsxFile from 'read-excel-file';
import swal from 'sweetalert';
const apiurl = MY_API_URL;
import {

	Button,
	Modal,
	Input,
	Badge
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	upExcelModal: false,
	upSingleModal: false,
	token: localStorage.token,
	options: [],
	// question: [],
	questionSelected: {},
	technologySelected: '',
	technologySelectedName: '',
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	quesidslen: 0,
};
class AddQuest extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			// eslint-disable-next-line
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			this.handleGetkeys();
		}
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleAexcel = () => {
		this.setState({
			upExcelModal: true,
			upSingleModal: false,
		});
	};
	handleSingle = () => {
		this.setState({
			upExcelModal: false,
			upSingleModal: true,
		});
	};
	handleCkey = () => {
		this.setState({
			upExcelModal: false,
			upSingleModal: false,
		});
	};

	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'allkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
				showPopover: true,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	uploadExcelQuestions = () => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addsqurl = apiurl + 'excelQuestions';
		var postData = {
			questions: this.state.excelQuestions,
			email: localStorage.by,
			technology: this.state.technologySelected,
		};
		axios
			.post(addsqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							upExcelModal: false,
							upSingleModal: false,
							showAlert: false,
						},
						() => {
							this.fetchQuestions();
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleSingleSubmit = (event) => {
		event.preventDefault();

		let optionss = [];
		optionss.push(this.state.optionA);
		optionss.push(this.state.optionB);
		optionss.push(this.state.optionC);
		optionss.push(this.state.optionD);
		console.log('OPTIONS ARRAY IS ', optionss);
		this.setState(
			{
				options: [...optionss],
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addsqurl = apiurl + 'addQuestion';
				var postData = {
					technology: this.state.technologySelected,
					options: [...this.state.options],
					email: localStorage.by,
					question: this.state.question,
					answer: this.state.correctAnswer,
				};
				axios
					.post(addsqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'danger',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{
									upExcelModal: false,
									upSingleModal: false,
									showAlert: false,
									question: '',
									options: [],
									optionA: '',
									optionB: '',
									optionC: '',
									optionD: '',
									correctAnswer: '',
								},
								() => {
									this.fetchQuestions();
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			},
		);
	};
	fetchQuestions = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			technology: this.state.technologySelected,
			limit: 'yes',
		};
		const getfqurl = apiurl + 'newlyaddedQuestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						questions: [...response.data.data],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleQuestion = (qid, checked) => {
		var quesids = [];
		console.log(qid + ' ' + checked);
		this.setState({ [qid]: checked }, () => {
			this.state.questions && this.state.questions.map((quest) => {
				console.log(quest.id, this.state[quest.id]);
				if (this.state[quest.id] == true) {
					quesids.push(quest.id);
				}
			});
			var quesidslen = quesids.length;
			this.setState({
				quesidslen: quesidslen
			})
		});

	};


	handleQuestionChange(question, editor) { //eslint-disable-line
		this.setState({ question });
	}
	handleOptionChange(option, editor) { //eslint-disable-line
		// console.log('optiona was optiona:', option);
		// console.log('optiona was editor:', editor);
		this.setState({ option });
	}
	// desableButtons(dd, gg) {
	// 	this.setState({ failbtn: true });
	// }
	_handleEditorFocusOut(noorid, tfor, opt, e) { //eslint-disable-line
		if (this.state[tfor] != '') {


			if (this.state[tfor]) {
				// post started
				let editkeyurl = '';
				if (tfor == 'question') {
					console.log('question change');
					editkeyurl = apiurl + 'editQnewques';
					var postData = {
						noorid: noorid,
						quesname: this.state[tfor],
					};
				} else if (tfor == 'option') {
					console.log('opt change');
					editkeyurl = apiurl + 'editQnewopt';
					var postData = { //eslint-disable-line
						noorid: noorid,
						optext: this.state[tfor],
						opt: opt,
					};
				}
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				axios
					.post(editkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState(
								{
									question: '',
									option: '',
									failbtn: false,
								},
								() => {
									swal(response.data.message, {
										icon: 'Danger',
									});
								},
							);

							window.location.reload(false);
						} else {
							this.setState(
								{
									question: '',
									option: '',
									failbtn: false,
								},
								() => {
									this.fetchQuestions();
									swal(response.data.message, {
										icon: 'success',
									});
								},
							);
						}
					});
				//post ended
			}
		}
	}
	handlechngAnswer = (quesid, oans, qans, id) => {
		console.log(quesid + 'quesid');
		console.log(id + 'id');
		console.log(qans + 'qans');
		if (oans == qans) {
			console.log(id + 'id');
		} else {
			swal({
				title: 'Change Answer',
				text: 'Are you sure?',
				icon: 'warning',
				buttons: true,
				dangerMode: true,
			}).then((keyconfirm) => {
				if (keyconfirm) {
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};

					const togkeyurl = apiurl + 'changeAnsQnew';
					var postData = {
						quesid: quesid,
						qans: qans,
						email: localStorage.by,
					};
					axios
						.post(togkeyurl, postData, {
							headers: headers,
						})
						.then((response) => {
							console.log(response.data.message);

							if (response.data.error) {
								swal(response.data.message, {
									icon: 'Danger',
								});
							} else {
								this.fetchQuestions();

								swal(response.data.message, {
									icon: 'success',
								});
							}
						})
						.catch((error) => {
							console.log('Error is ', error);
							swal('Try Again Later', {
								icon: 'Danger',
							});
						});
				}
			});
		}
	};
	handleDelQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Delete',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'delQnew';
				var postData = {
					quesid: quesid,
					email: localStorage.by,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.fetchQuestions();
							swal(response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleAppQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Approve',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'appQnew';
				var postData = {
					quesid: quesid,
					email: localStorage.by,
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.fetchQuestions();
							swal(response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	render() {
		const { question, optionA, optionB, optionC, optionD } = this.state;
		let options =
			this.state.keywords &&
			this.state.keywords.map((keyword) => {
				return { value: keyword.id, label: keyword.keyword };
			});

		// console.log('Options are ', options);
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-3">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies</h5>
								<Select
									options={options}
									onChange={(selectedOption) => {
										this.setState({
											technologySelected: selectedOption.value,
											technologySelectedName: selectedOption.label,
										});
									}}
								></Select>
								{/* <form className="form">
								<div className="form__form-group">
									<div className="form__form-group-field">
										<div className="form__form-group-icon">
											<SearchIcon />
										</div>
										<input
											name="tkey"
											type="text"
											placeholder="Search"
											onChange={(e) => {
												this.handleSearch(e);
											}}
										/>
									</div>
								</div>
							</form> */}
								{/* technologies here */}
								{/* <Table responsive hover>
								<tbody>
									{this.state.relatedKeywords.map((keyword, index) => {
										return (
											<tr key={index}>
												<td
													onClick={() => {
														this.setState({
															technologySelected: keyword.keyword,
														});
													}}
													style={{
														background:
															keyword.keyword === this.state.technologySelected
																? 'red'
																: 'transparent',

														cursor: 'pointer',
													}}
												>
													<strong>{keyword.keyword}</strong>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table> */}
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="grid card">
							<div className="card-body">
								{this.state.technologySelected ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.handleSingle}
									>
										Add Questions to <br />
										{this.state.technologySelectedName}
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Add Questions to <br />
										{this.state.technologySelectedName}
									</Button>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="grid card">
							<div className="card-body">
								{this.state.technologySelected ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.handleAexcel}
									>
										Upload Questions by CSV(excel) <br />
										{this.state.technologySelectedName}
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Upload Questions by CSV(excel) <br />
										{this.state.technologySelectedName}
									</Button>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="grid card">
							<div className="card-body">
								{this.state.technologySelected ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.fetchQuestions.bind(this)}
									>
										<strong style={{ fontSize: '18px' }}><RefreshIcon /> {this.state.questions && this.state.questions.length}</strong>	Refresh Recentyly added Questions
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Refresh Recentyly added Questions
									</Button>

								)}

							</div>
						</div>
					</div>
					<Modal
						isOpen={this.state.upExcelModal}
						modalClassName="ltr-support"
						className="modal-dialog--primary"
					>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Upload Question to {this.state.technologySelectedName}
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleUploadSubmit(event)}
							>
								<div className="form__form-group">
									<span className="form__form-group-label">
										Upload Question by CSV(excel){' '}
									</span>
									<div className="form__form-group">
										<div className="form__form-group-field">
											{this.state.technologySelected ? (
												<input
													type="file"
													id="input"
													onChange={(e) => {
														this.setState({
															excelFile: e.target.files[0],
														});
													}}
												/>
											) : (
												''
											)}
										</div>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									type="button"
									onClick={() => {
										readXlsxFile(this.state.excelFile).then((rows) => {
											let questionsArray = rows.filter((row, index) => {
												if (index > 0) {
													return {
														question: row[0],
														optionA: row[1],
														optionB: row[2],
														optionC: row[3],
														optionD: row[4],
														correct: row[5],
														technology: row[6],
													};
												}
											});
											this.setState(
												{
													excelQuestions: [...questionsArray],
												},
												() => {
													this.uploadExcelQuestions();
												},
											);
										});
									}}
								>
									Upload Questions
								</button>
							</form>
						</div>
					</Modal>

					<Modal
						isOpen={this.state.upSingleModal}
						modalClassName="ltr-support"
						className="modal-dialog--primary modal-dialog-big "
					>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Add Question to {this.state.technologySelectedName}
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleSingleSubmit(event)}
							>
								<div className="form__form-group">
									<span className="form__form-group-label">Question</span>
									<div className="form__form-group-field">
										<textarea
											name="question"
											value={question}
											type="textarea"
											placeholder="Enter Question"
											className="form-control"
											onChange={this.handleChange}
											required
										></textarea>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Option A</span>
									<div className="form__form-group-field">
										<textarea
											name="optionA"
											value={optionA}
											type="textarea"
											placeholder="Enter Option A"
											className="form-control"
											onChange={this.handleChange}
											required
										></textarea>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Option B</span>
									<div className="form__form-group-field">
										<textarea
											name="optionB"
											value={optionB}
											type="textarea"
											placeholder="Enter Option B"
											className="form-control"
											onChange={this.handleChange}
											required
										></textarea>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Option C</span>
									<div className="form__form-group-field">
										<textarea
											name="optionC"
											value={optionC}
											type="textarea"
											placeholder="Enter Option C"
											className="form-control"
											onChange={this.handleChange}
											required
										></textarea>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Option D</span>
									<div className="form__form-group-field">
										<textarea
											name="optionD"
											value={optionD}
											type="textarea"
											placeholder="Enter Option D"
											className="form-control"
											onChange={this.handleChange}
											required
										></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<br />
								</div>
								<div style={{ alignSelf: 'center', fontWeight: 'bold' }}>
									Correct Answer
								</div>
								<div>
									{['Option A', 'Option B', 'Option C', 'Option D'].map(
										(option, index) => {
											return (
												<Button
													key={index}
													color={
														option === this.state.correctAnswer
															? 'success'
															: 'default'
													}
													onClick={() => {
														this.setState({
															correctAnswer: index,
														});
													}}
												>
													{option}
												</Button>
											);
										},
									)}
								</div>
								<div className="col-md-12">
									<br />
								</div>
								<button className="btn btn-primary" type="submit">
									Add Question to {this.state.technologySelectedName}
								</button>
							</form>
						</div>
					</Modal>
				</div>
				{this.state.questions && this.state.questions.length >= 1 ? (
					<div className="row">
						<div className="col-md-10">
							<div className="grid card">
								<div className="card-body">
									<h5 className="bold-text">Entered Questions

									</h5>

								</div>
							</div>
						</div>

					</div>
				) : null}


				{this.state.questions &&
					this.state.questions.map((ques, index) => {
						return (
							<div className="col-md-11" key={index}>
								<div className="row">
									<div className="col-md-12">
										<div className="grid card">
											<div className="card-body">
												<Badge className="qtypename" color="primary">
													{this.state.technologySelectedName}{' '}
												</Badge>





												<h5
													className="col-md-10 bold-text"
													style={{ position: 'relative', paddingLeft: '10px' }}
												>
													<span className="cusquestionic">Q:{index + 1}</span>

													<Editor
														apiKey={TINYMCEAPIKEY}
														value={'<p>' + ques.question + '</p>'}
														init={{
															inline: true,
															height: 100,
															menubar: false,
															plugins: [
																'advlist autolink lists link image',
																'charmap print preview anchor help',
																'searchreplace visualblocks code',
																'insertdatetime media table paste wordcount',
															],
															toolbar: '',
														}}
														onEditorChange={this.handleQuestionChange.bind(
															this,
														)}
														onFocusOut={this._handleEditorFocusOut.bind(
															this,
															ques.id,
															'question',
															'opt',
														)}
													/>
												</h5>
												<div className="clearfix"></div>

												<div className="row">
													<div
														className={
															ques.correct == 0
																? 'col-md-10 mycusoptionsuccess'
																: 'col-md-10 mycusoptions'
														}
														style={{ position: 'relative' }}
													>
														<span className="cusoptionic">A:</span>

														<Editor
															apiKey={TINYMCEAPIKEY}
															value={'<p>' + ques.options[0] + '</p>'}
															init={{
																inline: true,
																height: 100,
																menubar: false,
																plugins: [
																	'advlist autolink lists link image',
																	'charmap print preview anchor help',
																	'searchreplace visualblocks code',
																	'insertdatetime media table paste wordcount',
																],
																toolbar: '',
															}}
															onEditorChange={this.handleOptionChange.bind(
																this,
															)}
															onFocusOut={this._handleEditorFocusOut.bind(
																this,
																ques.id,
																'option',
																0,
															)}
														/>
													</div>
													<div className="col-md-1 anscusoptions">
														<Input
															type="radio"
															name={ques.id}
															checked={ques.correct == 0 ? 1 : 0}
															onChange={this.handlechngAnswer.bind(
																this,
																ques.id,
																ques.correct,
																0,
															)}
														/>
														{ques.correct == 0 ? 'Ans' : null}
													</div>
													<div className="col-md-1">
														<span
															className="btn btn-sm btn-success"
															onClick={this.handleAppQues.bind(this, ques.id)}
														>
															Approve
														</span>
													</div>
													<div
														className={
															ques.correct == 1
																? 'col-md-10 mycusoptionsuccess'
																: 'col-md-10 mycusoptions'
														}
														style={{ position: 'relative' }}
													>
														<span className="cusoptionic">B:</span>

														<Editor
															apiKey={TINYMCEAPIKEY}
															value={'<p>' + ques.options[1] + '</p>'}
															init={{
																inline: true,
																height: 100,
																menubar: false,
																plugins: [
																	'advlist autolink lists link image',
																	'charmap print preview anchor help',
																	'searchreplace visualblocks code',
																	'insertdatetime media table paste wordcount',
																],
																toolbar: '',
															}}
															onEditorChange={this.handleOptionChange.bind(
																this,
															)}
															onFocusOut={this._handleEditorFocusOut.bind(
																this,
																ques.id,
																'option',
																1,
															)}
														/>
													</div>
													<div className="col-md-1 anscusoptions">
														<Input
															type="radio"
															name={ques.id}
															checked={ques.correct == 1 ? 1 : 0}
															onChange={this.handlechngAnswer.bind(
																this,
																ques.id,
																ques.correct,
																1,
															)}
														/>
														{ques.correct == 1 ? 'Ans' : null}
													</div>
													<div className="clearfix"></div>
													<div
														className={
															ques.correct == 2
																? 'col-md-10 mycusoptionsuccess'
																: 'col-md-10 mycusoptions'
														}
														style={{ position: 'relative' }}
													>
														<span className="cusoptionic">C:</span>

														<Editor
															apiKey={TINYMCEAPIKEY}
															value={'<p>' + ques.options[2] + '</p>'}
															init={{
																inline: true,
																height: 100,
																menubar: false,
																plugins: [
																	'advlist autolink lists link image',
																	'charmap print preview anchor help',
																	'searchreplace visualblocks code',
																	'insertdatetime media table paste wordcount',
																],
																toolbar: '',
															}}
															onEditorChange={this.handleOptionChange.bind(
																this,
															)}
															onFocusOut={this._handleEditorFocusOut.bind(
																this,
																ques.id,
																'option',
																2,
															)}
														/>
													</div>
													<div className="col-md-1 anscusoptions">
														<Input
															type="radio"
															name={ques.id}
															checked={ques.correct == 2 ? 1 : 0}
															onChange={this.handlechngAnswer.bind(
																this,
																ques.id,
																ques.correct,
																2,
															)}
														/>
														{ques.correct == 2 ? 'Ans' : null}
													</div>
													<div className="col-md-1 anscusoptions">
														<span
															className="btn btn-sm btn-danger float-right deliconques"
															onClick={this.handleDelQues.bind(this, ques.id)}
														>
															Delete
														</span>
													</div>
													<div className="clearfix"></div>
													<div
														className={
															ques.correct == 3
																? 'col-md-10 mycusoptionsuccess'
																: 'col-md-10 mycusoptions'
														}
														style={{ position: 'relative' }}
													>
														<span className="cusoptionic">D:</span>

														<Editor
															apiKey={TINYMCEAPIKEY}
															value={'<p>' + ques.options[3] + '</p>'}
															init={{
																inline: true,
																height: 100,
																menubar: false,
																plugins: [
																	'advlist autolink lists link image',
																	'charmap print preview anchor help',
																	'searchreplace visualblocks code',
																	'insertdatetime media table paste wordcount',
																],
																toolbar: '',
															}}
															onEditorChange={this.handleOptionChange.bind(
																this,
															)}
															onFocusOut={this._handleEditorFocusOut.bind(
																this,
																ques.id,
																'option',
																3,
															)}
														/>
													</div>
													<div className="col-md-1 anscusoptions">
														<Input
															type="radio"
															name={ques.id}
															checked={ques.correct == 3 ? 1 : 0}
															onChange={this.handlechngAnswer.bind(
																this,
																ques.id,
																ques.correct,
																3,
															)}
														/>
														{ques.correct == 3 ? 'Ans' : null}
													</div>
													<div className="clearfix"></div>
												</div>



											</div>
										</div>
									</div>
								</div>

							</div>
						);
					})}


			</div>
		);
	}
}

export default AddQuest;
