import React, { Component } from 'react';

import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import RefreshIcon from 'mdi-react/RefreshIcon';
// import Select from 'react-select';
import swal from 'sweetalert';
// import Alert from '../../../shared/components/Alert'; //eslint-disable-line
// import EditIcon from 'mdi-react/EditIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import CheckIcon from 'mdi-react/CheckIcon'; // eslint-disable-line
// import AlertIcon from 'mdi-react/AlertIcon';
const apiurl = MY_API_URL;
import {
	Button,
	Modal,
	Badge //eslint-disable-line
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',

	token: localStorage.token,
	options: [],
	// question: [],
	questionSelected: {},
	question: '',
	optionA: '',
	optionB: '',
	optionC: '',
	optionD: '',
	correctAnswer: '',
	duration: 0,
	quesidslen: 0,
	newdiflevel: '',
};
class TechCheckQuestions extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.audittechid) {
			this.fetchQuestions(localStorage.audittechid);
		} else {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleCkey = () => {
		this.setState({
			upSingleModal: false,
			viewquestion: '',
			viewoptionA: '',
			viewoptionB: '',
			viewoptionC: '',
			viewoptionD: '',
			viewcorrectAnswer: '',
			viewdiflevel: '',
			viewapproved: 0,
			vieweapproved: 0,
			viewrejected: 0
		});
	};
	viewTechQuestion = (question, optiona, optionb, optionc, optiond, ans, dlevel, duration, approved, eapproved, rejected) => {
		this.setState({
			upSingleModal: true,
			viewquestion: question,
			viewoptionA: optiona,
			viewoptionB: optionb,
			viewoptionC: optionc,
			viewoptionD: optiond,
			viewcorrectAnswer: ans,
			viewdiflevel: dlevel,
			viewduration: duration,
			viewapproved: approved,
			vieweapproved: eapproved,
			viewrejected: rejected
		});
	};

	handleSingleSubmit = (event) => {

		// alert(event);
		let optionss = [];
		optionss.push(this.state.optionA);
		optionss.push(this.state.optionB);
		optionss.push(this.state.optionC);
		optionss.push(this.state.optionD);
		console.log('OPTIONS ARRAY IS ', optionss);
		this.setState(
			{
				options: [...optionss],
			},
			() => {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const addsqurl = apiurl + 'techeditappQuestion';
				var postData = {
					technology: localStorage.audittechid,
					options: [...this.state.options],
					userid: localStorage.usercode,
					question: this.state.question,
					answer: this.state.correctAnswer,
					diflevel: this.state.diflevel,
					duration: this.state.duration,
					quesid: this.state.quesid,
					aevent: event,
				};
				axios
					.post(addsqurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'danger',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{

									upSingleModal: false,
									showAlert: false,
									question: '',
									options: [],
									optionA: '',
									optionB: '',
									optionC: '',
									optionD: '',
									quesid: '',
									correctAnswer: '',
									duration: '',
								},
								() => {
									this.fetchQuestions();
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Failed Try Again Later!',
						});
					});
			},
		);
	};


	fetchQuestions = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {

			technology: localStorage.audittechid,
			techtype: localStorage.audittechtype,

		};
		const getfqurl = apiurl + 'processedtechquestions';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('response.data', response.data);
				if (!response.data.error) {
					this.setState({
						questions: [...response.data.questions],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				console.log('error', error);
				// // eslint-disable-next-line
				this.props.history.push('/');
			});
	};






	handleDelQues = (quesid, id) => { //eslint-disable-line
		console.log(quesid + 'quesid');

		swal({
			title: 'Want to Report',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techdelQnew';
				var postData = {
					quesid: quesid,
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.fetchQuestions();
							swal(response.data.message, {
								icon: 'success',
							});
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};
	handleTransQues = (id) => { //eslint-disable-line


		swal({
			title: 'Want to Approve',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techfwdapprove';
				var postData = {

					id: id,
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.setState({
								newdiflevel: ''
							}, () => {
								this.fetchQuestions();
								swal(response.data.message, {
									icon: 'success',
								});
							});

						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};

	handleRejectQues = (id) => { //eslint-disable-line


		swal({
			title: 'Want to Reject',
			text: 'Are you sure?',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
		}).then((keyconfirm) => {
			if (keyconfirm) {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};

				const togkeyurl = apiurl + 'techfwdrejected';
				var postData = {

					id: id,
					userid: localStorage.usercode
				};
				axios
					.post(togkeyurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log(response.data.message);

						if (response.data.error) {
							swal(response.data.message, {
								icon: 'warning',
							});
						} else {
							this.setState({
								newdiflevel: ''
							}, () => {
								this.fetchQuestions();
								swal(response.data.message, {
									icon: 'success',
								});
							});

						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						swal('Try Again Later', {
							icon: 'Danger',
						});
					});
			}
		});
	};





	render() {

		return (
			<div className="col-md-12">
				<div className="row">



					<div className="col-md-6">
						<div className="grid card">
							<div className="card-body">
								{localStorage.audittechid ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.fetchQuestions.bind(this)}
									>
										<strong style={{ fontSize: '18px' }}><RefreshIcon /> {this.state.questions && this.state.questions.length}</strong>	Refresh  Questions
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Refresh   Questions of {localStorage.audittechname}
									</Button>

								)}


							</div>
						</div>
					</div>
					{this.state.questions && this.state.questions.length >= 1 ? (
						<div className="col-md-6">
							<div className="grid card">
								<div className="card-body">
									<h5 className="bold-text">Questions of {localStorage.audittechname}

									</h5>

								</div>
							</div>
						</div>
					) : null}


				</div>

				<div className="row">

					{this.state.questions &&
						this.state.questions.map((ques, index) => {
							var mainques = ques.mainques;
							var techquesofmain = ques.techquesofmain; // eslint-disable-line
							var diffone = 0;
							var difftwo = 0;
							var diffthree = 0;
							var tpfive = 0;
							var tone = 0;
							var tonepfive = 0;
							var ttwo = 0;
							var ttwopfive = 0;
							var tthree = 0;
							var tthreepfive = 0;
							var tfour = 0;
							var tfourpfive = 0;
							var tfive = 0;

							var approved = 0;
							var eapproved = 0;
							var rejected = 0;

							techquesofmain.map((techques) => {
								approved = approved + parseInt(techques.approved, 0);
								eapproved = eapproved + parseInt(techques.eapproved, 0);
								rejected = rejected + parseInt(techques.rejected, 0);
								if (techques.difficulty_level == 'Beginner') {
									diffone = diffone + 1;
								} else if (techques.difficulty_level == 'Intermediate') {
									difftwo = difftwo + 1;
								} else if (techques.difficulty_level == 'Advanced') {
									diffthree = diffthree + 1;
								}
								if (techques.duration == '0.5') {
									tpfive = tpfive + 1;
								} else if (techques.duration == '1') {
									tone = tone + 1;
								} else if (techques.duration == '1.5') {
									tonepfive = tonepfive + 1;
								} else if (techques.duration == '2') {
									ttwo = ttwo + 1;
								} else if (techques.duration == '2.5') {
									ttwopfive = ttwopfive + 1;
								} else if (techques.duration == '3') {
									tthree = tthree + 1;
								} else if (techques.duration == '3.5') {
									tthreepfive = tthreepfive + 1;
								} else if (techques.duration == '4') {
									tfour = tfour + 1;
								} else if (techques.duration == '4.5') {
									tfourpfive = tfourpfive + 1;
								} else if (techques.duration == '5') {
									tfive = tfive + 1;
								}
							});
							return (
								<div className="col-md-12" key={index}>
									<div className="row">
										<div className="col-md-8">
											<div className="grid card">
												<div className="card-body">
													<Badge style={{ marginRight: '20px', fontSize: '12px', float: 'right' }} color="primary">
														{mainques.difficulty_level}
													</Badge> <Badge style={{ marginRight: '20px', fontSize: '12px', float: 'right' }} color="primary">
														{mainques.duration}
													</Badge>


													<h5
														className="col-md-12 bold-text"
														style={{ position: 'relative', paddingLeft: '20px', marginBottom: '15px' }}
													>
														<span className="cusquestionic">Q:{index + 1}</span>

														<Editor
															apiKey={TINYMCEAPIKEY}
															value={'<p>' + mainques.question + '</p>'}
															disabled={true}
															init={{
																inline: true,
																height: 100,
																menubar: false,

																toolbar: '',
															}}

														/>
													</h5>
													<div className="clearfix"></div>

													<div className="row">
														<div
															className={
																mainques.correct == 0
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[0] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>


														<div
															className={
																mainques.correct == 1
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[1] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>



														<div className="clearfix"></div>
														<div
															className={
																mainques.correct == 2
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">C:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[2] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>

														<div className="clearfix"></div>
														<div
															className={
																mainques.correct == 3
																	? 'col-md-12 mycusoptionsuccess'
																	: 'col-md-12 mycusoptions'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">D:</span>

															<Editor
																apiKey={TINYMCEAPIKEY}
																value={'<p>' + mainques.options[3] + '</p>'}
																disabled={true}
																init={{
																	inline: true,
																	height: 100,
																	menubar: false,

																	toolbar: '',
																}}
															/>
														</div>


														<div className="clearfix"></div>
														<div className="col-md-12" style={{ marginTop: '15px' }}>
															<div className="row">
																<div className="col-md-4">
																	<h5>Status</h5>
																	{approved != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">Approved <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {approved} </Badge></Badge>) : null}
																	{eapproved != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="warning">Edited <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {eapproved} </Badge></Badge>) : null}
																	{rejected != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="danger">Rejected <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {rejected} </Badge></Badge>) : null}
																</div>
																<div className="col-md-4">
																	<h5>Recomended Duration</h5>
																	{tpfive != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">0.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tpfive} </Badge></Badge>) : null}
																	{tone != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">1min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tone} </Badge></Badge>) : null}
																	{tonepfive != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">1.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tonepfive} </Badge></Badge>) : null}
																	{ttwo != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">2min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {ttwo} </Badge></Badge>) : null}
																	{ttwopfive != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">2.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {ttwopfive} </Badge></Badge>) : null}
																	{tthree != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">3min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tthree} </Badge></Badge>) : null}
																	{tthreepfive != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">3.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tthreepfive} </Badge></Badge>) : null}
																	{tfour != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">4min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tfour} </Badge></Badge>) : null}
																	{tfourpfive != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">4.5 <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tfourpfive} </Badge></Badge>) : null}
																	{tfive != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">5min <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light"> {tfive} </Badge></Badge>) : null}
																</div>
																<div className="col-md-4">
																	<h5>Recomended Level</h5>
																	{diffone != 0 ? (
																		<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">
																			Beginner <Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light">
																				{diffone}
																			</Badge>
																		</Badge>
																	) : null}{' '}{difftwo != 0 ? (
																		<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="warning">
																			Intermediate<Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light">
																				{difftwo}
																			</Badge>
																		</Badge>
																	) : null}{' '}{diffthree != 0 ? (
																		<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="danger">
																			Advanced<Badge style={{ marginLeft: '20px', fontSize: '12px' }} color="light">
																				{diffthree}
																			</Badge>
																		</Badge>
																	) : null}

																</div>

															</div>
														</div>
													</div>



												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="grid card">
												<div className="card-body">
													{techquesofmain &&
														techquesofmain.map((techques, index) => {
															var mquestion = techques.question;
															var moptiona = techques.options[0];
															var moptionb = techques.options[1];
															var moptionc = techques.options[2];
															var moptiond = techques.options[3];
															return (
																<div className="row techquesofmain" key={index}>
																	<div className="col-md-12">
																		<b>Name: </b>{techques.techname}
																	</div>
																	{techques.rejected == 1 ? (
																		<div className="col-md-8 text-danger">
																			<b>Rejected </b>

																		</div>
																	) : (
																		<>
																			<div className="col-md-4">
																				<b>Level: </b><br />
																				{techques.difficulty_level == mainques.difficulty_level ? 'Default' : (
																					<div className="text-success" style={{ fontWeight: 'bold' }}>{techques.difficulty_level}</div>
																				)}
																			</div>
																			<div className="col-md-4">
																				<b>Time: </b><br />
																				{techques.duration == mainques.duration ? 'Default' : (
																					<div className="text-success" style={{ fontWeight: 'bold' }}>{techques.duration}</div>
																				)}
																			</div>
																		</>
																	)}



																	<div className="col-md-4">
																		<>
																			{techques.eapproved == 1 ? (
																				<span style={{ color: 'green', cursor: 'pointer' }}

																					onClick={this.viewTechQuestion.bind(this, mquestion, moptiona, moptionb, moptionc, moptiond, techques.correct, techques.difficulty_level, techques.duration, techques.approved, techques.eapproved, techques.rejected)}
																				>
																					<EyeIcon />
																				</span>
																			) : null}
																			{techques.rejected == 1 ? (
																				<span style={{ color: 'green', cursor: 'pointer', float: 'right' }}

																					onClick={this.handleRejectQues.bind(this, techques.id)}
																				>
																					<CheckIcon />
																				</span>
																			) : (
																				<span style={{ color: 'green', cursor: 'pointer', float: 'right' }}

																					onClick={this.handleTransQues.bind(this, techques.id)}
																				>
																					<CheckIcon />
																				</span>
																			)}
																		</>
																	</div>
																	<div className="clearfix"></div>
																	{techques.eapproved == 1 ? (
																		<>
																			{mainques.question == mquestion ? (
																				<div className="col-md-1">Q</div>
																			) : (
																				<div className="col-md-1 text-success" style={{ fontWeight: 'bold' }}>Q</div>
																			)}
																			{mainques.options[0] == moptiona ? (
																				<div className="col-md-1">A</div>
																			) : (
																				<div className="col-md-1 text-success" style={{ fontWeight: 'bold' }}>A</div>
																			)}
																			{mainques.options[1] == moptionb ? (
																				<div className="col-md-1">B</div>
																			) : (
																				<div className="col-md-1 text-success" style={{ fontWeight: 'bold' }}>B</div>
																			)}
																			{mainques.options[2] == moptionc ? (
																				<div className="col-md-1">C</div>
																			) : (
																				<div className="col-md-1 text-success" style={{ fontWeight: 'bold' }}>C</div>
																			)}
																			{mainques.options[3] == moptiond ? (
																				<div className="col-md-1">D</div>
																			) : (
																				<div className="col-md-1 text-success" style={{ fontWeight: 'bold' }}>D</div>
																			)}
																			{mainques.correct == techques.correct ? (
																				<div className="col-md-1">Ans</div>
																			) : (
																				<div className="col-md-3 text-success" style={{ fontWeight: 'bold' }}>Ans</div>
																			)}
																		</>
																	) : null}

																</div>
															);
														})}

												</div>
											</div>
										</div>
									</div>

								</div>
							);
						})}
					<Modal
						isOpen={this.state.upSingleModal}
						modalClassName="ltr-support"
						className="modal-dialog--primary modal-dialog-big "
					>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>

						</div>
						<div className="modal__body">
							<h5
								className="col-md-12 bold-text"
								style={{ position: 'relative', paddingLeft: '20px', marginBottom: '15px' }}
							>
								<span className="cusquestionic">Q: </span>

								<Editor
									apiKey={TINYMCEAPIKEY}
									value={'<p>' + this.state.viewquestion + '</p>'}
									disabled={true}
									init={{
										inline: true,
										height: 100,
										menubar: false,

										toolbar: '',
									}}

								/>
							</h5>
							<div className="clearfix"></div>

							<div className="row">
								<div
									className={
										this.state.viewcorrectAnswer == 0
											? 'col-md-12 mycusoptionsuccess'
											: 'col-md-12 mycusoptions'
									}
									style={{ position: 'relative' }}
								>
									<span className="cusoptionic">A:</span>

									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + this.state.viewoptionA + '</p>'}
										disabled={true}
										init={{
											inline: true,
											height: 100,
											menubar: false,

											toolbar: '',
										}}
									/>
								</div>


								<div
									className={
										this.state.viewcorrectAnswer == 1
											? 'col-md-12 mycusoptionsuccess'
											: 'col-md-12 mycusoptions'
									}
									style={{ position: 'relative' }}
								>
									<span className="cusoptionic">B:</span>

									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + this.state.viewoptionB + '</p>'}
										disabled={true}
										init={{
											inline: true,
											height: 100,
											menubar: false,

											toolbar: '',
										}}
									/>
								</div>



								<div className="clearfix"></div>
								<div
									className={
										this.state.viewcorrectAnswer == 2
											? 'col-md-12 mycusoptionsuccess'
											: 'col-md-12 mycusoptions'
									}
									style={{ position: 'relative' }}
								>
									<span className="cusoptionic">C:</span>

									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + this.state.viewoptionC + '</p>'}
										disabled={true}
										init={{
											inline: true,
											height: 100,
											menubar: false,

											toolbar: '',
										}}
									/>
								</div>

								<div className="clearfix"></div>
								<div
									className={
										this.state.viewcorrectAnswer == 3
											? 'col-md-12 mycusoptionsuccess'
											: 'col-md-12 mycusoptions'
									}
									style={{ position: 'relative' }}
								>
									<span className="cusoptionic">D:</span>

									<Editor
										apiKey={TINYMCEAPIKEY}
										value={'<p>' + this.state.viewoptionD + '</p>'}
										disabled={true}
										init={{
											inline: true,
											height: 100,
											menubar: false,

											toolbar: '',
										}}
									/>
								</div>


								<div className="clearfix"></div>
								<div className="col-md-12" style={{ marginTop: '15px' }}>
									<div className="row">
										<div className="col-md-4">
											<h5>Status: {this.state.viewapproved != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">Approved </Badge>) : null}
												{this.state.vieweapproved != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="warning">Edited </Badge>) : null}
												{this.state.viewrejected != 0 ? (<Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="danger">Rejected </Badge>) : null}</h5>

										</div>
										<div className="col-md-4">
											<h5>Duration: <Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">{this.state.viewduration}</Badge></h5>

										</div>
										<div className="col-md-4">
											<h5>Level: <Badge style={{ marginRight: '20px', marginBottom: '10px', fontSize: '12px' }} color="success">
												{this.state.viewdiflevel}
											</Badge></h5>



										</div>

									</div>
								</div>
							</div>

						</div>
					</Modal>

				</div>

			</div >
		);
	}
}

export default TechCheckQuestions;
