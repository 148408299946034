import React, { Component } from 'react';
/* eslint-disable  */

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import { Card, CardBody, Col, Badge, Table, Modal, Row } from 'reactstrap';
// import BasicUserinfo from '../../Apanel/BasicUserinfo';
import ResultMeter from '../../Apanel/ResultMeter';
// import TechName from '../../Apanel/TechName';
const apiurl = MY_API_URL;

import Alert from '../../../shared/components/Alert';
var dateFormat = require('dateformat');
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
const initialstate = {

	questions: [],
	questionIndex: 0,
	result: [],
	currentDuration: 0,

	customtestsnotnow: false,
	showEbox: false,
	showAdvbox: false,
	showJobAppbox: false,
	showJbox: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	verifiedTest: {},
	showDetails: true,
	closeall: false,
	isreqresume: false,
	blockbtn: 1,
	resumeup: false,
};
class Dashboard extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		let fsprefix = localStorage.sufix;
		let flprefix = [];
		let fnprefix = '';
		if (fsprefix) {
			flprefix = fsprefix.split('@');
			fnprefix = flprefix[1];
		}

		this.setState({ nprefix: fnprefix });
		this.checkjalerts();
		this.handleTestJobSubmit = this.handleTestJobSubmit.bind(this);


	}

	handleTestSubmit = (event) => {
		console.log(event);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var gpostData = {
			email: localStorage.by,
			testId: event,
		};
		console.log(event);
		const testidurl = apiurl + 'verifyTest';
		axios
			.post(testidurl, gpostData, {
				headers: headers,
			})
			.then((response) => {
				// console.log(response.data.data);
				this.setState({
					showAlert: true,
					alertType: 'success',
					alertText: response.data.message,
					verifiedTest: response.data.data,
					showEbox: true,
					showAdvbox: false,
					showCEbox: false,
					showJbox: false,
					closeall: true,
				});
			})
			.catch((error) => { // eslint-disable-line
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Request Failed Try Again Later!',
				});
			});
	};
	handleTestAdvSubmit = (event) => {
		console.log(event);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var gpostData = {
			email: localStorage.by,
			testId: event,
		};
		console.log(event);
		const testidurl = apiurl + 'verifyTestadv';
		axios
			.post(testidurl, gpostData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.data);
				this.setState({
					showAlert: true,
					alertType: 'success',
					alertText: response.data.message,
					verifiedTest: response.data.data,
					showEbox: false,
					showAdvbox: true,
					showCEbox: false,
					showJbox: false,
					closeall: true,
				});
			})
			.catch((error) => { // eslint-disable-line
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Request Failed Try Again Later!',
				});
			});
	};
	handleTestJobSubmit = (jobtestid) => {
		console.log(jobtestid);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var gpostData = {
			email: localStorage.by,
			testId: jobtestid,
		};
		console.log(jobtestid);
		const testidurl = apiurl + 'verifyTestjob';
		axios
			.post(testidurl, gpostData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.data);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState({
						showAlert: true,
						alertType: 'success',
						alertText: response.data.message,
						verifiedTest: response.data.data,
						showEbox: false,
						showAdvbox: false,
						showJobAppbox: true,
						showCEbox: false,
						showJbox: false,
						closeall: true,
					});
				}

			})
			.catch((error) => { // eslint-disable-line
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Request Failed Try Again Later!',
				});
			});
	};
	handlecustomtestSubmit = (event) => {
		console.log(event);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var gpostData = {
			email: localStorage.by,
			testId: event,
		};
		console.log(event);
		const testidurl = apiurl + 'verifycutomTest';
		axios
			.post(testidurl, gpostData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.data);

				this.setState({
					showAlert: true,
					alertType: 'success',
					alertText: response.data.message,
					verifiedTest: response.data.data,
					showCEbox: true,
					showAdvbox: false,
					showEbox: false,
					showJbox: false,
					closeall: true,
				});
			})
			.catch((error) => { // eslint-disable-line
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Request Failed Try Again Later!',
				});
			});
	};


	redirectUser = () => {
		localStorage.setItem('testId', this.state.verifiedTest.id);
		localStorage.setItem('ttypefor', this.state.verifiedTest.ttype);
		localStorage.setItem('test', JSON.stringify(this.state.verifiedTest));
		localStorage.setItem(
			'questionIndex',
			localStorage.getItem('testId') === this.state.verifiedTest.id &&
				localStorage.getItem('questionIndex')
				? localStorage.getItem('questionIndex') + 1
				: 0,
		);
		if (this.state.verifiedTest.ttype == 'cus') {
			// eslint-disable-next-line
			this.props.history.push('/customstarttest', {
				test: this.state.verifiedTest,
			});
		} else {
			// eslint-disable-next-line
			this.props.history.push('/starttest', {
				test: this.state.verifiedTest,
			});
		}
	};

	redirectUserJob = () => {
		localStorage.setItem('jobid', this.state.jobid);
		localStorage.setItem('testId', this.state.verifiedTest.id);
		localStorage.setItem('ttypefor', this.state.verifiedTest.ttype);
		localStorage.setItem('test', JSON.stringify(this.state.verifiedTest));
		localStorage.setItem(
			'questionIndex',
			localStorage.getItem('testId') === this.state.verifiedTest.id &&
				localStorage.getItem('questionIndex')
				? localStorage.getItem('questionIndex') + 1
				: 0,
		);

		// eslint-disable-next-line
		this.props.history.push('/starttest', {
			test: this.state.verifiedTest,
		});

	};

	redirectUserAdv = () => {
		localStorage.setItem('testId', this.state.verifiedTest.id);
		localStorage.setItem('ttypefor', this.state.verifiedTest.ttype);
		localStorage.setItem('test', JSON.stringify(this.state.verifiedTest));
		localStorage.setItem(
			'questionIndex',
			localStorage.getItem('testId') === this.state.verifiedTest.id &&
				localStorage.getItem('questionIndex')
				? localStorage.getItem('questionIndex') + 1
				: 0,
		);

		// eslint-disable-next-line
		this.props.history.push('/startadvtest', {
			testadv: this.state.verifiedTest,
		});

	};
	checkjalerts = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			uid: localStorage.usercode,
		};
		const getuurl = apiurl + 'checkjalerts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					console.log('checkalerts', response.data.message);
					console.log('newcandy', response.data.newcandy);

					// console.log('response.data', response.data);
					let allprefix = response.data.allprefix;
					let prefixoptions = [];

					allprefix.map((prefix) => {
						var flagname = prefix.ccode;
						flagname = flagname.toLowerCase();
						let prefixname =
							prefix.cname + '@' + prefix.prefix + '@' + prefix.id;

						prefixoptions.push({
							value: prefixname,
							label: (
								<div className="counoption">
									<img
										src={'flags/' + flagname + '.svg'}
										width="25px"
										height="15px"
									/>
									{prefix.prefix + ' - ' + prefix.cname}
								</div>
							),
						});
					});




					var mainreqdocs = response.data.reqdocs;
					var needreqdocs = [];
					mainreqdocs.map((mreq_doc) => {
						var dustdoc = mreq_doc.req_document.replace(/ /gi, '_');
						dustdoc = dustdoc.toLowerCase();
						needreqdocs.push({ id: mreq_doc.id, slug: dustdoc, docname: mreq_doc.req_document })

					});


					if (response.data.isprofile) {
						const isprofile = response.data.myprofile;
						let isreqresume = false;
						if (isprofile.resume) {
							isreqresume = true;
						}
						this.setState(
							{
								showAlert: false,
								showCEbox: false,
								showAdvbox: false,
								showEbox: false,
								showJbox: true,
								prefixoptions: prefixoptions,
								reqdocs: needreqdocs,
								newcandy: response.data.newcandy,
								newjobtitle: response.data.jobtitle,
								ajobid: response.data.ajobid,
								resume: isprofile.resume,
								req_document: '',
								email: localStorage.by,
								name: localStorage.name,
								mobile: localStorage.pmobile,
								image: localStorage.profile_img,
								technologies: localStorage.technologies,
								prefix: localStorage.sufix,
								secondary_phone: isprofile.secondary_phone,
								skype: isprofile.skype,
								current_location: isprofile.current_location,
								passport: isprofile.passport,
								ssn: isprofile.ssn,
								dob: isprofile.dob,
								work_auth: isprofile.work_auth,
								employer_info: isprofile.employer_info,
								relocation: isprofile.relocation,
								availability: isprofile.availability,
								linkedin: isprofile.linkedin,
								isreqresume: isreqresume,
							});
					} else {
						// /////////////////
						this.setState(
							{
								email: localStorage.by,
								name: localStorage.name,
								mobile: localStorage.pmobile,
								image: localStorage.profile_img,
								technologies: localStorage.technologies,
								prefix: localStorage.sufix,
								showAlert: false,
								showCEbox: false,
								showAdvbox: false,
								showEbox: false,
								showJbox: true,
								prefixoptions: prefixoptions,
								reqdocs: needreqdocs,
								newcandy: response.data.newcandy,
								newjobtitle: response.data.jobtitle,
								ajobid: response.data.ajobid,
								resume: '',
								req_document: '',
								secondary_phone: '',
								skype: '',
								current_location: '',
								passport: '',
								ssn: '',
								dob: '',
								work_auth: '',
								employer_info: '',
								relocation: '',
								availability: '',
								linkedin: '',
								isreqresume: this.state.isreqresume,
							});
						// /////////////
					}

					// alert('There is Job Application');

				} else {
					if (localStorage.profile_completed === '0') {
						localStorage.setItem('came_from', 'dashboard');
						// eslint-disable-next-line
						this.props.history.push({
							pathname: '/profile',
							backTo: '/',
						});
					} else {
						this.fetchTestsJob();
						this.fetchTests();
						this.fetchTestsAdv();
						this.fetchCustomTests();
					}
					console.log('checkalerts', response.data.message);

				}

			})
			.catch((error) => {
				console.log(error);
			});
	};
	handleCkey = () => {
		this.setState({
			showAlert: false,
			showCEbox: false,
			showAdvbox: false,
			showEbox: false,
			showJbox: false,

			resume: '',
			req_document: '',
			prefix: '',
			secondary_phone: '',
			skype: '',
			current_location: '',
			passport: '',
			ssn: '',
			dob: '',
			work_auth: '',
			employer_info: '',
			relocation: '',
			availability: '',
			linkedin: '',
		});
	};
	fetchTestsJob = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'fetchTestsJob';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					console.log('jobapptest', response.data.message);
				} else {
					// console.log('Job Data is', response.data.jobtests);
					let jobtestsare = response.data.jobtests[0];
					// console.log('jobtestsare', jobtestsare.jobid);
					this.handleTestJobSubmit(jobtestsare.test.id);
					this.setState({
						tests_job: [...jobtestsare.test],
						jobid: jobtestsare.jobid,
					});
				}

			})
			.catch((error) => {
				console.log(error);
			});
	};
	fetchTests = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'fetchTests';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('Data is', response.data.data);
				this.setState({
					tests: [...response.data.data],
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
	fetchTestsAdv = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'fetchTestsadv';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('Data is', response.data.data);
				this.setState({
					tests_adv: [...response.data.data],
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
	fetchCustomTests = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'fetchcustomTests';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('custom Data is', response.data.data);
				this.setState({
					customtests: [...response.data.data],
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	maskDate = (sep) => {
		return [/[0-3]/, /\d/, sep, /[0-1]/, /\d/, sep, /\d/, /\d/, /\d/, /\d/];
	};

	autoCorrectedDatePipe = (sep) => {
		const format = `dd${sep}mm${sep}yyyy`;
		return createAutoCorrectedDatePipe(format);
	};
	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleSelectChange = (selectedOption) => {
		let sprefix = selectedOption.value;
		let lprefix = sprefix.split('@');
		let nprefix = lprefix[1];
		this.setState({ prefix: sprefix, nprefix: nprefix });
	};

	handleJobSubmit = (event) => {
		event.preventDefault();

		if (isNaN(this.state.mobile)) {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Invalid Mobile',
			});
		} else {
			var uploaded_req_docs = [];
			this.state.reqdocs.map((req_doc) => {
				var dslug = req_doc.slug;
				var dname = req_doc.docname;
				uploaded_req_docs.push({ name: dname, slug: dslug, dfile: this.state[dslug] });
			});
			uploaded_req_docs = JSON.stringify(uploaded_req_docs);
			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};
			var postData = {
				jobid: this.state.ajobid,
				uid: localStorage.usercode,
				email: localStorage.by,
				mobile: this.state.mobile,
				name: this.state.name,
				resume: this.state.resume,
				req_document: uploaded_req_docs,
				prefix: this.state.prefix,
				secondary_phone: this.state.secondary_phone,
				skype: this.state.skype,
				current_location: this.state.current_location,
				passport: this.state.passport,
				ssn: this.state.ssn,
				dob: this.state.dob,
				work_auth: this.state.work_auth,
				employer_info: this.state.employer_info,
				relocation: this.state.relocation,
				availability: this.state.availability,
				linkedin: this.state.linkedin,
				newcandy: this.state.newcandy,
			};
			const pupurl = apiurl + 'addjobcandy';
			axios
				.post(pupurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log('Response is ', response.data);
					console.log(response.data.message);

					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState(
							{
								showAlert: true,
								alertType: 'success',
								alertText: response.data.message,
								showEbox: false,
								showObox: true,
							},
							() => {


								localStorage.setItem('name', this.state.name);
								localStorage.setItem(
									'mobile',
									this.state.nprefix + '-' + this.state.mobile,
								);

								localStorage.setItem('pmobile', this.state.mobile);
								localStorage.setItem('sufix', this.state.prefix);
								localStorage.setItem('profile_completed', 1);
								window.location.reload(false);
								this.fetchTestsJob();
							},
						);

					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Request Failed Try again Later!',
					});
				});
		}
	};
	// uploadfile = (thisone, mfile) => {
	// 	// console.log('mfile', mfile);
	// 	console.log('thisone', thisone);

	// 	const thisonefile = mfile.target.files[0];
	// 	console.log(this.state[thisone]);
	// 	// console.log(mfile.target.files[0]);
	// 	// console.log(resume);
	// 	const data = new FormData();

	// 	data.append('resume', thisonefile);
	// 	data.append('email', this.state.email);
	// 	const resumeurl = apiurl + 'resumeupload';
	// 	axios
	// 		.post(resumeurl, data)
	// 		.then((response) => {
	// 			// console.log('Response is ', response.data);
	// 			// console.log(response.data.message);
	// 			if (response.data.error) {
	// 				this.setState({
	// 					showAlert: true,
	// 					alertType: 'warning',
	// 					alertText: response.data.message,
	// 				});
	// 			} else {
	// 				const headers = {
	// 					ContentType: thisonefile.type,
	// 				};
	// 				const filetopost = response.data.filetopost;
	// 				const newfile = response.data.fileurl;
	// 				console.log(newfile);
	// 				// console.log(filetopost);
	// 				// console.log(resume);
	// 				axios
	// 					.put(filetopost, thisonefile, headers)
	// 					.then(() => {

	// 						this.setState(
	// 							{
	// 								showAlert: false,
	// 								[thisone]: newfile,
	// 							},
	// 							() => {
	// 								console.log('this.state', this.state);
	// 							},
	// 						);
	// 					})
	// 					.catch((error) => {
	// 						console.log('Error is ', error.response);
	// 						this.setState({
	// 							alertType: 'danger',
	// 							showAlert: true,
	// 							alertText: 'Failled Try Again Later!',
	// 						});
	// 					});
	// 			}
	// 		})
	// 		.catch(() => {
	// 			this.setState({
	// 				alertType: 'danger',
	// 				showAlert: true,
	// 				alertText: 'Failled Try Again Later!',
	// 			});
	// 		});
	// };

	uploadfile = (thisone, mfile) => {
		console.log('mfile', mfile);
		console.log('thisone', thisone);
		const thisonefile = mfile.target.files[0];
		this.setState({
			blockbtn: 2,
		}, () => {

			const data = new FormData();
			data.append('resume', thisonefile);
			const resumeurl = 'https://screenhr.com/samtosun.php?u=none&u=' + localStorage.usercode;
			axios.post(resumeurl, data)
				.then((response) => {
					console.log('Response is ', response.data);
					// console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
							resume: '',
							resumeup: false,
							blockbtn: 2,
						});
					} else {
						this.setState({
							showAlert: false,
							resume: response.data.fileurl,
							resumeup: true,
							blockbtn: 1,
						});
					}
				})
				.catch(() => {
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failled Try Again Later!',
					});
				});
		});
	};
	handlerelocationChange = secoption => {
		var relocation = secoption.value;
		this.setState({ relocation });
		console.log(`relocation selected:`, relocation);
	};

	render() {
		const { prefixoptions, prefix } = this.state; //eslint-disable-line
		const options = [
			{ value: 'Yes', label: 'Yes' },
			{ value: 'No', label: 'No' },
		];
		let comptindex = 1;
		let cuscomptest = 1;
		return (
			<div>
				{this.state.showJbox ? (
					<Modal
						isOpen={this.state.showJbox}
						modalClassName="ltr-support"
						className="modal-dialog--primary clientmodal"
					>
						<div className="modal__header">
							{!this.state.newcandy ? (
								<button
									className="lnr lnr-cross modal__close-btn"
									type="button"
									onClick={this.handleCkey}
								/>
							) : null}

							<h4 className="text-modal  modal__title" style={{ marginBottom: '40px' }}><b>Job: </b> {this.state.newjobtitle} </h4>

						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleJobSubmit(event)}
							>
								<Row style={{ width: '100%' }}>

									<Col md={6}>
										{/* old fields */}


										<div className="form__form-group">
											<span className="form__form-group-label">Your Full Name <span className='lablestar'>*</span></span>
											<div className="form__form-group-field">


												<input
													name="name"
													type="text"
													className="form-control"
													placeholder="Your Full Name"
													defaultValue={this.state.name ? this.state.name : ''}
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Primary Contact <span className='lablestar'>*</span></span>
											<div className="form__form-group-field">
												<Select
													name="prefix"
													value={prefixoptions.filter(
														(option) => option.value == prefix,
													)}
													onChange={this.handleSelectChange}
													options={prefixoptions}
													clearable={false}
													placeholder="Country"
													className="react-select"
													classNamePrefix="react-select"
												// defaultMenuIsOpen={true}
												// isDisabled={true}
												/>

												<input
													name="mobile"
													type="text"
													className="form-control"
													placeholder="Your Mobile"
													defaultValue={this.state.mobile ? this.state.mobile : ''}
													onChange={this.handleChange}
													pattern="^\d{10}$"
													title="Min and Max 10 Digits"
													required
												/>
											</div>
										</div>

										{/* old fields */}

										<div className="form__form-group">
											<span className="form__form-group-label">Secondary Contact <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">
												<input
													name="secondary_phone"
													type="text"
													className="form-control"
													placeholder="Contact Number"
													defaultValue={this.state.secondary_phone ? this.state.secondary_phone : ''}
													onChange={this.handleChange}
													// pattern="^\d{10}$"
													// pattern="\d*"
													// pattern="/^[\+\d]+(?:[\d-.\s()]*)$/mg"
													title="Min and Max 10 Digits"

												/>
											</div>
										</div>

										<div className="form__form-group">
											<span className="form__form-group-label">DOB dd-mm-yyyy <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">

												<MaskedInput
													name="dob"
													type="text"
													placeholder="dd-mm-yyyy"
													className="form-control"
													defaultValue={this.state.dob ? this.state.dob : ''}
													onChange={this.handleChange}
													mask={this.maskDate('-')}
													pipe={this.autoCorrectedDatePipe('-')}
												/>
											</div>
										</div>






										<div className="form__form-group">
											<span className="form__form-group-label">Skype ID <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">
												<input
													name="skype"
													type="text"
													className="form-control"
													placeholder="Enter Skype ID"
													defaultValue={this.state.skype ? this.state.skype : ''}
													onChange={this.handleChange}

												/>
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Linkedin <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">

												<input
													name="linkedin"
													type="text"
													placeholder="Linkedin"
													className="form-control"
													defaultValue={this.state.linkedin ? this.state.linkedin : ''}
													onChange={this.handleChange}

												/>
											</div>
										</div>
										<div className="form__form-group">
											<span className="form__form-group-label">Passport no. <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">

												<input
													name="passport"
													type="text"
													placeholder="Passport no."
													className="form-control"
													defaultValue={this.state.passport ? this.state.passport : ''}
													onChange={this.handleChange}

												/>
											</div>
										</div>

										<div className="form__form-group">
											<span className="form__form-group-label">SSN last 4 digit nos <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">

												<input
													name="ssn"
													type="text"
													placeholder="SSN last 4 digit nos"
													className="form-control"
													defaultValue={this.state.ssn ? this.state.ssn : ''}
													onChange={this.handleChange}

												/>
											</div>
										</div>






									</Col>
									<Col md={6}>

										<div className="form__form-group">
											<span className="form__form-group-label">Availability <span className='lablestar'>*</span></span>
											<div className="form__form-group-field">

												<input
													name="availability"
													type="text"
													placeholder="Availability"
													className="form-control"
													defaultValue={this.state.availability ? this.state.availability : ''}
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>

										<div className="form__form-group">
											<span className="form__form-group-label">Current Location <span className='lablestar'>*</span></span>
											<div className="form__form-group-field">

												<input
													name="current_location"
													type="text"
													placeholder="Current Location"
													className="form-control"
													defaultValue={this.state.current_location ? this.state.current_location : ''}
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>

										<div className="form__form-group">
											<span className="form__form-group-label">Relocation <span className='lablestar'>*</span></span>
											<div className="form__form-group-field" style={{ height: '30px' }}>

												<Select
													name="relocation"
													value={options.filter(
														(option) => option.value == this.state.relocation,
													)}
													onChange={this.handlerelocationChange}
													options={options}
													clearable={false}
													className="react-select-team"
													classNamePrefix="react-select"
													required
												/>

											</div>
										</div>





										<div className="form__form-group">
											<span className="form__form-group-label">Work Authorization <span className='lablestar'>*</span></span>
											<div className="form__form-group-field">

												<input
													name="work_auth"
													type="text"
													placeholder="Work Authorization"
													className="form-control"
													defaultValue={this.state.work_auth ? this.state.work_auth : ''}
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>

										<div className="form__form-group">
											<span className="form__form-group-label">Employer info if any <span className='lableoptional'>(optional)</span></span>
											<div className="form__form-group-field">

												<input
													name="employer_info"
													type="text"
													placeholder="Employer info if any"
													className="form-control"
													defaultValue={this.state.employer_info ? this.state.employer_info : ''}
													onChange={this.handleChange}

												/>
											</div>
										</div>


										{this.state.reqdocs && this.state.reqdocs.map((req_doc, index) => {

											return (
												<div className="form__form-group" key={index}>
													<span className="form__form-group-label">{req_doc.docname} <span className='lablestar'>*</span></span>
													<div className="form__form-group-field" style={{ height: '30px' }}>
														<input type="file" className="form-control" required onChange={this.uploadfile.bind(this, req_doc.slug)} />
													</div>
												</div>
											)
										})}


										{this.state.isreqresume ? (
											<div className="form__form-group">
												<span className="form__form-group-label">Resume  <span className='lableoptional'>(optional as it Exist)</span><span style={{ color: 'green' }}>{this.state.resumeup ? "Updated" : null}</span></span>
												<div className="form__form-group-field" style={{ height: '30px' }}>
													<input type="file" onChange={this.uploadfile.bind(this, 'resume')} />
												</div>
											</div>
										) : (
											<div className="form__form-group">
												<span className="form__form-group-label">Resume  <span className='lablestar'>*</span><span style={{ color: 'green' }}>{this.state.resumeup ? "Updated" : null}</span></span>
												<div className="form__form-group-field" style={{ height: '30px' }}>
													<input type="file" required onChange={this.uploadfile.bind(this, 'resume')} />
												</div>
											</div>
										)}

										{this.state.blockbtn == 2 ? (
											<span
												className="btn btn-primary account__btn account__btn--small"
												desabled="true"
											>
												Apply
											</span>
										) : (

											<button
												className="btn btn-primary account__btn account__btn--small"
												type="submit"
											>
												Apply
											</button>
										)}

									</Col>
								</Row>

							</form>
						</div>
					</Modal>
				) : null}
				{this.state.showJobAppbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Welcome to the
											<span className="account__logo-accent"> test!</span>
										</span>
									</h3>
								</div>

								<div style={{ color: 'red' }}>
									Read these instructions very carefully
								</div>
								<ul>
									<li>
										This test will take {this.state.verifiedTest.duration}{' '}
										minutes time to be completed
									</li>
									<li>You have to complete your test in one sitting</li>
									<li>
										Make sure you are connected to a stable internet connection
									</li>
									<li>
										Every question has an alloted time, if that that time is
										exceeded you will be automatically moved to the next
										Question
									</li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUserJob}
								>
									Start Application Test
								</button>
							</div>
						</div>
					</div>
				) : null}


				{this.state.showEbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Welcome to the
											<span className="account__logo-accent"> test!</span>
										</span>
									</h3>
								</div>

								<div style={{ color: 'red' }}>
									Read these instructions very carefully
								</div>
								<ul>
									<li>
										This test will take {this.state.verifiedTest.duration}{' '}
										minutes time to be completed
									</li>
									<li>You have to complete your test in one sitting</li>
									<li>
										Make sure you are connected to a stable internet connection
									</li>
									<li>
										Every question has an alloted time, if that that time is
										exceeded you will be automatically moved to the next
										Question
									</li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUser}
								>
									Start Test
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						{this.state.closeall ? null : (

							<div>
								{this.state.tests ? (
									<Row>
										{this.state.tests &&
											this.state.tests.map((test, index) => {
												if (test.taken <= 0) {
													return (
														<Col md={4} key={index}>
															<Card>
																<CardBody className="dashboard__health-chart-card">
																	<div className="card__title">
																		<h4 className="bold-text card__title-center">
																			{test.generated_id}
																		</h4>
																		<ul className="list-group list-group-flush">
																			<li className="list-group-item">
																				<b>From: </b>
																				{test.name}
																			</li>
																			<li className="list-group-item">
																				{test.sent_by}
																			</li>
																		</ul>
																		{test.helper &&
																			test.helper.map(
																				(selper, index) => {
																					return (
																						<Badge
																							key={index}
																							color="success"
																							style={{
																								padding: '5px',
																								margin: '5px',
																							}}
																						>
																							{selper.difficultyLevel}
																						</Badge>
																					);
																				},
																			)}
																	</div>

																	<button
																		className="btn btn-primary btn-block btn-sm"
																		onClick={this.handleTestSubmit.bind(
																			this,
																			test.id,
																		)}
																	>
																		Take Test
																	</button>
																</CardBody>
															</Card>
														</Col>
													);
												}
											})}
									</Row>
								) : null}
							</div>


						)}
					</div>
				)}

				{this.state.showAdvbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Welcome to the
											<span className="account__logo-accent"> test!</span>
										</span>
									</h3>
								</div>

								<div style={{ color: 'red' }}>
									Read these instructions very carefully
								</div>
								<ul>
									<li>
										This test will take {this.state.verifiedTest.duration}{' '}
										minutes time to be completed
									</li>
									<li>You have to complete your test in one sitting</li>
									<li>
										Make sure you are connected to a stable internet connection
									</li>
									<li>
										Every question has an alloted time, if that that time is
										exceeded you will be automatically moved to the next
										Question
									</li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUserAdv}
								>
									Start ADV Test
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						{this.state.closeall ? null : (


							<div>
								{this.state.tests_adv ? (
									<Row>
										{this.state.tests_adv &&
											this.state.tests_adv.map((test, index) => {
												if (test.taken <= 0) {
													return (
														<Col md={4} key={index}>
															<Card>
																<CardBody className="dashboard__health-chart-card">
																	<div className="card__title">
																		<h4 className="bold-text card__title-center">
																			{test.generated_id}
																		</h4>
																		<ul className="list-group list-group-flush">
																			<li className="list-group-item">
																				<b>From: </b>
																				{test.name}
																			</li>
																			<li className="list-group-item">
																				{test.sent_by}
																			</li>
																		</ul>
																		{test.helper &&
																			test.helper.map(
																				(selper, index) => {
																					return (
																						<Badge
																							key={index}
																							color="success"
																							style={{
																								padding: '5px',
																								margin: '5px',
																							}}
																						>
																							{selper.difficultyLevel}
																						</Badge>
																					);
																				},
																			)}
																	</div>

																	<button
																		className="btn btn-primary btn-block btn-sm"
																		onClick={this.handleTestAdvSubmit.bind(
																			this,
																			test.id,
																		)}
																	>
																		Take ADV Test
																	</button>
																</CardBody>
															</Card>
														</Col>
													);
												}
											})}
									</Row>
								) : null}
							</div>

						)}
					</div>
				)}


				{this.state.showCEbox ? (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">
										<span className="account__logo">
											Welcome to the
											<span className="account__logo-accent">
												{' '}
												Custom test!
											</span>
										</span>
									</h3>
								</div>

								<div style={{ color: 'red' }}>
									Read these instructions very carefully
								</div>
								<ul>
									<li>You have to complete your test in one sitting</li>
									<li>
										Make sure you are connected to a stable internet connection
									</li>
									<li>
										Every question has an alloted time, if that that time is
										exceeded you will be automatically moved to the next
										Question
									</li>
								</ul>
								<div className="account__or">
									<p>*</p>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									onClick={this.redirectUser}
								>
									Start Test
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>
						{this.state.closeall ? null : (
							<div>
								{this.state.customtests ? (
									<Row>
										{this.state.customtests &&
											this.state.customtests.map((customtest, index) => {
												if (customtest.taken <= 0) {
													return (
														<Col md={4} key={index}>
															<Card>
																<CardBody className="dashboard__health-chart-card">
																	<div className="card__title">
																		<h4 className="bold-text card__title-center">
																			{customtest.testname}
																		</h4>
																		<ul className="list-group list-group-flush">
																			<li className="list-group-item">
																				<b>From: </b>
																				{customtest.name}
																			</li>
																			<li className="list-group-item">
																				{customtest.sent_by}
																			</li>
																		</ul>
																	</div>

																	<button
																		className="btn btn-primary btn-block btn-sm"
																		onClick={this.handlecustomtestSubmit.bind(
																			this,
																			customtest.id,
																		)}
																	>
																		Take Custom Test
																	</button>
																</CardBody>
															</Card>
														</Col>
													);
												}
											})}
									</Row>
								) : null}

								<Col md={12}>
									<Card>
										<CardBody>
											<div className="card__title">
												<h5 className="bold-text">Completed Test Details</h5>
											</div>
											{this.state.tests ? (
												<Table responsive hover>
													<thead>
														<tr>
															<th>#</th>
															<th>Test ID</th>
															<th>Test Taken</th>
															<th>Technologies</th>
															<th>
																<span style={{ fontSize: '12px' }}>
																	Correct/InCorrect/UnAttempted
																</span>
															</th>
															<th>Result</th>
														</tr>
													</thead>
													<tbody>
														{this.state.tests &&
															this.state.tests.map((test, index) => {
																if (test.taken === 1) {
																	var myresult = test.helper;
																	var allresults = JSON.parse(test.results);
																	// console.log('results here ', allresults);
																	let totalCorrect = 0;
																	let totalIncorrect = 0; // eslint-disable-line
																	let totalUnattempted = 0; // eslint-disable-line
																	let totalquestions = 0;

																	allresults.map((result) => {
																		totalCorrect += result.correct; // eslint-disable-line
																		totalIncorrect += result.incorrect; // eslint-disable-line
																		totalUnattempted += result.unattempted; // eslint-disable-line
																	});
																	myresult.map((resul) => {
																		resul.questions = parseInt(resul.questions);
																		totalquestions += resul.questions;
																	});
																	totalCorrect =
																		(100 / totalquestions) * totalCorrect;
																	return (
																		<tr key={index}>
																			<td>{comptindex++}</td>

																			<td>
																				<h4>{test.generated_id}</h4>
																				<p>
																					From:
																					{test.name}
																					<br />
																					Email: {test.sent_by}
																					<br />
																					Mobile: {test.mobile}
																					<br />
																					{test.domain}
																				</p>
																			</td>

																			<td>
																				{dateFormat(test.taken_at, 'd-m-yyyy')}
																			</td>
																			<td>
																				<h4>
																					{test.helper &&
																						test.helper.map(
																							(selper, index) => {
																								return (
																									<Badge
																										key={index}
																										color="success"
																										style={{
																											padding: '5px',
																											margin: '5px',
																										}}
																									>
																										{selper.difficultyLevel}
																									</Badge>
																								);
																							},
																						)}
																				</h4>
																			</td>
																			<td>
																				<ResultMeter
																					marks={totalCorrect}
																					mmarks={totalquestions}
																				/>
																			</td>
																			<td>
																				<strong
																					style={{ cursor: 'pointer' }}
																					onClick={() => {
																						// eslint-disable-next-line
																						this.props.history.push({
																							pathname: '/testreport',
																							testId: test.id,
																							email: localStorage.by,
																						});
																					}}
																				>
																					View Result
																				</strong>
																			</td>
																		</tr>
																	);
																}
															})}
													</tbody>
												</Table>
											) : null}
										</CardBody>
									</Card>

									{/* Adv completed tests start */}
									<Card>
										<CardBody>
											<div className="card__title">
												<h5 className="bold-text">Completed Level Based Test Details</h5>
											</div>
											{this.state.tests_adv ? (
												<Table responsive hover>
													<thead>
														<tr>
															<th>#</th>
															<th>Test ID</th>
															<th>Test Taken</th>
															<th>Technologies</th>
															<th>
																<span style={{ fontSize: '12px' }}>
																	Correct/InCorrect/UnAttempted
																</span>
															</th>
															<th>Result</th>
														</tr>
													</thead>
													<tbody>
														{this.state.tests_adv &&
															this.state.tests_adv.map((test, index) => {
																if (test.taken === 1) {
																	var myresult = test.helper;
																	var allresults = JSON.parse(test.results);
																	// console.log('results here ', allresults);
																	let totalCorrect = 0;
																	let totalIncorrect = 0; // eslint-disable-line
																	let totalUnattempted = 0; // eslint-disable-line
																	let totalquestions = 0;

																	allresults.map((result) => {
																		totalCorrect += result.correct; // eslint-disable-line
																		totalIncorrect += result.incorrect; // eslint-disable-line
																		totalUnattempted += result.unattempted; // eslint-disable-line
																	});
																	myresult.map((resul) => {
																		resul.questions = parseInt(resul.questions);
																		totalquestions += resul.questions;
																	});
																	totalCorrect =
																		(100 / totalquestions) * totalCorrect;
																	return (
																		<tr key={index}>
																			<td>{comptindex++}</td>

																			<td>
																				<h4>{test.generated_id}</h4>
																				<p>
																					From:
																					{test.name}
																					<br />
																					Email: {test.sent_by}
																					<br />
																					Mobile: {test.mobile}
																					<br />
																					{test.domain}
																				</p>
																			</td>

																			<td>
																				{dateFormat(test.taken_at, 'd-m-yyyy')}
																			</td>
																			<td>
																				<h4>
																					{test.helper &&
																						test.helper.map(
																							(selper, index) => {
																								return (
																									<Badge
																										key={index}
																										color="success"
																										style={{
																											padding: '5px',
																											margin: '5px',
																										}}
																									>
																										{selper.difficultyLevel}
																									</Badge>
																								);
																							},
																						)}
																				</h4>
																			</td>
																			<td>
																				<ResultMeter
																					marks={totalCorrect}
																					mmarks={totalquestions}
																				/>
																			</td>
																			<td>
																				<strong
																					style={{ cursor: 'pointer' }}
																					onClick={() => {
																						// eslint-disable-next-line
																						this.props.history.push({
																							pathname: '/testadvreport',
																							testId: test.id,
																							email: localStorage.by,
																						});
																					}}
																				>
																					View Result
																				</strong>
																			</td>
																		</tr>
																	);
																}
															})}
													</tbody>
												</Table>
											) : null}
										</CardBody>
									</Card>
									{/* Adv completed tests end */}

									<Card>
										<CardBody>
											<div className="card__title">
												<h5 className="bold-text">
													Completed Custom Test Details
												</h5>
											</div>

											<Table responsive hover>
												<thead>
													<tr>
														<th>#</th>
														<th>Test Name</th>
														<th>Test Taken</th>
														<th>
															<span style={{ fontSize: '12px' }}>
																Correct/InCorrect/UnAttempted
															</span>
														</th>
														{/* <th>Result</th> */}
													</tr>
												</thead>
												<tbody>
													{this.state.customtests &&
														this.state.customtests.map((test, index) => {
															if (test.taken === 1) {
																var allresults = JSON.parse(test.results);
																// console.log('results here ', allresults);
																let allcusques = test.question_ids;
																let allcusquescnt = allcusques.split(',');
																let totalCorrect = parseInt(
																	allresults[0].correct,
																	0,
																);
																let totalIncorrect = parseInt(allresults[0].incorrect, 0,); // eslint-disable-line
																let totalUnattempted = parseInt(allresults[0].unattempted, 0,); // eslint-disable-line
																let totalquestions = allcusquescnt.length;

																totalCorrect =
																	(100 / totalquestions) * totalCorrect;
																return (
																	<tr key={index}>
																		<td>{cuscomptest++}</td>

																		<td>
																			<h4>{test.testname} </h4>
																		</td>

																		<td>
																			{dateFormat(test.taken_at, 'd-m-yyyy')}
																		</td>

																		<td>
																			<ResultMeter
																				marks={totalCorrect}
																				mmarks={totalquestions}
																			/>
																		</td>
																		{/* <td>
																		<strong
																			style={{ cursor: 'pointer' }}
																			onClick={() => {
																				localStorage.setItem(
																					'cusreporttestId',
																					test.id,
																				);

																				// eslint-disable-next-line
			this.props.history.push(
																					'/customtestreport',
																				);
																			}}
																		>
																			View Result
																		</strong>
																	</td> */}
																	</tr>
																);
															}
														})}
												</tbody>
											</Table>
										</CardBody>
									</Card>
								</Col>
							</div>
						)}
					</div>
				)}
				;
			</div>
		);
	}
}

export default Dashboard;
