import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { Link, withRouter } from 'react-router-dom';

import { Card, CardBody, Col, Row } from 'reactstrap';
/* global  */
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../Apanel/QueryMe';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../shared/components/Alert';
// import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
const apiurl = MY_API_URL;


// import img1 from '../../shared/img/texpert-img1.jpg';
// import img2 from '../../shared/img/texpert-img2.jpg';
// import img3 from '../../shared/img/texpert-img3.jpg';
const allpubem = [
	'gmail.com',
	'yahoo.com',
	'hotmail.com',
	'aol.com',
	'hotmail.co.uk',
	'hotmail.fr',
	'msn.com',
	'yahoo.fr',
	'wanadoo.fr',
	'orange.fr',
	'comcast.net',
	'yahoo.co.uk',
	'yahoo.com.br',
	'yahoo.co.in',
	'live.com',
	'rediffmail.com',
	'free.fr',
	'gmx.de',
	'web.de',
	'yandex.ru',
	'ymail.com',
	'libero.it',
	'outlook.com',
	'uol.com.br',
	'bol.com.br',
	'mail.ru',
	'cox.net',
	'hotmail.it',
	'sbcglobal.net',
	'sfr.fr',
	'live.fr',
	'verizon.net',
	'live.co.uk',
	'googlemail.com',
	'yahoo.es',
	'ig.com.br',
	'live.nl',
	'bigpond.com',
	'terra.com.br',
	'yahoo.it',
	'neuf.fr',
	'yahoo.de',
	'alice.it',
	'rocketmail.com',
	'att.net',
	'laposte.net',
	'facebook.com',
	'bellsouth.net',
	'yahoo.in',
	'hotmail.es',
	'charter.net',
	'yahoo.ca',
	'yahoo.com.au',
	'rambler.ru',
	'hotmail.de',
	'tiscali.it',
	'shaw.ca',
	'yahoo.co.jp',
	'sky.com',
	'earthlink.net',
	'optonline.net',
	'freenet.de',
	't-online.de',
	'aliceadsl.fr',
	'virgilio.it',
	'home.nl',
	'qq.com',
	'telenet.be',
	'me.com',
	'yahoo.com.ar',
	'tiscali.co.uk',
	'yahoo.com.mx',
	'voila.fr',
	'gmx.net',
	'mail.com',
	'planet.nl',
	'tin.it',
	'live.it',
	'ntlworld.com',
	'arcor.de',
	'yahoo.co.id',
	'frontiernet.net',
	'hetnet.nl',
	'live.com.au',
	'yahoo.com.sg',
	'zonnet.nl',
	'club-internet.fr',
	'juno.com',
	'optusnet.com.au',
	'blueyonder.co.uk',
	'bluewin.ch',
	'skynet.be',
	'sympatico.ca',
	'windstream.net',
	'mac.com',
	'centurytel.net',
	'chello.nl',
	'live.ca',
	'aim.com',
	'bigpond.net.au',
];
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
	email: '',
	otp: '',
	pwd: '',
	testId: '',
	showLinkbox: true,
	showEbox: true,
	showObox: false,
	showCbox: false,
	showRbox: false,
	showMbox: false,
	wantpwd: false,
	loginwithpwd: false,
	showAlert: false,
	alertType: '',
	alertText: '',
	rtype: '',
	domain: '',
	otpurl: '',
	thismacad: '',
	allpublic: allpubem,
	dimgurl: DUM_IMG_URL,
};

class IndexPanel extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.token) {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		} else {
			this.fetchCompanies();
		}
	}

	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		// console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleLadd = () => {
		this.setState({
			showEbox: true,
			showObox: false,
			showCbox: false,
			showRbox: false,
			wantpwd: false,
			loginwithpwd: false,
		});
	};
	handleCadd = () => {
		this.setState({
			showEbox: false,
			showObox: false,
			showCbox: true,
			showRbox: false,
			wantpwd: false,
			loginwithpwd: false,
		});
	};
	handleRadd = () => {
		this.setState(
			{
				showEbox: false,
				showObox: false,
				showCbox: false,
				showRbox: true,
				wantpwd: false,
				loginwithpwd: false,
			},
			() => {
				window.scrollTo(0, 0);
			},
		);
	};
	handleEmailSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
		};
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var postData = {
				email: this.state.email,
			};
			const loginurl = apiurl + 'login';
			axios
				.post(loginurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState({
							showAlert: true,
							alertType: 'success',
							alertText: response.data.message,
							wantpwd: response.data.pwd,
							showEbox: false,
							showObox: true,
							otpurl: 'validateOTP',
						});
					}
				})
				.catch(() => {
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failed Try Again Later!',
					});
				});
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleCompSubmit = (event) => {
		event.preventDefault();

		this.setState({
			rtype: 'company',
		});

		var email = this.state.email;
		var email_string_array = email.split('@');
		var domain_string_location = email_string_array.length - 1;
		var emaildomain = email_string_array[domain_string_location];

		let checkForDomainError = false;

		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var njk = this.state.allpublic.includes(emaildomain);
			if (njk) {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: 'Registration for Public Domain Restricted',
				});
			} else {
				this.state.allCompanies.map((comp) => {
					if (comp.domain === emaildomain) {
						checkForDomainError = true;
					}
				});
				if (checkForDomainError) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: emaildomain + ' Domain Already Registered',
					});
				} else {
					const loginurl = apiurl + 'adduser';
					const headers = {
						'Content-Type': 'application/json',
					};
					var postData = {
						email: this.state.email,
						domain: emaildomain,
						rtype: 'company',
					};
					axios
						.post(loginurl, postData, {
							headers: headers,
						})
						.then((response) => {
							if (response.data.error) {
								this.setState({
									showAlert: true,
									alertType: 'warning',
									alertText: response.data.message,
								});
							} else {
								this.setState({
									showAlert: true,
									alertType: 'success',
									alertText: response.data.message,
									wantpwd: response.data.pwd,
									showEbox: false,
									showRbox: false,
									showCbox: false,
									showObox: true,
									otpurl: 'verifyOTP',
								});
							}
						})
						.catch(() => {
							this.setState({
								alertType: 'danger',
								showAlert: true,
								alertText: 'Failed Try Again Later!',
							});
						});
				}
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleRecSubmit = (event) => {
		event.preventDefault();
		var email = this.state.email;
		var email_string_array = email.split('@');
		var domain_string_location = email_string_array.length - 1;
		var emaildomain = email_string_array[domain_string_location];

		let checkForDomainError = false;

		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			var njk = this.state.allpublic.includes(emaildomain);
			if (njk) {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: 'Registration for Public Domain Restricted',
				});
			} else {
				this.state.allCompanies.map((comp) => {
					if (comp.domain === emaildomain) {
						checkForDomainError = true;
					}
				});
				if (checkForDomainError) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText:
							emaildomain + ' Already Registered Ask them to OnBoard you',
					});
				} else {
					this.setState({
						rtype: 'recruiter',
					});

					const adduserurl = apiurl + 'adduser';
					const headers = {
						'Content-Type': 'application/json',
					};
					var postData = {
						email: this.state.email,
						domain: '',
						rtype: 'recruiter',
					};
					axios
						.post(adduserurl, postData, {
							headers: headers,
						})
						.then((response) => {
							if (response.data.error) {
								this.setState({
									showAlert: true,
									alertType: 'warning',
									alertText: response.data.message,
								});
							} else {
								this.setState({
									showAlert: true,
									alertType: 'success',
									alertText: response.data.message,
									showEbox: false,
									showRbox: false,
									showCbox: false,
									showObox: true,
									otpurl: 'verifyOTP',
								});
							}
						})
						.catch(() => {
							this.setState({
								alertType: 'danger',
								showAlert: true,
								alertText: 'Failed Try Again Later!',
							});
						});
				}
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handleOtpSubmit = (event) => {
		event.preventDefault();
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {
			if (isNaN(this.state.otp)) {
				this.setState({
					showAlert: true,
					alertType: 'danger',
					alertText: 'Enter Valid OTP Only Numbers',
				});
			} else {
				const headers = {
					'Content-Type': 'application/json',
				};
				var postData = {
					otp: this.state.otp,
					email: this.state.email,
				};

				const otpurl = apiurl + this.state.otpurl;

				axios
					.post(otpurl, postData, {
						headers: headers,
					})
					.then((response) => {
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'warning',
								alertText: response.data.message,
							});
						} else {
							console.log(response.data.data);
							if (response.data.data.role == 'company') {
								localStorage.setItem('dashboards', response.data.data.dashboards);
							} else if (response.data.data.role == 'recruiter') {

								if (response.data.data.compcode) {
									localStorage.setItem('compcode', response.data.data.compcode);
								}
								if (response.data.data.usertype == 1) {
									localStorage.setItem('dashbutype', 'rec');
								} else if (response.data.data.usertype == 2) {
									localStorage.setItem('dashbutype', 'man');
								} else if (response.data.data.usertype == 3) {
									localStorage.setItem('dashbutype', 'tl');
								} else if (response.data.data.usertype == 6) {
									localStorage.setItem('dashbutype', 'bdm');
									localStorage.setItem('allleadsts', response.data.allleadsts);
									localStorage.setItem('allclitype', response.data.allclitype);
								}
								localStorage.setItem('dashbid', response.data.data.dashboard);
								var ccode = response.data.data.ccode;
								ccode = ccode.toLowerCase();
								localStorage.setItem('flgccode', ccode);

							}
							this.setState(
								{

									showAlert: false,
									showLinkbox: false,
									showEbox: false,
									showObox: false,
									wantpwd: false,
									loginwithpwd: false,
								},
								() => {
									var role = response.data.data.role;
									var upanel = response.data.upanel;
									localStorage.setItem('usercode', response.data.data.id);
									localStorage.setItem('token', response.data.token);
									localStorage.setItem('roleSelected', role);
									localStorage.setItem('by', response.data.data.email);
									localStorage.setItem('domain', response.data.data.domain);
									if (response.data.data.image_url == 0) {
										response.data.data.image_url = this.state.dimgurl;
									}
									localStorage.setItem(
										'profile_img',
										response.data.data.image_url,
									);
									localStorage.setItem('name', response.data.data.name);
									localStorage.setItem('sufix', response.data.data.sufix);
									localStorage.setItem(
										'mobile',
										response.data.data.mprefix +
										'-' +
										response.data.data.mobile,
									);
									localStorage.setItem('pmobile', response.data.data.mobile);
									localStorage.setItem('upanel', upanel);
									localStorage.setItem(
										'profile_completed',
										response.data.data.profile_completed,
									);
									localStorage.setItem('logo', response.data.data.logo);
									localStorage.setItem(
										'createdon',
										response.data.data.createdon,
									);

									if (role === 'test_taker') {
										localStorage.setItem(
											'tests_taken',
											response.data.data.tests_taken,
										);
									}

									window.location.reload(false);
								},
							);


						}
					})
					.catch((error) => {
						console.log(error);
					});
			}
		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};

	handlePWDSubmit = (event) => {
		event.preventDefault();
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		let isvalidrec = re.test(this.state.email);
		if (isvalidrec) {

			const headers = {
				'Content-Type': 'application/json',
			};
			var postData = {
				pwd: this.state.pwd,
				email: this.state.email,
			};

			const otpurl = apiurl + 'newlogin';

			axios
				.post(otpurl, postData, {
					headers: headers,
				})
				.then((response) => {
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						console.log(response.data.data);
						if (response.data.data.role == 'company') {
							localStorage.setItem('dashboards', response.data.data.dashboards);
						} else if (response.data.data.role == 'recruiter') {
							if (response.data.data.usertype == 1) {
								localStorage.setItem('dashbutype', 'rec');
							} else if (response.data.data.usertype == 2) {
								localStorage.setItem('dashbutype', 'man');
							} else if (response.data.data.usertype == 3) {
								localStorage.setItem('dashbutype', 'tl');
							} else if (response.data.data.usertype == 6) {
								localStorage.setItem('dashbutype', 'bdm');
								localStorage.setItem('allleadsts', response.data.allleadsts);
								localStorage.setItem('allclitype', response.data.allclitype);
							}
							localStorage.setItem('dashbid', response.data.data.dashboard);
							var ccode = response.data.data.ccode;
							ccode = ccode.toLowerCase();
							localStorage.setItem('flgccode', ccode);

						}
						this.setState(
							{

								showAlert: false,
								showLinkbox: false,
								showEbox: false,
								showObox: false,
								wantpwd: false,
								loginwithpwd: false,
							},
							() => {
								var role = response.data.data.role;
								var upanel = response.data.upanel;
								localStorage.setItem('usercode', response.data.data.id);
								localStorage.setItem('linkedin', this.state.linkedin);
								localStorage.setItem('token', response.data.token);
								localStorage.setItem('roleSelected', role);
								localStorage.setItem('by', response.data.data.email);
								localStorage.setItem('domain', response.data.data.domain);
								if (response.data.data.image_url == 0) {
									response.data.data.image_url = this.state.dimgurl;
								}
								localStorage.setItem(
									'profile_img',
									response.data.data.image_url,
								);
								localStorage.setItem('name', response.data.data.name);
								localStorage.setItem('sufix', response.data.data.sufix);
								localStorage.setItem(
									'mobile',
									response.data.data.mprefix +
									'-' +
									response.data.data.mobile,
								);
								localStorage.setItem('pmobile', response.data.data.mobile);
								localStorage.setItem('upanel', upanel);
								localStorage.setItem(
									'profile_completed',
									response.data.data.profile_completed,
								);
								localStorage.setItem('logo', response.data.data.logo);
								localStorage.setItem(
									'createdon',
									response.data.data.createdon,
								);

								if (role === 'test_taker') {
									localStorage.setItem(
										'tests_taken',
										response.data.data.tests_taken,
									);
								}

								window.location.reload(false);
							},
						);


					}
				})
				.catch((error) => {
					console.log(error);
				});

		} else {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Enter Valid Email ID',
			});
		}
	};
	fetchCompanies = () => {
		const headers = {
			'Content-Type': 'application/json',
		};
		var postData = {
			allcomps: '',
		};
		const testidurl = apiurl + 'fetchCompanies';
		axios
			.post(testidurl, postData, {
				headers: headers,
			})
			.then((response) => {
				// console.log([...response.data.data]);
				this.setState({
					allCompanies: [...response.data.data],
				});
			})
			.catch((error) => {
				console.log('Error is ', error);
			});
	};
	showPassword = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	};
	render() {
		const {
			showPassword,
			email,
			otp,
			pwd,

		} = this.state;
		return (
			<div>


				<div
					className="container"
					style={{ paddingTop: '62px', width: '100%', background: '#fff' }}
				>
					<Card>
						<CardBody className="logincardbody">
							<Row className="loginrow">
								<Col md={4}>
									<></>
								</Col>
								<Col md={4}>
									<div className="account onlogin">
										<div className="account__wrapper login__wrapper">
											{this.state.showAlert ? (
												<Alert color={this.state.alertType}>
													<p>{this.state.alertText}</p>
												</Alert>
											) : null}
											{this.state.showEbox ? (
												<div>
													<div className="account__head">
														<h3 className="account__title login__title">
															<span className="account__logo">
																Login to
																<span className="account__logo-accent">
																	{' '}
																	screenhr
																</span>
															</span>
														</h3>
														<h4 className="account__subhead subhead">
															Enter Registered Email ID to Get OTP
														</h4>
													</div>
													<form
														className="form"
														onSubmit={(event) => this.handleEmailSubmit(event)}
													>
														<div className="form__form-group">
															<span className="form__form-group-label">
																Email ID
															</span>
															<div className="form__form-group-field">
																<div className="form__form-group-icon">
																	<AccountOutlineIcon />
																</div>

																<input
																	name="email"
																	type="email"
																	placeholder="Your Email"
																	value={email}
																	onChange={this.handleChange}
																	required
																	onKeyUp={(e) => {
																		e.preventDefault();

																		if (e.keyCode == 13) {
																			this.handleEmailSubmit(event);
																		}
																	}}
																/>
															</div>
														</div>
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="submit"
														>
															Sign in
														</button>
													</form>
													{/* <button
														className="btn btn-outline-primary account__btn account__btn--small"
														onClick={this.handleCadd}
													>
														Register as a Company / Organization
													</button>
													<button
														className="btn btn-outline-primary account__btn account__btn--small"
														onClick={this.handleRadd}
													>
														Register as Independent Recruiter
													</button> */}
												</div>
											) : null}
											{this.state.showObox ? (
												<div>
													<div className="account__head">
														<h3 className="account__title login__title">
															<span className="account__logo">
																Validate
																<span className="account__logo-accent">
																	{' '}
																	OTP
																</span>
															</span>
														</h3>
														<h4 className="account__subhead subhead">
															Enter OTP sent to your Email ID to Verify
														</h4>
													</div>
													<form
														className="form"
														onSubmit={(event) => this.handleOtpSubmit(event)}
													>
														<div className="form__form-group">
															<span className="form__form-group-label">
																Validate OTP
															</span>
															<div className="form__form-group-field">
																<div className="form__form-group-icon">
																	<KeyVariantIcon />
																</div>
																<input
																	name="otp"
																	type={showPassword ? 'text' : 'password'}
																	placeholder="Enter OTP"
																	value={otp}
																	onChange={this.handleChange}
																	required
																	onKeyUp={(e) => {
																		e.preventDefault();

																		if (e.keyCode == 13) {
																			this.handleOtpSubmit(event);
																		}
																	}}
																	pattern="\d{5}"
																/>
																<button
																	className={`form__form-group-button${showPassword ? ' active' : ''
																		}`}
																	onClick={(e) => this.showPassword(e)}
																	type="button"
																>
																	<EyeIcon />
																</button>
															</div>
														</div>
														<button
															className="btn btn-primary account__btn account__btn--small"
															// onClick={this.handleOtpSubmit}
															type="submit"
														>
															Validate OTP
														</button>
														{this.state.wantpwd ? (
															<div className="account__or">
																<p style={{ cursor: 'pointer' }} onClick={() => {
																	this.setState({
																		loginwithpwd: true,
																		showObox: false
																	});
																}}>login with Password Click Here</p>
															</div>
														) : null}

														<div>


														</div>
													</form>
												</div>
											) : null}

											{this.state.loginwithpwd ? (
												<div>
													<div className="account__head">
														<h3 className="account__title login__title">
															<span className="account__logo">
																Validate with
																<span className="account__logo-accent">
																	{' '}
																	Password
																</span>
															</span>
														</h3>
														<h4 className="account__subhead subhead">
															Enter Your Password to Verify
														</h4>
													</div>
													<form
														className="form"
														onSubmit={(event) => this.handlePWDSubmit(event)}
													>
														<div className="form__form-group">
															<span className="form__form-group-label">
																Validate with Password
															</span>
															<div className="form__form-group-field">
																<div className="form__form-group-icon">
																	<KeyVariantIcon />
																</div>
																<input
																	name="pwd"
																	type={showPassword ? 'text' : 'password'}
																	placeholder="Enter Password"
																	value={pwd}
																	onChange={this.handleChange}
																	required
																	pattern=".{8,}"
																	title="Eight or more characters"
																/>
																<button
																	className={`form__form-group-button${showPassword ? ' active' : ''
																		}`}
																	onClick={(e) => this.showPassword(e)}
																	type="button"
																>
																	<EyeIcon />
																</button>
															</div>
														</div>
														<button
															className="btn btn-primary account__btn account__btn--small"
															// onClick={this.handleOtpSubmit}
															type="submit"
														>
															Validate with Password
														</button>
														{this.state.wantpwd ? (
															<div className="account__or">
																<p style={{ cursor: 'pointer' }} onClick={() => {
																	this.setState({
																		loginwithpwd: false,
																		showObox: true,
																	});
																}}>Else login with OTP Click Here</p>
															</div>
														) : null}

														<div>


														</div>
													</form>
												</div>
											) : null}

											{this.state.showCbox ? (
												<div>
													<div className="account__head">
														<h3 className="account__title login__title">
															<span className="account__logo">
																Register as
																<span className="account__logo-accent">
																	{' '}
																	Company / Organization
																</span>
															</span>
														</h3>
														<h4 className="account__subhead subhead">
															Enter Email and Company Domain to Register
														</h4>
													</div>
													<form
														className="form"
														onSubmit={(event) => this.handleCompSubmit(event)}
													>
														<div className="form__form-group">
															<span className="form__form-group-label">
																Email ID
															</span>
															<div className="form__form-group-field">
																<div className="form__form-group-icon">
																	<AccountOutlineIcon />
																</div>

																<input
																	name="email"
																	type="email"
																	placeholder="Your Email"
																	value={email}
																	onChange={this.handleChange}
																	required
																/>
															</div>
														</div>

														<button
															className="btn btn-primary account__btn account__btn--small"
															type="submit"
														>
															Sign up as Company / Organization
														</button>
													</form>
													<div className="account__or">
														<p>Or Back to Sign in</p>
													</div>
													<button
														className="btn btn-outline-primary account__btn account__btn--small"
														onClick={this.handleLadd}
													>
														Sign In
													</button>
												</div>
											) : null}
											{this.state.showRbox ? (
												<div>
													<div className="account__head">
														<h3 className="account__title login__title">
															<span className="account__logo">
																Register as
																<span className="account__logo-accent">
																	{' '}
																	Independent Recruiter
																</span>
															</span>
														</h3>
														<h4 className="account__subhead subhead">
															Enter Email ID to Get OTP
														</h4>
													</div>
													<form
														className="form"
														onSubmit={(event) => this.handleRecSubmit(event)}
													>
														<div className="form__form-group">
															<span className="form__form-group-label">
																Email ID
															</span>
															<div className="form__form-group-field">
																<div className="form__form-group-icon">
																	<AccountOutlineIcon />
																</div>

																<input
																	name="email"
																	type="email"
																	placeholder="Your Email"
																	value={email}
																	onChange={this.handleChange}
																	required
																	onKeyUp={(e) => {
																		e.preventDefault();

																		if (e.keyCode == 13) {
																			this.handleRecSubmit(event);
																		}
																	}}
																/>
															</div>
														</div>
														<button
															className="btn btn-primary account__btn account__btn--small"
															type="submit"
														>
															Sign up as Independent Recruiter
														</button>
													</form>
													<div className="account__or">
														<p>Or Back to Sign in</p>
													</div>
													<button
														className="btn btn-outline-primary account__btn account__btn--small"
														onClick={this.handleLadd}
													>
														Sign In
													</button>
												</div>
											) : null}

											{/* <div>
												<a
													target="_blank" rel="noreferrer"
													href="https://www.youtube.com/embed/O4ed2TxtQGw"
												>
													See demo videos
												</a>
												<Link style={{ float: 'right' }} to="/faqs">
													FAQS
												</Link>
											</div> */}

										</div>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default withRouter(IndexPanel);
