import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../../Apanel/QueryMe';
import {
	Card,
	CardBody,
	Col,
	Badge,
	Table,
} from 'reactstrap';
import ProfileImage from '../../Apanel/ProfileImage';
const apiurl = MY_API_URL;

const initialstate = {
	image: DUM_IMG_URL,
	allusers: [],
};
class AllUsers extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetUsers(localStorage.token);
	}

	handleGetUsers = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
			role: 'all',
		};
		const getuurl = apiurl + 'texperts';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						allusers: [...response.data.data],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		return (
			<Col md={12}>
				<Card>
					<CardBody>
						<div className="card__title">
							<h5 className="bold-text">
								<span style={{ textTransform: 'capitalize' }}>
									{localStorage.roleSelected}
								</span>
								All Users
							</h5>
						</div>
						<Table responsive hover>
							<thead>
								<tr>
									<th>#</th>
									<th></th>
									<th>Manage</th>
									<th>Email</th>
									<th>Type</th>
									<th>Name</th> <th>Mobile</th>
									<th>Registered</th>
								</tr>
							</thead>
							<tbody>
								{this.state.allusers &&
									this.state.allusers.map((huser, index) => {
										return (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>
													<ProfileImage
														clname="topbar__avatar-img"
														flpath={
															huser.image_url
																? huser.image_url
																: this.state.image
														}
													/>
												</td>
												<td>
													<h3>
														<span
															className={`sidebar__link-icon lnr lnr-eye`}
														/>
														<span
															className={`sidebar__link-icon lnr lnr-pencil`}
														/>
														<span
															className={`sidebar__link-icon lnr lnr-trash`}
														/>
													</h3>
												</td>
												<td>{huser.email}</td>
												<td>
													<h4>
														<Badge color="success">{huser.role}</Badge>
													</h4>
												</td>
												<td>{huser.name}</td>
												<td>{huser.mobile}</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</Col>
		);
	}
}

export default AllUsers;
