import React, { Component } from 'react';
/* eslint-disable */

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Doughnut } from 'react-chartjs-2';
var dateFormat = require('dateformat');
var download = require('downloadjs');
const apiurl = MY_API_URL;
import {
	Card,
	CardBody,
	Col,
	Table,
	Button,
	ButtonGroup,
	ButtonToolbar,
	Modal,
	ListGroup,
	ListGroupItem,
	Badge,
	Input,
} from 'reactstrap';
const initialstate = {
	token: localStorage.token,
	results: [],
};
class ComponentToPrint extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'CustomTestReport');
			// eslint-disable-next-line
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			if (localStorage.cusreporttestId) {
				this.handleGetTest(localStorage.cusreporttestId);
			} else {
				// eslint-disable-next-line
				this.props.history.push('/dashboard');
			}
		}
	}
	getQuesName = (testid) => {
		return 'question Name';
	};
	handleGetTest = (testid) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			testid: testid,
		};
		const getfqurl = apiurl + 'getcustomreport';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
				} else {
					this.setState({
						results: response.data.data[0].results,
						newtestname: response.data.data[0].testname,
						customtest: [...response.data.data],
						questions: [...response.data.question_ids],
					});
				}
			})
			.catch((error) => {
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		const { results } = this.state;
		console.log('results ', results);
		return (
			<div>
				{this.state.newtestname ? (
					<Col md={8}>
						<Card>
							<CardBody>
								<h5 className="bold-text" style={{ marginBottom: '20px' }}>
									Test Name: {this.state.newtestname}
								</h5>

								{this.state.questions &&
									this.state.questions.map((ques, index) => {
										let qid = ques.id;
										let myans = results[0][qid];
										let resul = '';
										console.log(myans, ques.correct);
										if (myans == ques.correct) {
											console.log('correct');
											resul = 'col-md-12 reportmycusoptionsuccess';
										} else {
											resul = 'col-md-12 reportmycusoptiondanger';
										}
										return (
											<div className="col-md-12 viewques" key={index}>
												<h5
													className="bold-text"
													style={{
														position: 'relative',
														marginTop: '15px',
														marginBottom: '5px',
													}}
												>
													<span className="viewcusquestionic">Q:</span>
													{ques.question}
												</h5>
												<div className="clearfix"></div>
												{ques.questype == 1 ? (
													<div className="row">
														<div
															className={
																myans == 0
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>
															{ques.options[0]}
														</div>

														<div
															className={
																myans == 1
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>
															{ques.options[1]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																myans == 2
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">C:</span>
															{ques.options[2]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																myans == 3
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">D:</span>
															{ques.options[3]}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 2 ? (
													<div className="row">
														<div
															className={
																myans == 0
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">A:</span>
															{ques.options[0]}
														</div>

														<div className="clearfix"></div>
														<div
															className={
																myans == 1
																	? resul
																	: 'col-md-12 reportmycusoption'
															}
															style={{ position: 'relative' }}
														>
															<span className="cusoptionic">B:</span>
															{ques.options[1]}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
												{ques.questype == 3 ? (
													<div className="row">
														<div className="col-md-12 reportmycusoption">
															{myans}
														</div>

														<div className="clearfix"></div>
													</div>
												) : null}
											</div>
										);
									})}
							</CardBody>
						</Card>
					</Col>
				) : null}
			</div>
		);
	}
}
class CustomTestReport extends Component {
	render() {
		return (
			<div>
				<ReactToPrint content={() => this.componentRef}>
					<PrintContextConsumer>
						{({ handlePrint }) => (
							<button
								className="btn btn-sm btn-success float-right"
								onClick={handlePrint}
							>
								Print this out!
							</button>
						)}
					</PrintContextConsumer>
				</ReactToPrint>
				<ComponentToPrint ref={(el) => (this.componentRef = el)} />
			</div>
		);
	}
}
export default CustomTestReport;
