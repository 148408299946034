import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL, DUM_IMG_URL } from '../QueryMe';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Alert from '../../../shared/components/Alert';
import ProfileImage from '../ProfileImage';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
const apiurl = MY_API_URL;
import { Card, CardBody, Col, Row, Button } from 'reactstrap'; //eslint-disable-line
import swal from 'sweetalert';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
const initialstate = {
	email: localStorage.by,
	name: localStorage.name,
	mobile: localStorage.pmobile,
	image: localStorage.profile_img,
	technologies: localStorage.technologies,
	showAlert: false,
	alertType: '',
	alertText: '',
	profile: null,
	prefix: localStorage.sufix,
	prefixoptions: [],

	secondary_phone: '',
	skype: '',
	current_location: '',
	passport: '',
	ssn: '',
	dob: '',
	work_auth: '',
	employer_info: '',
	relocation: '',
	availability: '',
	linkedin: '',
	resume: '',
	isreqresume: false,
	blockbtn: 1,
	resumeup: false,
};
class ProfileEdit extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };

	componentDidMount() {
		if (localStorage.gotodash === '1') {
			// eslint-disable-next-line
			this.props.history.push({
				pathname: '/profile',
				backTo: '/',
			});
		} else {
			let fsprefix = localStorage.sufix;
			let flprefix = [];
			let fnprefix = '';
			if (fsprefix) {
				flprefix = fsprefix.split('@');
				fnprefix = flprefix[1];
			}

			this.setState({ nprefix: fnprefix });

			this.handleGetkeys();

			if (localStorage.profile_img) {
				this.setState({
					image: localStorage.profile_img,
				});
			} else {
				this.setState({
					image: DUM_IMG_URL,
				});
			}
		}

	}
	handleGetkeys = () => {
		console.log('localStorage.jstatus', localStorage.jstatus);
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			uid: localStorage.usercode,
		};
		const getuurl = apiurl + 'myprofile';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					// console.log('data', response.data);
					let allprefix = response.data.allprefix;
					let prefixoptions = [];

					allprefix.map((prefix) => {
						var flagname = prefix.ccode;
						flagname = flagname.toLowerCase();
						let prefixname =
							prefix.cname + '@' + prefix.prefix + '@' + prefix.id;

						prefixoptions.push({
							value: prefixname,
							label: (
								<div className="counoption">
									<img
										src={'flags/' + flagname + '.svg'}
										width="25px"
										height="15px"
									/>
									{prefix.prefix + ' - ' + prefix.cname}
								</div>
							),
						});
					});
					let isreqresume = false;
					if (response.data.resumed.resume) {
						isreqresume = true;
					}
					this.setState({
						secondary_phone: response.data.profile.secondary_phone,
						skype: response.data.profile.skype,
						current_location: response.data.profile.current_location,
						passport: response.data.profile.passport,
						ssn: response.data.profile.ssn,
						dob: response.data.profile.dob,
						work_auth: response.data.profile.work_auth,
						employer_info: response.data.profile.employer_info,
						relocation: response.data.profile.relocation,
						availability: response.data.profile.availability,
						linkedin: response.data.profile.linkedin,
						resume: response.data.resumed.resume,
						prefixoptions: prefixoptions,
						isreqresume: isreqresume,
					});

				} else {
					// eslint-disable-next-line
					this.props.history.push('/profileedit'); //eslint-disable-line
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/dashboard'); //eslint-disable-line
			});
	};
	maskDate = (sep) => {
		return [/[0-3]/, /\d/, sep, /[0-1]/, /\d/, sep, /\d/, /\d/, /\d/, /\d/];
	};

	autoCorrectedDatePipe = (sep) => {
		const format = `dd${sep}mm${sep}yyyy`;
		return createAutoCorrectedDatePipe(format);
	};
	handleSelectChange = (selectedOption) => {
		let sprefix = selectedOption.value;
		let lprefix = sprefix.split('@');

		let nprefix = lprefix[1];
		this.setState({ prefix: sprefix, nprefix: nprefix });
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleProfileSubmit = (event) => {
		event.preventDefault();
		if (isNaN(this.state.mobile)) {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: 'Invalid Mobile',
			});
		} else if (this.state.relocation == '') {
			swal('Select Relocation', {
				icon: 'warning',
			});
		} else if (this.state.resume == '') {
			swal('Resume Not Uploaded', {
				icon: 'warning',
			});
		} else {

			const headers = {
				'Content-Type': 'application/json',
				Authorization: localStorage.token,
			};

			var postData = {
				email: this.state.email,
				mobile: this.state.mobile,
				name: this.state.name,
				image: this.state.image,
				uid: localStorage.usercode,
				prefix: this.state.prefix,
				resume: this.state.resume,
				secondary_phone: this.state.secondary_phone,
				skype: this.state.skype,
				current_location: this.state.current_location,
				passport: this.state.passport,
				ssn: this.state.ssn,
				dob: this.state.dob,
				work_auth: this.state.work_auth,
				employer_info: this.state.employer_info,
				relocation: this.state.relocation,
				availability: this.state.availability,
				linkedin: this.state.linkedin,
			};
			const pupurl = apiurl + 'updateProfile';
			axios
				.post(pupurl, postData, {
					headers: headers,
				})
				.then((response) => {
					console.log('Response is ', response.data);
					console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
						});
					} else {
						this.setState(
							{
								showAlert: true,
								alertType: 'success',
								alertText: response.data.message,
								showEbox: false,
								showObox: true,
							},
							() => {
								localStorage.setItem('profile_img', this.state.image);
								localStorage.setItem('name', this.state.name);
								localStorage.setItem(
									'mobile',
									this.state.nprefix + '-' + this.state.mobile,
								);

								localStorage.setItem('pmobile', this.state.mobile);
								localStorage.setItem('sufix', this.state.prefix);
								localStorage.setItem('profile_completed', 1);
								localStorage.setItem('gotodash', 1);
								window.location.reload(false);
							},
						);
					}
				})
				.catch((error) => {
					console.log('Error is ', error);
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Request Failed Try again Later!',
					});
				});
		}
	};
	uploadImage = (mfile) => {
		const proimg = mfile.target.files[0];
		console.log(this.state.image);
		// console.log(mfile.target.files[0]);
		// console.log(proimg);
		const data = new FormData();

		data.append('proimg', proimg);
		data.append('email', this.state.email);
		data.append('role', localStorage.roleSelected);
		const proimgurl = apiurl + 'imgupload';
		axios
			.post(proimgurl, data)
			.then((response) => {
				// console.log('Response is ', response.data);
				// console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					const headers = {
						ContentType: proimg.type,
					};
					const imgtopost = response.data.imgtopost;
					const newfile = response.data.fileurl;
					console.log(newfile);
					// console.log(imgtopost);
					// console.log(proimg);
					axios
						.put(imgtopost, proimg, headers)
						.then(() => {

							this.setState(
								{
									showAlert: false,
									image: newfile,
								},
								() => {
									console.log(this.state.image);
								},
							);
						})
						.catch((error) => {
							console.log('Error is ', error.response);
							this.setState({
								alertType: 'danger',
								showAlert: true,
								alertText: 'Failled Try Again Later!',
							});
						});
				}
			})
			.catch(() => {
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failled Try Again Later!',
				});
			});
	};


	uploadfile = (thisone, mfile) => {
		console.log('mfile', mfile);
		console.log('thisone', thisone);
		const thisonefile = mfile.target.files[0];
		this.setState({
			blockbtn: 2,
		}, () => {

			const data = new FormData();
			data.append('resume', thisonefile);
			const resumeurl = 'https://screenhr.com/samtosun.php?r=none&u=' + localStorage.usercode;
			axios.post(resumeurl, data)
				.then((response) => {
					console.log('Response is ', response.data);
					// console.log(response.data.message);
					if (response.data.error) {
						this.setState({
							showAlert: true,
							alertType: 'warning',
							alertText: response.data.message,
							resume: '',
							resumeup: false,
							blockbtn: 2,
						});
					} else {
						this.setState({
							showAlert: false,
							resume: response.data.fileurl,
							resumeup: true,
							blockbtn: 1,
						});
					}
				})
				.catch(() => {
					this.setState({
						alertType: 'danger',
						showAlert: true,
						alertText: 'Failled Try Again Later!',
					});
				});
		});
	};
	handlerelocationChange = secoption => {
		var relocation = secoption.value;
		this.setState({ relocation });
		console.log(`relocation selected:`, relocation);
	};

	render() {

		const { image, email, prefixoptions, prefix, name, mobile, secondary_phone, skype, current_location, passport, ssn, dob, work_auth, employer_info, relocation, availability, linkedin, resume, isreqresume } = this.state; //eslint-disable-line
		const options = [//eslint-disable-line
			{ value: 'Yes', label: 'Yes' },
			{ value: 'No', label: 'No' },
		];

		return (
			<div className="account">
				<div className="account__wrapper acwrapbox">
					<div className="account__card accardbox" style={{ paddingTop: '30px' }}>
						<div className="account__head">
							<h3 className="account__title">Edit Profile</h3>
							<h4 className="account__subhead subhead">
								<Link to="/profile">Back to Profile</Link>
							</h4>
						</div>

						{this.state.showAlert ? (
							<Alert color={this.state.alertType}>
								<p>{this.state.alertText}</p>
							</Alert>
						) : null}

						{/* newwwwwwwwwwww start */}

						<form
							className="form"
							onSubmit={(event) => this.handleProfileSubmit(event)}
						>
							<Row style={{ width: '100%' }}>

								<Col md={6}>
									{/* old fields */}
									<Row style={{ width: '100%' }}>
										<Col md={4}>
											<div className="profile__information" style={{ padding: '5px' }}>
												<div className="profile__avatar" style={{ height: '50px', width: '50px' }}>
													<ProfileImage clname="" flpath={image} />
												</div>
											</div>
										</Col>
										<Col md={8}>
											<div className="form__form-group">
												<span className="form__form-group-label">Profile Image</span>
												<div className="form__form-group-field">
													<input type="file" onChange={this.uploadImage} />
												</div>
											</div>
										</Col>
									</Row>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="email"
												type="email"
												placeholder="Your Email"
												defaultValue={email ? email : ''}
												required
												readOnly
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Your Full Name <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="name"
												type="text"
												placeholder="Your Full Name"
												defaultValue={name ? name : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Primary Contact <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">
											<Select
												name="prefix"
												value={prefixoptions.filter(
													(option) => option.value == prefix,
												)}
												onChange={this.handleSelectChange}
												options={prefixoptions}
												clearable={false}
												placeholder="Country"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>

											<input
												name="mobile"
												type="text"
												placeholder="Your Mobile"
												defaultValue={mobile ? mobile : ''}
												onChange={this.handleChange}
												pattern="^\d{10}$"
												title="Min and Max 10 Digits"
												required
											/>
										</div>
									</div>

									{/* old fields */}

									<div className="form__form-group">
										<span className="form__form-group-label">Secondary Contact <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">
											<input
												name="secondary_phone"
												type="text"
												placeholder="Your Mobile"
												defaultValue={secondary_phone ? secondary_phone : ''}
												onChange={this.handleChange}
												// pattern="^\d{10}$"
												// pattern="\d*"
												// pattern="/^[\+\d]+(?:[\d-.\s()]*)$/mg"
												title="Secondary Contact"

											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">DOB dd-mm-yyyy <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<MaskedInput
												name="dob"
												type="text"
												placeholder="dd-mm-yyyy"
												defaultValue={dob ? dob : ''}
												onChange={this.handleChange}
												mask={this.maskDate('-')}
												pipe={this.autoCorrectedDatePipe('-')}
											/>
										</div>
									</div>






									<div className="form__form-group">
										<span className="form__form-group-label">Skype ID <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">
											<input
												name="skype"
												type="text"
												placeholder="Enter Skype ID"
												defaultValue={skype ? skype : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="linkedin"
												type="text"
												placeholder="Linkedin"
												defaultValue={linkedin ? linkedin : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>






								</Col>
								<Col md={6}>

									<div className="form__form-group">
										<span className="form__form-group-label">Availability <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="availability"
												type="text"
												placeholder="Availability"
												defaultValue={availability ? availability : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Current Location <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="current_location"
												type="text"
												placeholder="Current Location"
												defaultValue={current_location ? current_location : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Relocation <span className='lablestar'>*</span></span>
										<div className="form__form-group-field" style={{ height: '30px' }}>

											<Select
												name="relocation"
												value={options.filter(
													(option) => option.value == relocation,
												)}
												onChange={this.handlerelocationChange}
												options={options}
												clearable={false}
												className="react-select-team"
												classNamePrefix="react-select"
												required
											/>

										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Passport no. <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="passport"
												type="text"
												placeholder="Passport no."
												defaultValue={passport ? passport : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">SSN last 4 digit nos <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="ssn"
												type="text"
												placeholder="SSN last 4 digit nos"
												defaultValue={ssn ? ssn : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>



									<div className="form__form-group">
										<span className="form__form-group-label">Work Authorization <span className='lablestar'>*</span></span>
										<div className="form__form-group-field">

											<input
												name="work_auth"
												type="text"
												placeholder="Work Authorization"
												defaultValue={work_auth ? work_auth : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>

									<div className="form__form-group">
										<span className="form__form-group-label">Employer info if any <span className='lableoptional'>(optional)</span></span>
										<div className="form__form-group-field">

											<input
												name="employer_info"
												type="text"
												placeholder="Employer info if any"
												defaultValue={employer_info ? employer_info : ''}
												onChange={this.handleChange}

											/>
										</div>
									</div>






									{isreqresume ? (
										<div className="form__form-group">
											<span className="form__form-group-label">Resume  <span className='lableoptional'>(optional as it Exist)</span> <span style={{ color: 'green' }}>{this.state.resumeup ? "Updated" : null}</span> </span>
											<div className="form__form-group-field" style={{ height: '30px' }}>
												<input type="file" onChange={this.uploadfile.bind(this, 'resume')} />
											</div>
										</div>
									) : (
										<div className="form__form-group">
											<span className="form__form-group-label">Resume  <span className='lablestar'>*</span> <span style={{ color: 'green' }}>{this.state.resumeup ? "Updated" : null}</span> </span>
											<div className="form__form-group-field" style={{ height: '30px' }}>
												<input type="file" required onChange={this.uploadfile.bind(this, 'resume')} />
											</div>
										</div>
									)}



									{this.state.blockbtn == 2 ? (
										<span
											className="btn btn-primary account__btn account__btn--small"
											desabled="true"
										>
											Update Profile
										</span>
									) : (

										<button
											className="btn btn-primary account__btn account__btn--small"
											type="submit"
										>
											Update Profile
										</button>
									)}
								</Col>
							</Row>

						</form>

						{/* newwwwwwwwwww enddddddddd */}


					</div>
				</div>
			</div >
		);
	}
}

export default withRouter(ProfileEdit);
