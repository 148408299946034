import React, { Component } from 'react';
/* global  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import MngLead from '../../RecruiterPanel/components/clients/MngLead';
const initialstate = {
	showAddLead: false,
};
class RecruiterSidebarContent extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
	};
	constructor(props) {
		super(props);
		this._onButtonClick = this._onButtonClick.bind(this);
	}
	state = { ...initialstate };
	justLogout = () => {
		//localStorage.clear();
		// eslint-disable-next-line
		this.props.history.push('/'); //eslint-disable-line
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};
	_onButtonClick() {
		this.setState({
			showAddLead: true,
		});
	}
	render() {
		return (
			<div className="sidebar__content">
				<ul className="sidebar__block">
					<SidebarLink
						title="Mail"
						icon="list"
						route="/mail"
						onClick={this.hideSidebar}
					/>
					<SidebarLink
						title="Phone Screen"
						icon="list"
						route="/faqtechview"
						onClick={this.hideSidebar}
					/>
					<SidebarCategory title="Tech Tests" icon="layers">
						<SidebarLink
							title="Add Test"
							// icon="list"
							route="/addtest"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Saved Tests"
							// icon="list"
							route="/savetest"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Sent Tests"
							// icon="user"
							route="/senttests"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Completed Tests"
							// icon="list"
							route="/comtest"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
					<SidebarCategory title="Custom Tests" icon="layers">
						<SidebarLink
							title="Add Custom Test"
							// icon="list"
							route="/addcustomtest"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="UnSaved Test List"
							// icon="list"
							route="/mycustoms"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Saved Test List"
							// icon="user"
							route="/customsavedtest"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Sent Test List"
							// icon="list"
							route="/customsenttest"
							onClick={this.hideSidebar}
						/>
						<SidebarLink
							title="Completed Custom Tests"
							// icon="list"
							route="/cuscomtest"
							onClick={this.hideSidebar}
						/>
					</SidebarCategory>
					{/* <SidebarLink
						title="Tickets"
						icon="list"
						route="/mytickets"
						onClick={this.hideSidebar}
					/> */}
					{/* <SidebarLink title="Log Out" icon="exit" onClick={this.justLogout} /> */}
				</ul>

				{localStorage.dashbutype && localStorage.dashbutype == 'man' ? (
					<ul className="sidebar__block">
						<SidebarCategory title="Reports" icon="layers">
							<SidebarLink
								title="Team Users"
								// icon="list"
								route="/teamusers"
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title="Reports"
								// icon="list"
								route="/reportspage"
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title="Weekly Report"
								// icon="list"
								route="/reportseven"
								onClick={this.hideSidebar}
							/>

						</SidebarCategory>
					</ul>
				) : null}
				{localStorage.dashbutype && localStorage.dashbutype == 'tl' ? (
					<ul className="sidebar__block">
						<SidebarCategory title="Reports" icon="layers">
							<SidebarLink
								title="Team Users"
								// icon="list"
								route="/teamusers"
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title="Reports"
								// icon="list"
								route="/reportspage"
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title="Weekly Report"
								// icon="list"
								route="/reportseven"
								onClick={this.hideSidebar}
							/>

						</SidebarCategory>
					</ul>
				) : null}
				{localStorage.dashbutype && localStorage.dashbutype == 'bdm' ? (
					<ul className="sidebar__block">
						<SidebarCategory title="Business Development" icon="layers">
							<SidebarLink
								title="Client List"
								// icon="list"
								route="/clientlist"
								onClick={this.hideSidebar}
							/>
							<SidebarLink
								title="Add Client"
								// icon="list"
								route="/reportspage"
								onClick={this.hideSidebar}
							/>


						</SidebarCategory>
					</ul>
				) : null}
				{this.state.showAddLead ?
					<MngLead /> :
					null
				}
			</div>
		);
	}
}

export default RecruiterSidebarContent;
