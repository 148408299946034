import React, { Component } from 'react';


import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

import axios from 'axios';
import { MY_API_URL } from '../../Apanel/QueryMe';

const apiurl = MY_API_URL;
import { Card, CardBody, Col, Row, Badge, Button } from 'reactstrap';
const initialstate = {
	questats: [],
	token: localStorage.token,
};
class Dashboard extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		localStorage.setItem('gotodash', 0);
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'dashboard');
			// eslint-disable-next-line
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.getQueStats();
			this.handleGetkeys();
			this.handleGetfaqs(localStorage.token);
		}
	}
	getQueStats = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
		};
		const getuurl = apiurl + 'getQueStats';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						questats: [...response.data.data],
					});
				}
			})
			.catch(() => {
				//localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: localStorage.token,
		};
		const getuurl = apiurl + 'adminbelowkeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allkeys = [...response.data.nowkeys];

					let ntechs = [];
					allkeys.map((ques) => {
						if (ques.quescnt < 25) {
							ntechs.push([ques.keyword, ques.quescnt]);
						}
					});
					console.log('ntechs', ntechs);
					this.setState({
						keywords: ntechs,
					});
				}
			})
			.catch(() => { });
	};
	handleGetfaqs = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'adminbelowfaqs';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					const allfaqkeys = [...response.data.nowkeys];

					let fntechs = [];
					allfaqkeys.map((ques) => {
						if (ques.quescnt < 10) {
							fntechs.push([ques.keyword, ques.quescnt]);
						}
					});
					console.log('fntechs', fntechs);
					this.setState({
						faqs: fntechs,
					});
				}
			})
			.catch(() => { });
	};
	render() {
		const multiDataSet = [
			{
				columns: ['Technology', 'Questions Count'],
				data: this.state.keywords,
			},
		];
		const faqmultiDataSet = [
			{
				columns: ['Technology', 'Questions Count'],
				data: this.state.faqs,
			},
		];
		return (
			<Row>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Total</h5>
							</div>
							<p>
								<Badge style={{ fontSize: '15px' }} color="primary">
									{this.state.questats[0]}
								</Badge>
							</p>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Approved</h5>
							</div>
							<p>
								<Badge style={{ fontSize: '15px' }} color="success">
									{this.state.questats[1]}
								</Badge>
							</p>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Pending</h5>
							</div>
							<p>
								<Badge style={{ fontSize: '15px' }} color="warning">
									{this.state.questats[2]}
								</Badge>
							</p>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Technology Questions below 25</h5>
							</div>

							<ExcelFile
								filename={'MCQ_Below_25'}
								element={
									<Button className="btn-sm btn-block" color="success">
										Download Excel
									</Button>
								}
							>
								<ExcelSheet
									dataSet={multiDataSet}
									name="Technology Questions below 25"
								/>
							</ExcelFile>
						</CardBody>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Faq Questions below 10</h5>
							</div>

							<ExcelFile
								filename={'Faqs_Below_10'}
								element={
									<Button className="btn-sm btn-block" color="success">
										Download Excel
									</Button>
								}
							>
								<ExcelSheet
									dataSet={faqmultiDataSet}
									name="Faq Questions below 10"
								/>
							</ExcelFile>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default Dashboard;
