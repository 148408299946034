import React, { Component } from 'react';
/* eslint-disable  */
import axios from 'axios';
import { MY_API_URL } from '../QueryMe';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import Alert from '../../../shared/components/Alert';
import ProfileImage from '../ProfileImage';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
const apiurl = MY_API_URL;
import Resizer from 'react-image-file-resizer';
import SearchIcon from 'mdi-react/SearchIcon';
import {
	Card,
	CardBody,
	Col,
	Row,
	Table,
	Badge,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	selectedTechnologies: [],
	techusedby: [],
	technologyToSearch: '',
	difficultyLevels: [],
	email: localStorage.by,
	name: localStorage.name,
	mobile: localStorage.pmobile,
	image: localStorage.profile_img,
	technologies: localStorage.technologies,
	linkedin: localStorage.linkedin,
	showAlert: false,
	alertType: '',
	alertText: '',
	pwd: '',
	cpwd: '',
	profile: null,
	prefix: localStorage.sufix,
	prefixoptions: [],
	failbtn: false,
};
class AllUserProfileEdit extends Component {
	constructor(props) {
		super(props);
		this.base64Image = this.base64Image.bind(this);
	}
	state = { ...initialstate };

	componentDidMount() {
		if (localStorage.gotodash === '1') {
			// eslint-disable-next-line
			this.props.history.push({
				pathname: '/dashboard',
				backTo: '/',
			});
		} else {
			let fsprefix = localStorage.sufix;
			let flprefix = [];
			let fnprefix = '';
			if (fsprefix) {
				flprefix = fsprefix.split('@');
				fnprefix = flprefix[1];
			}

			this.setState({ nprefix: fnprefix });
			this.handleGetprefix();
			this.handleGetkeys();
		}
	}
	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			userid: localStorage.usercode
		};
		const getuurl = apiurl + 'alltechkeys';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {

				if (!response.data.error) {

					var st = [];
					var dt = [];
					var usr = [];
					if (response.data.mykeys.length >= 1) {
						response.data.mykeys.map((mkey) => {
							st.push(mkey.id);
							dt.push(mkey.keyword);
							usr.push(mkey.users);
						});
					}

					this.setState({
						mykeys: [...response.data.mykeys],
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
						selectedTechnologies: [...st],
						difficultyLevels: [...dt],
						techusedby: [...usr],
					});
				}
			})
			.catch((error) => { //eslint-disable-line
				localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	unselectTechnology = (technology) => {
		console.log('Technology is ', technology);
		console.log('Selected Technologies', this.state.selectedTechnologies);
		console.log('Selected difficultyLevels', this.state.difficultyLevels);
		let index = this.state.selectedTechnologies.indexOf(technology);
		console.log('Index is ', index);
		let copyArray = [...this.state.selectedTechnologies];
		let copyDIfArray = [...this.state.difficultyLevels];
		copyArray.splice(index, 1);
		copyDIfArray.splice(index, 1);
		this.setState(
			{
				selectedTechnologies: [...copyArray],
				difficultyLevels: [...copyDIfArray],
				techwarning: false,
			},
			() => {
				console.log('Selected Technologies', this.state.selectedTechnologies);
				console.log('Selected difficultyLevels', this.state.difficultyLevels);
			},
		);
	};

	setMyTech = (event) => {
		event.preventDefault();
		var mytecharray = this.state.selectedTechnologies;
		this.setState(
			{
				showAlert: false,
			},
			() => {
				if (mytecharray) {
					// var faqtechs = mytecharray.join(',');
					const headers = {
						'Content-Type': 'application/json',
						Authorization: localStorage.token,
					};
					var postData = {
						userid: localStorage.usercode,
						technologies: mytecharray
					};
					const getuurl = apiurl + 'setmytech';
					axios
						.post(getuurl, postData, {
							headers: headers,
						})
						.then((response) => {
							if (response.data.error) {
								this.setState({
									showAlert: true,
									alertType: 'warning',
									alertText: 'Failed Try Again Later!',
								});

							} else {
								this.handleGetkeys();
							}
						})
						.catch((error) => { //eslint-disable-line
							localStorage.clear();
							// eslint-disable-next-line
							this.props.history.push('/'); //eslint-disable-line
						});

				} else {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: 'You need to select at least one technology',
					});
				}
			},
		);
	};
	handleSelectChange = (selectedOption) => {
		let sprefix = selectedOption.value;
		let lprefix = sprefix.split('@');

		let nprefix = lprefix[1];
		this.setState({ prefix: sprefix, nprefix: nprefix });
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleProfileSubmit = (event) => {
		event.preventDefault();
		if (this.state.pwd != this.state.cpwd) {
			this.setState({
				showAlert: true,
				alertType: 'warning',
				alertText: "Password and Confirm Password Not Matched",
			});
		} else {

			if (isNaN(this.state.mobile)) {
				this.setState({
					showAlert: true,
					alertType: 'warning',
					alertText: 'Invalid Mobile',
				});
			} else {
				const headers = {
					'Content-Type': 'application/json',
					Authorization: localStorage.token,
				};
				var postData = {
					email: this.state.email,
					mobile: this.state.mobile,
					name: this.state.name,
					image: this.state.image,
					pwd: this.state.pwd,
					role: localStorage.roleSelected,
					technologies: localStorage.technologies,
					prefix: this.state.prefix,
					linkedin: this.state.linkedin,
				};
				const pupurl = apiurl + 'updateUserProfile';
				axios
					.post(pupurl, postData, {
						headers: headers,
					})
					.then((response) => {
						console.log('Response is ', response.data);
						console.log(response.data.message);
						if (response.data.error) {
							this.setState({
								showAlert: true,
								alertType: 'warning',
								alertText: response.data.message,
							});
						} else {
							this.setState(
								{
									showAlert: true,
									alertType: 'success',
									alertText: response.data.message,
									showEbox: false,
									showObox: true,
								},
								() => {
									localStorage.setItem('profile_img', this.state.image);
									localStorage.setItem('name', this.state.name);
									localStorage.setItem('linkedin', this.state.linkedin);
									localStorage.setItem(
										'mobile',
										this.state.nprefix + '-' + this.state.mobile,
									);

									localStorage.setItem('pmobile', this.state.mobile);
									localStorage.setItem('sufix', this.state.prefix);
									localStorage.setItem('profile_completed', 1);
									localStorage.setItem('gotodash', 1);
									window.location.reload(false);
								},
							);
						}
					})
					.catch((error) => {
						console.log('Error is ', error);
						this.setState({
							alertType: 'danger',
							showAlert: true,
							alertText: 'Request Failed Try again Later!',
						});
					});
			}
		}
	};

	handleGetprefix = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'getprefix';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log('allprefix: ', response.data.allprefix);
				if (!response.data.error) {
					let allprefix = response.data.allprefix;
					let prefixoptions = [];

					allprefix.map((prefix) => {
						var flagname = prefix.ccode;
						flagname = flagname.toLowerCase();
						let prefixname =
							prefix.cname + '@' + prefix.prefix + '@' + prefix.id;

						prefixoptions.push({
							value: prefixname,
							label: (
								<div className="counoption">
									<img
										src={'flags/' + flagname + '.svg'}
										width="25px"
										height="15px"
									/>
									{prefix.prefix + ' - ' + prefix.cname}
								</div>
							),
						});
					});
					this.setState({
						prefixoptions: prefixoptions,
					});
				}
			})
			.catch(() => {
				// //localStorage.clear();
				// // eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	getImageType(giventype) {
		var type = '';

		switch (giventype) {
			case 'image/png':
				type = 'PNG';
				break;
			case 'image/gif':
				type = 'JPEG';
				break;
			case 'image/jpeg':
				type = 'JPEG';
				break;
			case 'image/jpg':
				type = 'JPEG';
				break;
			default:
				type = 'JPEG';
				break;
		}
		return type;
	}
	base64Image(event) {
		var mig = event.target.files[0];
		console.log('gottype', event.target.files[0].type);
		var gottype = this.getImageType(event.target.files[0].type);

		console.log('gottype', gottype);
		this.setState(
			{
				failbtn: true,
			},
			() => {
				console.log(this.state.image);
				var fileInput = false;
				if (mig) {
					fileInput = true;
				}
				if (fileInput) {
					Resizer.imageFileResizer(
						mig,
						300,
						300,
						gottype,
						100,
						0,
						(uri) => {
							console.log(uri);
							const baseimageuri = uri;

							const headers = {
								'Content-Type': 'application/json',
								Authorization: localStorage.token,
							};
							var postData = {
								base64: baseimageuri,
							};

							const base64loadurl = apiurl + 'base64load';
							axios
								.post(base64loadurl, postData, {
									headers: headers,
								})
								.then((response) => {
									console.log('Response is ', response.data);

									if (response.data.error) {
										this.setState({
											showAlert: true,
											alertType: 'warning',
											alertText: response.data.message,
											failbtn: false,
										});
									} else {
										this.setState({
											showAlert: false,
											image: response.data.fileurl,
											failbtn: false,
										});
									}
								})
								.catch(() => {
									this.setState({
										alertType: 'danger',
										showAlert: true,
										alertText: 'Failled Try Again Later!',
									});
								});
						},
						'base64',
					);
				}
			},
		);
	}
	showPassword = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	};
	render() {
		const {
			prefixoptions,
			prefix,
			failbtn,
			pwd,
			cpwd,
			showPassword,
			linkedin
		} = this.state;


		return (
			<>
				{localStorage.profile_completed === '0' ? (
					<Col md={{ size: 8, offset: 2 }}>
						<Card>
							<CardBody>
								<div className="card__title">
									<h3 className="bold-text">
										Edit Profile
										<Link className="float-right" style={{ fontSize: '14px' }} to="/profile">Back to Profile</Link>
									</h3>
									{this.state.showAlert ? (
										<Alert color={this.state.alertType}>
											<p>{this.state.alertText}</p>
										</Alert>
									) : null}
								</div>
								<form
									className="form"
									onSubmit={(event) => this.handleProfileSubmit(event)}
								>
									<Row style={{ width: '100%' }}>
										{/* left side */}
										<Col md={6}>
											{/* profile image */}
											<Row style={{ width: '100%' }}>
												<Col md={4}>
													<div className="profile__information">
														<div className="profile__avatar">
															<ProfileImage clname="userprofileimg" flpath={this.state.image} />
														</div>
													</div>
												</Col>
												<Col md={8}>
													<div className="form__form-group">
														<span className="form__form-group-label">Profile Image</span>
														<div className="form__form-group-field">
															<input type="file" onChange={this.base64Image} />
															{/* <input type="file" onChange={this.uploadImage} /> */}
														</div>
													</div>
												</Col>
											</Row>
											{/* profile image end */}

											<div className="form__form-group">
												<span className="form__form-group-label">Your Full Name</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<AccountOutlineIcon />
													</div>

													<input
														name="name"
														type="text"
														placeholder="Your Full Name"
														defaultValue={this.state.name ? this.state.name : ''}
														onChange={this.handleChange}
														required
													/>
												</div>
											</div>
											<div className="form__form-group">
												<span className="form__form-group-label">Your Mobile</span>
												<div className="form__form-group-field">
													<Select
														name="prefix"
														value={prefixoptions.filter(
															(option) => option.value == prefix,
														)}
														onChange={this.handleSelectChange}
														options={prefixoptions}
														clearable={false}
														placeholder="Country"
														className="react-select"
														classNamePrefix="react-select"
													// defaultMenuIsOpen={true}
													// isDisabled={true}
													/>

													<input
														name="mobile"
														type="text"
														placeholder="Your Mobile"
														defaultValue={this.state.mobile ? this.state.mobile : ''}
														onChange={this.handleChange}
														pattern="^\d{10}$"
														title="Min and Max 10 Digits"
														required
													/>
												</div>
											</div>
											<div className="form__form-group">
												<span className="form__form-group-label">Linkedin</span>
												<div className="form__form-group-field">


													<input
														name="linkedin"
														type="url"
														placeholder="Enter Linkedin"
														className="form-control"
														value={linkedin}
														onChange={this.handleChange}

													/>
												</div>
											</div>
										</Col>
										{/* right side */}
										<Col md={6}>
											<div className="form__form-group">
												<span className="form__form-group-label">Ema il ID</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<AccountOutlineIcon />
													</div>

													<input
														name="email"
														type="email"
														placeholder="Your Email"
														defaultValue={this.state.email ? this.state.email : ''}
														required
														readOnly
													/>
												</div>
											</div>

											<div className="form__form-group">
												<span className="form__form-group-label">Your Password</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="pwd"
														type={showPassword ? 'text' : 'password'}
														placeholder="Your Password"
														value={pwd}
														onChange={this.handleChange}
														pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
														title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
														required
														onKeyUp={(e) => {
															e.preventDefault();
															if (this.state.pwd != this.state.cpwd) {
																this.setState({
																	showAlert: true,
																	alertType: 'warning',
																	alertText: "Password and Confirm Password Not Matched",
																});
															} else {
																this.setState({
																	showAlert: false,
																	alertType: 'success',
																	alertText: "Password and Confirm Password  Matched",
																});
															}
														}}
													/>
													<button
														className={`form__form-group-button${showPassword ? ' active' : ''
															}`}
														onClick={(e) => this.showPassword(e)}
														type="button"
													>
														<EyeIcon />
													</button>
												</div>
											</div>

											<div className="form__form-group">
												<span className="form__form-group-label">Confirm Password</span>
												<div className="form__form-group-field">
													<div className="form__form-group-icon">
														<KeyVariantIcon />
													</div>

													<input
														name="cpwd"
														type={showPassword ? 'text' : 'password'}
														placeholder="Confirm Password"
														value={cpwd}
														onChange={this.handleChange}
														pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
														title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
														required
														onKeyUp={(e) => {
															e.preventDefault();
															if (this.state.pwd != this.state.cpwd) {
																this.setState({
																	showAlert: true,
																	alertType: 'warning',
																	alertText: "Password and Confirm Password Not Matched",
																});
															} else {
																this.setState({
																	showAlert: false,
																	alertType: 'success',
																	alertText: "Password and Confirm Password  Matched",
																});
															}
														}}
													/>
													<button
														className={`form__form-group-button${showPassword ? ' active' : ''
															}`}
														onClick={(e) => this.showPassword(e)}
														type="button"
													>
														<EyeIcon />
													</button>
												</div>
											</div>

											<button
												className="btn btn-primary account__btn account__btn--small"
												// onClick={this.handleProfileSubmit}
												type="submit"
												disabled={failbtn}
											>
												Update Profile
											</button>
										</Col>
									</Row>








								</form>
							</CardBody>
						</Card>
					</Col>
				) : (
					<div className="account">
						<div className="account__wrapper">
							<div className="account__card">
								<div className="account__head">
									<h3 className="account__title">Edit Profile</h3>
									<h4 className="account__subhead subhead">
										<Link to="/profile">Back to Profile</Link>
									</h4>
								</div>

								{this.state.showAlert ? (
									<Alert color={this.state.alertType}>
										<p>{this.state.alertText}</p>
									</Alert>
								) : null}

								<form
									className="form"
									onSubmit={(event) => this.handleProfileSubmit(event)}
								>
									<div className="profile__information">
										<div className="profile__avatar">
											<ProfileImage clname="" flpath={this.state.image} />
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Profile Image</span>
										<div className="form__form-group-field">
											<input type="file" onChange={this.base64Image} />
											{/* <input type="file" onChange={this.uploadImage} /> */}
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Email ID</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="email"
												type="email"
												placeholder="Your Email"
												defaultValue={this.state.email ? this.state.email : ''}
												required
												readOnly
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Your Full Name</span>
										<div className="form__form-group-field">
											<div className="form__form-group-icon">
												<AccountOutlineIcon />
											</div>

											<input
												name="name"
												type="text"
												placeholder="Your Full Name"
												defaultValue={this.state.name ? this.state.name : ''}
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Your Mobile</span>
										<div className="form__form-group-field">
											<Select
												name="prefix"
												value={prefixoptions.filter(
													(option) => option.value == prefix,
												)}
												onChange={this.handleSelectChange}
												options={prefixoptions}
												clearable={false}
												placeholder="Country"
												className="react-select"
												classNamePrefix="react-select"
											// defaultMenuIsOpen={true}
											// isDisabled={true}
											/>

											<input
												name="mobile"
												type="text"
												placeholder="Your Mobile"
												defaultValue={this.state.mobile ? this.state.mobile : ''}
												onChange={this.handleChange}
												pattern="^\d{10}$"
												title="Min and Max 10 Digits"
												required
											/>
										</div>
									</div>
									<div className="form__form-group">
										<span className="form__form-group-label">Linkedin</span>
										<div className="form__form-group-field">


											<input
												name="linkedin"
												type="url"
												placeholder="Enter Linkedin"
												className="form-control"
												value={linkedin}
												onChange={this.handleChange}

											/>
										</div>
									</div>

									<button
										className="btn btn-primary account__btn account__btn--small"
										// onClick={this.handleProfileSubmit}
										type="submit"
										disabled={failbtn}
									>
										Update Profile
									</button>
								</form>
							</div>
						</div>
					</div>
				)}

				<div className="col-md-12">
					<div className="row">
						<div className="col-md-3 order-md-3 order-sm-1 order-12">
							<div className="grid card">
								<div className="card-body">
									<h5 className="bold-text">Technologies Selected  <span className='float-right'>UsedBy</span></h5>


									{/* technologies here */}
									<Table responsive hover>
										<tbody>

											{this.state.selectedTechnologies.map(
												(technology, index) => {
													return (
														<tr key={index}>
															<td
															>
																{this.state.difficultyLevels[index]}

																<i
																	className="lnr lnr-cross"
																	style={{ marginLeft: 10 }}
																	onClick={() => {
																		this.unselectTechnology(technology);
																	}}
																></i>
																<Badge className='float-right' color="success">{this.state.techusedby[index]}</Badge>

															</td>
														</tr>

													);
												},
											)}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
						<div className="col-md-3 order-md-2 order-sm-12 order-1">
							<div className="row">
								<div className="col-md-12">
									<div className="grid card">
										<div className="card-body">
											<h3>{this.state.selectedTechnologies.length}/10</h3>
											{this.state.techwarning ? (
												<span style={{ color: 'red' }}>Cant Add More Than 10 Technologies</span>
											) : null}
										</div>
									</div>
								</div>


								<div className="col-md-12">
									<div className="grid card">
										<div className="card-body">
											<form
												className="form"
												onSubmit={(event) => this.setMyTech(event)}
											>
												{this.state.selectedTechnologies.length > 0 ? (
													<button
														className="btn btn-primary account__btn account__btn--small"
														type="submit"
													>
														Save Technologies
													</button>
												) : (
													<button
														className="btn btn-primary account__btn account__btn--small"
														type="button"
														desabled="true"
													>
														Save Technologies
													</button>
												)}
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-3 order-md-1 order-sm-1 order-12">
							<div className="grid card">
								<div className="card-body">
									<h5 className="bold-text">Technologies</h5>

									<form className="form">
										<div className="form__form-group">
											<div className="form__form-group-field">
												<div className="form__form-group-icon">
													<SearchIcon />
												</div>
												<input
													name="tkey"
													type="text"
													placeholder="Search"
													onChange={(e) => {
														this.handleSearch(e);
													}}
												/>
											</div>
										</div>
									</form>
									{/* technologies here */}
									<Table responsive hover>
										<tbody>
											{this.state.relatedKeywords.map((keyword, index) => {
												if (
													this.state.selectedTechnologies.indexOf(keyword.id) ===
													-1
												) {

													return (
														<tr key={index}>
															<td

																onClick={() => {
																	if (
																		this.state.selectedTechnologies.indexOf(
																			keyword.id,
																		) == -1
																	) {
																		if (this.state.selectedTechnologies.length <= 9) {
																			this.setState(
																				{
																					selectedTechnologies: [
																						...this.state.selectedTechnologies,
																						keyword.id,
																					],
																					techwarning: false,
																				},
																				() => {
																					let previousDifficultyArray = this.state
																						.difficultyLevels;
																					previousDifficultyArray.push(
																						keyword.keyword,
																					);

																					this.setState({
																						difficultyLevels: previousDifficultyArray,
																					});
																				},
																			);
																		} else if (this.state.selectedTechnologies.length >= 10) {
																			this.setState({
																				techwarning: true,
																			});
																		}
																	}
																}
																}

															>
																<strong>{keyword.keyword}</strong>
															</td>
														</tr>
													);
												}
											})}
										</tbody>
									</Table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

		);
	}
}

export default withRouter(AllUserProfileEdit);
