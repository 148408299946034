import React, { Component } from 'react';

import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MY_API_URL, TINYMCEAPIKEY } from '../../../Apanel/QueryMe';

import { Card, CardBody, Col, Table, Row } from 'reactstrap';

const apiurl = MY_API_URL;
const initialstate = {
	tinymceapikey: TINYMCEAPIKEY,
	relatedKeywords: [],
	keywords: [],
	token: localStorage.token,
};
class FaqTechQues extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		// console.log('ok', localStorage.faqtechs);
		if (localStorage.faqtechs) {
			this.handleGetkeys(localStorage.faqtechs);
		} else {
			// eslint-disable-next-line
			this.props.history.push('/dashboard'); //eslint-disable-line
		}
	}

	handleGetkeys = (faqtechs) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			faqtechs: faqtechs,
			email: localStorage.by,
		};
		const getuurl = apiurl + 'fetchFaqTechQues';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					let ftechs = [...response.data.nowkeys];
					this.setState(
						{
							keywords: [...response.data.nowkeys],
							relatedKeywords: [...response.data.nowkeys],
						},
						() => {
							ftechs.map((ftech, index) => {
								if (index == 0) {
									this.setState({ [ftech.key_id]: 1 });
								} else {
									this.setState({ [ftech.key_id]: 0 });
								}
							});
						},
					);
				}
			})
			.catch((error) => { //eslint-disable-line
				// localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	toggle = (tab) => {
		const { activeTab } = this.state;
		if (activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};
	showTechfaqs = (ftech, fidx, e) => { //eslint-disable-line
		let mftechs = this.state.relatedKeywords;

		mftechs.map((mftech, index) => {
			if (index == fidx) {
				this.setState({ [mftech.key_id]: 1 });
			} else {
				this.setState({ [mftech.key_id]: 0 });
			}
		});
	};
	render() {
		const { tinymceapikey } = this.state;

		return (
			<Row style={{ margin: '0px' }}>
				<Col md={3}>
					<Card>
						<CardBody>
							<div className="card__title">
								<h5 className="bold-text">Technologies</h5>
							</div>

							{this.state.relatedKeywords &&
								this.state.relatedKeywords.map((hkey, index) => {
									return (
										<h5
											key={index}
											className="bold-text faqtechlist"
											onClick={this.showTechfaqs.bind(this, hkey.key_id, index)}
										>
											{hkey.keyword}
										</h5>
									);
								})}
						</CardBody>
					</Card>
				</Col>
				<Col md={9}>
					<Card>
						{this.state.relatedKeywords &&
							this.state.relatedKeywords.map((hkey, index) => {
								if (this.state[hkey.key_id] == 1) {
									let allques = hkey.allq;
									return (
										<CardBody style={{ padding: '15px' }} key={index}>
											<div className="card__title">
												<h5
													className="bold-text"
													style={{ fontSize: '30px', padding: '20px' }}
												>
													Questions of {hkey.keyword}{' '}
												</h5>
											</div>

											<Table hover>
												<tbody>
													{allques.length >= 1 ? (
														allques.map((fques, cindex) => {
															return (
																<tr key={cindex}>
																	<td>
																		<div className="faqqueslist">
																			<span className="faqqueslable">
																				{cindex + 1}:
																			</span>
																			<span className="faquestion">
																				<Editor
																					apiKey={tinymceapikey}
																					disabled={true}
																					value={fques.question}
																					init={{
																						inline: true,
																						height: 100,
																						menubar: false,

																						toolbar: '',
																					}}
																				/>
																			</span>
																			<div className="clearfix"></div>
																			<span className="faqqueslable">A:</span>
																			<Editor
																				apiKey={tinymceapikey}
																				disabled={true}
																				value={fques.answer}
																				init={{
																					inline: true,
																					height: 100,
																					menubar: false,

																					toolbar: '',
																				}}
																			/>
																		</div>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td>
																<div className="faqqueslist">
																	<Editor
																		apiKey={tinymceapikey}
																		disabled={true}
																		value="we don't have any FAQ&#39;S on this selected technology and we are working hard in making sure to have question on all technologies please spare us some time and if you need questions on certain technology you can get in touch with our recruiter connect"
																		init={{
																			inline: true,
																			height: 100,
																			menubar: false,

																			toolbar: '',
																		}}
																	/>
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										</CardBody>
									);
								}
							})}
					</Card>
				</Col>
			</Row>
		);
	}
}

export default FaqTechQues;
