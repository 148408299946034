import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL } from '../Apanel/QueryMe';
import { withRouter } from 'react-router-dom';

import RecruiterPanel from '../RecruiterPanel/index';
import TechnicalPanel from '../TechnicalPanel/index';
import AdminPanel from '../AdminPanel/index';
import CreaterPanel from '../CreaterPanel/index';
import ITPanel from '../ITPanel/index';
import AuditorPanel from '../AuditorPanel/index';
import CompanyPanel from '../CompanyPanel/index';
import TestsPanel from '../TestsPanel/index';
const apiurl = MY_API_URL;
const initialstate = {};

class Layout extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		this.handleGetUser(localStorage.token);
	}

	handleGetUser = (token) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			token: token,
		};
		const getuurl = apiurl + 'getuser';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				response;
				console.log('user ok ');
			})
			.catch((error) => {
				console.log('Error is ', error);
				localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	render() {
		var userPanel;
		var upanel = localStorage.upanel;
		if (upanel === 'admin-panel') {
			userPanel = <AdminPanel />;
		} else if (upanel === 'creater-panel') {
			userPanel = <CreaterPanel />;
		} else if (upanel === 'it-panel') {
			userPanel = <ITPanel />;
		} else if (upanel === 'auditor-panel') {
			userPanel = <AuditorPanel />;
		} else if (upanel === 'company-panel') {
			userPanel = <CompanyPanel />;
		} else if (upanel === 'recruiter-panel') {
			userPanel = <RecruiterPanel />;
		} else if (upanel === 'tech-panel') {
			userPanel = <TechnicalPanel />;
		} else if (upanel === 'tests-view') {

			userPanel = <TestsPanel />;
		}
		return <div>{userPanel}</div>;
	}
}

export default withRouter(Layout);
