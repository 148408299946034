import React, { Component } from 'react';

import axios from 'axios';
import { MY_API_URL, TINYMCEAPIKEY } from '../../Apanel/QueryMe';
import { Editor } from '@tinymce/tinymce-react';
import Alert from '../../../shared/components/Alert';
import Select from 'react-select';
import readXlsxFile from 'read-excel-file';
const apiurl = MY_API_URL;
import {
	Table,
	Button,
	Modal,
} from 'reactstrap';
const initialstate = {
	keywords: [],
	relatedKeywords: [],
	showAlert: false,
	alertType: '',
	alertText: '',
	upExcelModal: false,
	upSingleModal: false,
	token: localStorage.token,

	tinymceapikey: TINYMCEAPIKEY,
	technologySelected: '',
	technologySelectedName: '',
	question: '',
	answer: '',
};
class AddFaqs extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };
	componentDidMount() {
		if (localStorage.profile_completed === '0') {
			localStorage.setItem('came_from', 'addquest');
			// eslint-disable-next-line
			this.props.history.push({ pathname: '/profile', backTo: '/' }); //eslint-disable-line
		} else {
			this.handleGetkeys();
		}
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		console.log(name + ' ' + value);
		this.setState({ [name]: value });
	};
	handleAexcel = () => {
		this.setState({
			upExcelModal: true,
			upSingleModal: false,
		});
	};
	handleSingle = () => {
		this.setState({
			upExcelModal: false,
			upSingleModal: true,
		});
	};
	handleCkey = () => {
		this.setState({
			upExcelModal: false,
			upSingleModal: false,
		});
	};

	handleGetkeys = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			status: 1,
		};
		const getuurl = apiurl + 'allkeywords';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						keywords: [...response.data.allkeys],
						relatedKeywords: [...response.data.allkeys],
					});
				}
			})
			.catch(() => {
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};

	fuzzy = (source, target) => {
		var hay = source.keyword.toLowerCase(),
			i = 0,
			n = -1,
			l;

		if (!target) {
			return;
		}

		target = target.toLowerCase();

		for (; (l = target[i++]);) {
			if (!~(n = hay.indexOf(l, n + 1))) {
				return false;
			}
		}

		return true;
	};
	handleSearch = (e) => {
		this.setState(
			{
				technologyToSearch: e.target.value,
				showPopover: true,
			},
			() => {
				if (this.state.technologyToSearch === '') {
					this.setState({
						relatedKeywords: [...this.state.keywords],
					});
				} else {
					let relatedKeywords = this.state.keywords.filter((keyword) => {
						return this.fuzzy(keyword, this.state.technologyToSearch);
					});
					this.setState({
						relatedKeywords: [...relatedKeywords],
					});
				}
			},
		);
	};

	uploadExcelQuestions = () => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addsqurl = apiurl + 'excelFaqs';
		var postData = {
			questions: this.state.excelQuestions,
			email: localStorage.by,
			technology: this.state.technologySelected,
		};
		axios
			.post(addsqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'warning',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							upExcelModal: false,
							upSingleModal: false,
							showAlert: false,
						},
						() => {
							this.fetchQuestions();
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	handleSingleSubmit = (event) => {
		event.preventDefault();

		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};

		const addsqurl = apiurl + 'addFaq';
		var postData = {
			technology: this.state.technologySelected,
			email: localStorage.by,
			question: this.state.question,
			answer: this.state.answer,
		};
		axios
			.post(addsqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				console.log(response.data.message);
				if (response.data.error) {
					this.setState({
						showAlert: true,
						alertType: 'danger',
						alertText: response.data.message,
					});
				} else {
					this.setState(
						{
							upExcelModal: false,
							upSingleModal: false,
							showAlert: false,
							question: '',
							answer: [],
						},
						() => {
							this.fetchQuestions();
						},
					);
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				this.setState({
					alertType: 'danger',
					showAlert: true,
					alertText: 'Failed Try Again Later!',
				});
			});
	};
	fetchQuestions = () => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: localStorage.token,
		};
		var postData = {
			email: localStorage.by,
			technology: this.state.technologySelected,
			limit: 'yes',
		};
		const getfqurl = apiurl + 'newlyaddedFaqs';
		axios
			.post(getfqurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (!response.data.error) {
					this.setState({
						questions: [...response.data.data],
					});
				}
			})
			.catch(() => {
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	handleQuestionChange(question, editor) {
		editor;
		this.setState({ question });
	}
	handleAnswerChange(answer, editor) {
		editor;
		this.setState({ answer });
	}
	render() {
		const { tinymceapikey } = this.state;
		let options =
			this.state.keywords &&
			this.state.keywords.map((keyword) => {
				return { value: keyword.id, label: keyword.keyword };
			});
		// console.log('Options are ', options);
		return (
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-3">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Technologies</h5>
								<Select
									options={options}
									onChange={(selectedOption) => {
										this.setState({
											technologySelected: selectedOption.value,
											technologySelectedName: selectedOption.label,
										});
									}}
								></Select>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="grid card">
							<div className="card-body">
								{this.state.technologySelected ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.handleSingle}
									>
										Add Faqs to <br />
										{this.state.technologySelectedName}
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Add Faqs to <br />
										{this.state.technologySelectedName}
									</Button>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="grid card">
							<div className="card-body">
								{this.state.technologySelected ? (
									<Button
										className="btn-sm btn-block"
										color="success"
										onClick={this.handleAexcel}
									>
										Upload Faqs by CSV(excel) <br />
										{this.state.technologySelectedName}
									</Button>
								) : (
									<Button
										className="btn-sm btn-block"
										color="success"
										disabled
										style={{ cursor: 'not-allowed' }}
									>
										Upload Faqs by CSV(excel) <br />
										{this.state.technologySelectedName}
									</Button>
								)}
							</div>
						</div>
					</div>
					<Modal
						isOpen={this.state.upExcelModal}
						modalClassName="ltr-support"
						className="modal-dialog--primary"
					>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Upload Faq to {this.state.technologySelectedName}
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleUploadSubmit(event)}
							>
								<div className="form__form-group">
									<span className="form__form-group-label">
										Upload Faq by CSV(excel){' '}
									</span>
									<div className="form__form-group">
										<div className="form__form-group-field">
											{this.state.technologySelected ? (
												<input
													type="file"
													id="input"
													onChange={(e) => {
														this.setState({
															excelFile: e.target.files[0],
														});
													}}
												/>
											) : (
												''
											)}
										</div>
									</div>
								</div>
								<button
									className="btn btn-primary account__btn account__btn--small"
									type="button"
									onClick={() => {
										readXlsxFile(this.state.excelFile).then((rows) => {
											let questionsArray = rows.filter((row, index) => {
												if (index > 0) {
													return {
														question: row[0],
														answer: row[1],
													};
												}
											});
											this.setState(
												{
													excelQuestions: [...questionsArray],
												},
												() => {
													this.uploadExcelQuestions();
												},
											);
										});
									}}
								>
									Upload Faqs
								</button>
							</form>
						</div>
					</Modal>

					<Modal
						isOpen={this.state.upSingleModal}
						modalClassName="ltr-support"
						className="modal-dialog--primary modal-dialog-big "
					>
						<div className="modal__header">
							<button
								className="lnr lnr-cross modal__close-btn"
								type="button"
								onClick={this.handleCkey}
							/>
							<h4 className="text-modal  modal__title">
								Add Faq to {this.state.technologySelectedName}
							</h4>
						</div>
						<div className="modal__body">
							{this.state.showAlert ? (
								<Alert color={this.state.alertType}>
									<p>{this.state.alertText}</p>
								</Alert>
							) : null}
							<form
								className="form"
								onSubmit={(event) => this.handleSingleSubmit(event)}
							>
								<div className="form__form-group">
									<span className="form__form-group-label">Question</span>
									<div className="form__form-group-field">
										<Editor
											apiKey={tinymceapikey}
											value=""
											id="question"
											init={{
												inline: true,
												height: 200,
												menubar: false,
												plugins: [
													'advlist autolink lists link image',
													'charmap print preview anchor help',
													'searchreplace visualblocks code',
													'insertdatetime media table paste wordcount',
												],
												toolbar: '',
											}}
											onEditorChange={this.handleQuestionChange.bind(this)}
										/>
									</div>
								</div>
								<div className="form__form-group">
									<span className="form__form-group-label">Answer</span>
									<div className="form__form-group-field">
										<Editor
											apiKey={tinymceapikey}
											value=""
											id="answer"
											init={{
												inline: true,
												height: 200,
												menubar: false,
												plugins: [
													'advlist autolink lists link image',
													'charmap print preview anchor help',
													'searchreplace visualblocks code',
													'insertdatetime media table paste wordcount',
												],
												toolbar: '',
											}}
											onEditorChange={this.handleAnswerChange.bind(this)}
										/>
									</div>
								</div>

								<div className="col-md-12">
									<br />
								</div>
								<button className="btn btn-primary" type="submit">
									Add Faq to {this.state.technologySelectedName}
								</button>
							</form>
						</div>
					</Modal>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="grid card">
							<div className="card-body">
								<h5 className="bold-text">Entered FAQ&apos;S</h5>
								<Table responsive hover>
									<thead>
										<tr>
											<th>#</th>
											<th>Technology</th>
											<th>Status</th>
											<th>Question</th>
										</tr>
									</thead>
									<tbody>
										{this.state.questions &&
											this.state.questions.map((ques, index) => {
												return (
													<tr key={index}>
														<td>{index + 1}</td>

														<td>
															<strong>
																{this.state.technologySelectedName}
															</strong>
														</td>
														<td>
															<strong>{ques.status}</strong>
														</td>
														<td>
															<strong>
																<div
																	dangerouslySetInnerHTML={{
																		__html: ques.question,
																	}}
																/>
															</strong>
														</td>
													</tr>
												);
											})}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AddFaqs;
