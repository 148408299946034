import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'; //eslint-disable-line
import { Card, CardBody, Col, Row } from 'reactstrap';
import { MY_API_URL } from '../Apanel/QueryMe';
import axios from 'axios';
/* global  */

const queryString = require('query-string');
import Topbar from '../Apanel/topbar/IndexTopbar';

const apiurl = MY_API_URL;
const initialstate = {
	sidebarShow: false,
	sidebarCollapse: false,
	jtoken: '',
	jstatus: true,

};

class ApplyToJob extends Component {
	constructor(props) {
		super(props);
	}
	state = { ...initialstate };

	componentDidMount() {
		const value = queryString.parse(this.props.location.search);
		const jtoken = value.token;
		this.handleGetUser(jtoken);
	}
	handleGetUser = (jtoken) => {
		const headers = {
			'Content-Type': 'application/json',
		};
		var postData = {
			jtoken: jtoken,
		};
		const getuurl = apiurl + 'jtoken';
		axios
			.post(getuurl, postData, {
				headers: headers,
			})
			.then((response) => {
				if (response.data.error) {
					this.setState({ jstatus: false });

				} else {
					this.setState({ jstatus: true }, () => {
						console.log('user ok ');

						// eslint-disable-next-line
						this.props.history.push({
							pathname: '/welcome',
							jtoken: jtoken,
							japid: response.data.japid,
						});
					});
				}
			})
			.catch((error) => {
				console.log('Error is ', error);
				localStorage.clear();
				// eslint-disable-next-line
				this.props.history.push('/'); //eslint-disable-line
			});
	};
	changeSidebarVisibility = () => {
		this.setState((prevState) => ({
			sidebarCollapse: !prevState.sidebarCollapse,
		}));
	};

	changeMobileSidebarVisibility = () => {
		this.setState((prevState) => ({ sidebarShow: !prevState.sidebarShow }));
	};


	render() {

		return (
			<div>
				<div className="layout layout--top-navigation">
					<Topbar
						changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
						changeSidebarVisibility={this.changeSidebarVisibility}
					/>
				</div>
				<div
					className="container__wrap"
					style={{ paddingTop: '62px', width: '100%', background: '#fff' }}
				>
					<Card>
						<CardBody className="logincardbody">
							<Row className="loginrow">
								<Col md={6} className="login-dsk">
									<div className="card__title" style={{ textAlign: 'center' }}>
										<h2 className="bold-text">
											{this.state.jstatus ? (
												<span style={{ textAlign: 'center' }}>
													Loading Please Wait .....
												</span>
											) : (
												<span style={{ textAlign: 'center' }}>
													Invalid Link ...
												</span>
											)}
											<br />


										</h2>
									</div>




								</Col>

							</Row>
						</CardBody>
					</Card>
				</div>
			</div>
		);
	}
}

export default withRouter(ApplyToJob);
